"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// 名称：赤峰建筑业协会培训中心
var CFICON = 'https://res.hangxintong.cn/cfpx.ico';
var CFCONFIG = {
  ficon: 'https://res.hangxintong.cn/cfpx.ico',
  title: '赤峰建筑业培训中心',
  host: 'cfpx.hangxintong.cn'
};
var setFavicon = function setFavicon(host) {
  var icon = '';
  if (host === CFCONFIG.host) {
    icon = CFCONFIG.ficon;
  }
  if (!icon) return;
  var link = document.querySelector('link[real*=icon]') || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = icon;
  document.getElementsByTagName('head')[0].appendChild(link);
  document.title = CFCONFIG.title;
};
var _default = exports.default = setFavicon;