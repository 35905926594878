var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            title: "图片剪裁",
            visible: _vm.value,
            "append-to-body": "",
            "element-loading-text": "图片上传中...",
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(0, 0, 0, 0.8)",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.value = $event
            },
          },
        },
        [
          _c("div", { staticClass: "cropper-content" }, [
            _c(
              "div",
              {
                staticClass: "cropper",
                staticStyle: { "text-align": "center" },
              },
              [
                _c("VueCropper", {
                  ref: "cropper",
                  staticStyle: {
                    width: "80%",
                    height: "30rem",
                    margin: "0 auto",
                  },
                  attrs: {
                    img: _vm.img,
                    "output-type": _vm.outputType,
                    info: true,
                    full: _vm.full,
                    "can-move": _vm.canMove,
                    "can-move-box": _vm.canMoveBox,
                    original: _vm.original,
                    "auto-crop": _vm.autoCrop,
                    fixed: _vm.fixed,
                    "fixed-number": _vm.fixedNumber,
                    "center-box": _vm.centerBox,
                    "info-true": _vm.infoTrue,
                    "fixed-box": _vm.fixedBox,
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.finish } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }