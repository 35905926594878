var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "announcementbox",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "titleBg d-flex align-items-center justify-content-between",
        },
        [
          _c("div", { staticClass: "titleTextSy" }, [_vm._v("确认订单信息")]),
          _c(
            "button",
            {
              staticClass: "border backSy",
              on: {
                click: function ($event) {
                  return _vm.backHtml()
                },
              },
            },
            [_vm._v("返回")]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "d-flex flex-column h-100 overflow-auto box",
          staticStyle: { background: "#FFFFFF", height: "100%" },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex-column",
              staticStyle: { flex: "1", padding: "2.93rem 10rem" },
            },
            [
              _c("div", { staticClass: "textSyP" }, [_vm._v(" 商品介绍 ")]),
              _c("div", { staticClass: "textSyC" }, [
                _vm._v(" 商品名称：2020年内蒙二级建造师考试 "),
              ]),
              _c("div", { staticClass: "textSyC border-bottom" }, [
                _vm._v(" 商品编号：123456 "),
              ]),
              _c(
                "div",
                {
                  staticClass: "textSyP",
                  staticStyle: { "margin-top": "1.14rem" },
                },
                [_vm._v(" 考试报名费 ")]
              ),
              _vm._m(0),
              _c(
                "div",
                {
                  staticClass: "textSyC",
                  staticStyle: { "margin-top": "1.14rem" },
                },
                [_vm._v(" 订单编号：E-200221140185572975 ")]
              ),
              _c("div", { staticClass: "textSyC border-bottom" }, [
                _vm._v(" 购买时间：2020-02-21 12:00 "),
              ]),
              _c(
                "div",
                {
                  staticClass: "textSyP",
                  staticStyle: { "margin-top": "1.14rem" },
                },
                [_vm._v(" 支付方式 ")]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "btn-Parent border rounded d-flex align-items-center",
                  on: {
                    click: function ($event) {
                      return _vm.toPay(1)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/img/icon_wx_pay.png"),
                      alt: "",
                    },
                  }),
                  _c("div", [_vm._v(" 微信支付 ")]),
                ]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "btn-Parent border rounded d-flex align-items-center",
                  on: {
                    click: function ($event) {
                      return _vm.toPay(2)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/img/icon_zhf_pay.png"),
                      alt: "",
                    },
                  }),
                  _c("div", [_vm._v(" 支付宝支付 ")]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex priceP align-items-end" }, [
      _c("div", [_vm._v("￥128.00")]),
      _c("div", [_vm._v("/元")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }