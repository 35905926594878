"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'NoticeDetails',
  props: ['id'],
  data: function data() {
    return {
      dna98anomn: ''
    };
  },
  created: function created() {
    this.alasnams(this.id);
  },
  methods: {
    guanbis: function guanbis() {
      this.$emit('back', false);
    },
    alasnams: function alasnams(id) {
      var _this = this;
      (0, _request.default)({
        url: 'zyg/message/webStation/info/' + id,
        methods: 'get'
      }).then(function (res) {
        if (res.code == 200) {
          _this.dna98anomn = res.data;
          _this.notes(res.data.id, res.data.sjrName);
        } else {
          _this.$message.error(res.message);
        }
      });
    },
    notes: function notes(id, name) {
      (0, _request.default)({
        url: 'zyg/message/webStation/' + id,
        method: 'put'
      });
    } // notes(id, name) {
    //   request({
    //     url: 'zyg/member/dues/log/notes/?logId=' + id + '&memberId=' + '' + '&userName=' + name,
    //     method: 'put'
    //   })
    // }
  }
};