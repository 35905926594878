var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "boos" },
    [
      _vm._m(0),
      !_vm.wer43wewe
        ? _c(
            "div",
            { staticClass: "daibose" },
            _vm._l(_vm.content, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "zhutis",
                  on: {
                    click: function ($event) {
                      return _vm.xiangqinags(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "zutione" }, [
                    _c("p", [_vm._v(_vm._s(item.title || "--"))]),
                    _c(
                      "p",
                      { staticStyle: { "margin-left": "16px" } },
                      [
                        item.status == 1
                          ? _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("未读"),
                            ])
                          : _c("el-tag", { attrs: { type: "info" } }, [
                              _vm._v("已读"),
                            ]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "zutitwe" }, [
                    _vm._v(" " + _vm._s(item.text || "--") + " "),
                  ]),
                  _c("div", { staticClass: "jikans" }, [
                    _vm._v(
                      " 通知时间:" + _vm._s(item.createTime || "--") + " "
                    ),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm.wer43wewe
        ? _c("NoticeDetails", {
            attrs: { id: _vm.ids },
            on: { back: _vm.coles },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "certificate-header" }, [
      _c("span", { staticClass: "kaozheng" }, [_vm._v("我的通知")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }