"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.showLoading = exports.hideLoading = void 0;
var _elementUi = require("element-ui");
var loadingCount = 0;
var loading;
var startLoading = function startLoading() {
  loading = _elementUi.Loading.service({
    // lock: true,
    text: '加载中……',
    background: 'rgba(0, 0, 0, 0.7)'
  });
};
var endLoading = function endLoading() {
  loading.close();
};
var showLoading = exports.showLoading = function showLoading() {
  if (loadingCount === 0) {
    startLoading();
  }
  loadingCount += 1;
};
var hideLoading = exports.hideLoading = function hideLoading() {
  if (loadingCount <= 0) {
    return;
  }
  loadingCount -= 1;
  if (loadingCount === 0) {
    endLoading();
  }
};