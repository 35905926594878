var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "teachtwo",
    },
    [
      _vm.list.length != 0
        ? _c(
            "div",
            { staticClass: "teachTwo" },
            [
              _vm.isUpToStand === 1 &&
              (_vm.listname1 !== 0 || _vm.listname !== 0)
                ? _c(
                    "el-alert",
                    {
                      staticClass: "apst",
                      attrs: { title: "", type: "success", closable: false },
                    },
                    [_c("span", [_vm._v("当前课程学时已达标")])]
                  )
                : _vm._e(),
              _vm.effective && _vm.listname !== 0
                ? _c(
                    "el-alert",
                    {
                      staticClass: "apst",
                      attrs: { title: "", type: "error", closable: false },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "以下课程需完成" + _vm._s(_vm.listname) + "课时的学习"
                        ),
                      ]),
                      _vm.listnameRrainIsSup === 1
                        ? _c("span", [_vm._v("，并全程进行视频监学")])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              !_vm.effective
                ? _c(
                    "el-alert",
                    { attrs: { type: "error", closable: false } },
                    [
                      _vm._v(
                        "该课程" +
                          _vm._s(_vm.avalidTime) +
                          "到期，无法继续学习 "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "el-collapse",
                  {
                    key: index,
                    staticClass: "otitle",
                    model: {
                      value: _vm.activeNames,
                      callback: function ($$v) {
                        _vm.activeNames = $$v
                      },
                      expression: "activeNames",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      { attrs: { title: item.courseName, name: index } },
                      [
                        item.teachers && item.teachers.length > 0
                          ? _c(
                              "div",
                              { staticClass: "teacher-container" },
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("讲师："),
                                ]),
                                _vm._l(item.teachers, function (it, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "teacherCard" },
                                    [
                                      it.teacherIcon
                                        ? _c("img", {
                                            attrs: {
                                              src: it.teacherIcon,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            "popper-class": "tooltip-teacher",
                                            disabled:
                                              !it.teacherIntroduce ||
                                              it.teacherIntroduce.length == 0,
                                            effect: "dark",
                                            content: it.teacherIntroduce,
                                            placement: "bottom-start",
                                          },
                                        },
                                        [
                                          _c("span", { staticClass: "name" }, [
                                            _vm._v(_vm._s(it.teacherName)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._l(item.chapters, function (items, index1) {
                          return _c(
                            "div",
                            { key: index1 },
                            [
                              item.chapters.length > 0
                                ? _c(
                                    "h2",
                                    {
                                      staticStyle: {
                                        "font-size": "18px",
                                        "margin-left": "18px",
                                        "margin-top": "1rem",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(items.chapterTitle) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._l(items.section, function (item1, index2) {
                                return _c(
                                  "div",
                                  {
                                    key: index2,
                                    staticStyle: { position: "relative" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "95%",
                                          margin: "0 auto",
                                          display: "flex",
                                          "justify-content": "space-between",
                                          "align-items": "center",
                                          background: "rgba(244, 244, 244, 1)",
                                          "margin-top": "20px",
                                          cursor: "pointer",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-left": "24px",
                                            },
                                          },
                                          [
                                            _c("p", [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-right": "8px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    { staticClass: "iconfont" },
                                                    [_vm._v("")]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(item1.sectionTitle) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-right": "40px",
                                              display: "flex",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-right": "24px",
                                                },
                                              },
                                              [
                                                item1.userStudyLog !== null
                                                  ? [
                                                      _vm.order &&
                                                      item1.userStudyLog &&
                                                      item1.userStudyLog
                                                        .status == 2
                                                        ? _c("span", [
                                                            _vm._v(
                                                              " 学时：(" +
                                                                _vm._s(
                                                                  item1.classHours
                                                                ) +
                                                                "学时) "
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm.order &&
                                                      item1.userStudyLog &&
                                                      item1.userStudyLog
                                                        .status == 1
                                                        ? _c("span", [
                                                            _vm._v(
                                                              " 进度：(" +
                                                                _vm._s(
                                                                  item1.completeRatio
                                                                ) +
                                                                ")"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm.order &&
                                                      item1.userStudyLog &&
                                                      item1.userStudyLog
                                                        .status == 3
                                                        ? _c("span", [
                                                            _vm._v(
                                                              " 学时：存疑 "
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                      _vm.order &&
                                                      item1.userStudyLog &&
                                                      item1.userStudyLog
                                                        .status == 4
                                                        ? _c("span", [
                                                            _vm._v(" 待审核 "),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  : _vm._e(),
                                              ],
                                              2
                                            ),
                                            void 0,
                                            _vm.order &&
                                            (item1.userStudyLog === null
                                              ? false
                                              : item1.userStudyLog &&
                                                (item1.userStudyLog.status ==
                                                  1 ||
                                                  item1.userStudyLog.status ==
                                                    4))
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      color: "#f62727",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.playVideo(
                                                          item1,
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 继续学习 ")]
                                                )
                                              : _vm.order &&
                                                (item1.userStudyLog === null
                                                  ? false
                                                  : item1.userStudyLog &&
                                                    item1.userStudyLog.status ==
                                                      2)
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      color: "#279cf6",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.playVideo(
                                                          item1,
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 重新观看 ")]
                                                )
                                              : _vm.order &&
                                                item1.userStudyLog &&
                                                item1.userStudyLog.status == 3
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      color: "#279cf6",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        if (
                                                          $event.target !==
                                                          $event.currentTarget
                                                        ) {
                                                          return null
                                                        }
                                                        return _vm.reLearning(
                                                          item1
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 重新学习 ")]
                                                )
                                              : _vm.order &&
                                                (item1.userStudyLog === null
                                                  ? true
                                                  : false)
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      color: "#0060ef",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.playVideo(
                                                          item1,
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 播放 ")]
                                                )
                                              : item1.isTryLearn == 0 &&
                                                _vm.order.status != 2
                                              ? _c("p", {
                                                  staticStyle: {
                                                    color: "#f62727",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.cID === "627"
                                                        ? "播放"
                                                        : "付费观看"
                                                    ),
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.playVideo(
                                                        item1,
                                                        item
                                                      )
                                                    },
                                                  },
                                                })
                                              : item1.isTryLearn == 1 &&
                                                _vm.order.status != 2
                                              ? _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      color: "#279cf6",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.playVideo(
                                                          item1,
                                                          item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 试看 ")]
                                                )
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                    !_vm.effective
                                      ? _c("div", {
                                          staticStyle: {
                                            background: "#f4f4f4",
                                            opacity: "0.5",
                                            height: "44px",
                                            width: "95%",
                                            position: "absolute",
                                            top: "0",
                                            left: "2.5%",
                                            cursor: "not-allowed",
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                )
                              }),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.searchTrainList.length > 0
        ? _c(
            "div",
            { staticClass: "teachTwo" },
            [
              _vm.effective && _vm.listname1 !== 0
                ? _c(
                    "el-alert",
                    {
                      staticClass: "apst",
                      attrs: { title: "", type: "error", closable: false },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "以下课程需完成" +
                            _vm._s(_vm.listname1) +
                            "课时的学习"
                        ),
                      ]),
                      _vm.listNameRrainIsSup1 === 1
                        ? _c("span", [_vm._v("，并全程进行视频监学")])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              !_vm.effective
                ? _c(
                    "el-alert",
                    { attrs: { type: "error", closable: false } },
                    [
                      _vm._v(
                        "该课程" + _vm._s(_vm.avalidTime) + "到期，无法继续学习"
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._l(_vm.searchTrainList, function (item, index) {
                return _c(
                  "el-collapse",
                  {
                    key: index,
                    staticClass: "otitle",
                    model: {
                      value: _vm.activeNames1,
                      callback: function ($$v) {
                        _vm.activeNames1 = $$v
                      },
                      expression: "activeNames1",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      { attrs: { title: item.courseName, name: index } },
                      [
                        item.teachers && item.teachers.length > 0
                          ? _c(
                              "div",
                              { staticClass: "teacher-container" },
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("讲师："),
                                ]),
                                _vm._l(item.teachers, function (it, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "teacherCard" },
                                    [
                                      it.teacherIcon
                                        ? _c("img", {
                                            attrs: { src: it.teacherIcon },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            "popper-class": "tooltip-teacher",
                                            disabled:
                                              !it.teacherIntroduce ||
                                              it.teacherIntroduce.length == 0,
                                            effect: "dark",
                                            content: it.teacherIntroduce,
                                            placement: "bottom-start",
                                          },
                                        },
                                        [
                                          _c("span", { staticClass: "name" }, [
                                            _vm._v(_vm._s(it.teacherName)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._l(item.chapters, function (items, index1) {
                          return _c(
                            "div",
                            {
                              key: index1,
                              staticStyle: { position: "relative" },
                            },
                            [
                              item.chapters.length > 0
                                ? _c(
                                    "h2",
                                    {
                                      staticStyle: {
                                        "font-size": "18px",
                                        "margin-left": "18px",
                                        "margin-top": "1rem",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(items.chapterTitle) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._l(items.section, function (item1, index2) {
                                return _c("div", { key: index2 }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "95%",
                                        margin: "0 auto",
                                        display: "flex",
                                        "justify-content": "space-between",
                                        "align-items": "center",
                                        background: "rgba(244, 244, 244, 1)",
                                        "margin-top": "20px",
                                        cursor: "pointer",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-left": "24px",
                                          },
                                        },
                                        [
                                          _c("p", [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-right": "8px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "i",
                                                  { staticClass: "iconfont" },
                                                  [_vm._v("")]
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(item1.sectionTitle) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-right": "40px",
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-right": "24px",
                                              },
                                            },
                                            [
                                              _vm.order &&
                                              item1.userStudyLog &&
                                              item1.userStudyLog.status == 2
                                                ? _c("span", [
                                                    _vm._v(
                                                      " 学时：(" +
                                                        _vm._s(
                                                          item1.classHours
                                                        ) +
                                                        "学时) "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm.order &&
                                              item1.userStudyLog &&
                                              item1.userStudyLog.status == 1
                                                ? _c("span", [
                                                    _vm._v(
                                                      " 进度：(" +
                                                        _vm._s(
                                                          item1.completeRatio
                                                        ) +
                                                        ") "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm.order &&
                                              item1.userStudyLog &&
                                              item1.userStudyLog.status == 3
                                                ? _c("span", [
                                                    _vm._v(" 学时：存疑 "),
                                                  ])
                                                : _vm._e(),
                                              _vm.order &&
                                              item1.userStudyLog &&
                                              item1.userStudyLog.status == 4
                                                ? _c("span", [
                                                    _vm._v(" 待审核 "),
                                                  ])
                                                : _vm._e(),
                                            ]
                                          ),
                                          _vm.order &&
                                          (item1.userStudyLog === null
                                            ? false
                                            : item1.userStudyLog &&
                                              (item1.userStudyLog.status == 1 ||
                                                item1.userStudyLog.status == 4))
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    color: "#f62727",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.playVideo(
                                                        item1,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 继续学习 ")]
                                              )
                                            : _vm.order &&
                                              item1.userStudyLog &&
                                              item1.userStudyLog.status == 2
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    color: "#279cf6",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.playVideo(
                                                        item1,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 重新观看 ")]
                                              )
                                            : _vm.order &&
                                              item1.userStudyLog &&
                                              item1.userStudyLog.status == 3
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    color: "#279cf6",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.reLearning(
                                                        item1
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 重新学习 ")]
                                              )
                                            : _vm.order &&
                                              (item1.userStudyLog === null
                                                ? true
                                                : false)
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    color: "#0060ef",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.playVideo(
                                                        item1,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 播放 ")]
                                              )
                                            : item1.isTryLearn == 0 &&
                                              _vm.order.status != 2
                                            ? _c("p", {
                                                staticStyle: {
                                                  color: "#f62727",
                                                },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.cID === "627"
                                                      ? "播放"
                                                      : "付费观看"
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.playVideo(
                                                      item1,
                                                      item
                                                    )
                                                  },
                                                },
                                              })
                                            : item1.isTryLearn == 1 &&
                                              _vm.order.status != 2
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    color: "#279cf6",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.playVideo(
                                                        item1,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 试看 ")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                  !_vm.effective
                                    ? _c("div", {
                                        staticStyle: {
                                          background: "#f4f4f4",
                                          opacity: "0.5",
                                          height: "44px",
                                          width: "95%",
                                          position: "absolute",
                                          top: "0",
                                          left: "2.5%",
                                          cursor: "not-allowed",
                                        },
                                      })
                                    : _vm._e(),
                                ])
                              }),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.dialogVideoVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "mChangeHeader",
              attrs: {
                visible: _vm.dialogVideoVisible,
                "append-to-body": "",
                "close-on-click-modal": false,
                title: "课件播放",
                width: "75rem",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVideoVisible = $event
                },
                close: _vm.closeVideoWin,
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    left: "20px",
                    top: "17px",
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "1.5rem", height: "1.5rem" },
                    attrs: { src: require("@/assets/img/assoc_dialog.png") },
                  }),
                ]
              ),
              _c(
                "div",
                { staticStyle: { color: "white", "max-height": "36.25rem" } },
                [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c(
                      "div",
                      { staticStyle: { flex: "1", background: "#232323" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "mVidowWrap",
                            staticStyle: { height: "32.3rem" },
                          },
                          [
                            _vm.updateVideoWrap
                              ? _c(
                                  "div",
                                  {
                                    class: {
                                      hidePlayerSpeed:
                                        !_vm.currentPlayItem.isExternal,
                                      showPlayerSpeed:
                                        _vm.currentPlayItem.isExternal,
                                    },
                                  },
                                  [
                                    _c("div", {
                                      ref: "prismPlayer",
                                      attrs: { id: "player-con" },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm.isTryLearn
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "flex-basis": "17.5rem",
                              padding: "2.12rem 1rem",
                              background: "#131313",
                              "max-height": "36.25rem",
                              overflow: "auto",
                            },
                            attrs: { id: "style-3" },
                          },
                          _vm._l(
                            _vm.coursePlayList.chapters,
                            function (itemC, indexC) {
                              return _c("div", { key: indexC }, [
                                _c(
                                  "div",
                                  { staticStyle: { "padding-bottom": "1rem" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.computedIndex(indexC + 1)) +
                                        _vm._s(itemC.chapterTitle) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: { "padding-bottom": "0.6rem" },
                                  },
                                  _vm._l(itemC.section, function (ele, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: i,
                                        staticClass: "singleVideo",
                                        class: { playActive: ele.isPlay },
                                        staticStyle: {
                                          "margin-bottom": "0.6rem",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.mChangePlay(ele)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "iconfont",
                                            staticStyle: {
                                              "margin-right": "0.5rem",
                                              color: "#9cd4ff",
                                            },
                                          },
                                          [_vm._v("  ")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "mFlex",
                                            staticStyle: {
                                              color: "rgba(255, 255, 255, 0.6)",
                                              width: "87%",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "mEllipsis",
                                                staticStyle: {
                                                  width: "60%",
                                                  overflow: "hidden",
                                                  "text-overflow": "ellipsis",
                                                  "white-space": "nowrap",
                                                },
                                                attrs: {
                                                  title: ele.sectionTitle,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(ele.sectionTitle) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c("div", [
                                              _vm._v(
                                                "(" +
                                                  _vm._s(
                                                    (ele.duration / 60).toFixed(
                                                      1
                                                    )
                                                  ) +
                                                  "分钟)"
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading2,
              expression: "loading2",
            },
          ],
          staticClass: "dialog_box",
          attrs: {
            title: "人脸比对窗口",
            visible: _vm.visible,
            "show-close": false,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "680px",
            "element-loading-text": "正在核验中...",
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(0, 0, 0, 0.8)",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "p",
            {
              staticStyle: {
                "font-size": "1.05rem",
                color: "red",
                "line-height": "0.5rem",
              },
            },
            [
              _vm._v(
                " 请调整坐姿确保面部位于现场照片采集中，同时点击点击比对按钮 "
              ),
            ]
          ),
          _c("div", { staticClass: "box" }, [
            _c(
              "p",
              { staticStyle: { "text-align": "center", width: "500px" } },
              [_vm._v("现场照片")]
            ),
            _c("video", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.video_show,
                  expression: "video_show",
                },
              ],
              staticClass: "canvas",
              attrs: {
                id: "videoCamera",
                width: _vm.videoWidth,
                height: _vm.videoHeight,
                autoplay: "",
              },
            }),
            _c("canvas", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.can_show,
                  expression: "can_show",
                },
              ],
              staticClass: "canvas",
              attrs: {
                id: "canvasCamera",
                width: _vm.videoWidth,
                height: _vm.videoHeight,
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "box", staticStyle: { "padding-left": "30px" } },
            [
              _c("p", { staticStyle: { "text-align": "center" } }, [
                _vm._v("留底照片"),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "center",
                    border: "1px solid #ccc",
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "100px" },
                    attrs: {
                      src: _vm.uinfo && _vm.uinfo.bottomPicture,
                      alt: "",
                    },
                  }),
                ]
              ),
            ]
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { display: "block", margin: "0 auto" },
                  attrs: { icon: "el-icon-camera", type: "warning", plain: "" },
                  on: { click: _vm.drawImage },
                },
                [_vm._v("比对并继续学习 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "branch-box",
              attrs: {
                title: _vm.textMap[_vm.dialogStatus],
                visible: _vm.dialogFormVisible,
                "close-on-click-modal": false,
                width: "40%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "20px",
                    left: "26px",
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "25px", height: "25px" },
                    attrs: {
                      src: require("../../assets/img/assoc_dialog.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c(
                "el-tabs",
                { staticClass: "table-box" },
                [
                  _c(
                    "el-form",
                    {
                      staticStyle: { margin: "0 30px" },
                      attrs: {
                        model: _vm.temp,
                        "label-position": "left",
                        "label-width": "140px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "实操描述：" } },
                        [
                          _c("el-input", {
                            staticClass: "textClassHt",
                            staticStyle: { height: "160px" },
                            attrs: {
                              type: "textarea",
                              rows: 2,
                              placeholder: "请输入内容",
                            },
                            model: {
                              value: _vm.temp.textarea,
                              callback: function ($$v) {
                                _vm.$set(_vm.temp, "textarea", $$v)
                              },
                              expression: "temp.textarea",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _vm._v(
                                "最多上传" +
                                  _vm._s(_vm.handUploadEnd) +
                                  "张图片"
                              ),
                            ]
                          ),
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                action: "",
                                "list-type": "picture-card",
                                "on-remove": _vm.handleRemove,
                                "http-request": _vm.handSuccess,
                                "on-preview": _vm.handlePictureCardPreview,
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-plus",
                                attrs: { slot: "default" },
                                slot: "default",
                              }),
                            ]
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: { visible: _vm.dialogVisible },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.dialogVisible = $event
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  width: "100%",
                                  src: _vm.dialogImageUrl,
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.updateDatalist } }, [
                    _vm._v("存草稿"),
                  ]),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogStatus === "create"
                            ? _vm.createData()
                            : _vm.updateData()
                        },
                      },
                    },
                    [_vm._v("提交 ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible,
            width: "30%",
            "close-on-press-escape": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.resmageList))]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisiblejinshan,
            width: "30%",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisiblejinshan = $event
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.messagejin))]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.qudings } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.realNameDialog,
            width: "30%",
            close: (_vm.dialogVisible = false),
          },
          on: {
            "update:visible": function ($event) {
              _vm.realNameDialog = $event
            },
          },
        },
        [
          _vm.uinfo
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  staticStyle: { "font-size": "16px", color: "#333" },
                },
                [
                  _c("h5", { staticStyle: { "margin-bottom": "20px" } }, [
                    _vm._v("确认实名认证信息"),
                  ]),
                  _c("p", [_vm._v("姓名：" + _vm._s(_vm.uinfo.realname))]),
                  _c("p", [_vm._v("身份证号：" + _vm._s(_vm.uinfo.identity))]),
                  _c(
                    "footer",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end",
                        "margin-top": "50px",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: {
                            color: "#409eff",
                            "border-color": "#409eff",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push(
                                "/personalCenter/personalInfo"
                              )
                            },
                          },
                        },
                        [_vm._v("重新认证 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitConformationRealName },
                        },
                        [_vm._v("确认无误 ")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }