"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAssocBanner = getAssocBanner;
exports.getAssocFooter = getAssocFooter;
exports.getAssocId = getAssocId;
exports.getAssocLogo = getAssocLogo;
exports.getAssocToken = getAssocToken;
exports.getToken = getToken;
exports.getUserInfo = getUserInfo;
exports.removeToken = removeToken;
exports.removeUserInfo = removeUserInfo;
exports.setAssocBanner = setAssocBanner;
exports.setAssocFooter = setAssocFooter;
exports.setAssocId = setAssocId;
exports.setAssocLogo = setAssocLogo;
exports.setAssocToken = setAssocToken;
exports.setToken = setToken;
exports.setUserInfo = setUserInfo;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var TokenKey = 'hangxintong_train_student';
var UserKey = 'hangxintong_user';
var AssocTokenKey = 'hangxintong_train_assoc';
var AssocIdKey = 'hangxintong_assoc_id';
var AssocLogoKey = 'hangxintong_assoc_logo';
var AssocFooter = 'hangxintong_assoc_footer';
var AssocBannerKey = 'hangxintong_assoc_banner';
function getUserInfo() {
  // return Cookies.get(TokenKey)
  // console.warn(UserKey)
  return sessionStorage.getItem(UserKey);
}
function setUserInfo(userInfo) {
  // return Cookies.get(TokenKey)
  return sessionStorage.setItem(UserKey, {
    id: userInfo.id,
    name: userInfo.username
  });
}
function removeUserInfo() {
  // return Cookies.remove(TokenKey)
  return sessionStorage.removeItem(UserKey);
}
function getToken() {
  // return Cookies.get(TokenKey)
  return getAssocToken();
}
function setToken(token) {
  // return Cookies.set(TokenKey, token)
  return setAssocToken(token);
}
function removeToken() {
  // return Cookies.remove(TokenKey)
  return sessionStorage.removeItem(TokenKey);
}
function getAssocToken() {
  // return Cookies.get(AssocTokenKey)
  return sessionStorage.getItem(TokenKey);
}
function setAssocToken(token) {
  return sessionStorage.setItem(TokenKey, token);
  // return localStorage.setItem(TokenKey, token)
  // return localStorage.setItem(TokenKey, token)
  // return Cookies.set(AssocTokenKey, token)
}
function getAssocId() {
  return _jsCookie.default.get(AssocIdKey);
}
function setAssocId(id) {
  return _jsCookie.default.set(AssocIdKey, id);
}
function getAssocLogo() {
  return _jsCookie.default.get(AssocLogoKey);
}
function setAssocLogo(assocLogo) {
  return _jsCookie.default.set(AssocLogoKey, assocLogo);
}
function getAssocFooter() {
  return _jsCookie.default.get(AssocFooter);
}
function setAssocFooter(footer) {
  return _jsCookie.default.set(AssocFooter, footer);
}
function getAssocBanner() {
  return _jsCookie.default.get(AssocBannerKey);
}
function setAssocBanner(banner) {
  return _jsCookie.default.set(AssocBannerKey, banner);
}