"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _examination = require("../../api/examination.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      id: '',
      loading: true,
      data: ''
    };
  },
  methods: {
    backHtml: function backHtml() {
      window.history.back(-1);
    },
    toPay: function toPay(val) {
      if (val == 1) {
        this.showMsg(2, '暂未开放');
      } else if (val == 2) {
        window.location.href = "http://apialipay.dev.hangxintong.cn/alipay/order/confrim?oid=" + this.data.id;
      }
    },
    /*获取全部考试列表(无需登录)*/initData: function initData() {
      var that = this;
      var dataBody = {
        "examPlanId": this.id
      };
      (0, _examination.buyExam)(dataBody).then(function (response) {
        console.log(response);
        that.loading = false;
        if (response.code == 200) {
          //数据获取成功
          that.data = response.data;
        } else {
          that.showMsg(2, response.message);
        }
      });
    },
    //提示信息
    showMsg: function showMsg(type, msg) {
      if (type == 1) {
        this.$notify({
          title: "提示",
          message: msg,
          type: "success"
        });
      } else {
        this.$notify({
          title: "提示",
          message: msg,
          type: "error"
        });
      }
    }
  },
  created: function created() {
    this.id = this.$route.params.id;
    this.initData();
  },
  watch: {}
};