"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.asyncRouter = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.array.includes.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _theme_second_layout = _interopRequireDefault(require("@/layout/theme_second_layout"));
// 根据域名导出对应的首页 和2级页的路由
// 默认layout
// 皮肤2-->layout

// 皮肤2的router
var theme_second_router = [{
  // 首页
  path: '/',
  component: _theme_second_layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/theme_second.vue'));
      });
    },
    meta: {
      title: '',
      icon: 'dashboard'
    }
  }]
}, {
  //新闻列表
  path: "/news",
  name: "news",
  component: _theme_second_layout.default,
  redirect: "/newsList",
  children: [{
    name: "newsList",
    path: "newsList",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/news/index.vue'));
      });
    },
    meta: {
      title: "",
      icon: "dashboard"
    }
  }, {
    name: "newsDetail",
    path: "newsDetail/:id",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/news/newsDetail/index.vue'));
      });
    },
    meta: {
      title: "",
      icon: "dashboard"
    }
  }, {
    name: "newsSearch",
    path: "newsSearch",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/newsSearch/index.vue'));
      });
    },
    meta: {
      title: "",
      icon: "dashboard"
    }
  }]
}, {
  // 全部课程
  path: '/',
  component: _theme_second_layout.default,
  children: [{
    path: 'allcourses',
    name: 'allcoursespage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/allcourses/allcourseThemeSecond'));
      });
    },
    meta: {
      title: '全部课程',
      icon: 'allcourses'
    }
  }]
}];
// 默认router
var default_router = [{
  // 首页
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    meta: {
      title: '',
      icon: 'dashboard'
    }
  }]
}, {
  // 全部课程
  path: '/',
  component: _layout.default,
  children: [{
    path: 'allcourses',
    name: 'allcoursespage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/allcourses/newAllcoursespage'));
      });
    },
    meta: {
      title: '全部课程',
      icon: 'allcourses'
    }
  }]
}];
// 内建协router
var njx_router = [{
  // 首页
  path: '/',
  component: _layout.default,
  redirect: '/allcourses',
  children: [{
    path: 'allcourses',
    name: 'allcoursespage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/allcourses/newAllcoursespage'));
      });
    },
    meta: {
      title: '全部课程',
      icon: 'allcourses'
    }
  }]
}, {
  // 首页
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    name: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/allcourses/newAllcoursespage'));
      });
    },
    meta: {
      title: '',
      icon: 'dashboard'
    }
  }]
}, {
  // 全部课程
  path: '/',
  component: _layout.default,
  children: [{
    path: 'allcourses',
    name: 'allcoursespage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/allcourses/newAllcoursespage'));
      });
    },
    meta: {
      title: '全部课程',
      icon: 'allcourses'
    }
  }]
}];
// 135白名单
var njx_whitelist = ['njxpx.hangxintong.cn', '135pxdev.hangxintong.cn', 'localhost:9528'];
var initRouter = function initRouter() {
  var host = location.host;
  //使用皮肤2的协会host
  var theme_second_List = ['cfpx.hangxintong.cn', '147px.dev.hangxintong.cn', '147pxdev.hangxintong.cn']; //'cfpx.hangxintong.cn'
  //flag 是否存在于皮肤2的数组中
  var flag = theme_second_List.includes(host);
  //确定使用皮肤1还是皮肤2
  var Layout = flag ? _theme_second_layout.default : _layout.default;
  //确定使用皮肤1的路由，还是皮肤2的路由
  var defaultCustomRouter = flag ? theme_second_router : default_router;
  if (njx_whitelist.includes(host)) {
    // 使用内建协的路由
    defaultCustomRouter = njx_router;
  }
  return {
    Layout: Layout,
    defaultCustomRouter: defaultCustomRouter
  };
};
//导出路由和布局
var asyncRouter = exports.asyncRouter = initRouter();