"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es.object.freeze.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.string.link.js");
var _homeinterface = require("@/api/homeinterface");
var _classes = require("@/api/classes");
var _auth = require("@/utils/auth");
var _vuex = require("vuex");
var _verified = _interopRequireDefault(require("@/icons/verified.png"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Allcoursespage',
  data: function data() {
    return {
      recentTrainingList: [],
      // 最近学习课程
      btns: Object.freeze([{
        id: 1,
        name: '我的学习',
        url: '/personalCenter/personalStudy'
      },
      // { id: 2, name: '我的考试', url: '' },
      {
        id: 3,
        name: '我的证书',
        url: '/personalCenter/certificate'
      }, {
        id: 4,
        name: '个人中心',
        url: '/personalCenter/personalInfo'
      }]),
      verifiedUrl: _verified.default,
      // 已经认证图片地址
      zhanwusuju: '',
      index: 0,
      popularcourses: [],
      //培训课程列表
      classtificateList: [],
      //分类列表
      assocId: (0, _auth.getAssocId)(),
      type: 2,
      num: 0,
      chuanguolai: this.$route.query.neirong,
      //
      searchListOption: {
        page: 1,
        size: 12,
        keyword: '',
        classId: this.$route.query.id || ''
      },
      isActiveId: this.$route.query.id || null,
      //渲染当前分类选中样式
      total: 0
    };
  },
  watch: {
    //此处监听两个值，会导致query用于两个值时，首次变化时请求两次接口，再次变化无影响
    '$route.query.neirong': function $routeQueryNeirong(val) {
      this.chuanguolai = val;
      this.popularcoursesfun();
    },
    //此处监听id，配合搜索框实现无分类id搜索全局
    '$route.query.id': function $routeQueryId(val) {
      this.searchListOption.classId = val;
      this.isActiveId = val;
      this.popularcoursesfun();
    }
    // '$route.hash': {
    //   handler: (val) => {
    //     console.log("hash", val)
    //     if (val) {
    //       const id = val.slice(1)
    //       console.log('hashId', id, document.getElementById(id))
    //       document.getElementById(id)?.scrollIntoView()
    //     }
    //   },
    //   immediate: true
    // }
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['avatar', 'name', 'token'])), (0, _vuex.mapState)(['app'])),
  created: function created() {
    var _this$app$assocInfo,
      _this = this;
    if (!((_this$app$assocInfo = this.app.assocInfo) !== null && _this$app$assocInfo !== void 0 && _this$app$assocInfo.assocToken)) {
      this.$store.dispatch('app/domain').then(function () {
        _this.$store.dispatch('app/getAssocInfo');
        var mySelf = _this;
        eventBus.$on('xxxx', function (data) {
          mySelf.chuanguolai = data;
          mySelf.popularcoursesfun();
        });
        _this.popularcoursesfun();
        _this.getClasstifityList();
      }).catch(function (err) {
        console.error(err);
      });
    } else {
      var mySelf = this;
      eventBus.$on('xxxx', function (data) {
        mySelf.chuanguolai = data;
        mySelf.popularcoursesfun();
      });
      this.popularcoursesfun();
      this.getClasstifityList();
    }
    if (this.avatar && this.name) {
      this.getRecentTrainingData();
    }
  },
  methods: {
    // 获取学员最近学习的3门课程
    getRecentTrainingData: function getRecentTrainingData() {
      var _this2 = this;
      (0, _classes.getRecentTraining)().then(function (res) {
        if (res.code === 200) {
          _this2.recentTrainingList = res.data || [];
        }
      });
    },
    //根据id到指定位置
    link: function link() {
      var _this3 = this;
      this.$nextTick(function () {
        console.log(_this3.$route.query.id);
        var target = document.getElementById("".concat(_this3.$route.query.id));
        var parent = document.getElementById('scroll');
        console.log(target.offsetTop, parent.offsetTop, '222');
        parent.scrollTo(0, target.offsetTop - parent.offsetTop - 200);
      });
    },
    // 全部项目-一级菜单
    //分类点击
    //在router中传入id，配合watch监听(不传入id时会因为watch导致classid为空)
    classifyClick: function classifyClick(id, index) {
      this.searchListOption.keyword = '';
      this.chuanguolai = '';
      this.searchListOption.page = 1;
      this.$router.push({
        path: 'allcourses',
        query: {
          id: id
        }
      });
    },
    //分类点击全部考试
    getAllExmian: function getAllExmian() {
      this.searchListOption.keyword = '';
      this.chuanguolai = '';
      this.searchListOption.page = 1;
      this.$router.push({
        path: 'allcourses',
        query: {
          id: ''
        }
      });
    },
    pagingSizeChange: function pagingSizeChange(val) {
      // this.$router.push('/allExamination/' + val)
      this.searchListOption.page = val;
      this.popularcoursesfun();
    },
    teachinfoClick: function teachinfoClick(id) {
      this.$router.push({
        path: '/teachinfo/' + id
      });
    },
    // 分类
    getClasstifityList: function getClasstifityList() {
      var _this4 = this;
      (0, _homeinterface.newAllClasstifityList)({
        type: 2
      }).then(function (res) {
        var data = res.data;
        data.forEach(function (item) {
          if (item.show) {
            item.isShowContent = item.classId == _this4.$route.query.id ? true : item.classId == _this4.$route.query.parentId ? true : false;
            _this4.classtificateList.push(item);
          }
        });
        if (_this4.$route.query.id) {
          _this4.link();
        }
      });
    },
    // 课程列表(js)
    popularcoursesfun: function popularcoursesfun() {
      var _this5 = this;
      this.searchListOption.keyword = this.chuanguolai;
      this.popularcourses = [];
      (0, _homeinterface.newAllcourseList)(this.searchListOption).then(function (res) {
        if (res.code == 200) {
          _this5.popularcourses = res.data.list;
          _this5.total = res.data.total;
        } else {
          _this5.$message.error(res.message);
        }
      });
    },
    getCertificateShow: function getCertificateShow(item, index) {
      console.log(item, index, 'neirong');
      if (item.isShowContent) {
        item.isShowContent = false;
        this.$set(this.classtificateList, index, (0, _objectSpread2.default)({}, item));
      } else {
        item.isShowContent = true;
        this.$set(this.classtificateList, index, (0, _objectSpread2.default)({}, item));
      }
    }
  }
};