var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "orderdetailsbox" }, [
      _c("div", { staticClass: "orderdetailsone" }, [
        _c("p", [_vm._v("订单详情")]),
        _c("p", [_vm._v("返回上一页")]),
      ]),
      _c("div", { staticClass: "orderdetailsnav" }, [
        _c("div", { staticClass: "orderdetailsnavone" }, [
          _c("p", { staticStyle: { "padding-top": "15px" } }, [
            _vm._v("订单编号：154651546135451354"),
          ]),
          _c("p", { staticStyle: { "margin-top": "15px" } }, [
            _vm._v("订单状态：待付款"),
          ]),
          _c("div", { staticClass: "orderdetailsnavdiv" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/img/79ae946f463f57adda046c6b7fafc056.jpg"),
                alt: "",
              },
            }),
            _c("p", [_vm._v("2020初级会计精讲班（赠智能题库）")]),
            _c("p", [_vm._v("￥50.00")]),
          ]),
          _c("p", { staticClass: "opp" }, [
            _vm._v("下单时间：2020-06-02 12:06:02"),
          ]),
          _c("div", { staticClass: "orderdetailsfoo" }, [
            _c("div", [
              _c("p", [_vm._v("商品个数："), _c("span", [_vm._v("2个")])]),
              _c("p", [_vm._v("会员优惠："), _c("span", [_vm._v("16.00元")])]),
            ]),
            _c("div", [
              _c("p", [_vm._v("金额合计："), _c("span", [_vm._v("156.00元")])]),
              _c("p", [_vm._v("应付总额："), _c("span", [_vm._v("140.00元")])]),
            ]),
          ]),
          _c("p", [_c("br")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }