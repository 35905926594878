"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/views/examinationSystem/layout"));
// 考试系统路由

var examinationSystem = {
  path: '/examinationSystem',
  component: _layout.default,
  redirect: '/examinationSystem/login',
  children: [{
    path: '/examinationSystem/login',
    name: 'examinationSystemLogin',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/examinationSystem/login'));
      });
    },
    meta: {
      visitFlag: true,
      title: '考试系统-用户登录'
    }
  }, {
    path: '/examinationSystem/examList/:page',
    name: 'examList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/examinationSystem/examList'));
      });
    },
    meta: {
      visitFlag: true,
      title: '考试系统-考试列表'
    }
  }]
};
var _default = exports.default = examinationSystem;