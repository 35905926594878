"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getNews = getNews;
exports.getNewsDetailById = getNewsDetailById;
exports.searchNewsByKeywords = searchNewsByKeywords;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 
 * @param {*} careId 新闻分类id
 * @returns 
 */
function getNews(_ref) {
  var careId = _ref.careId,
    _ref$size = _ref.size,
    size = _ref$size === void 0 ? 5 : _ref$size;
  return (0, _request.default)({
    method: "get",
    url: "/zyg/webserver/news/list",
    params: {
      careId: careId,
      size: size
    }
  });
}

// 根据id获取新闻详情
function getNewsDetailById(id) {
  return (0, _request.default)({
    method: "get",
    // /association/nuxt/info/newInfo
    // /zyg/webserver/news/info
    url: "/zyg/association/nuxt/info/newInfo",
    params: {
      id: id
    }
  });
}

// 根据关键字搜索新闻列表
function searchNewsByKeywords(_ref2) {
  var keyword = _ref2.keyword,
    _ref2$page = _ref2.page,
    page = _ref2$page === void 0 ? 1 : _ref2$page,
    _ref2$pageSize = _ref2.pageSize,
    pageSize = _ref2$pageSize === void 0 ? 100 : _ref2$pageSize;
  return (0, _request.default)({
    method: "get",
    url: "/zyg/webserver/news/search",
    params: {
      keyword: keyword,
      page: page,
      pageSize: pageSize
    }
  });
}
// const { data, code } = await $axios(`/webserver/news/info?id=${params.id}`)