var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            title: "报名表单",
            visible: _vm.showDialog,
            width: "613px",
            top: "10vh",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c("div", { staticClass: "flex-column prentView" }, [
            _c(
              "div",
              {
                staticClass:
                  "dialogTop d-flex align-items-center border-bottom",
              },
              [
                _c("img", { attrs: { src: _vm.faceImg, alt: "" } }),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column justify-content-between dp",
                  },
                  [
                    _c("div", { staticClass: "dc" }, [
                      _vm._v(" " + _vm._s(_vm.username) + " "),
                    ]),
                    _c("div", { staticClass: "dc" }, [
                      _vm._v("电话：" + _vm._s(_vm.mobile)),
                    ]),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "overflow-auto", staticStyle: { flex: "1" } },
              [
                _c(
                  "div",
                  { staticStyle: { height: "60vh", padding: "0 4.57rem" } },
                  _vm._l(_vm.formList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticStyle: { "padding-bottom": "0.5rem" },
                      },
                      [
                        item.type == "radio"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "title d-flex align-items-center",
                                    class: item.options.required
                                      ? "active"
                                      : "",
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                                _vm._l(
                                  item.options.options,
                                  function (children, childrenIndex) {
                                    return _c(
                                      "div",
                                      {
                                        key: childrenIndex,
                                        staticClass: "rounded",
                                        class:
                                          children.sel == 1
                                            ? "choice-sel"
                                            : "choice-unsel",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center",
                                            on: {
                                              click: function ($event) {
                                                return _vm.choiceClick(
                                                  item.elementId,
                                                  children.value,
                                                  1
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src:
                                                  children.sel == 1
                                                    ? _vm.icon_bj_xz
                                                    : _vm.icon_wxz,
                                                alt: "",
                                              },
                                            }),
                                            _c("div", { staticClass: "t1" }, [
                                              _vm._v(_vm._s(children.value)),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        ["member", "checkbox"].includes(item.type)
                          ? _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "title d-flex align-items-center",
                                    class: item.options.required
                                      ? "active"
                                      : "",
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                                _vm._l(
                                  item.options.options,
                                  function (children, childrenIndex) {
                                    return _c(
                                      "div",
                                      {
                                        key: childrenIndex,
                                        staticClass: "rounded",
                                        class:
                                          children.sel == 1
                                            ? "choice-sel"
                                            : "choice-unsel",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-items-center",
                                            on: {
                                              click: function ($event) {
                                                return _vm.choiceClick(
                                                  item.elementId,
                                                  children.value,
                                                  2
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src:
                                                  children.sel == 1
                                                    ? _vm.icon_bj_xz
                                                    : _vm.icon_wxz,
                                                alt: "",
                                              },
                                            }),
                                            _c("div", { staticClass: "t1" }, [
                                              _vm._v(_vm._s(children.value)),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        item.type == "textarea"
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "title d-flex align-items-center",
                                  class: item.options.required ? "active" : "",
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              ),
                              _c("div", { staticClass: "d-flex" }, [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.value,
                                      expression: "item.value",
                                    },
                                  ],
                                  staticClass: "border rounded w-100",
                                  attrs: {
                                    disabled: item.options.disabled,
                                    placeholder: item.options.placeholder,
                                  },
                                  domProps: { value: item.value },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        item,
                                        "value",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ])
                          : _vm._e(),
                        ["input", "personnel"].includes(item.type)
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "title d-flex align-items-center",
                                  class: item.options.required ? "active" : "",
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              ),
                              _c("div", { staticClass: "d-flex" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: item.value,
                                      expression: "item.value",
                                    },
                                  ],
                                  staticClass: "border rounded w-100",
                                  attrs: {
                                    disabled: item.options.disabled,
                                    placeholder: item.options.placeholder,
                                  },
                                  domProps: { value: item.value },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        item,
                                        "value",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ])
                          : _vm._e(),
                        item.type == "select"
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "title d-flex align-items-center",
                                  class: item.options.required ? "active" : "",
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex w-100" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "w-100",
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    },
                                    _vm._l(
                                      item.options.options,
                                      function (childrenItem) {
                                        return _c("el-option", {
                                          key: childrenItem.value,
                                          attrs: {
                                            label: childrenItem.value,
                                            value: childrenItem.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        item.type == "datePicker"
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "title d-flex align-items-center",
                                  class: item.options.required ? "active" : "",
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex w-100" },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "w-100",
                                    attrs: {
                                      type: "date",
                                      placeholder: "选择日期",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: item.value,
                                      callback: function ($$v) {
                                        _vm.$set(item, "value", $$v)
                                      },
                                      expression: "item.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        item.type == "time"
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "title d-flex align-items-center",
                                  class: item.options.required ? "active" : "",
                                },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              ),
                              _c(
                                "div",
                                { staticClass: "d-flex w-100" },
                                [
                                  _c("el-time-picker", {
                                    staticClass: "w-100",
                                    attrs: {
                                      placeholder: item.options.placeholder,
                                      "value-format": "HH:mm:ss",
                                    },
                                    model: {
                                      value: item.value,
                                      callback: function ($$v) {
                                        _vm.$set(item, "value", $$v)
                                      },
                                      expression: "item.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        item.type == "imgupload"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "title d-flex align-items-center",
                                    class: item.options.required
                                      ? "active"
                                      : "",
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                ),
                                _c(
                                  "el-upload",
                                  {
                                    attrs: {
                                      action: "",
                                      "list-type": "picture-card",
                                      "on-preview":
                                        _vm.handlePictureCardPreview,
                                      "file-list": _vm.imgList,
                                      "on-remove": function (file) {
                                        return _vm.handleRemove(file, item)
                                      },
                                      "http-request": function (file) {
                                        return _vm.uploadSuccess(file, item)
                                      },
                                      multiple: true,
                                      limit: item.options.length,
                                      "on-change": _vm.imgListChange(
                                        item.elementId
                                      ),
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-plus" })]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _c("div", { staticClass: "d-flex" }, [
              _c(
                "button",
                { staticClass: "rounded w-100", on: { click: _vm.submit } },
                [_vm._v("提交")]
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, size: "tiny" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            top: "40vh",
            visible: _vm.TipsDialogVisible,
            "show-close": false,
            width: "25%",
            center: "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.TipsDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center justify-content-center" },
            [_vm._v(" " + _vm._s(_vm.tips) + " ")]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.correctBtnText
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.closeCorrectDialog },
                    },
                    [_vm._v(_vm._s(_vm.correctBtnText))]
                  )
                : _vm._e(),
              _vm.errorBtnText
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.closeErrorDialog },
                    },
                    [_vm._v(_vm._s(_vm.errorBtnText))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }