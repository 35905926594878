"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _format = require("@/utils/format");
var _NewsTitle = _interopRequireDefault(require("@/components/NewsTitle"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  data: function data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: '标题'
    },
    list: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isShowBtn: {
      type: Boolean,
      default: true
    }
  },
  components: {
    NewsTitle: _NewsTitle.default
  },
  computed: {},
  methods: {
    // 培训通知点击更多按钮
    handleMore: function handleMore() {
      console.log('培训通知点击更多按钮');
      // this.$router.push({
      //   path: 'announcement',
      // })
      this.$emit('goMore');
    },
    // 新闻跳详情
    goDetail: function goDetail(item) {
      this.$emit('goDetail', item);
    },
    //格式化时间函数
    formatCreatetime: _format.formatCreatetime
  }
};