"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.push.js");
var _table = require("@/api/table.js");
var _personal = require("@/api/personal");
var _refundProgress = _interopRequireDefault(require("./refundProgress.vue"));
var _request = _interopRequireDefault(require("@/utils/request.js"));
var _payservice = require("@/api/payservice");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  inject: ["handleSelect"],
  components: {
    refundProgress: _refundProgress.default
  },
  data: function data() {
    return {
      total: 100,
      list: null,
      disabList: false,
      refundProgressItem: null,
      showProgress: false,
      textarea: "",
      progressActive: 3,
      listLoading: true,
      tkPayWay: null,
      // 退款item payWay
      listQuery: {
        // cid: getAssocId("mCid"),
        page: 1,
        size: 100
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 获取订单信息
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _personal.orderList)(this.listQuery).then(function (response) {
        _this.list = response.data.content.map(function (item) {
          item.maynot = false;
          if (item.status == 2) {
            var countDown = new Date(item.payTime).getTime() + 604800000;
            var NowData = new Date().getTime();
            if (countDown <= NowData) {
              item.maynot = false;
            } else {
              item.maynot = true;
            }
          }
          return item;
        });
        _this.total = response.data.totalElements;
        _this.listLoading = false;
      });
    },
    // qxClick() {
    //   request({
    //     url: "/isOrderFlag",
    //     method: "get",
    //     params: {
    //       orderId: this.refundProgressItem.id,
    //     },
    //   }).then((response) => {
    //     if (response.code == 200) {
    //       this.oid = response.data.id;
    //       this.disabList = true;
    //     } else {
    //       this.$alert(response.message, "提示", {
    //         confirmButtonText: "确定",
    //         type: "info",
    //         callback: (action) => {},
    //       });
    //     }
    //   });
    //   // this.oid = item.id
    //   // this.disabList= true
    // },
    qxClick: function qxClick(item) {
      var _this2 = this;
      this.tkPayWay = item.payWay;
      this.textarea = "";
      (0, _request.default)({
        url: "/isOrderFlag",
        method: "get",
        params: {
          orderId: item.id
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this2.oid = response.data.id;
          _this2.disabList = true;
        } else {
          _this2.$alert(response.message, "提示", {
            confirmButtonText: "确定",
            type: "info",
            callback: function callback(action) {}
          });
        }
      });
    },
    //取消订单
    deletemappingfun: function deletemappingfun(id) {
      var _this3 = this;
      (0, _table.DeleteMapping)(id).then(function (res) {
        if (res.code === 200) {
          _this3.$message({
            message: "取消成功",
            type: "success"
          });
          _this3.getList();
        }
      });
    },
    closeDid: function closeDid() {
      this.getList();
      this.showProgress = false;
    },
    refundProgress: function refundProgress(item) {
      this.refundProgressItem = item;
      this.showProgress = true;
    },
    details: function details(item, jumpType) {
      this.handleSelect(11, item, jumpType, '4-3');
    },
    detailsSub: function detailsSub(item, jumpType) {
      this.handleSelect(11, item, jumpType, '4-3');
    },
    // 兑换码支付退款
    volumeClick: function volumeClick() {
      var _this4 = this;
      (0, _payservice.couponRefund)(this.oid, this.textarea).then(function (res) {
        if (res.code == 200) {
          _this4.showProgress = false;
          _this4.disabList = false;
          _this4.getList();
          _this4.$message({
            message: "已提交申请，请耐心等待平台审核",
            type: "success"
          });
        } else {
          _this4.disabList = false;
          _this4.$message.error(res.message);
        }
      });
    },
    tkMoneyClick: function tkMoneyClick() {
      var _this5 = this;
      if (!this.textarea) {
        this.$alert("请填写退款原因！", "提示", {
          confirmButtonText: "确定",
          type: "info",
          callback: function callback(action) {}
        });
        return false;
      }
      switch (this.tkPayWay) {
        case 6:
          this.volumeClick();
          break;
        default:
          (0, _request.default)({
            url: "/order/applyForRefund",
            method: "post",
            params: {
              oid: this.oid,
              remark: this.textarea
            }
          }).then(function (response) {
            if (response.code == 200) {
              _this5.showProgress = false;
              _this5.disabList = false;
              _this5.getList();
              _this5.$message({
                message: "申请退款成功",
                type: "success"
              });
            } else {
              _this5.disabList = false;
              _this5.$message.error(response.message);
            }
          });
          break;
      }
    },
    reQxClick: function reQxClick() {
      this.qxClick(this.refundProgressItem);
    },
    payment: function payment(item) {
      this.$router.push({
        path: "/payment/" + item.id
      });
    }
  }
};