"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.find.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.regexp.constructor.js");
require("core-js/modules/es.regexp.dot-all.js");
require("core-js/modules/es.regexp.sticky.js");
require("core-js/modules/es.regexp.to-string.js");
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Certificate',
  data: function data() {
    return {
      index: 0,
      popularcourses: [],
      popularcoursestwe: '',
      assocId: (0, _auth.getAssocId)(),
      classified: '',
      type: 2,
      certPhoto: '',
      certTitle: '',
      createTime: '',
      zhenglist: false,
      wuzheng: false,
      yulan: false,
      certId: '',
      certList: [],
      currentPage1: '',
      page: 1,
      totalElements: 0,
      size: 8,
      centerDialogVisible: false,
      imgUrl: '',
      isshow: true,
      ctList: '',
      cartItem: null,
      certListLoading: false,
      // 证书列表loading
      ticketTemplate: '',
      previewObj: ''
    };
  },
  created: function created() {
    this.getbaseinfo();
  },
  methods: {
    toCertInfo: function toCertInfo() {
      var _this = this;
      this.cartItem = this.certList.find(function (item) {
        return item.id === _this.$route.query.certId;
      });
      if (this.cartItem) {
        this.isshow = false;
      } else {
        this.$message.error('未查询到该证书');
      }
    },
    btnback: function btnback() {
      // this.$router.push(`/personalCenter/certificate`)
      this.isshow = true;
    },
    download: function download() {
      console.log('print');
      var bdhtml = window.document.body.innerHTML;
      var jubuData;
      if (document.querySelectorAll('#pdfDom').length > 1) {
        document.querySelectorAll('#pdfDom').forEach(function (it) {
          jubuData += it.innerHTML;
        });
      } else {
        jubuData = document.querySelector('#pdfDom').innerHTML;
      }
      window.document.body.innerHTML = jubuData;
      window.document.body.style.padding = '200px';
      window.document.body.style.fontSize = '18px';
      window.print();
      // 重新给页面内容赋值；
      // console.log(document.execCommand("print"))
      // if(console.log(document.execCommand("print"))){
      //   window.close()
      window.document.body.innerHTML = bdhtml;
      // }

      location.reload();
      // this.getPdf()
    },
    dainjifun: function dainjifun(val) {
      // this.cartItem = item
      // this.$router.push(`/personalCenter/certificate?certId=${item.id}`)
      // // 预览方法、并带上id、
      // this.isshow = false
      this.previewObj = val;
      console.log(val);
      var strAll = '';
      if (val.ticketTemplate || val.ticketTemplate.templateHtml) {
        strAll = val.ticketTemplate.templateHtml;
        for (var key in val) {
          if (Object.hasOwnProperty.call(val, key)) {
            if (val.applyInfo) {
              var s = JSON.parse(val.applyInfo);
              for (var _key in s) {
                if (Object.hasOwnProperty.call(s, _key)) {
                  strAll = strAll.replace(new RegExp('{' + _key + '}', 'g'), s[_key]);
                }
              }
            }
            strAll = strAll.replace(new RegExp('{' + key + '}', 'g'), val[key]);
          }
        }
      } else {
        strAll = val.ticketTemplate.templateHtml;
      }
      this.ticketTemplate = strAll;
      this.isshow = false;
    },
    pagingSizeChange: function pagingSizeChange(val) {
      // 分页方法
      this.page = val;
      this.getbaseinfo(); // 调用接口的方法
    },
    handleSizeChange: function handleSizeChange(val) {
      console.log("\u6BCF\u9875 ".concat(val, " \u6761"));
    },
    handleCurrentChange: function handleCurrentChange(val) {
      console.log("\u5F53\u524D\u9875: ".concat(val));
    },
    getbaseinfo: function getbaseinfo() {
      var _this2 = this;
      this.certListLoading = true;
      var that = this;
      // 1、调证书列表接口、获取个人证书结果信息、
      // 2.有则循环展示、
      (0, _request.default)({
        url: 'zyg/exam/admissionTicket',
        method: 'get',
        params: {
          page: that.page,
          size: that.size
        }
      }).then(function (res) {
        if (res.code === 200) {
          _this2.certList = res.data.list;
          _this2.totalElements = res.data.total;
          if (_this2.$route.query.certId) _this2.toCertInfo();
        }
      }).finally(function () {
        _this2.certListLoading = false;
      });
    },
    getbasyulan: function getbasyulan() {
      var that = this;
      // 1、调预览证书接口、获取证书结果信息、
      // 2.展示
      (0, _request.default)({
        url: 'zyg/lmTrainExamCertCertificateLog/getOne',
        method: 'post',
        params: {
          id: this.id
        }
      }).then(function (res) {
        that.ctList = res.data;
      });
    },
    printpage: function printpage(obj) {
      // 打印方法、@click="printpage(i)"放到打印按钮DIV里、:id="'print'+i"放到需要打印的的DIV里
      var head_str = '<html><head><title></title></head><body>'; // 先生成头部
      var foot_str = '</body></html>'; // 生成尾部
      var older = document.body.innerHTML;
      var new_str = document.getElementById('print' + obj).innerHTML; // 获取指定打印区域
      console.log(obj);
      // eslint-disable-next-line no-unused-vars
      var old_str = document.body.innerHTML; // 获得原本页面的代码
      document.body.innerHTML = head_str + new_str + foot_str; // 构建新网页
      window.print(); // 打印刚才新建的网页
      document.body.innerHTML = older; // 将网页还原
      return false;
    }
  }
};