var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container examination-box",
      staticStyle: { "margin-left": "0em" },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "border-bottom": "0.1rem solid #E4E4E4",
            background: "#fff",
            "font-weight": "bold",
            "font-size": "1.1rem",
            color: "#303030",
            height: "3rem",
            display: "flex",
            "align-items": "center",
            "padding-left": "1.6rem",
          },
        },
        [_vm._v(" 我的考试 ")]
      ),
      _c("div", { staticClass: "examination-content" }, [
        _c("div", { staticClass: "tab-tilte" }, [
          _c(
            "p",
            {
              class: { active: _vm.cur == 0 },
              on: {
                click: function ($event) {
                  _vm.cur = 0
                },
              },
            },
            [
              _c("i", {
                staticClass: "el-icon-document-copy",
                staticStyle: { "margin-right": "11px" },
              }),
              _vm._v(" 鉴定考试 "),
            ]
          ),
          _c(
            "p",
            {
              class: { active: _vm.cur == 1 },
              on: {
                click: function ($event) {
                  _vm.cur = 1
                },
              },
            },
            [
              _c("i", {
                staticClass: "el-icon-document-copy",
                staticStyle: { "margin-right": "11px" },
              }),
              _vm._v(" 八大员考试 "),
            ]
          ),
        ]),
        _c("div", [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.cur == 0,
                  expression: "cur==0",
                },
              ],
              staticClass: "box1",
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "12px",
                    color: "#999999",
                    "font-size": "14px",
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.liebiaostop,
                        border: "",
                        height: "540",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", width: "130", label: "编号" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "考试名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_vm._v(" " + _vm._s(row.mingcname) + " ")]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "科目名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_vm._v(" " + _vm._s(row.kemuname) + " ")]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "等级" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_vm._v(" " + _vm._s(row.dengji) + " ")]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "180" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.mingcname
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "kaosjhi",
                                        on: {
                                          click: function ($event) {
                                            return _vm.kaoshikaosonclic(row)
                                          },
                                        },
                                      },
                                      [_vm._v("开始考试")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.cur == 1,
                  expression: "cur==1",
                },
              ],
              staticClass: "box1",
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "12px",
                    color: "#999999",
                    "font-size": "14px",
                  },
                },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.liebiaosbtm,
                        border: "",
                        height: "540",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", width: "130", label: "编号" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "考试名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_vm._v(" " + _vm._s(row.mingcname) + " ")]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "科目名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_vm._v(" " + _vm._s(row.kemuname) + " ")]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "等级" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [_vm._v(" " + _vm._s(row.dengji) + " ")]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", width: "180" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (ref) {
                              var row = ref.row
                              return [
                                row.mingcname
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "kaosjhi",
                                        on: {
                                          click: function ($event) {
                                            return _vm.kaoshikaosonclic(row)
                                          },
                                        },
                                      },
                                      [_vm._v("开始考试")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }