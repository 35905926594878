"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'InvoiceDetails',
  components: {},
  props: {
    currentContent: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    invoiceInfo: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  inject: ['applyInvoice'],
  data: function data() {
    return {
      // 订单状态
      orderStatus: {
        1: '待付款',
        2: '已付款',
        3: '取消',
        4: '支付失败',
        5: '已退款'
      }
    };
  },
  created: function created() {
    console.log('详情对象', this.currentContent);
  },
  mounted: function mounted() {},
  methods: {
    back: function back() {
      this.$emit('back');
    },
    formatStatus: function formatStatus(status) {
      var statusMap = {
        0: '待申请',
        1: '已申请',
        2: '--'
      };
      return statusMap[status];
    }
  }
};