var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "registration" },
    [
      _c("h4", [_vm._v("报名申请")]),
      _c(
        "section",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "header-cell-style": {
                  "text-align": "center",
                  borderColor: "#ccc",
                },
                "cell-style": { "text-align": "center", borderColor: "#ccc" },
                height: "600",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "itemName", label: "项目名称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "价格", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.freeBuy
                          ? _c("span", [_vm._v("免费")])
                          : _c("span", [
                              _vm._v(" ¥" + _vm._s(scope.row.price) + " "),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "myApplyStatus",
                  label: "申请状态",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: { width: "110", label: "付款状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.status === 10
                                ? scope.row.myPayStatus
                                : "--"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", width: "180", label: "提交时间" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === 1
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.see(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e(),
                        scope.row.status === 10 && scope.row.payStatus === 1
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { color: "red" },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push(
                                      "/payment/" + scope.row.trainId
                                    )
                                  },
                                },
                              },
                              [_vm._v("付款 ")]
                            )
                          : _vm._e(),
                        scope.row.status === 11
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.seeReject(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看拒绝原因")]
                            )
                          : _vm._e(),
                        scope.row.status === 11
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { color: "red" },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push(
                                      "/fillOutTheForm/validateForm/" +
                                        scope.row.trainId +
                                        "?enrollInfoId=" +
                                        scope.row.enrollInfoId
                                    )
                                  },
                                },
                              },
                              [_vm._v(" 重新提交 ")]
                            )
                          : _vm._e(),
                        scope.row.validateMethod == "slryaqsckhjxjysdyz"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.yulans(scope.row)
                                  },
                                },
                              },
                              [_vm._v(" 申报表 ")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "footer",
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-size": _vm.size,
              layout: "prev, pager, next",
              total: _vm.totalPage,
            },
            on: { "current-change": _vm.currentChange },
          }),
        ],
        1
      ),
      _vm.isRefusePopup
        ? _c(
            "hxt-popup",
            {
              attrs: {
                icon: require("@/assets/img/assoc_dialog.png"),
                title: "拒绝原因",
                visible: _vm.isRefusePopup,
                size: "1",
                "show-close": true,
              },
              on: {
                close: function ($event) {
                  _vm.isRefusePopup = false
                },
              },
            },
            [
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c(
                  "div",
                  { staticStyle: { padding: "20px" } },
                  [
                    _c("el-input", {
                      attrs: {
                        value: _vm.rejectReason,
                        type: "textarea",
                        autosize: { minRows: 9, maxRows: 9 },
                        readonly: true,
                        resize: "none",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.isRefusePopup = false
                        },
                      },
                    },
                    [_vm._v("确定 ")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.showLoadingDiv
        ? _c(
            "el-dialog",
            {
              staticClass: "loading_div",
              attrs: {
                visible: _vm.showLoadingDiv,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                title: "预览",
                width: "80rem",
                top: "2rem",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showLoadingDiv = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    height: "calc(100vh - 180px)",
                    "overflow-x": "auto",
                    "margin-top": "-22px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        border: "1px solid",
                        display: "flex",
                        "flex-direction": "column",
                        "align-items": "center",
                        margin: "18px",
                      },
                      attrs: { id: "pdfDom" },
                    },
                    [
                      _c(
                        "h3",
                        {
                          staticStyle: {
                            "padding-top": "10px",
                            "margin-bottom": "20px",
                            "font-weight": "bolder",
                            "font-size": "20px",
                            "text-align": "center",
                          },
                        },
                        [
                          _vm._v(
                            " 鄂尔多斯建筑施工企业“安管人员”安全生产知识继续教育报名表"
                          ),
                        ]
                      ),
                      _c(
                        "table",
                        {
                          staticStyle: {
                            border: "1px solid #000",
                            "font-size": "14px",
                            width: "1020px",
                            height: "1400px",
                            margin: "25px 0",
                          },
                        },
                        [
                          _c(
                            "tr",
                            {
                              staticStyle: {
                                border: "1px solid #000",
                                "font-size": "14px",
                              },
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "45px",
                                    height: "40px",
                                    "text-align": "center",
                                    "vertical-align": "middle",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                  },
                                },
                                [_vm._v(" 工作单位 ")]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "45px",
                                    height: "40px",
                                    "text-align": "center",
                                    "vertical-align": "middle",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                  },
                                  attrs: { colspan: "5" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.yualmsihw.workUnit || "--") + " "
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  staticClass: "photo",
                                  staticStyle: {
                                    width: "150px",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                    "text-align": "center",
                                  },
                                  attrs: { rowspan: "5" },
                                },
                                [_vm._v("照片 ")]
                              ),
                            ]
                          ),
                          _c(
                            "tr",
                            {
                              staticStyle: {
                                border: "1px solid #000",
                                "font-size": "16px",
                              },
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "45px",
                                    height: "40px",
                                    "text-align": "center",
                                    "vertical-align": "middle",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                  },
                                },
                                [_vm._v(" 姓名 ")]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "45px",
                                    height: "40px",
                                    "text-align": "center",
                                    "vertical-align": "middle",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.yualmsihw.name) + " ")]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "15px",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                    "text-align": "center",
                                  },
                                },
                                [_vm._v("性别")]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "15px",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                    "text-align": "center",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.yualmsihw.sex))]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "65px",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                    "text-align": "center",
                                  },
                                },
                                [_vm._v("出生年月")]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "70px",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                    "text-align": "center",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.yualmsihw.birthday) + " ")]
                              ),
                            ]
                          ),
                          _c(
                            "tr",
                            {
                              staticStyle: {
                                border: "1px solid #000",
                                "font-size": "16px",
                              },
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "45px",
                                    height: "40px",
                                    "text-align": "center",
                                    "vertical-align": "middle",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                  },
                                },
                                [_vm._v(" 职务 ")]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "45px",
                                    height: "40px",
                                    "text-align": "center",
                                    "vertical-align": "middle",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                  },
                                },
                                [_vm._v(" " + _vm._s(_vm.yualmsihw.job) + " ")]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "15px",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                    "text-align": "center",
                                  },
                                },
                                [_vm._v("联系电话")]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "45px",
                                    height: "40px",
                                    "text-align": "center",
                                    "vertical-align": "middle",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                  },
                                  attrs: { colspan: "3" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.yualmsihw.mobile) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "tr",
                            {
                              staticStyle: {
                                border: "1px solid #000",
                                "font-size": "16px",
                              },
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "45px",
                                    height: "40px",
                                    "text-align": "center",
                                    "vertical-align": "middle",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                  },
                                },
                                [_vm._v(" 考生类型 ")]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "45px",
                                    height: "40px",
                                    "text-align": "center",
                                    "vertical-align": "middle",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.yualmsihw.candidateCategory) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "15px",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                    "text-align": "center",
                                  },
                                },
                                [_vm._v("身份证")]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "45px",
                                    height: "40px",
                                    "text-align": "center",
                                    "vertical-align": "middle",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                  },
                                  attrs: { colspan: "3" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.yualmsihw.cardNumber) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "tr",
                            {
                              staticStyle: {
                                border: "1px solid #000",
                                "font-size": "16px",
                              },
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "45px",
                                    height: "40px",
                                    "text-align": "center",
                                    "vertical-align": "middle",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                  },
                                  attrs: { colspan: "3" },
                                },
                                [_vm._v(" 安全考核合格证编号 ")]
                              ),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "45px",
                                    height: "40px",
                                    "text-align": "center",
                                    "vertical-align": "middle",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                  },
                                  attrs: { colspan: "3" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.yualmsihw
                                          .securityReviewCertificateNo
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "tr",
                            {
                              staticStyle: {
                                border: "1px solid #000",
                                "font-size": "16px",
                              },
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    height: "250px",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                    "text-align": "center",
                                  },
                                  attrs: { colspan: "7" },
                                },
                                [_vm._v(" 粘贴身份证正面和反面 ")]
                              ),
                            ]
                          ),
                          _c(
                            "tr",
                            {
                              staticClass: "last",
                              staticStyle: {
                                height: "200px",
                                border: "1px solid #000",
                                "font-size": "16px",
                              },
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    padding: "0 10px",
                                    border: "1px solid #000",
                                    "font-size": "16px",
                                  },
                                  attrs: { colspan: "7" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "left",
                                        "margin-top": "-150px",
                                        "font-size": "16px",
                                      },
                                    },
                                    [_vm._v("报考单位(盖章)")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        margin: "160px 120px 140px 0",
                                        "text-align": "right",
                                        "font-size": "16px",
                                      },
                                    },
                                    [_vm._v("（公章）")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "right",
                                        "margin-bottom": "-150px",
                                        "font-size": "16px",
                                      },
                                    },
                                    [
                                      _vm._v(" 年"),
                                      _c("span", {
                                        staticStyle: { padding: "5px 25px" },
                                      }),
                                      _vm._v(" 月"),
                                      _c("span", {
                                        staticStyle: { padding: "5px 25px" },
                                      }),
                                      _vm._v(" 日 "),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    "margin-top": "15px",
                    "margin-bottom": "-25px",
                  },
                },
                [
                  _c("p"),
                  _c(
                    "p",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", type: "primary" },
                          on: { click: _vm.xiazais },
                        },
                        [_vm._v("下载")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }