var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboardThemeSecondContainer" }, [
    _c("div", { staticClass: "mainBox" }, [
      _c("div", { staticClass: "main" }, [
        _vm._m(0),
        _c("div", { staticClass: "contentBar" }, [
          _c(
            "div",
            { staticClass: "menuBar" },
            _vm._l(_vm.trainList, function (item) {
              return _c(
                "div",
                {
                  key: item.classId,
                  staticClass: "menuItem",
                  on: {
                    click: function ($event) {
                      return _vm.goClassList(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "menuText" }, [
                    _c("div", { staticClass: "mainTitle" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c("div", { staticClass: "subTitle" }, [
                      _vm._v(_vm._s(item.alias)),
                    ]),
                  ]),
                  _c("i", { staticClass: "el-icon-arrow-right" }),
                ]
              )
            }),
            0
          ),
          _c("div", { staticClass: "newsBox" }, [
            _c("div", { staticClass: "topNewsBar" }, [
              _c("div", { staticClass: "topNews" }, [
                _c(
                  "div",
                  {
                    staticClass: "news",
                    on: {
                      click: function ($event) {
                        return _vm.goDetail(_vm.topNews, "notice")
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.topNews.title) + " ")]
                ),
              ]),
              _c("div", { staticClass: "searchBar" }, [
                _c(
                  "div",
                  { staticClass: "inputBar" },
                  [
                    _c("el-input", {
                      model: {
                        value: _vm.keyword,
                        callback: function ($$v) {
                          _vm.keyword = $$v
                        },
                        expression: "keyword",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "searchBtn", on: { click: _vm.handleSearch } },
                  [_vm._v("立即搜索")]
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "newsBar" },
              [
                _c(
                  "el-carousel",
                  [
                    _c("el-carousel-item", [
                      _c("img", {
                        staticClass: "poster",
                        attrs: {
                          src: "https://res.hangxintong.cn/nuxtweb/cfpx/微信图片_20220718164040.png",
                          alt: "",
                        },
                      }),
                    ]),
                    _c("el-carousel-item", [
                      _c("img", {
                        staticClass: "poster",
                        attrs: {
                          src: "https://res.hangxintong.cn/nuxtweb/cfpx/微信图片_20220718164013.png",
                          alt: "",
                        },
                      }),
                    ]),
                    _c("el-carousel-item", [
                      _c("img", {
                        staticClass: "poster",
                        attrs: {
                          src: "https://res.hangxintong.cn/nuxtweb/cfpx/微信图片_20220718164033.png",
                          alt: "",
                        },
                      }),
                    ]),
                    _c("el-carousel-item", [
                      _c("img", {
                        staticClass: "poster",
                        attrs: {
                          src: "https://res.hangxintong.cn/nuxtweb/cfpx/poster_04.png",
                          alt: "",
                        },
                      }),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "newsContent" },
                  [
                    _c("NewsList", {
                      attrs: { list: _vm.trainNews, title: "培训通知" },
                      on: {
                        goMore: function ($event) {
                          return _vm.goMore(1)
                        },
                        goDetail: function ($event) {
                          return _vm.goDetail($event, "notice")
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "newsArea" }, [
      _c(
        "div",
        { staticClass: "leftNewsBox" },
        [
          _c("NewsList", {
            attrs: { title: "中心动态", list: _vm.centerNews },
            on: {
              goMore: function ($event) {
                return _vm.goMore(2)
              },
              goDetail: function ($event) {
                return _vm.goDetail($event, "news")
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "rightNewsBox" },
        [
          _c("NewsList", {
            attrs: { title: "资料下载", list: _vm.downloadNews },
            on: {
              goMore: function ($event) {
                return _vm.goMore(3)
              },
              goDetail: function ($event) {
                return _vm.goDetail($event, "news")
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titleBar" }, [
      _c("i", {
        staticClass: "el-icon-s-unfold",
        staticStyle: { fontsize: "18px" },
      }),
      _c("span", { staticClass: "title" }, [_vm._v("全部")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }