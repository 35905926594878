"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.function.name.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    // 裁剪图片的地址
    img: {
      type: String,
      required: true
    },
    // size: {
    //   type: Boolean,
    //   default: true,
    // },
    // 裁剪框的大小信息
    info: {
      type: Boolean,
      default: true
    },
    // 裁剪生成图片的质量
    outputSize: {
      type: Number,
      default: 0.8
    },
    // 裁剪生成图片的格式
    outputType: {
      type: String,
      default: "jpeg"
    },
    // 图片是否允许滚轮缩放
    canScale: {
      type: Boolean,
      default: true
    },
    // 是否默认生成截图框
    autoCrop: {
      type: Boolean,
      default: true
    },
    // 默认生成截图框宽度
    autoCropWidth: {
      type: Number,
      default: 300
    },
    // 默认生成截图框高度
    autoCropHeight: {
      type: Number,
      default: 200
    },
    // 固定截图框大小 不允许改变
    fixedBox: {
      type: Boolean,
      default: false
    },
    // 是否开启截图框宽高固定比例
    fixed: {
      type: Boolean,
      default: true
    },
    // 截图框的宽高比例
    fixedNumber: {
      type: Array,
      default: function _default() {
        return [16, 9];
      }
    },
    // 是否输出原图比例的截图
    full: {
      type: Boolean,
      default: true
    },
    // 截图框能否拖动
    canMoveBox: {
      type: Boolean,
      default: true
    },
    // 上传图片按照原始比例渲染
    original: {
      type: Boolean,
      default: false
    },
    // 截图框是否被限制在图片里面
    centerBox: {
      type: Boolean,
      default: true
    },
    // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
    infoTrue: {
      type: Boolean,
      default: true
    },
    // 上传图片是否可以移动
    canMove: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data: function data() {
    return {
      loading: false
      // 裁剪组件的基础配置option
      // option: {
      //   img: "", // 裁剪图片的地址
      //   info: true, // 裁剪框的大小信息
      //   outputSize: 0.8, // 裁剪生成图片的质量
      //   outputType: "jpeg", // 裁剪生成图片的格式
      //   canScale: true, // 图片是否允许滚轮缩放
      //   autoCrop: true, // 是否默认生成截图框
      //   autoCropWidth: 300, // 默认生成截图框宽度
      //   autoCropHeight: 200, // 默认生成截图框高度
      //   fixedBox: false, // 固定截图框大小 不允许改变
      //   fixed: true, // 是否开启截图框宽高固定比例
      //   fixedNumber: [16, 9], // 截图框的宽高比例
      //   full: true, // 是否输出原图比例的截图
      //   canMoveBox: true, // 截图框能否拖动
      //   original: false, // 上传图片按照原始比例渲染
      //   centerBox: true, // 截图框是否被限制在图片里面
      //   infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      //   canMove: false, // 上传图片是否可以移动
      // },
    };
  },
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    finish: function finish() {
      var _this = this;
      this.loading = true;
      this.$refs.cropper.getCropBlob(function (datas) {
        _this.aliUploadBase64(datas, "realNameAuthentication", true).then(function (res) {
          if (res.res.status === 200) {
            _this.$emit("returnUrl", "https://oss.hangxintong.cn/".concat(res.name));
          } else {
            _this.$message.error("图片上传失败，请稍后重试");
          }
          _this.$emit("input", false);
        }).finally(function () {
          _this.loading = false;
        });
      });
    },
    closeDialog: function closeDialog() {
      this.$emit("input", false);
    }
  }
};