"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.includes.js");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Contact',
  data: function data() {
    return {
      cid: (0, _auth.getAssocId)()
    };
  },
  computed: {
    isShow: function isShow() {
      return ['135'].includes(this.cid);
    }
  }
};