"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.creditHourReductionList = exports.applyClassHours = void 0;
exports.getAssocInfo = getAssocInfo;
exports.getInfo = getInfo;
exports.login = login;
exports.loginByIdentity = loginByIdentity;
exports.logout = logout;
exports.peixuncaidan22 = peixuncaidan22;
exports.registrationList = exports.reductionConfig = exports.qualifiedClassHours = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
// import mData from '@/store/modules/mStore.js'

function login(data) {
  return (0, _request.default)({
    url: '/login/login',
    method: 'post',
    params: {
      mobile: data.mobile,
      passwd: data.passwd,
      type: 'trainee_login'
    }
  });
}
function loginByIdentity(identity) {
  return (0, _request.default)({
    url: 'zyg/examSimulation/login',
    method: 'post',
    data: {
      identity: identity,
      loginType: 'examinee_login'
    }
  });
}

// 获取协会信息(banner、footer、assocToken、assocTName、id)
function getAssocInfo() {
  return (0, _request.default)({
    url: '/tokenConfig/getAssocInfo',
    method: 'get'
  });
}
// 获取首页navber列表
function peixuncaidan22(cid, ctype) {
  return (0, _request.default)({
    url: '/trainMenu/trainMenuList',
    method: 'get',
    params: {
      cid: cid,
      ctype: ctype
    }
  });
}
function getInfo() {
  return (0, _request.default)({
    url: '/self/info2',
    method: 'get'
  });
}
function logout() {
  return (0, _request.default)({
    url: '/user/logout',
    method: 'post'
  });
}
// 申请学时减免前获取申请配置
var reductionConfig = exports.reductionConfig = function reductionConfig(trainId) {
  return (0, _request.default)({
    url: '/zyg/web/training/learner/courseReduce/apply/config',
    method: 'GET',
    params: {
      // 培训项目ID
      trainId: trainId
    }
  });
};
// 提交学时减免申请
var applyClassHours = exports.applyClassHours = function applyClassHours(data) {
  return (0, _request.default)({
    url: '/zyg/web/training/learner/courseReduce/apply',
    method: 'POST',
    data: data
  });
};
// 学时减免申请列表
var creditHourReductionList = exports.creditHourReductionList = function creditHourReductionList(_ref) {
  var page = _ref.page,
    size = _ref.size;
  return (0, _request.default)({
    url: '/zyg/web/training/learner/courseReduce/apply/list',
    method: 'GET',
    params: {
      page: page,
      size: size
    }
  });
};
// 报名申请列表
var registrationList = exports.registrationList = function registrationList(_ref2) {
  var page = _ref2.page,
    size = _ref2.size;
  return (0, _request.default)({
    url: "zyg/train/validate/findVerifyResultByPage",
    method: 'GET',
    params: {
      assocId: (0, _auth.getAssocId)(),
      page: page,
      size: size
    }
  });
};
// 获取距离达标学时
var qualifiedClassHours = exports.qualifiedClassHours = function qualifiedClassHours(_ref3) {
  var trainId = _ref3.trainId,
    studentId = _ref3.studentId;
  return (0, _request.default)({
    url: "zyg/train/studyLog/getReduceHoursAvailable",
    method: 'GET',
    params: {
      assocId: (0, _auth.getAssocId)(),
      trainId: trainId,
      studentId: studentId
    }
  });
};