var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "layout_content",
      attrs: { "element-loading-text": "正在验证IP地址，请耐心等待" },
    },
    [
      _vm.showFlag
        ? [
            _c("div", { staticClass: "top_img" }, [
              !_vm.isApp
                ? _c("div", { staticClass: "toolbarBox" }, [
                    _c("div", { staticClass: "inner" }, [
                      _c(
                        "div",
                        {
                          staticClass: "backWrap",
                          on: {
                            click: function ($event) {
                              return _vm.$router.push("/")
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-s-home" }),
                          _vm._v(" 返回首页"),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "infoBox",
                          staticStyle: { "margin-top": "10px" },
                        },
                        [
                          _c(
                            "el-dropdown",
                            { staticClass: "avatar-container" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "avatar-wrapper",
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-right": "5px",
                                        cursor: "pointer",
                                        "line-height": "1",
                                      },
                                    },
                                    [
                                      _c("el-avatar", {
                                        staticClass: "avaterImg",
                                        attrs: { size: 35, src: _vm.avatar },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "userName" }, [
                                    _vm._v(" " + _vm._s(_vm.name) + " "),
                                  ]),
                                  _c("div", [
                                    _c("i", {
                                      staticClass: "el-icon-caret-bottom icons",
                                    }),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-dropdown-menu",
                                {
                                  staticClass: "user-dropdown",
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "text-align": "center",
                                        "line-height": "50px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "toubu",
                                          on: { click: _vm.personalcenter },
                                        },
                                        [_vm._v("个人中心")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "text-align": "center",
                                        "line-height": "40px",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "toubu",
                                          on: { click: _vm.Logouts },
                                        },
                                        [_vm._v("退出登录")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.assocId !== 251
                ? _c("div", { staticClass: "titleBox" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.title || "--") +
                        _vm._s(
                          _vm.examTitleMapping[_vm.assocId] ||
                            _vm.examTitleMapping.default
                        ) +
                        " "
                    ),
                  ])
                : _c(
                    "div",
                    {
                      staticClass: "titleBox",
                      staticStyle: { "font-size": "60px" },
                    },
                    [
                      _c("div", { staticClass: "customTitle" }, [
                        _vm._v(" 模拟考试系统 "),
                      ]),
                    ]
                  ),
            ]),
            _c("router-view"),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }