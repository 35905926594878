var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.dialogStartExamination
        ? _c(
            "el-dialog",
            {
              staticClass: "startExamination_dialog",
              attrs: {
                "close-on-press-escape": false,
                "close-on-click-modal": false,
                showCancelButton: false,
                visible: _vm.dialogStartExamination,
                fullscreen: true,
                "show-close": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogStartExamination = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.page_loading,
                      expression: "page_loading",
                    },
                  ],
                  staticClass: "flex-column box",
                  attrs: { "element-loading-text": _vm.page_loading_text },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between titleBg",
                    },
                    [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c("img", { attrs: { src: _vm.assocLogo, alt: "" } }),
                        _c("div", { staticClass: "titleText" }, [
                          _vm._v("考试系统"),
                        ]),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "border backSy",
                          on: { click: _vm.backHtml },
                        },
                        [_vm._v("返回")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex childBox",
                      staticStyle: { height: "calc(100vh - 5rem)" },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { height: "calc(100vh - 5rem)" } },
                        [
                          _c(
                            "div",
                            { staticClass: "timeBox flex-column d-flex" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "border-bottom textSy1 d-flex align-items-center",
                                },
                                [_vm._v(" 剩余时间 ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "textSy2 d-flex align-items-center justify-content-center",
                                  staticStyle: { flex: "1" },
                                },
                                [_vm._v(" " + _vm._s(_vm.timeText) + " ")]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "faceBox flex-column d-flex" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "border-bottom textSy1 d-flex align-items-center",
                                },
                                [_vm._v(" 人脸核验 ")]
                              ),
                              _vm.faceTime
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-center",
                                      staticStyle: { flex: "1" },
                                    },
                                    [
                                      _c("video", {
                                        ref: "videoCameraTwe",
                                        attrs: {
                                          id: "videoCameraTwe",
                                          width: 170,
                                          height: 170,
                                        },
                                      }),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-center",
                                      staticStyle: { "margin-top": "4rem" },
                                    },
                                    [_vm._v(" 该考试未开启监考模式 ")]
                                  ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "faceBox flex-column d-flex" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "border-bottom textSy1 d-flex align-items-center",
                                },
                                [_vm._v(" 考生信息 ")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column align-items-center justify-content-center",
                                  staticStyle: { flex: "1" },
                                },
                                [
                                  _c("img", {
                                    staticClass: "faceImg",
                                    attrs: { src: _vm.userFace, alt: "" },
                                  }),
                                  _c("div", { staticClass: "infoSy" }, [
                                    _vm._v("姓名：" + _vm._s(_vm.userName)),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "timeBox d-flex flex-column",
                              staticStyle: {
                                "margin-top": "0.84rem",
                                height: "calc(100vh - 45.5rem)",
                                "min-height": "120px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "border-bottom textSy1 d-flex align-items-center",
                                },
                                [_vm._v(" 试题提示信息 ")]
                              ),
                              _vm.problemItem
                                ? _c("div", {
                                    staticClass:
                                      "tipsSy overflow-auto d-flex align-items-center justify-content-center",
                                    staticStyle: {
                                      flex: "1",
                                      padding: "1.64rem 1.71rem",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "problemBox d-flex flex-column",
                          staticStyle: { height: "calc(100vh - 5rem)" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "border-bottom title d-flex align-items-center",
                            },
                            [_vm._v(" 考试题目 ")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex flex-column specificproblem",
                              staticStyle: { flex: "1" },
                            },
                            [
                              _vm.problemItem
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "border-bottom problemtitle",
                                      staticStyle: {
                                        "padding-bottom": "1.41rem",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "problemtitle" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.problemItemIndex + 1) +
                                              "、"
                                          ),
                                        ]
                                      ),
                                      _c("span", {
                                        staticClass: "problemtitle",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.problemItem.tqContentOb.title
                                          ),
                                        },
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "problemtitle" },
                                        [
                                          _vm._v(
                                            " (" +
                                              _vm._s(
                                                _vm.problemItem.tqType == 1
                                                  ? "单选题"
                                                  : _vm.problemItem.tqType == 2
                                                  ? "多选题"
                                                  : _vm.problemItem.tqType == 3
                                                  ? "判断题"
                                                  : _vm.problemItem.tqType == 4
                                                  ? "填空题"
                                                  : _vm.problemItem.tqType == 5
                                                  ? "问答题"
                                                  : "组合题"
                                              ) +
                                              "，" +
                                              _vm._s(_vm.problemItem.score) +
                                              "分) "
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", [
                                _vm.problemItem.tqType == 1
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.problemItem.tqContentOb.options,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.choiceItemClick(
                                                    1,
                                                    item,
                                                    _vm.problemItem.id,
                                                    _vm.problemItem.tqType
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center choiceItem",
                                                  class:
                                                    item.sel == 1
                                                      ? "item-option-choice-sel"
                                                      : "item-option-choice",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src:
                                                        item.sel == 1
                                                          ? _vm.icon_xz
                                                          : _vm.icon_wxz,
                                                      alt: "",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "con" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.answerList[index]
                                                        ) + "、"
                                                      ),
                                                    ]
                                                  ),
                                                  _c("div", {
                                                    staticClass: "con",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.value
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm.problemItem.tqType == 2
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.problemItem.tqContentOb.options,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.choiceItemClick(
                                                    1,
                                                    item,
                                                    _vm.problemItem.id,
                                                    _vm.problemItem.tqType
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center choiceItem",
                                                  class:
                                                    item.sel == 1
                                                      ? "item-option-choice-sel"
                                                      : "item-option-choice",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src:
                                                        item.sel == 1
                                                          ? _vm.icon_xz
                                                          : _vm.icon_wxz,
                                                      alt: "",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "con" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.answerList[index]
                                                        ) + "、"
                                                      ),
                                                    ]
                                                  ),
                                                  _c("div", {
                                                    staticClass: "con",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.value
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm.problemItem.tqType == 3
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.problemItem.tqContentOb.options,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.choiceItemClick(
                                                    1,
                                                    item,
                                                    _vm.problemItem.id,
                                                    _vm.problemItem.tqType
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center choiceItem",
                                                  class:
                                                    item.sel == 1
                                                      ? "item-option-choice-sel"
                                                      : "item-option-choice",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src:
                                                        item.sel == 1
                                                          ? _vm.icon_xz
                                                          : _vm.icon_wxz,
                                                      alt: "",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "con" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.answerList[index]
                                                        ) + "、"
                                                      ),
                                                    ]
                                                  ),
                                                  _c("div", {
                                                    staticClass: "con",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.value
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm.problemItem.tqType == 4
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.problemItem.tqContentOb.options,
                                        function (item, index) {
                                          return _c("div", { key: index }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "item-option-completion",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "divNum" },
                                                  [
                                                    _vm._v(
                                                      "填空" + _vm._s(index + 1)
                                                    ),
                                                  ]
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: item.value,
                                                      expression: "item.value",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: "请填写",
                                                  },
                                                  domProps: {
                                                    value: item.value,
                                                  },
                                                  on: {
                                                    input: [
                                                      function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          item,
                                                          "value",
                                                          $event.target.value
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.completionChange(
                                                          1,
                                                          item,
                                                          _vm.problemItem.id,
                                                          _vm.problemItem.tqType
                                                        )
                                                      },
                                                    ],
                                                  },
                                                }),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm.problemItem.tqType == 5
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "item-option-completion",
                                        },
                                        [
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.problemItem.value,
                                                  expression:
                                                    "problemItem.value",
                                                },
                                              ],
                                              staticClass:
                                                "w-100 border rounded",
                                              attrs: { placeholder: "请填写" },
                                              domProps: {
                                                value: _vm.problemItem.value,
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.problemItem,
                                                      "value",
                                                      $event.target.value
                                                    )
                                                  },
                                                  function ($event) {
                                                    return _vm.completionChange(
                                                      1,
                                                      _vm.problemItem.value,
                                                      _vm.problemItem.id,
                                                      _vm.problemItem.tqType
                                                    )
                                                  },
                                                ],
                                              },
                                            }),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.problemItem.tqType == 6
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.problemItem.children,
                                        function (item, index) {
                                          return _c("div", { key: index }, [
                                            item.tqContentOb.title
                                              ? _c("div", {
                                                  staticClass:
                                                    "d-flex problemtitle-combination",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item.tqContentOb.title
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            item.tqType == 1
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    item.tqContentOb.options,
                                                    function (
                                                      itemChildren,
                                                      childrenIndex
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: childrenIndex,
                                                          staticClass:
                                                            "option-choice",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.choiceItemClick(
                                                                2,
                                                                itemChildren,
                                                                item.id,
                                                                item.tqType
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-items-center choiceItem",
                                                              class:
                                                                itemChildren.sel ==
                                                                1
                                                                  ? "item-option-choice-sel"
                                                                  : "item-option-choice",
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src:
                                                                    itemChildren.sel ==
                                                                    1
                                                                      ? _vm.icon_xz
                                                                      : _vm.icon_wxz,
                                                                  alt: "",
                                                                },
                                                              }),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "con",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .answerList[
                                                                        index
                                                                      ]
                                                                    ) + "、"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("div", {
                                                                staticClass:
                                                                  "con",
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      itemChildren.value
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            item.tqType == 2
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    item.tqContentOb.options,
                                                    function (
                                                      itemChildren,
                                                      childrenIndex
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: childrenIndex,
                                                          staticClass:
                                                            "option-choice",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.choiceItemClick(
                                                                2,
                                                                itemChildren,
                                                                item.id,
                                                                item.tqType
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-items-center choiceItem",
                                                              class:
                                                                itemChildren.sel ==
                                                                1
                                                                  ? "item-option-choice-sel"
                                                                  : "item-option-choice",
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src:
                                                                    itemChildren.sel ==
                                                                    1
                                                                      ? _vm.icon_xz
                                                                      : _vm.icon_wxz,
                                                                  alt: "",
                                                                },
                                                              }),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "con",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .answerList[
                                                                        index
                                                                      ]
                                                                    ) + "、"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("div", {
                                                                staticClass:
                                                                  "con",
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      itemChildren.value
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            item.tqType == 3
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    item.tqContentOb.options,
                                                    function (
                                                      itemChildren,
                                                      childrenIndex
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: childrenIndex,
                                                          staticClass:
                                                            "option-choice",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.choiceItemClick(
                                                                2,
                                                                itemChildren,
                                                                item.id,
                                                                item.tqType
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-items-center choiceItem",
                                                              class:
                                                                itemChildren.sel ==
                                                                1
                                                                  ? "item-option-choice-sel"
                                                                  : "item-option-choice",
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src:
                                                                    itemChildren.sel ==
                                                                    1
                                                                      ? _vm.icon_xz
                                                                      : _vm.icon_wxz,
                                                                  alt: "",
                                                                },
                                                              }),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "con",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm
                                                                        .answerList[
                                                                        index
                                                                      ]
                                                                    ) + "、"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("div", {
                                                                staticClass:
                                                                  "con",
                                                                domProps: {
                                                                  innerHTML:
                                                                    _vm._s(
                                                                      itemChildren.value
                                                                    ),
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            item.tqType == 4
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    item.tqContentOb.options,
                                                    function (
                                                      itemChildren,
                                                      childrenIndex
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: childrenIndex,
                                                          staticClass:
                                                            "completionParent",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "item-option-completion-combination",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "divNum",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "填空" +
                                                                      _vm._s(
                                                                        childrenIndex +
                                                                          1
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      itemChildren.value,
                                                                    expression:
                                                                      "itemChildren.value",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  type: "text",
                                                                  placeholder:
                                                                    "请填写",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    itemChildren.value,
                                                                },
                                                                on: {
                                                                  input: [
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      ) {
                                                                        return
                                                                      }
                                                                      _vm.$set(
                                                                        itemChildren,
                                                                        "value",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.completionChange(
                                                                        2,
                                                                        itemChildren,
                                                                        item.id,
                                                                        item.tqType
                                                                      )
                                                                    },
                                                                  ],
                                                                },
                                                              }),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            item.tqType == 5
                                              ? _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-option-completion-combination",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "d-flex",
                                                        },
                                                        [
                                                          _c("textarea", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  item.value,
                                                                expression:
                                                                  "item.value",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "w-100 border rounded",
                                                            attrs: {
                                                              placeholder:
                                                                "请填写",
                                                            },
                                                            domProps: {
                                                              value: item.value,
                                                            },
                                                            on: {
                                                              input: [
                                                                function (
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event
                                                                      .target
                                                                      .composing
                                                                  ) {
                                                                    return
                                                                  }
                                                                  _vm.$set(
                                                                    item,
                                                                    "value",
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                },
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.completionChange(
                                                                    2,
                                                                    item.value,
                                                                    item.id,
                                                                    item.tqType
                                                                  )
                                                                },
                                                              ],
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "bottom border-top d-flex align-items-center justify-content-between",
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-center",
                                  class:
                                    _vm.problemItem.isMark == 1
                                      ? "btnBJ-XZ"
                                      : "btnBJ-WXZ",
                                  on: {
                                    click: function ($event) {
                                      return _vm.buttomBntClick(1)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont iconzu74978",
                                    staticStyle: {
                                      "font-size": "14px",
                                      "margin-right": "1rem",
                                    },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.problemItem.isMark == 1
                                          ? "已标记"
                                          : "标记"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "d-flex" }, [
                                _vm.problemItemIndex !== 0
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btnSYT",
                                        on: {
                                          click: function ($event) {
                                            return _vm.submitSingleTopic("pre")
                                          },
                                        },
                                      },
                                      [_vm._v(" 上一题 ")]
                                    )
                                  : _vm._e(),
                                _vm.problemItemIndex !== this.allList.length - 1
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btnXYT",
                                        on: {
                                          click: function ($event) {
                                            return _vm.submitSingleTopic("next")
                                          },
                                        },
                                      },
                                      [_vm._v(" 下一题 ")]
                                    )
                                  : _vm._e(),
                                _vm.problemItemIndex === this.allList.length - 1
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btnSYT",
                                        on: { click: _vm.clickPaperBtn },
                                      },
                                      [_vm._v(" 交卷 ")]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "cardBox d-flex flex-column",
                          staticStyle: { height: "calc(100vh - 5rem)" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "border-bottom title d-flex align-items-center justify-content-between",
                            },
                            [
                              _c("div", { staticClass: "t1" }, [
                                _vm._v("答题卡"),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center selType",
                                },
                                [
                                  _c("div", {
                                    staticClass: "rounded-circle img cardItem2",
                                  }),
                                  _c("div", [
                                    _vm._v("已答" + _vm._s(_vm.answerNum)),
                                  ]),
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/img/icon_wxz.png"),
                                      alt: "",
                                    },
                                  }),
                                  _c("div", [
                                    _vm._v("未答" + _vm._s(_vm.unAnswerNum)),
                                  ]),
                                  _c("div", {
                                    staticClass: "rounded-circle img cardItem3",
                                  }),
                                  _c("div", [
                                    _vm._v("标记" + _vm._s(_vm.isMarkCount)),
                                  ]),
                                  _c("div", {
                                    staticClass: "rounded-circle img cardItem5",
                                  }),
                                  _c("div", [_vm._v("当前")]),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "cardBox-body",
                              staticStyle: { flex: "1" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "row row-cols-6" },
                                _vm._l(_vm.allList, function (item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass:
                                        "d-flex align-items-center justify-content-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center justify-content-center cardItem",
                                          class:
                                            _vm.problemItemIndex == index
                                              ? "cardItem5"
                                              : item.isMarkType == 1
                                              ? "cardItem2"
                                              : item.isMarkType == "2"
                                              ? "cardItem4"
                                              : item.isMarkType == "3"
                                              ? "cardItem3"
                                              : "cardItem3",
                                          on: {
                                            click: function ($event) {
                                              return _vm.submitSingleTopic(
                                                "card",
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              " " + _vm._s(index + 1) + " "
                                            ),
                                          ]),
                                          item.isMarkType == 3 ||
                                          item.isMarkType == 4
                                            ? _c("div", [
                                                _c("i", {
                                                  staticClass:
                                                    "iconfont iconzu74978",
                                                  staticStyle: {
                                                    "font-size": "14px",
                                                  },
                                                }),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c("canvas", {
        staticStyle: { display: "none" },
        attrs: {
          id: "canvasCamera",
          width: _vm.videoWidth,
          height: _vm.videoHeight,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            top: "40vh",
            visible: _vm.errorDialogVisible,
            "show-close": false,
            width: "25%",
            center: "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.errorDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center justify-content-center" },
            [_vm._v(" " + _vm._s(_vm.tips) + " ")]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.correctBtnText
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.closeCorrectDialog },
                    },
                    [_vm._v(_vm._s(_vm.correctBtnText))]
                  )
                : _vm._e(),
              _vm.errorBtnText
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.closeErrorDialog },
                    },
                    [_vm._v(_vm._s(_vm.errorBtnText))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }