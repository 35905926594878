"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.YsPay = YsPay;
exports.aliPay = aliPay;
exports.buyFreeCourse = void 0;
exports.checkCouponRedeemCode = checkCouponRedeemCode;
exports.couponRefund = couponRefund;
exports.findByAssocId = findByAssocId;
exports.getOrderInfo = getOrderInfo;
exports.getShipCode = getShipCode;
exports.isSellOnLine = isSellOnLine;
exports.payWithCoupon = payWithCoupon;
exports.queryAliPay = queryAliPay;
exports.queryPayWay = queryPayWay;
exports.ysOrderRefund = ysOrderRefund;
var _request = _interopRequireDefault(require("@/utils/request.js"));
var _auth = require("@/utils/auth");
// 查询收款账户开通状态
function findByAssocId() {
  return (0, _request.default)({
    url: '/zyg/payservice/pay4Account/findByAssocId?assocId=' + (0, _auth.getAssocId)(),
    method: 'get'
  });
}
// 查询协会是否开通支付宝
function queryAliPay() {
  return (0, _request.default)({
    url: '/zyg/api/finance/isOpenAliPay'
  });
}
// 查询协会是否开通各种支付方式（支付宝、银盛）
function queryPayWay() {
  return (0, _request.default)({
    url: '/zyg/payService/customer/assocPayWay'
  });
}
// 银盛扫码支付
function YsPay(data) {
  return (0, _request.default)({
    url: '/zyg/payService/customer/yse/qrCodePay',
    method: 'GET',
    params: {
      // 订单ID
      id: data.id,
      // 支付方式 11银盛微信 12 银盛支付宝
      payWay: data.payWay
    }
  });
}
function ysOrderRefund(data) {
  return (0, _request.default)({
    url: '/zyg/payService/customer/orderRefundCreate',
    method: 'post',
    data: data
  });
}
// 支付宝支付
function aliPay(data) {
  return (0, _request.default)({
    url: '/zyg/payService/orderPay',
    method: 'post',
    data: data
  });
}
// 获取订单信息
function getOrderInfo(orderId) {
  return (0, _request.default)({
    url: '/order/info',
    method: 'post',
    params: {
      id: orderId
    }
  });
}
// 获取支付二维码
function getShipCode(data) {
  return (0, _request.default)({
    headers: {
      uid: (0, _auth.getAssocId)()
    },
    url: '/payservice/pay4MemberDuesBill/generatePay4YseMembershipCode',
    method: 'post',
    data: data
  });
}

// 抵用券支付
function payWithCoupon(couponRedeemCode, orderId) {
  return (0, _request.default)({
    headers: {
      xid: (0, _auth.getAssocId)()
    },
    url: '/zyg/coupon/payWithCoupon',
    method: 'post',
    params: {
      couponRedeemCode: couponRedeemCode,
      orderId: orderId
    }
  });
}

// 检查兑换码
function checkCouponRedeemCode(couponRedeemCode, orderId) {
  return (0, _request.default)({
    headers: {
      xid: (0, _auth.getAssocId)()
    },
    url: '/zyg/coupon/checkCouponRedeemCode',
    method: 'get',
    params: {
      couponRedeemCode: couponRedeemCode,
      orderId: orderId
    }
  });
}

// 抵用券购买后退款
function couponRefund(orderId, remark) {
  return (0, _request.default)({
    headers: {
      xid: (0, _auth.getAssocId)()
    },
    url: '/zyg/coupon/couponRefund',
    method: 'post',
    params: {
      orderId: orderId,
      remark: remark
    }
  });
}
var buyFreeCourse = exports.buyFreeCourse = function buyFreeCourse(_ref) {
  var orderId = _ref.orderId,
    trainId = _ref.trainId;
  return (0, _request.default)({
    url: '/zyg/web/training/learner/freeBuy/auto/pay',
    method: 'POST',
    data: {
      // 订单ID
      orderId: orderId,
      // 培训项目ID
      trainId: trainId
    }
  });
};
// 查询协会是否开启线上售课程
function isSellOnLine() {
  return (0, _request.default)({
    method: 'get',
    url: '/zyg/web/training/learner/assocConfig/getGroupDetails',
    params: {
      group: 'studyExam',
      code: 'sellOnLine'
    }
  });
}