"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIPs = getIPs;
exports.getUserIP = getUserIP;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.match.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.array.splice.js");
function getUserIP(onNewIP) {
  var MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
  var pc = new MyPeerConnection({
    iceServers: []
  });
  var noop = function noop() {};
  var localIPs = {};
  var ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
  var iterateIP = function iterateIP(ip) {
    if (!localIPs[ip]) onNewIP(ip);
    localIPs[ip] = true;
  };
  pc.createDataChannel('');
  pc.createOffer().then(function (sdp) {
    sdp.sdp.split('\n').forEach(function (line) {
      if (line.indexOf('candidate') < 0) return;
      line.match(ipRegex).forEach(iterateIP);
    });
    pc.setLocalDescription(sdp, noop, noop);
  }).catch(function (reason) {});
  pc.onicecandidate = function (ice) {
    if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
  };
}
function getIPs() {
  var _this = this;
  var RTCPeerConnection = window.RTCPeerConnection || window.webkitRTCPeerConnection || window.mozRTCPeerConnection;
  if (RTCPeerConnection) (function () {
    var rtc = new RTCPeerConnection({
      iceServers: []
    });
    if (1 || window.mozRTCPeerConnection) {
      rtc.createDataChannel('', {
        reliable: false
      });
    }
    ;
    rtc.onicecandidate = function (evt) {
      if (evt.candidate) grepSDP("a=" + evt.candidate.candidate);
    };
    rtc.createOffer(function (offerDesc) {
      grepSDP(offerDesc.sdp);
      rtc.setLocalDescription(offerDesc);
    }, function (e) {
      console.warn("offer failed", e);
    });
    var addrs = Object.create(null);
    addrs["0.0.0.0"] = false;
    function updateDisplay(newAddr) {
      if (newAddr in addrs) return;else addrs[newAddr] = true;
      var displayAddrs = Object.keys(addrs).filter(function (k) {
        return addrs[k];
      });
      for (var i = 0; i < displayAddrs.length; i++) {
        if (displayAddrs[i].length > 16) {
          displayAddrs.splice(i, 1);
          i--;
        }
      }
      console.log('内网ip', displayAddrs[0]); //打印出内网ip
      _this.user.ip_in = displayAddrs[0]; //获取内网ip
    }
    function grepSDP(sdp) {
      var hosts = [];
      sdp.split('\r\n').forEach(function (line, index, arr) {
        if (~line.indexOf("a=candidate")) {
          var parts = line.split(' '),
            addr = parts[4],
            type = parts[7];
          if (type === 'host') updateDisplay(addr);
        } else if (~line.indexOf("c=")) {
          var parts = line.split(' '),
            addr = parts[2];
          updateDisplay(addr);
        }
      });
    }
  })();else {
    console.log("请使用主流浏览器：chrome,firefox,opera,safari");
  }
}