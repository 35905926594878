"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteCartCourseByIds = exports.deleteCartCourse = exports.createOrderForCart = exports.courseClazz = exports.courseAddCart = exports.changeCartGoodsNum = exports.cartList = exports.allCourseList = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
// 个人中心 课程列表-- 加入购物车
var courseAddCart = exports.courseAddCart = function courseAddCart(data) {
  return (0, _request.default)({
    url: '/zyg/web/training/learner/cart',
    method: 'POST',
    data: data
  });
};
// 个人中心  购物车列表
var cartList = exports.cartList = function cartList(_ref) {
  var page = _ref.page,
    size = _ref.size;
  return (0, _request.default)({
    url: '/zyg/web/training/learner/cart/list',
    method: 'GET',
    params: {
      page: page,
      size: size
    }
  });
};
// 个人中心 课程列表-- 删除购物车课程
var deleteCartCourse = exports.deleteCartCourse = function deleteCartCourse(id) {
  return (0, _request.default)({
    url: "/zyg/web/training/learner/cart/".concat(id),
    method: 'DELETE'
  });
};
// 个人中心 课程列表-- 删除购物车课程
var deleteCartCourseByIds = exports.deleteCartCourseByIds = function deleteCartCourseByIds(data) {
  return (0, _request.default)({
    url: "/zyg/web/training/learner/cart",
    method: 'DELETE',
    data: data
  });
};

// 个人中心 课程列表-- 变更购物车商品购买数量
var changeCartGoodsNum = exports.changeCartGoodsNum = function changeCartGoodsNum(data) {
  return (0, _request.default)({
    url: "/zyg/web/training/learner/cart/goodsNum/".concat(data.id),
    method: 'PUT',
    params: {
      goodsNum: data.goodsNum
    }
  });
};
// 全部课程列表
var allCourseList = exports.allCourseList = function allCourseList(_ref2) {
  var page = _ref2.page,
    size = _ref2.size,
    classId = _ref2.classId,
    keyword = _ref2.keyword,
    freeBuy = _ref2.freeBuy;
  return (0, _request.default)({
    url: '/zyg/web/training/learner/item/list',
    method: 'GET',
    params: {
      page: page,
      size: size,
      classId: classId,
      keyword: keyword,
      freeBuy: freeBuy
    }
  });
};
// 课程分类
var courseClazz = exports.courseClazz = function courseClazz(type) {
  return (0, _request.default)({
    url: '/zyg/web/training/learner/clazz',
    method: 'GET',
    params: {
      type: type //  分类类型[1试卷 2培训 3讲师  4题库 5证书 6课程]
    }
  });
};

// 个人中心 批量采购-- 创建订单
var createOrderForCart = exports.createOrderForCart = function createOrderForCart(data) {
  return (0, _request.default)({
    url: '/zyg/web/order/shoppingCart/generation',
    method: 'POST',
    data: data
  });
};