"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buyExamPlanOrder = buyExamPlanOrder;
exports.generateOrder = generateOrder;
var _request = _interopRequireDefault(require("@/utils/request"));
// 生成培训订单
function generateOrder(courseId) {
  return (0, _request.default)({
    url: '/order/buyTrain',
    method: 'post',
    params: {
      courseId: courseId
    }
  });
}

// 生成考试报名订单
function buyExamPlanOrder(examPlanId) {
  return (0, _request.default)({
    url: 'zyg/order/buyExam',
    method: 'post',
    params: {
      examPlanId: examPlanId
    }
  });
}