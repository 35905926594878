"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _train = require("@/api/train");
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  provide: function provide() {
    return {
      goBack: this.goBack
    };
  },
  data: function data() {
    return {
      g8gbiwebn: ''
    };
  },
  created: function created() {
    this.getValidateTypeFUN(this.$route.params.trainId);
  },
  mounted: function mounted() {},
  methods: {
    getValidateTypeFUN: function getValidateTypeFUN(id) {
      var _this = this;
      (0, _train.getValidateType)(id).then(function (res) {
        if (res.code == 200) {
          _this.g8gbiwebn = res.data;
        } else {
          _this.$message.error(res.message);
        }
      }).catch(function () {});
    },
    goBack: function goBack() {
      this.$router.go(-1);
    }
  }
};