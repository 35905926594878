var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "newsListContainer" }, [
    _c(
      "div",
      { staticClass: "newsTitle" },
      [
        _c("NewsTitle", {
          attrs: { title: _vm.title, isShowBtn: _vm.isShowBtn },
          on: { more: _vm.handleMore },
        }),
      ],
      1
    ),
    _vm.list.length > 0
      ? _c(
          "ul",
          { staticClass: "newsContentInner" },
          _vm._l(_vm.list, function (item, index) {
            return _c("li", { key: index, staticClass: "newsItem" }, [
              _c("div", { staticClass: "news" }, [
                _c("div", { staticClass: "circle" }),
                _c(
                  "div",
                  {
                    staticClass: "newsText",
                    on: {
                      click: function ($event) {
                        return _vm.goDetail(item)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.title) + " ")]
                ),
              ]),
              _c("div", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.formatCreatetime(item.createTime))),
              ]),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }