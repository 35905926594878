"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAssocToken = getAssocToken;
var _request = _interopRequireDefault(require("@/utils/request"));
var _setFavicon = _interopRequireDefault(require("@/utils/setFavicon"));
// 设置favicon图标

// 获取当前环境变量
var NODE_ENV = process.env.NODE_ENV;
// 默认值：从地址栏获取域名
// let pcTrainWebUrl = window.location.hostname
// const pcTrainWebUrl = location.hostname === 'localhost' ? '135pxdev.hangxintong.cn' : location.hostname
// const pcTrainWebUrl = location.hostname === 'localhost' ? 'btpx.hangxintong.cn' : location.hostname // 包头鸿博
var pcTrainWebUrl = location.hostname === 'localhost' ? '805pxdev.hangxintong.cn' : location.hostname;
// const pcTrainWebUrl = location.hostname === 'localhost' ? 'njxpx.hangxintong.cn' : location.hostname
// if (NODE_ENV === 'development') {
// 当前环境是开发环境时，设置域名为内建协
// pcTrainWebUrl = 'njxpx.hangxintong.cn'
// pcTrainWebUrl =
// pcTrainWebUrl = 'nxcpzxpx.hangxintong.cn'
// 开发环境设置域名为赤峰协会
// pcTrainWebUrl = 'cfpx.hangxintong.cn'
// pcTrainWebUrl = '147px.dev.hangxintong.cn'
// }
// const hostName = '210pxdev.hangxintong.cn'
// const hostName = '135px.dev.hangxintong.cn'
// const pcTrainWebUrl = location.hostname === 'localhost' ? hostName : location.hostname

(0, _setFavicon.default)(pcTrainWebUrl);
function getAssocToken() {
  return (0, _request.default)({
    url: '/tokenConfig/getAssocToken',
    method: 'get',
    params: {
      pcTrainWebUrl: pcTrainWebUrl
    }
  });
}