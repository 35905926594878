"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Topnav = _interopRequireDefault(require("./Topnav.vue"));
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ThemeSecondHeader',
  data: function data() {
    return {};
  },
  components: {
    TopNav: _Topnav.default
  },
  computed: {},
  methods: {}
};