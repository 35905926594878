var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contactWrap" }, [
    _vm.isShow
      ? _c("h1", { staticClass: "contactTitle" }, [_vm._v("联系我们")])
      : _vm._e(),
    _vm.isShow
      ? _c("div", { staticClass: "info" }, [
          _c("div", { staticClass: "infoTitle" }, [_vm._v("技术支持：")]),
          _vm._m(0),
        ])
      : _c("div", { staticClass: "info emptyInfo" }, [_vm._v("暂无数据")]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "infoItem" }, [
        _c("span", [_vm._v("技术QQ号1:")]),
        _c("span", [_vm._v("1371954772")]),
      ]),
      _c("div", { staticClass: "infoItem" }, [
        _c("span", [_vm._v("技术QQ号2:")]),
        _c("span", [_vm._v("357519603")]),
      ]),
      _c("div", { staticClass: "infoItem" }, [
        _c("span", [_vm._v("技术QQ号3:")]),
        _c("span", [_vm._v("415871084")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }