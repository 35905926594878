var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "app-title" }, [_vm._v("我参与的")]),
        _c("exam-List", {
          attrs: {
            contentcontent: _vm.contentcontent,
            total: _vm.total,
            size: _vm.listQuery.size,
            currentp: _vm.listQuery.page,
          },
          on: { changeSize: _vm.changeSize },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }