"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _toConsumableArray2 = _interopRequireDefault(require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/views/examinationSystem/layout"));
var _theme = require("./theme");
var _networkTraining = _interopRequireDefault(require("@/router/networkTraining"));
var _examinationSystem = _interopRequireDefault(require("@/router/examinationSystem"));
var _fillOutTheForm = _interopRequireDefault(require("@/router/fillOutTheForm"));
_vue.default.use(_vueRouter.default);

/* Layout */

//根据协会id，返回首页、二级页的router和layout布局,
var Layout = _theme.asyncRouter.Layout,
  defaultCustomRouter = _theme.asyncRouter.defaultCustomRouter;
// import LayoutNormal from '@/layout' // 版本1layout
// import LayoutVersion2 from '@/layout/CfLayout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [].concat((0, _toConsumableArray2.default)(defaultCustomRouter), [
// 加入首页和列表页路由（这个路由根据协会改变）
_networkTraining.default,
// 个人中心路由
_examinationSystem.default,
// 考试相关路由
_fillOutTheForm.default, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/landing',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/landing/index'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/cannotAccess',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/abnormal/cannotAccess'));
    });
  },
  hidden: true,
  meta: {
    visitFlag: true,
    title: '无法访问',
    icon: 'cannotAccess'
  }
}, {
  path: '/contact',
  component: Layout,
  hidden: true,
  meta: {
    visitFlag: false,
    title: '联系我们',
    icon: 'cannotAccess'
  },
  redirect: '/contact/us',
  children: [{
    path: '/contact/us',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/contact/index.vue'));
      });
    },
    meta: {
      visitFlag: false,
      title: '联系我们',
      icon: 'cannotAccess'
    }
  }]
},
// {
//    path:'/teachinfo',
//    component:()=>import('@/views/teachinfo/index'),
//    hidden: true
// },
{
  path: '/memberToTrain',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/memberToTrain'));
    });
  },
  hidden: true
},
// {
//   // 首页
//   path: '/',
//   component: Layout,
//   redirect: '/dashboard',
//   children: [
//     {
//       path: 'dashboard',
//       name: 'index',
//       component: () => import('@/views/dashboard/index'),
//       meta: {
//         title: '',
//         icon: 'dashboard',
//       },
//     },
//   ],
// },
// {
//   // 全部课程
//   path: '/',
//   component: Layout,
//   children: [
//     {
//       path: 'allcourses',
//       name: 'allcoursespage',
//       component: () => import('@/views/allcourses/allcoursespage'),
//       meta: {
//         title: '全部课程',
//         icon: 'allcourses',
//       },
//     },
//   ],
// },

{
  // 通告
  path: '/',
  component: Layout,
  children: [{
    path: 'announcement',
    name: 'announcementpage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/announcement/announcementpage'));
      });
    },
    meta: {
      title: '通知公告',
      icon: 'announcementpage'
    }
  }]
},
// {
//   //全部考试 父级
//   path: "/",
//   component: Layout,
//   children: [{
//     path: "examination",
//     name: "examination",
//     component: () => import("@/views/examination/examinationpage"),
//     meta: {
//       title: "全部考试",
//       icon: "announcementpage"
//     }
//   }]
// },
{
  // 全部考试 子级
  path: '/',
  component: _layout.default,
  children: [{
    path: '/allExamination/:page',
    name: 'allExamination',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/examination/allExamination'));
      });
    },
    meta: {
      visitFlag: true,
      title: '全部考试',
      icon: 'announcementpage'
    }
  }]
}, {
  // 全部考试 子级
  path: '/',
  component: _layout.default,
  children: [{
    path: 'forMe/:page',
    name: 'forMe',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/examination/forMe'));
      });
    },
    meta: {
      visitFlag: true,
      title: '全部考试',
      icon: 'announcementpage'
    }
  }]
}, {
  // 考试详情
  path: '/',
  component: _layout.default,
  children: [{
    path: 'examdetail/:id',
    name: 'examdetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/examination/examinationDetails'));
      });
    },
    meta: {
      visitFlag: true,
      title: '考试详情',
      icon: 'announcementpage'
    }
  }]
}, {
  // 等待考试
  path: '/',
  component: _layout.default,
  children: [{
    path: 'waitingForTheExam/:id',
    name: 'waitingForTheExam',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/examination/waitingForTheExam'));
      });
    },
    meta: {
      visitFlag: true,
      title: '等待考试',
      icon: 'announcementpage'
    }
  }]
}, {
  // 开始考试（正式考试）
  path: '/',
  component: _layout.default,
  children: [{
    path: 'startExamination/:id',
    name: 'startExamination',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/examination/startExamination'));
      });
    },
    meta: {
      visitFlag: true,
      title: '开始考试',
      icon: 'announcementpage'
    }
  }]
}, {
  // 开始考试（模拟考试）
  path: '/',
  component: _layout.default,
  children: [{
    path: 'mockExamination/:id',
    name: 'mockExamination',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/examination/MockExamination'));
      });
    },
    meta: {
      visitFlag: true,
      title: '开始考试',
      icon: 'announcementpage'
    }
  }]
}, {
  // 自定义表单
  path: '/',
  component: _layout.default,
  children: [{
    path: 'dynamicForm/:id',
    name: 'dynamicForm',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/examination/dynamicForm'));
      });
    },
    meta: {
      visitFlag: true,
      title: '动态表单',
      icon: 'announcementpage'
    }
  }]
}, {
  // 考试结果
  path: '/',
  component: _layout.default,
  children: [{
    path: 'JudgingPapers',
    name: 'JudgingPapers',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/examination/JudgingPapers'));
      });
    },
    meta: {
      visitFlag: true,
      title: '等待结果',
      icon: 'announcementpage'
    }
  }]
}, {
  // 考试缴费
  path: '/',
  component: _layout.default,
  children: [{
    path: 'ExaminationFee/:id',
    name: 'ExaminationFee',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/examination/ExaminationFee'));
      });
    },
    meta: {
      visitFlag: true,
      title: '考试缴费',
      icon: 'announcementpage'
    }
  }]
}, {
  // 查看答案
  path: '/',
  component: _layout.default,
  children: [{
    path: 'showAnswers',
    name: 'showAnswers',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/examination/showAnswers'));
      });
    },
    meta: {
      visitFlag: true,
      title: '考题答案',
      icon: 'announcementpage'
    }
  }]
}, {
  // 查看证书
  path: '/',
  component: _layout.default,
  children: [{
    path: 'myCertificateDetails/:id',
    name: 'myCertificateDetails',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/examination/myCertificateDetails'));
      });
    },
    meta: {
      visitFlag: true,
      title: '查看证书',
      icon: 'announcementpage'
    }
  }]
}, {
  // 通告详情
  path: '/',
  component: Layout,
  // redirect: '/announcement',
  children: [{
    path: 'announcementdetails/:id',
    name: 'announcementdetails',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/announcementdetails/announcementdetails'));
      });
    },
    meta: {
      title: '通告详情',
      icon: 'announcementdetails'
    }
  }]
}, {
  // 支付
  path: '/',
  component: Layout,
  // redirect: '/payment',
  children: [{
    path: '/payment/:id',
    name: 'paymentpage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/payment/paymentpage'));
      });
    },
    meta: {
      title: '确认支付',
      icon: 'payment'
    }
  }, {
    path: '/ExaminationPayment',
    name: 'ExaminationPayment',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/payment/ExaminationPayment'));
      });
    },
    meta: {
      title: '考试支付',
      icon: 'ExaminationPayment'
    }
  }]
}, {
  path: '/fillOutTheForm/sno2ij928vn/',
  component: Layout,
  hidden: true,
  children: [{
    path: '/fillOutTheForm/sno2ij928vn/',
    name: 'sno2ij928vn',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/fillOutTheForm/formInfo/sno2ij928vn'));
      });
    },
    meta: {
      title: 'sno2ij928vn',
      icon: 'sno2ij928vn'
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/personalCenter',
  children: [
  // {
  //   path: 'personalcenterTab',
  //   name: 'personalcenterTab',
  //   component: () =>
  //     import('@/views/personalCenter/personalcenterTab'),
  //   meta: {
  //     title: '个人中心'
  //   },
  //   children: [
  //     {
  //       path: "personalcenterInfo",
  //       component: () =>
  //         import('@/views/personalCenter/personalcenter/personalInfo'),
  //     }
  //   ]
  // },
  {
    path: 'allexam',
    name: 'allexam',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/exam/allexampage'));
      });
    },
    meta: {
      title: '个人中心'
    }
  }, {
    path: 'allexam/:page',
    name: 'allexam1',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/exam/allexampage'));
      });
    },
    meta: {
      title: '个人中心'
    }
  }, {
    // 首页
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [{
      path: 'dashboard',
      name: 'Dashboard',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
        });
      },
      meta: {
        title: '',
        icon: 'dashboard'
      }
    }]
  }, {
    // 全部课程
    path: '/',
    component: Layout,
    // redirect: '/allcourses',
    children: [{
      path: 'allcourses',
      name: 'allcoursespage',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/allcourses/newAllcoursespage.vue'));
        });
      },
      meta: {
        title: '全部课程',
        icon: 'allcourses'
      }
    }]
  }, {
    // 通告
    path: '/',
    component: Layout,
    // redirect: '/announcement',
    children: [{
      path: 'announcement',
      name: 'announcementpage',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/announcement/announcementpage'));
        });
      },
      meta: {
        title: '通知公告',
        icon: 'announcementpage'
      }
    }]
  }, {
    // 通告详情
    path: '/',
    component: Layout,
    // redirect: '/announcement',
    children: [{
      path: 'announcementdetails/:id',
      name: 'announcementdetails',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/announcementdetails/announcementdetails'));
        });
      },
      meta: {
        title: '通告详情',
        icon: 'announcementdetails'
      }
    }]
  }, {
    // 支付
    path: '/',
    component: Layout,
    // redirect: '/payment',
    children: [{
      path: '/payment/:id',
      name: 'paymentpage',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/payment/paymentpage'));
        });
      },
      meta: {
        title: '确认支付',
        icon: 'payment'
      }
    }]
  },
  // {
  //   path: "/payment",
  //   component: Layout,
  //   hidden: true,
  //   children: [
  //     {
  //       path: "/payment/:id",
  //       name: "paymentpage",
  //       component: () => import("@/views/payment/paymentpage"),
  //       meta: { title: "payment", icon: "payment" }
  //     }
  //   ]
  // },
  // {
  //   path: "/",
  //   component: Layout,
  //   redirect: "/personalCenter",
  //   children: [{
  //     path: "personalCenter",
  //     name: "personalcenterTab",
  //     component: () => import("@/views/personalCenter/personalcenterTab"),
  //     meta: {
  //       title: "个人中心"
  //     }
  //   }]
  // },
  {
    path: '/',
    component: Layout,
    redirect: '/orderdetails',
    children: [{
      path: 'orderdetails',
      name: 'orderdetailspage',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/orderdetails/orderdetailspage'));
        });
      },
      meta: {
        title: '订单详情'
      }
    }]
  }, {
    path: '/example',
    component: Layout,
    redirect: '/example/table',
    name: 'Example',
    meta: {
      title: 'Example',
      icon: 'el-icon-s-help'
    },
    children: [{
      path: 'table',
      name: 'Table',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/table/index'));
        });
      },
      meta: {
        title: 'Table',
        icon: 'table'
      }
    }, {
      path: 'tree',
      name: 'Tree',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/tree/index'));
        });
      },
      meta: {
        title: 'Tree',
        icon: 'tree'
      }
    }]
  }, {
    path: 'myexam',
    name: 'myexam',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/exam/myexampage'));
      });
    },
    meta: {
      title: '个人中心'
    }
  }, {
    path: 'myexam/:page',
    name: 'myexam1',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/exam/myexampage'));
      });
    },
    meta: {
      title: '个人中心'
    }
  }, {
    path: 'allexam',
    name: 'allexam',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/exam/allexampage'));
      });
    },
    meta: {
      title: '个人中心'
    }
  }, {
    path: 'allexam/:page',
    name: 'allexam1',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/exam/allexampage'));
      });
    },
    meta: {
      title: '个人中心'
    }
  }, {
    path: 'myexam',
    name: 'myexam',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/exam/myexampage'));
      });
    },
    meta: {
      title: '个人中心'
    }
  }, {
    path: 'myexam/:page',
    name: 'myexam1',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/exam/myexampage'));
      });
    },
    meta: {
      title: '个人中心'
    }
  }, {
    path: 'certificate/:page',
    name: 'myexam1',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/certificate'));
      });
    },
    meta: {
      title: '个人中心'
    }
  }, {
    path: 'certificatecopy',
    name: 'myexam1',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/certificatecopy'));
      });
    },
    meta: {
      title: '个人中心--证书列表'
    }
  }]
}, {
  path: '/',
  component: Layout,
  redirect: '/orderdetails',
  children: [{
    path: 'orderdetails',
    name: 'orderdetailspage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/orderdetails/orderdetailspage'));
      });
    },
    meta: {
      title: '订单详情'
    }
  }]
}, {
  path: '/example',
  component: Layout,
  redirect: '/example/table',
  name: 'Example',
  meta: {
    title: 'Example',
    icon: 'el-icon-s-help'
  },
  children: [{
    path: 'table',
    name: 'Table',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/table/index'));
      });
    },
    meta: {
      title: 'Table',
      icon: 'table'
    }
  }, {
    path: 'tree',
    name: 'Tree',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/tree/index'));
      });
    },
    meta: {
      title: 'Tree',
      icon: 'tree'
    }
  }]
}, {
  path: '/form',
  component: Layout,
  children: [{
    path: 'index',
    name: 'Form',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/form/index'));
      });
    },
    meta: {
      title: 'Form',
      icon: 'form'
    }
  }]
},
// 详情
{
  path: '/',
  component: Layout,
  redirect: '/teachinfo/:id',
  children: [{
    path: '/teachinfo/:id',
    name: 'teachinfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/teachinfo/index'));
      });
    }
  }]
}, {
  path: 'certificatecopy',
  name: 'myexam1',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/personalCenter/personalcenter/certificatecopy'));
    });
  },
  meta: {
    title: '个人中心--证书列表'
  }
}, {
  path: '/',
  component: Layout,
  redirect: '/orderdetails',
  children: [{
    path: 'orderdetails',
    name: 'orderdetailspage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/orderdetails/orderdetailspage'));
      });
    },
    meta: {
      title: '订单详情'
    }
  }]
}, {
  path: '/example',
  component: Layout,
  redirect: '/example/table',
  name: 'Example',
  meta: {
    title: 'Example',
    icon: 'el-icon-s-help'
  },
  children: [{
    path: 'table',
    name: 'Table',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/table/index'));
      });
    },
    meta: {
      title: 'Table',
      icon: 'table'
    }
  }, {
    path: 'tree',
    name: 'Tree',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/tree/index'));
      });
    },
    meta: {
      title: 'Tree',
      icon: 'tree'
    }
  }]
}, {
  path: '/form',
  component: Layout,
  children: [{
    path: 'index',
    name: 'Form',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/form/index'));
      });
    },
    meta: {
      title: 'Form',
      icon: 'form'
    }
  }]
},
// 详情
{
  path: '/',
  component: Layout,
  redirect: '/teachinfo/:id',
  children: [{
    path: '/teachinfo/:id',
    name: 'teachinfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/teachinfo/index'));
      });
    }
  }]
}, {
  path: '/thirdpartener/eemcLogin/:userCode',
  name: 'thirdpartener-eemc-login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/thirdpartener/eemcLogin'));
    });
  },
  hidden: true
},
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}]);
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;