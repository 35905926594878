var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "announcementbox ",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "titleBg d-flex align-items-center justify-content-between",
        },
        [
          _c("div", { staticClass: "titleTextSy" }, [_vm._v("我获得的证书")]),
          _c(
            "button",
            {
              staticClass: "border backSy",
              on: {
                click: function ($event) {
                  return _vm.backHtml()
                },
              },
            },
            [_vm._v("返回")]
          ),
        ]
      ),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "d-flex flex-column h-100 overflow-auto box",
        staticStyle: { background: "#FFFFFF" },
      },
      [
        _c("div", { staticClass: "t1" }, [
          _vm._v(" 2020年二级建造师二建公路实务考试证明 "),
        ]),
        _c("div", { staticClass: "t2" }, [_vm._v(" 发证时间：2020-11.11 ")]),
        _c("div", {
          staticClass: "d-flex align-items-center justify-content-center",
          staticStyle: { flex: "1" },
        }),
        _c(
          "div",
          { staticClass: "d-flex align-items-center justify-content-center" },
          [_c("button", { staticClass: "btnSy" })]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }