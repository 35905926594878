var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: {
        display: "flex",
        "flex-direction": "column",
        height: "100%",
      },
    },
    [
      _vm.showType == 1
        ? _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                height: "100%",
                "min-height": "500px",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    height: "2.8rem",
                    "border-bottom": "0.1rem solid #E4E4E4",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        "align-items": "center",
                        "justify-content": "center",
                        height: "100%",
                        width: "6.3rem",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.itemClick(0)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "1",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center",
                          },
                          style: {
                            color: _vm.selIndex == 0 ? "#0060EF" : "#303030",
                          },
                        },
                        [_vm._v("全部")]
                      ),
                      _c("div", {
                        staticStyle: { height: "0.2rem", width: "100%" },
                        style: {
                          background: _vm.selIndex == 0 ? "#0060EF" : "#F5F7F9",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        "align-items": "center",
                        "justify-content": "center",
                        height: "100%",
                        width: "6.3rem",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.itemClick(1)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "1",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center",
                          },
                          style: {
                            color: _vm.selIndex == 1 ? "#0060EF" : "#303030",
                          },
                        },
                        [_vm._v("开票中")]
                      ),
                      _c("div", {
                        staticStyle: { height: "0.2rem", width: "100%" },
                        style: {
                          background: _vm.selIndex == 1 ? "#0060EF" : "#F5F7F9",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-direction": "column",
                        "align-items": "center",
                        "justify-content": "center",
                        height: "100%",
                        width: "6.3rem",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.itemClick(2)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "1",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center",
                          },
                          style: {
                            color: _vm.selIndex == 2 ? "#0060EF" : "#303030",
                          },
                        },
                        [_vm._v("已开票")]
                      ),
                      _c("div", {
                        staticStyle: { height: "0.2rem", width: "100%" },
                        style: {
                          background: _vm.selIndex == 2 ? "#0060EF" : "#F5F7F9",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticStyle: { flex: "1" } },
                [_c(_vm.currentRole, { tag: "component" })],
                1
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }