"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
var _auth = require("@/utils/auth");
var _elementUi = require("element-ui");
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      // coursesType: '2', // 1燃气  2能效中心
      userCode: '',
      form: {},
      loadingInstance: null
    };
  },
  created: function created() {
    this.userCode = this.$route.params.userCode;
    // if (this.$route.query.type) {
    //   this.coursesType = this.$route.query.type
    // }
    console.log('userCode', this.userCode, this.$route.query);
    if (!this.userCode) {
      this.loadingInstance.close();
      this.$router.replace('/');
    } else {
      this.fetchData();
    }
  },
  mounted: function mounted() {
    var options = {};
    this.loadingInstance = _elementUi.Loading.service(options);
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      console.log(111111111111111);
      this.$store.dispatch('app/domain').then(function () {
        console.log(22222222222222);
        _this.doUserLogin(_this.userCode);
      });
    },
    doUserLogin: function doUserLogin(userCode) {
      var _this2 = this;
      console.log(33333333333);
      this.$store.dispatch('user/eemcLogin', {
        usercode: userCode
      }).then(function (res) {
        console.log(res);
        _this2.$store.dispatch('user/getInfo').then(function (data) {
          console.info('user/getInfo成功', data);
          _this2.loadingInstance.close();
          // this.$router.replace('/dashboard')
          // 修改能效中心，登录成功后，跳转个人中心
          _this2.$router.replace('/personalCenter/personalStudy');
        }).catch(function (err) {
          console.warn('user/getInfo失败', err);
          _this2.loadingInstance.close();
        });
      }).catch(function (err) {
        console.warn(err);
        _this2.$message({
          message: err.message,
          type: 'warning'
        });
        _this2.$router.replace('/dashboard');
        _this2.loadingInstance.close();
      });
    }
  }
};