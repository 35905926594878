var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "teachfour" },
    [
      _c("div", { staticClass: "four-top" }, [
        _c("h2", [_vm._v("实操说明")]),
        _c("div", {
          style: { height: _vm.hightName },
          attrs: { id: "contBox" },
          domProps: { innerHTML: _vm._s(_vm.list) },
        }),
      ]),
      _c("div", { staticClass: "four-top" }, [
        _c("h2", [_vm._v("实操内容")]),
        _c("div", { staticClass: "htoul" }, [
          _c(
            "ul",
            _vm._l(_vm.oprtContent, function (item, index) {
              return _c("li", { key: index }, [
                _c("div", { staticClass: "liDiv" }, [
                  _c("p", [_vm._v("第" + _vm._s(item.handClassHour) + "课时")]),
                  item.id == "" && item.status == ""
                    ? _c(
                        "p",
                        {
                          staticStyle: { color: "#0060ef", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.photoClick(item)
                            },
                          },
                        },
                        [_vm._v(" 上传 ")]
                      )
                    : _vm._e(),
                  item.id != "" && item.status == 1
                    ? _c(
                        "p",
                        {
                          staticStyle: { color: "#edac4a", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.bcClick(item)
                            },
                          },
                        },
                        [_vm._v(" 已保存 ")]
                      )
                    : _vm._e(),
                  item.id != "" && item.status == 2
                    ? _c("p", { staticStyle: { color: "#303030" } }, [
                        _vm._v(" 已提交 "),
                      ])
                    : _vm._e(),
                ]),
                item.operationImg != ""
                  ? _c(
                      "div",
                      { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                      _vm._l(
                        item.operationImg.split(","),
                        function (item1, index1) {
                          return _c(
                            "div",
                            {
                              key: index1,
                              staticStyle: {
                                "margin-bottom": "5px",
                                display: "flex",
                                "margin-top": "10px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "100px",
                                    height: "160px",
                                    "margin-left": "5px",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    attrs: { src: item1, alt: "" },
                                  }),
                                ]
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e(),
                item.operationImg == ""
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "14px",
                          color: "#787878",
                          "margin-top": "16px",
                        },
                      },
                      [_vm._v(" 照片:0张 ")]
                    )
                  : _vm._e(),
              ])
            }),
            0
          ),
        ]),
      ]),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "branch-box",
              attrs: {
                title: _vm.textMap[_vm.dialogStatus],
                visible: _vm.dialogFormVisible,
                "close-on-click-modal": false,
                width: "40%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "20px",
                    left: "26px",
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "25px", height: "25px" },
                    attrs: {
                      src: require("../../assets/img/assoc_dialog.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c(
                "el-tabs",
                { staticClass: "table-box" },
                [
                  _c(
                    "el-form",
                    {
                      staticStyle: { margin: "0 30px" },
                      attrs: {
                        model: _vm.temp,
                        "label-position": "left",
                        "label-width": "140px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "实操描述：" } },
                        [
                          _c("el-input", {
                            staticClass: "textClassHt",
                            staticStyle: { height: "160px" },
                            attrs: {
                              type: "textarea",
                              rows: 2,
                              placeholder: "请输入内容",
                            },
                            model: {
                              value: _vm.temp.textarea,
                              callback: function ($$v) {
                                _vm.$set(_vm.temp, "textarea", $$v)
                              },
                              expression: "temp.textarea",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _vm._v(
                                "最多上传" +
                                  _vm._s(_vm.handUploadEnd) +
                                  "张图片"
                              ),
                            ]
                          ),
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                action: "",
                                "http-request": _vm.handSuccess,
                                "list-type": "picture-card",
                                "on-remove": _vm.handleRemove,
                                "on-preview": _vm.handlePictureCardPreview,
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-plus",
                                attrs: { slot: "default" },
                                slot: "default",
                              }),
                            ]
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: { visible: _vm.dialogVisible },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.dialogVisible = $event
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  width: "100%",
                                  src: _vm.dialogImageUrl,
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.updateDatalist } }, [
                    _vm._v(" 存草稿"),
                  ]),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogStatus === "create"
                            ? _vm.createData()
                            : _vm.updateData()
                        },
                      },
                    },
                    [_vm._v("提交 ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }