import { render, staticRenderFns } from "./announcementdetails.vue?vue&type=template&id=c7ed0d06&scoped=true"
import script from "./announcementdetails.vue?vue&type=script&lang=js"
export * from "./announcementdetails.vue?vue&type=script&lang=js"
import style0 from "./announcementdetails.vue?vue&type=style&index=0&id=c7ed0d06&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7ed0d06",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\阿山的项目\\pc培训（19090）\\hangxintong-pctrain\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c7ed0d06')) {
      api.createRecord('c7ed0d06', component.options)
    } else {
      api.reload('c7ed0d06', component.options)
    }
    module.hot.accept("./announcementdetails.vue?vue&type=template&id=c7ed0d06&scoped=true", function () {
      api.rerender('c7ed0d06', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/announcementdetails/announcementdetails.vue"
export default component.exports