"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkIp = checkIp;
exports.findWebsiteInfoByXId = findWebsiteInfoByXId;
exports.getAssocInfo = getAssocInfo;
exports.getIpAddress = getIpAddress;
exports.getLmAssocConfig = getLmAssocConfig;
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
function getAssocInfo() {
  return (0, _request.default)({
    url: '/tokenConfig/getAssocInfo',
    method: 'get'
  });
}

// ip《白名单》校验接口
function checkIp() {
  return (0, _request.default)({
    url: 'zyg/examSimulation/checkIp',
    method: 'get'
  });
}

// 验证IP白名单接口
function getLmAssocConfig() {
  console.log((0, _auth.getAssocId)());
  return (0, _request.default)({
    url: 'zyg/lmAssocConfig/conf/list',
    method: 'get',
    params: {
      assocId: (0, _auth.getAssocId)(),
      cfgBizGroup: 'membership'
    }
  });
}

// 验证IP白名单接口
function findWebsiteInfoByXId() {
  return (0, _request.default)({
    url: 'zyg//sitebuilder/findWebsiteInfoByXId',
    method: 'get',
    headers: {
      xId: (0, _auth.getAssocId)()
    }
  });
}

// 配置项-获取本机ip
function getIpAddress() {
  return (0, _request.default)({
    url: 'zyg/ipConfig/getIpAddress',
    method: 'get'
  });
}