"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatCreatetime = formatCreatetime;
/**
 * 格式化时间
 * @param {*} time 字符串时间对象 2022-1-1 13:3:3
 * @returns 
 */
function formatCreatetime(time) {
  if (!time || typeof time !== 'string') {
    return '必须是字符串类型';
  }
  var result = time.split(' ')[0];
  return result ? result : '';
}