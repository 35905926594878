"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
var _icon_verification_examples = _interopRequireDefault(require("@/assets/img/icon_verification_examples.png"));
var _examination = require("@/api/examination.js");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'ExamListItem',
  props: ['itemcon'],
  data: function data() {
    return {
      page: 1,
      dataList: [],
      total: 0,
      size: 6,
      loading: true,
      hasToken: '',
      searchContent: '',
      id: '',
      dialogFaceVerification: false,
      videoWidth: '254',
      videoHeight: '254',
      verification: _icon_verification_examples.default,
      imgSrc: '',
      thisCancas: null,
      thisContext: null,
      thisVideo: null,
      openVideo: false,
      bottomPicture: '',
      // 底图
      comparisonBtn: true,
      errorDialogVisible: false,
      tips: '',
      correctBtnText: '',
      errorBtnText: '',
      planId: '',
      tipsChild: '',
      dialogLoad: false
    };
  },
  computed: {
    examMethodcin: function examMethodcin() {
      var msg = '';
      switch (this.itemcon.examMethod) {
        case 0:
          msg = '定时定点';
          break;
        case 1:
          msg = '定时';
          break;
        case 2:
          msg = '自由';
          break;
      }
      return msg;
    },
    btnrighttext: function btnrighttext() {
      var status = this.itemcon.status;
      var msg = '';
      if (status == 0) {
        msg = '  报名培训项目  ';
      } else if (status == 1) {
        msg = '  报名培训项目  ';
      } else if (status == 2) {
        msg = '开始考试';
      } else {
        msg = '查看结果';
      }
      return msg;
    },
    // 处理加载数据
    handleData1: function handleData1() {
      var dataList = this.itemcon;
      // examSignUpOpen  是否开启报名 0开启（自定义表单报名） 1 未开启
      // examMethod 考试方式  0 定时定点  1 定时  2 自由
      // examCate  考试方式  0 不限 1 app考  2  pc考试
      if (dataList.status == 3) {
        dataList.statusText = '已结束';
        dataList.statusValue = 4;
        dataList.btnLeftText = '查看详情';
        dataList.btnLeftType = '查看详情';
        dataList.btnRightText = '';
        dataList.btnRightType = ''; // 报名 直接报名
      } else {
        if (dataList.userExamInfo) {
          // 已经存在考试信息，说明考过了
          if (dataList.userExamInfo && dataList.userExamInfo.examStatus == 1) {
            // 正在考试
            dataList.statusText = '答题中';
            dataList.statusValue = 4;
            dataList.btnLeftText = '查看详情';
            dataList.btnLeftType = '查看详情';
            dataList.btnRightText = '继续考试';
            dataList.btnRightType = '考试'; // 报名 直接报名
          } else if (dataList.userExamInfo && dataList.userExamInfo.examStatus == 2) {
            // 判卷中
            dataList.statusText = '判卷中';
            dataList.statusValue = 4;
            dataList.btnLeftText = '查看详情';
            dataList.btnLeftType = '查看详情';
            dataList.btnRightText = '查看结果';
            dataList.btnRightType = '判卷中结果';
          } else if (dataList.userExamInfo && dataList.userExamInfo.examStatus == 3) {
            // 已出结果
            if (dataList.userExamInfo.isPass == 1) {
              // 通过考试
              dataList.statusText = '合格';
              dataList.statusValue = 3;
              dataList.btnLeftText = '查看详情';
              dataList.btnLeftType = '查看详情';
              dataList.btnRightText = '查看结果';
              dataList.btnRightType = '合格结果';
            } else if (dataList.userExamInfo.isPass == 2) {
              // 未通过考试
              dataList.statusText = '不合格';
              dataList.statusValue = 4;
              dataList.btnLeftText = '查看结果';
              dataList.btnLeftType = '不合格结果'; // 查看试卷
              if (dataList.examNum == 0) {
                dataList.btnRightText = '再次考试';
                dataList.btnRightType = '考试'; // 开始考试
              } else {
                if (dataList.userExamInfoNum < dataList.examNum) {
                  dataList.btnRightText = '再次考试';
                  dataList.btnRightType = '考试'; // 开始考试
                } else {
                  dataList.btnRightText = '';
                }
              }
            }
          }
        } else {
          // 没考过
          if (dataList.signUpInfo) {
            // 已报名
            if (dataList.signUpInfo.status == 0) {
              // 可报名
              dataList.statusText = '未报名';
              dataList.statusValue = 2;
              if (dataList.examSignUpOpen == 0) {
                // 0开启（自定义表单报名）
                dataList.btnLeftText = '查看详情';
                dataList.btnLeftType = '查看详情';
                dataList.btnRightText = '报名';
                dataList.btnRightType = '表单报名'; // 报名 去自定义表单界面
              } else if (dataList.examSignUpOpen == 1) {
                // 1 未开启自定义表单报名
                dataList.btnLeftText = '查看详情';
                dataList.btnLeftType = '查看详情';
                dataList.btnRightText = '报名';
                dataList.btnRightType = '直接报名'; // 报名 直接报名
              }
            } else if (dataList.signUpInfo.status == 1) {
              // 待审
              dataList.statusText = '审核中';
              dataList.statusValue = 2;
              dataList.btnLeftText = '查看详情';
              dataList.btnLeftType = '查看详情';
              dataList.btnRightText = '';
            } else if (dataList.signUpInfo.status == 2) {
              // 审核通过
              if (dataList.price == '' || dataList.price == 0) {
                // 不需要付费
                dataList.statusText = '待考试';
                dataList.statusValue = 2;
                dataList.btnLeftText = '查看详情';
                dataList.btnLeftType = '查看详情';
                dataList.btnRightText = '开始考试';
                dataList.btnRightType = '考试'; // /开始考试 直接跳转到考试界面
              } else {
                if (dataList.signUpInfo.isPay == 2) {
                  // 已缴费
                  dataList.statusText = '待考试';
                  dataList.statusValue = 2;
                  dataList.btnLeftText = '查看详情';
                  dataList.btnLeftType = '查看详情';
                  dataList.btnRightText = '开始考试';
                  dataList.btnRightType = '考试'; // /开始考试 直接跳转到考试界面
                } else {
                  // 未缴费
                  dataList.statusText = '待付费';
                  dataList.statusValue = 3;
                  dataList.btnLeftText = '查看详情';
                  dataList.btnLeftType = '查看详情';
                  dataList.btnRightText = '缴费';
                  dataList.btnRightType = '缴费'; // /开始考试 直接跳转到考试界面
                }
              }
            } else if (dataList.signUpInfo.status == 3) {
              // 拒绝
              dataList.statusText = '已拒绝';
              dataList.statusValue = 4;
              dataList.btnLeftText = '查看原因';
              dataList.btnLeftType = '查看原因';
              dataList.btnRightText = '重新报名';
              if (dataList.examSignUpOpen == 0) {
                // 0开启（自定义表单报名）
                dataList.btnRightType = '表单报名'; // 报名 去自定义表单界面
              } else if (dataList.examSignUpOpen == 1) {
                // 1 未开启自定义表单报名
                dataList.btnRightType = '直接报名'; // 报名 直接报名
              }
            }
          } else {
            // 未报名
            if (dataList.examMethod == 2) {
              // 不需要报名，学完自由考
              if (dataList.isPayItem == 2) {
                // 已报名培训项目  直接显示开始考试
                dataList.statusText = '待考试';
                dataList.statusValue = 1;
                dataList.btnLeftText = '查看详情';
                dataList.btnLeftType = '查看详情';
                dataList.btnRightText = '开始考试';
                dataList.btnRightType = '考试'; // /开始考试 直接跳转到考试界面
              } else {
                // 未购买培训项目  需提示去购买培训项目
                dataList.statusText = '待报名';
                dataList.statusValue = 2;
                dataList.btnLeftText = '查看详情';
                dataList.btnLeftType = '查看详情';
                dataList.btnRightText = '报名培训项目';
                dataList.btnRightType = '报名培训项目'; // /开始考试 直接跳转到考试界面
              }
            } else {
              if (dataList.isPayItem == 2) {
                // 已报名培训项目
                dataList.statusText = '未报名';
                dataList.statusValue = 2;
                if (dataList.examSignUpOpen == 0) {
                  // 0开启（自定义表单报名）
                  dataList.btnLeftText = '查看详情';
                  dataList.btnLeftType = '查看详情';
                  dataList.btnRightText = '报名';
                  dataList.btnRightType = '表单报名'; // 报名 去自定义表单界面
                } else if (dataList.examSignUpOpen == 1) {
                  // 1 未开启自定义表单报名
                  dataList.btnLeftText = '查看详情';
                  dataList.btnLeftType = '查看详情';
                  dataList.btnRightText = '报名';
                  dataList.btnRightType = '直接报名'; // 报名 直接报名
                }
              } else {
                dataList.statusText = '待报名';
                dataList.statusValue = 2;
                dataList.btnLeftText = '查看详情';
                dataList.btnLeftType = '查看详情';
                dataList.btnRightText = '报名培训项目';
                dataList.btnRightType = '报名培训项目'; // /开始考试 直接跳转到考试界面
              }
            }
          }
        }
      }
      if (dataList.examStartTime) {
        dataList.examStartTime = dataList.examStartTime.substring(0, 16);
      }

      // this.loading = false
      return dataList;
    }
  },
  watch: {
    imgSrc: function imgSrc(val) {
      if (val) {
        var file = this.dataURLtoFile(val, new Date().getTime() + '.png');
        var formFile = new FormData();
        formFile.append('file', file);
        this.uploadPic(formFile);
      }
    }
  },
  created: function created() {
    // this.hasToken = getToken();
    // if (getToken()) {
    //   // 首先判断登录没有
    //   this.initDataForLogin();
    // } else {
    //   this.initData();
    // }
  },
  methods: {
    // 处理加载数据
    handleData: function handleData() {
      var dataList = this.itemcon;
      // examSignUpOpen  是否开启报名 0开启（自定义表单报名） 1 未开启
      // examMethod 考试方式  0 定时定点  1 定时  2 自由
      // examCate  考试方式  0 不限 1 app考  2  pc考试
      if (dataList.status == 3) {
        dataList.statusText = '已结束';
        dataList.statusValue = 4;
        dataList.btnLeftText = '查看详情';
        dataList.btnLeftType = '查看详情';
        dataList.btnRightText = '';
        dataList.btnRightType = ''; // 报名 直接报名
      } else {
        if (dataList.userExamInfo != '') {
          // 已经存在考试信息，说明考过了
          if (dataList.userExamInfo && dataList.userExamInfo.examStatus == 1) {
            // 正在考试
            dataList.statusText = '答题中';
            dataList.statusValue = 4;
            dataList.btnLeftText = '查看详情';
            dataList.btnLeftType = '查看详情';
            dataList.btnRightText = '继续考试';
            dataList.btnRightType = '考试'; // 报名 直接报名
          } else if (dataList.userExamInfo && dataList.userExamInfo.examStatus == 2) {
            // 判卷中
            dataList.statusText = '判卷中';
            dataList.statusValue = 4;
            dataList.btnLeftText = '查看详情';
            dataList.btnLeftType = '查看详情';
            dataList.btnRightText = '查看结果';
            dataList.btnRightType = '判卷中结果';
          } else if (dataList.userExamInfo && dataList.userExamInfo.examStatus == 3) {
            // 已出结果
            if (dataList.userExamInfo.isPass == 1) {
              // 通过考试
              dataList.statusText = '合格';
              dataList.statusValue = 3;
              dataList.btnLeftText = '查看详情';
              dataList.btnLeftType = '查看详情';
              dataList.btnRightText = '查看结果';
              dataList.btnRightType = '合格结果';
            } else if (dataList.userExamInfo.isPass == 2) {
              // 未通过考试
              dataList.statusText = '不合格';
              dataList.statusValue = 4;
              dataList.btnLeftText = '查看结果';
              dataList.btnLeftType = '不合格结果'; // 查看试卷
              if (dataList.examNum == 0) {
                dataList.btnRightText = '再次考试';
                dataList.btnRightType = '考试'; // 开始考试
              } else {
                if (dataList.userExamInfoNum < dataList.examNum) {
                  dataList.btnRightText = '再次考试';
                  dataList.btnRightType = '考试'; // 开始考试
                } else {
                  dataList.btnRightText = '';
                }
              }
            }
          }
        } else {
          // 没考过
          if (dataList.signUpInfo != '') {
            // 已报名
            if (dataList.signUpInfo.status == 0) {
              // 可报名
              dataList.statusText = '未报名';
              dataList.statusValue = 2;
              if (dataList.examSignUpOpen == 0) {
                // 0开启（自定义表单报名）
                dataList.btnLeftText = '查看详情';
                dataList.btnLeftType = '查看详情';
                dataList.btnRightText = '报名';
                dataList.btnRightType = '表单报名'; // 报名 去自定义表单界面
              } else if (dataList.examSignUpOpen == 1) {
                // 1 未开启自定义表单报名
                dataList.btnLeftText = '查看详情';
                dataList.btnLeftType = '查看详情';
                dataList.btnRightText = '报名';
                dataList.btnRightType = '直接报名'; // 报名 直接报名
              }
            } else if (dataList.signUpInfo.status == 1) {
              // 待审
              dataList.statusText = '审核中';
              dataList.statusValue = 2;
              dataList.btnLeftText = '查看详情';
              dataList.btnLeftType = '查看详情';
              dataList.btnRightText = '';
            } else if (dataList.signUpInfo.status == 2) {
              // 审核通过
              if (dataList.price == '' || dataList.price == 0) {
                // 不需要付费
                dataList.statusText = '待考试';
                dataList.statusValue = 2;
                dataList.btnLeftText = '查看详情';
                dataList.btnLeftType = '查看详情';
                dataList.btnRightText = '开始考试';
                dataList.btnRightType = '考试'; // /开始考试 直接跳转到考试界面
              } else {
                if (dataList.signUpInfo.isPay == 2) {
                  // 已缴费
                  dataList.statusText = '待考试';
                  dataList.statusValue = 2;
                  dataList.btnLeftText = '查看详情';
                  dataList.btnLeftType = '查看详情';
                  dataList.btnRightText = '开始考试';
                  dataList.btnRightType = '考试'; // /开始考试 直接跳转到考试界面
                } else {
                  // 未缴费
                  dataList.statusText = '待付费';
                  dataList.statusValue = 3;
                  dataList.btnLeftText = '查看详情';
                  dataList.btnLeftType = '查看详情';
                  dataList.btnRightText = '缴费';
                  dataList.btnRightType = '缴费'; // /开始考试 直接跳转到考试界面
                }
              }
            } else if (dataList.signUpInfo.status == 3) {
              // 拒绝
              dataList.statusText = '已拒绝';
              dataList.statusValue = 4;
              dataList.btnLeftText = '查看原因';
              dataList.btnLeftType = '查看原因';
              dataList.btnRightText = '重新报名';
              if (dataList.examSignUpOpen == 0) {
                // 0开启（自定义表单报名）
                dataList.btnRightType = '表单报名'; // 报名 去自定义表单界面
              } else if (dataList.examSignUpOpen == 1) {
                // 1 未开启自定义表单报名
                dataList.btnRightType = '直接报名'; // 报名 直接报名
              }
            }
          } else {
            // 未报名
            if (dataList.examMethod == 2) {
              // 不需要报名，学完自由考
              if (dataList.isPayItem == 2) {
                // 已报名培训项目  直接显示开始考试
                dataList.statusText = '待考试';
                dataList.statusValue = 1;
                dataList.btnLeftText = '查看详情';
                dataList.btnLeftType = '查看详情';
                dataList.btnRightText = '开始考试';
                dataList.btnRightType = '考试'; // /开始考试 直接跳转到考试界面
              } else {
                // 未购买培训项目  需提示去购买培训项目
                dataList.statusText = '待报名';
                dataList.statusValue = 2;
                dataList.btnLeftText = '查看详情';
                dataList.btnLeftType = '查看详情';
                dataList.btnRightText = '报名培训项目';
                dataList.btnRightType = '报名培训项目'; // /开始考试 直接跳转到考试界面
              }
            } else {
              if (dataList.isPayItem == 2) {
                // 已报名培训项目
                dataList.statusText = '未报名';
                dataList.statusValue = 2;
                if (dataList.examSignUpOpen == 0) {
                  // 0开启（自定义表单报名）
                  dataList.btnLeftText = '查看详情';
                  dataList.btnLeftType = '查看详情';
                  dataList.btnRightText = '报名';
                  dataList.btnRightType = '表单报名'; // 报名 去自定义表单界面
                } else if (dataList.examSignUpOpen == 1) {
                  // 1 未开启自定义表单报名
                  dataList.btnLeftText = '查看详情';
                  dataList.btnLeftType = '查看详情';
                  dataList.btnRightText = '报名';
                  dataList.btnRightType = '直接报名'; // 报名 直接报名
                }
              } else {
                dataList.statusText = '待报名';
                dataList.statusValue = 2;
                dataList.btnLeftText = '查看详情';
                dataList.btnLeftType = '查看详情';
                dataList.btnRightText = '报名培训项目';
                dataList.btnRightType = '报名培训项目'; // /开始考试 直接跳转到考试界面
              }
            }
          }
        }
      }
      if (dataList.examStartTime) {
        dataList.examStartTime = dataList.examStartTime.substring(0, 16);
      }
      this.loading = false;
      return dataList;
    },
    // 人脸比对拍摄照片
    takePhoto: function takePhoto() {
      var _this = this;
      _this.dialogLoad = true;
      // canvas画图
      _this.thisContext.drawImage(_this.thisVideo, 0, 0, _this.videoWidth, _this.videoHeight);
      // 获取图片base64链接
      var image = this.thisCancas.toDataURL('image/png');
      _this.imgSrc = image; // 赋值并预览图片
    },
    comparisonPic: function comparisonPic(pic) {
      var that = this;
      (0, _examination.aiFaceMatch)({
        face: this.bottomPicture,
        face1: pic,
        bottomPicture: '' // 底图
      }).then(function (response) {
        that.dialogLoad = false;
        if (response.code == 200) {
          // 数据获取成功
          if (response.flag) {
            if (parseInt(response.data) >= 70) {
              that.closeFaceVerificationDialog(2);
              that.imgSrc = '';
            } else {
              that.comparisonBtn = true;
              that.showMsg(2, '人脸比对失败，请重试');
            }
          } else {
            that.imgSrc = '';
            that.comparisonBtn = true;
            that.showMsg(2, response.message);
          }
        } else {
          that.imgSrc = '';
          that.comparisonBtn = true;
          that.showMsg(2, response.message);
        }
      }).catch(function (error) {
        that.dialogLoad = false;
        that.imgSrc = '';
        that.comparisonBtn = true;
        that.showMsg(2, error.message);
      });
    },
    // 验证考试数据
    VerifyTestData: function VerifyTestData(list) {
      if (list.examMethod == 0) {
        // 定时定点考
        if (list.examCate == 1) {
          // 需要在app 考
          this.showMsg(2, '此考试不能在电脑端进行');
        } else {
          this.$router.push('/waitingForTheExam/' + list.id);
        }
      } else if (list.examMethod == 1) {
        // 定时考
        if (list.examCate == 1) {
          // 需要在app 考
          this.showMsg(2, '此考试不能在电脑端进行');
        } else {
          this.$router.push('/waitingForTheExam/' + list.id);
        }
      } else if (list.examMethod == 2) {
        // 自由考
        if (list.isPayItem == 2) {
          // 培训项目已付费
          this.id = list.id;
          this.AppExamination(list);
        } else {
          // 培训项目未付费，需提示用户去购买培训项目
          this.planId = list.itemId;
          this.errorDialogVisible = true;
          this.tips = '您需要购买课程';
          this.tipsChild = '您报名的考试需先购买关联课程学习，完成学时达标进行报名考试';
          this.correctBtnText = '跳转课程';
          this.errorBtnText = '关闭';
        }
      }
    },
    // 开始考试
    AppExamination: function AppExamination(list) {
      console.log(list);
      this.$router.push({
        path: "/waitingForTheExam/".concat(list.id)
      });
      // 是否开启考前人脸核验  0 是  1 否
      // const that = this;
      // if (list.examStartFaceOpen == 0) {
      //   // 去人脸核验
      //   that.imgSrc = "";
      //   that.getBaseInfo();
      // } else if (list.examStartFaceOpen == 1) {
      //   this.$router.push("/startExamination/" + list.id + ",1");
      // }
    },
    godetails: function godetails() {
      this.$router.push('/examdetail/' + this.itemcon.id);
    },
    // 单条考试信息中 底部左边按钮
    itemBtnLeftClick: function itemBtnLeftClick(type, item) {
      var body;
      if (type == '查看详情') {
        body = {
          from: '详情',
          id: item.id
        };
      } else if (type == '合格试卷') {
        body = {
          from: '合格试卷',
          id: item.userExamInfo.utrId
        };
      } else if (type == '查看原因') {
        body = {
          from: '查看原因',
          data: item
        };
      } else if (type == '不合格试卷') {
        body = {
          from: '不合格试卷',
          data: item
        };
      } else if (type == '不合格结果') {
        body = {
          from: '不合格结果',
          data: item
        };
      }
      this.OnNoticeData(body);
    },
    // 单条考试信息中 底部右边按钮
    itemBtnRightClick: function itemBtnRightClick(type, item) {
      if ((0, _auth.getToken)()) {
        // 首先判断登录没有
        var body;
        if (type == '表单报名') {
          body = {
            from: '表单报名',
            data: item
          };
        } else if (type == '直接报名') {
          this.toSignUp(item.id);
        } else if (type == '考试') {
          body = {
            from: '考试',
            data: item
          };
        } else if (type == '判卷中结果') {
          body = {
            from: '判卷中结果',
            data: item
          };
        } else if (type == '查看证书') {
          body = {
            from: '查看证书',
            data: item
          };
        } else if (type == '缴费') {
          body = {
            from: '缴费',
            data: item
          };
        } else if (type == '报名培训项目') {
          body = {
            from: '报名培训项目',
            data: item
          };
        } else if (type == '继续学习') {
          body = {
            from: '继续学习',
            data: item
          };
        } else if (type == '查看证书') {
          body = {
            from: '查看证书',
            data: item
          };
        } else if (type == '合格结果') {
          body = {
            from: '合格结果',
            data: item
          };
        }
        this.OnNoticeData(body);
      } else {
        // 没有登录   需要提示登录
        eventBus.$emit('zhucedengluss', true);
      }
    },
    toSignUp: function toSignUp(id) {
      var _this2 = this;
      var that = this;
      var dataBody = {
        id: id,
        cate: 1
      };
      (0, _examination.signUp)(dataBody).then(function (response) {
        if (response.code == 200) {
          that.showMsg(1, '报名成功');
          that.dataList = [];
          that.initDataForLogin();
        } else {
          that.showMsg(2, response.message);
          _this2.planId = list.itemId;
          _this2.errorDialogVisible = true;
          _this2.tips = '您需要购买课程';
          _this2.tipsChild = '您报名的考试需先购买关联课程学习，完成学时达标进行报名考试';
          _this2.correctBtnText = '跳转课程';
          _this2.errorBtnText = '关闭';
        }
      });
    },
    OnNoticeData: function OnNoticeData(data) {
      console.log(data, '222');
      var that = this;
      if (data.from == '详情') {
        this.id = data.id;
        this.$router.push('/examdetail/' + data.id);
      } else if (data.from == '考试') {
        this.VerifyTestData(data.data);
      } else if (data.from == '表单报名') {
        var formId = data.data.formId;
        if (formId) {
          this.$router.push('/dynamicForm/' + formId + ',' + data.data.id);
        } else {
          this.showMsg(2, '表单数据未关联');
        }
      } else if (data.from == '判卷中结果') {
        this.id = data.id;
        // this.$router.push('/JudgingPapers/' + data.data.userExamInfo.utrId)
        this.$router.push({
          path: '/JudgingPapers',
          query: {
            id: data.data.userExamInfo.utrId,
            type: 1
          }
        });
      } else if (data.from == '缴费') {
        this.$router.push('/ExaminationFee/' + data.data.id);
      } else if (data.from == '合格试卷') {
        this.$router.push('/showAnswers/' + data.id + ',考试结果');
      } else if (data.from == '报名培训项目') {
        this.$router.push('/teachinfo/' + data.data.itemId);
      } else if (data.from == '继续学习') {
        this.$router.push('/teachinfo/' + data.data.itemId);
      } else if (data.from == '查看原因') {
        this.errorDialogVisible = true;
        this.tips = '审核未通过';
        this.tipsChild = data.data.signUpInfo.remark;
        this.correctBtnText = '';
        this.errorBtnText = '关闭';
      } else if (data.from == '不合格试卷') {
        this.$router.push('/showAnswers/' + data.data.userExamInfo.utrId + ',考试结果');
      } else if (data.from == '合格结果') {
        this.id = data.id;
        // this.$router.push('/JudgingPapers/' + data.data.userExamInfo.utrId)
        this.$router.push({
          path: '/JudgingPapers',
          query: {
            id: data.data.userExamInfo.utrId,
            type: 1
          }
        });
      } else if (data.from == '不合格结果') {
        this.id = data.id;
        // this.$router.push('/JudgingPapers/' + data.data.userExamInfo.utrId)
        this.$router.push({
          path: '/JudgingPapers',
          query: {
            id: data.data.userExamInfo.utrId,
            type: 1
          }
        });
      }
    },
    closeErrorDialog: function closeErrorDialog() {
      if (this.errorBtnText == '关闭') {
        this.errorDialogVisible = false;
      }
    },
    initDataForLogin: function initDataForLogin() {
      var _this3 = this;
      var that = this;
      var dataBody = {
        cid: (0, _auth.getAssocId)(),
        ctype: 4,
        page: this.page,
        size: this.size,
        search: this.searchContent
      };
      (0, _examination.getAllExaminationListForLogin)(dataBody).then(function (response) {
        if (response.code == 200) {
          // 数据获取成功
          // console.log(response.data.totalElements)
          _this3.total = response.data.totalElements;
          response.data.content.forEach(function (item, i) {
            _this3.dataList.push(item);
          });
          // examSignUpOpen  是否开启报名 0开启（自定义表单报名） 1 未开启
          // examMethod 考试方式  0 定时定点  1 定时  2 自由
          // examCate  考试方式  0 不限 1 app考  2  pc考试
          var _that = _this3;
          _that.handleData();
        } else {
          that.showMsg(2, response.message);
        }
      });
    },
    initData: function initData() {
      var that = this;
      var dataBody = {
        cid: (0, _auth.getAssocId)(),
        ctype: 4,
        page: this.page,
        size: this.size
      };
      (0, _examination.getAllExaminationList)(dataBody).then(function (response) {
        if (response.code == 200) {
          // 数据获取成功
          that.total = response.data.totalElements;
          response.data.content.forEach(function (item, i) {
            that.dataList.push(item);
          });
          that.handleData();
        } else {
          that.showMsg(2, response.message);
        }
      });
    }
  }
};