var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "announcementbox" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "d-flex flex-column h-100",
        },
        [
          _c("div", { staticClass: "titleBg d-flex justify-content-between" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "login_out" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-right": "1rem" },
                    attrs: { loading: _vm.refreshListLoading },
                    on: { click: _vm.initDataForLogin },
                  },
                  [
                    !_vm.refreshListLoading
                      ? _c("i", { staticClass: "el-icon-refresh" })
                      : _vm._e(),
                    _vm._v(" 刷新列表 "),
                  ]
                ),
                _c("el-button", { on: { click: _vm.logout } }, [
                  _c("i", { staticClass: "iconfont icona-zu74549" }),
                  _vm._v(" 退出登录 "),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "container w-100",
              staticStyle: {
                "max-width": "100%",
                height: "calc(100vh - 22rem)",
                "overflow-y": "auto",
              },
            },
            [
              _vm.dataList.length != 0
                ? _c(
                    "div",
                    { staticClass: "row row-cols-2" },
                    _vm._l(_vm.dataList, function (item, i) {
                      return _c(
                        "div",
                        {
                          key: i,
                          staticClass: "col",
                          staticStyle: { padding: "0", margin: "0" },
                        },
                        [
                          _c("div", { staticClass: "shadow item" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-between",
                              },
                              [
                                _c("div", { staticClass: "text lin-1" }, [
                                  _vm._v(_vm._s(item.planName)),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "\n                  position-relative\n                  d-flex\n                  align-items-center\n                  justify-content-center\n                ",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "img",
                                      attrs: {
                                        src: require("@/assets/img/icon_kc_sx.png"),
                                        alt: "",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "position-absolute sx_Text pl-3",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              item.examMethod == 0
                                                ? "定时定点考"
                                                : item.examMethod == 1
                                                ? "定时考"
                                                : "学完自由考"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "item_99 d-flex align-items-center",
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    " 考试时长：" +
                                      _vm._s(
                                        item.examTime == 0
                                          ? "不限时长"
                                          : item.examTime + "分钟"
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    "题数：" +
                                      _vm._s(item.examQuestionNum) +
                                      "题"
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    "总分：" + _vm._s(item.examScore) + "分"
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    "合格：" + _vm._s(item.examPassScore) + "分"
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-between",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-items-center" },
                                  [
                                    item.examStartTime
                                      ? _c(
                                          "div",
                                          { staticClass: "item_99_c" },
                                          [
                                            _vm._v(
                                              " 考试时间：" +
                                                _vm._s(item.examStartTime) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                item.price
                                  ? _c("div", { staticClass: "d-flex" }, [
                                      _c("div", { staticClass: "item_99_c" }, [
                                        _vm._v("考试报名费："),
                                      ]),
                                      _c("div", { staticClass: "item_red" }, [
                                        _vm._v(_vm._s(item.price) + "/元"),
                                      ]),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center justify-content-end mt-2",
                              },
                              [
                                item.btnLeftText
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "\n                  btnSy2\n                  d-flex\n                  align-items-center\n                  justify-content-center\n                ",
                                        on: {
                                          click: function ($event) {
                                            return _vm.itemBtnLeftClick(
                                              item.btnLeftType,
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.btnLeftText) + " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                item.btnRightText
                                  ? _c(
                                      "button",
                                      {
                                        staticClass:
                                          "\n                  btnSy1\n                  d-flex\n                  align-items-center\n                  justify-content-center\n                ",
                                        on: {
                                          click: function ($event) {
                                            return _vm.itemBtnRightClick(
                                              item.btnRightType,
                                              item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.btnRightText) + " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.dataList.length == 0 && !_vm.refreshListLoading
                ? _c(
                    "div",
                    {
                      staticClass:
                        "w-100 h-100 d-flex align-items-center justify-content-center",
                      staticStyle: { background: "#ffffff" },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/zanwushuju.png"),
                          alt: "",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm.dataList.length != 0
            ? _c(
                "div",
                {
                  staticClass: "d-flex justify-content-end",
                  staticStyle: { "margin-top": "3rem" },
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "prev, pager, next",
                      "page-size": _vm.size,
                      "current-page": _vm.page,
                      total: _vm.total,
                    },
                    on: { "current-change": _vm.pagingSizeChange },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "z-index": "999999" },
          attrs: {
            title: "提示",
            top: "20vh",
            visible: _vm.dialogFaceVerification,
            close: "closeFaceVerificationDialog(1)",
            width: "40%",
            center: "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFaceVerification = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoad,
                  expression: "dialogLoad",
                },
              ],
              staticClass: "d-flex w-100",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex-column d-flex justify-content-center align-items-center",
                  staticStyle: { flex: "1" },
                },
                [
                  _c("div", { staticClass: "dialog_tips_blue" }, [
                    _vm._v("现场照片"),
                  ]),
                  _c("video", {
                    attrs: {
                      id: "videoCamera",
                      width: _vm.videoWidth,
                      height: _vm.videoHeight,
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "flex-column align-items-center d-flex align-items-center",
                  staticStyle: { flex: "1" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-column align-items-center d-flex align-items-center",
                      staticStyle: { flex: "1" },
                    },
                    [
                      _c("div", { staticClass: "dialog_tips_blue" }, [
                        _vm._v("留底照片"),
                      ]),
                      _c("img", {
                        staticStyle: { width: "7.57rem", height: "9.07rem" },
                        attrs: { src: _vm.bottomPicture, alt: "" },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-column align-items-end d-flex align-items-center",
                      staticStyle: { flex: "1" },
                    },
                    [
                      _c("div", { staticClass: "dialog_tips_blue" }, [
                        _vm._v("比对照片"),
                      ]),
                      _c("img", {
                        staticStyle: { width: "7.57rem", height: "9.07rem" },
                        attrs: {
                          src: _vm.imgSrc ? _vm.imgSrc : _vm.verification,
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.comparisonBtn
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.takePhoto },
                    },
                    [_vm._v("比对")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c("canvas", {
        staticStyle: { display: "none" },
        attrs: {
          id: "canvasCamera",
          width: _vm.videoWidth,
          height: _vm.videoHeight,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            top: "20vh",
            visible: _vm.errorDialogVisible,
            "show-close": false,
            width: "25%",
            center: "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.errorDialogVisible = $event
            },
          },
        },
        [
          _vm.tips
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center",
                  staticStyle: { "font-size": "1.71rem", color: "#303030" },
                },
                [_vm._v(" " + _vm._s(_vm.tips) + " ")]
              )
            : _vm._e(),
          _vm.tipsChild
            ? _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "1rem",
                    color: "#333333",
                    "margin-top": "1.14rem",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.tipsChild) + " ")]
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.correctBtnText
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.closeCorrectDialog },
                    },
                    [_vm._v(_vm._s(_vm.correctBtnText))]
                  )
                : _vm._e(),
              _vm.errorBtnText
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.closeErrorDialog },
                    },
                    [_vm._v(_vm._s(_vm.errorBtnText))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex" }, [
      _c(
        "div",
        {
          staticClass:
            "\n            d-flex\n            flex-column\n            align-items-center\n            justify-content-center\n            clickItem\n            Sel\n          ",
        },
        [
          _c("div", { staticClass: "text d-flex align-items-center" }, [
            _vm._v("全部考试"),
          ]),
          _c("div", { staticClass: "btm" }),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }