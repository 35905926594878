"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.object.to-string.js");
var _train = require("@/api/train");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  inject: ['handleSelect'],
  data: function data() {
    return {
      total: 100,
      list: null,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 100,
        status: 1
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    dlkeoon: function dlkeoon(item) {
      var _this = this;
      this.$confirm('此操作将永久删除该报名信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        (0, _train.deleteSignUsp)(item.enrollInfoId).then(function (res) {
          if (res.code == 200) {
            _this.getList();
          } else {
            _this.$message.error(res.message);
          }
        });
      });
    },
    //订单支付
    deletemappingfun: function deletemappingfun(item) {
      // console.log(item.trainId)
      this.$router.push({
        path: '/payment/' + item.trainId
      });
    },
    // 获取订单信息
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _train.getRegistrationListByStatus)(10).then(function (res) {
        if (res.code === 200) {
          _this2.list = res.data.list;
          _this2.total = res.data.total;
        }
      }).finally(function () {
        _this2.listLoading = false;
      });
    }
  }
};