var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "teachfive" }, [
    _vm.pllist == null
      ? _c("p", { staticClass: "nonenav" }, [_vm._v(" 该项目无评论!! ")])
      : _c("div", { staticClass: "five-title" }, [
          _c("span", { staticStyle: { "margin-left": "40px" } }, [
            _vm._v("共计评论：" + _vm._s(_vm.pllist.totalNum) + "条"),
          ]),
          _c("span", { staticStyle: { "margin-left": "88px" } }, [
            _vm._v(" 好评率：" + _vm._s(_vm.pllist.goodRatio) + "% "),
          ]),
        ]),
    _c("div", { staticClass: "five-bottom" }, [
      _c(
        "ul",
        _vm._l(_vm.pllistNum, function (item, index) {
          return _c("li", { key: index }, [
            _c("div", { staticClass: "five-img" }, [
              _c("img", { attrs: { src: item.avatar, alt: "" } }),
            ]),
            _c("div", { staticClass: "five-title1" }, [
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c("span", { staticStyle: { color: "#2680EB" } }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _c(
                    "span",
                    {
                      staticStyle: { "margin-left": "24px", color: "#999999" },
                    },
                    [_vm._v(_vm._s(item.createTime))]
                  ),
                  _c(
                    "span",
                    { staticStyle: { "margin-left": "24px" } },
                    [
                      _c("el-rate", {
                        attrs: { disabled: "" },
                        model: {
                          value: item.score,
                          callback: function ($$v) {
                            _vm.$set(item, "score", $$v)
                          },
                          expression: "item.score",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", { staticStyle: { "margin-top": "10px" } }, [
                _vm._v(" " + _vm._s(item.content) + " "),
              ]),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }