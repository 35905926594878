var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detailContainer" }, [
    _c("div", { staticClass: "titleBox" }, [
      _c("span", [_vm._v("订单详情")]),
      _c("span", { staticClass: "back", on: { click: _vm.back } }, [
        _vm._v("返回上一页"),
      ]),
    ]),
    _c("div", { staticClass: "infos" }, [
      _c("div", { staticClass: "topBox" }, [
        _c("div", { staticClass: "leftTopBox" }, [
          _c("div", { staticClass: "infoItem" }, [
            _c("span", [_vm._v(" 项目名称： ")]),
            _c("span", { staticClass: "infoDark" }, [
              _vm._v(" " + _vm._s(_vm.currentContent.goodsTitle) + " "),
            ]),
          ]),
          _c("div", { staticClass: "infoItem" }, [
            _c("span", [_vm._v(" 订单编号： ")]),
            _c("span", { staticClass: "infoDark" }, [
              _vm._v(_vm._s(_vm.currentContent.orderNum)),
            ]),
          ]),
          _c("div", { staticClass: "infoItem" }, [
            _c("span", [_vm._v(" 订单金额： ")]),
            _c("span", { staticClass: "price" }, [
              _vm._v("¥" + _vm._s(_vm.currentContent.goodsMoney)),
            ]),
          ]),
          _c("div", { staticClass: "infoItem" }, [
            _c("span", [_vm._v(" 订单状态： ")]),
            _c("span", { staticClass: "infoDark" }, [
              _vm._v(_vm._s(_vm.orderStatus[_vm.currentContent.status])),
            ]),
          ]),
          _c("div", { staticClass: "infoItem" }, [
            _c("span", [_vm._v(" 下单时间： ")]),
            _c("span", { staticClass: "infoDark" }, [
              _vm._v(_vm._s(_vm.currentContent.payTime)),
            ]),
          ]),
        ]),
      ]),
      _c("div", [
        _c(
          "div",
          { staticClass: "infoItem " },
          [
            _c("span", [_vm._v(" 发票状态： ")]),
            _c("span", { staticClass: "infoDark" }, [
              _vm._v(_vm._s(_vm.formatStatus(_vm.currentContent.isInvoice))),
            ]),
            _vm.currentContent.isInvoice === 0 &&
            _vm.currentContent.status === 2 &&
            _vm.currentContent.payWay !== 6
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "30px" },
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.applyInvoice(_vm.currentContent)
                      },
                    },
                  },
                  [_vm._v("申请发票 ")]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.currentContent.isInvoice === 1
          ? _c("div", { staticClass: "infoItem " }, [
              _c("span", [_vm._v(" 申请时间： ")]),
              _c("span", { staticClass: "infoDark" }, [
                _vm._v(_vm._s(_vm.currentContent.updateTime)),
              ]),
            ])
          : _vm._e(),
      ]),
      _vm.invoiceInfo.name
        ? _c("div", { staticClass: "bottomBox" }, [
            _vm._m(0),
            _vm._m(1),
            _vm._m(2),
            _vm._m(3),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "infoItem" }, [
      _c("span", [_vm._v(" 发票抬头： ")]),
      _c("span", { staticClass: "infoDark" }, [
        _vm._v(" 内蒙古富华建设工程管理有限公司 "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "infoItem" }, [
      _c("span", [_vm._v(" 发票类型： ")]),
      _c("span", { staticClass: "infoDark" }, [_vm._v(" 增值税电子普通发票 ")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "infoItem" }, [
      _c("span", [_vm._v(" 售票邮箱： ")]),
      _c("span", { staticClass: "infoDark" }, [
        _vm._v(" jeotibo@cegkimsu.mg "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "infoItem" }, [
      _c("span", [_vm._v(" 换开原因： ")]),
      _c("span", { staticClass: "infoDark" }, [_vm._v(" 抬头名称有错 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }