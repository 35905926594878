"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _objectSpread2 = _interopRequireDefault(require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _train = require("@/api/train");
var _auth = require("@/utils/auth");
var _request = _interopRequireDefault(require("@/utils/request.js"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'sno2ij928vn',
  data: function data() {
    return {
      g8gbiwebn: '',
      mc9u823nod: ''
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['assocTel'])),
  created: function created() {
    console.log("电话", this.assocTel);
    this.getValidateTypeFUN(this.$route.query.id);
    // this.signUpVerifyResultFUN()
  },
  methods: {
    signUpVerifyResultFUN: function signUpVerifyResultFUN() {
      var _this = this;
      (0, _request.default)({
        url: 'zyg/train/validate/signUpVerifyResult?trainId=' + this.$route.query.id + '&assocId=' + (0, _auth.getAssocId)(),
        method: 'GET'
      }).then(function (res) {
        if (res.code == 200) {
          _this.mc9u823nod = 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + res.data.ticket;
        } else {
          _this.$message.error(res.message);
        }
      });
    },
    getValidateTypeFUN: function getValidateTypeFUN(id) {
      var _this2 = this;
      (0, _train.getValidateType)(id).then(function (res) {
        if (res.code == 200) {
          _this2.g8gbiwebn = res.data;
        } else {
          _this2.$message.error(res.message);
        }
      }).catch(function () {});
    },
    //关闭
    goBack: function goBack() {
      if (this.$route.query.type == 1) {
        this.$router.push({
          path: '/teachinfo/' + this.$route.query.id
        });
      } else if (this.$route.query.type == 2) {
        this.$router.push({
          path: '/personalCenter/myRegistration/underReview'
        });
      }
    }
  }
};