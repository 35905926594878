"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.string.match.js");
require("core-js/modules/web.atob.js");
require("core-js/modules/web.dom-exception.constructor.js");
require("core-js/modules/web.dom-exception.stack.js");
require("core-js/modules/web.dom-exception.to-string-tag.js");
require("core-js/modules/es.array-buffer.constructor.js");
require("core-js/modules/es.array-buffer.slice.js");
require("core-js/modules/es.typed-array.uint8-array.js");
require("core-js/modules/es.typed-array.at.js");
require("core-js/modules/es.typed-array.copy-within.js");
require("core-js/modules/es.typed-array.every.js");
require("core-js/modules/es.typed-array.fill.js");
require("core-js/modules/es.typed-array.filter.js");
require("core-js/modules/es.typed-array.find.js");
require("core-js/modules/es.typed-array.find-index.js");
require("core-js/modules/es.typed-array.find-last.js");
require("core-js/modules/es.typed-array.find-last-index.js");
require("core-js/modules/es.typed-array.for-each.js");
require("core-js/modules/es.typed-array.includes.js");
require("core-js/modules/es.typed-array.index-of.js");
require("core-js/modules/es.typed-array.iterator.js");
require("core-js/modules/es.typed-array.join.js");
require("core-js/modules/es.typed-array.last-index-of.js");
require("core-js/modules/es.typed-array.map.js");
require("core-js/modules/es.typed-array.reduce.js");
require("core-js/modules/es.typed-array.reduce-right.js");
require("core-js/modules/es.typed-array.reverse.js");
require("core-js/modules/es.typed-array.set.js");
require("core-js/modules/es.typed-array.slice.js");
require("core-js/modules/es.typed-array.some.js");
require("core-js/modules/es.typed-array.sort.js");
require("core-js/modules/es.typed-array.subarray.js");
require("core-js/modules/es.typed-array.to-locale-string.js");
require("core-js/modules/es.typed-array.to-reversed.js");
require("core-js/modules/es.typed-array.to-sorted.js");
require("core-js/modules/es.typed-array.to-string.js");
require("core-js/modules/es.typed-array.with.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/web.url.js");
require("core-js/modules/web.url-search-params.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
require("core-js/modules/web.btoa.js");
require("core-js/modules/es.json.stringify.js");
var _examination = require("@/api/examination");
var _order = require("@/api/order");
var _auth = require("@/utils/auth");
var _icon_verification_examples = _interopRequireDefault(require("@/assets/img/icon_verification_examples.png"));
var _examinationSystem = require("@/api/examinationSystem");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    getUserInfo: _examination.getUserInfo,
    aiFaceMatch: _examination.aiFaceMatch //底图与人脸做比对,
  },
  data: function data() {
    return {
      page: 1,
      dataList: [],
      total: 0,
      size: 6,
      loading: true,
      searchContent: '',
      id: '',
      dialogFaceVerification: false,
      videoWidth: '254',
      videoHeight: '254',
      verification: _icon_verification_examples.default,
      imgSrc: '',
      thisCancas: null,
      thisContext: null,
      thisVideo: null,
      openVideo: false,
      bottomPicture: '',
      //底图
      comparisonBtn: true,
      errorDialogVisible: false,
      tips: '',
      correctBtnText: '',
      errorBtnText: '',
      planId: '',
      tipsChild: '',
      dialogLoad: false,
      refreshListLoading: true
    };
  },
  created: function created() {
    // 浏览器控制按钮前进后退触发函数
    window.addEventListener('popstate', this.popstate, false);
    this.getListByPage();
  },
  beforeDestroy: function beforeDestroy() {
    // 销毁vm组件 避免堆栈溢出，多次创建、多次触发
    window.removeEventListener('popstate', this.popstate, false);
  },
  destroyed: function destroyed() {
    // 销毁vm组件 避免堆栈溢出，多次创建、多次触发
    window.removeEventListener('popstate', this.popstate, false);
  },
  watch: {
    imgSrc: function imgSrc(val) {
      if (val) {
        var file = this.dataURLtoFile(val, new Date().getTime() + '.png');
        var formFile = new FormData();
        formFile.append('file', file);
        this.uploadPic(formFile);
      }
    }
  },
  methods: {
    getListByPage: function getListByPage() {
      this.page = parseInt(this.$route.params.page);
      this.initDataForLogin();
    },
    // 用户点击浏览器前进后退按钮时重新获取路由数据
    popstate: function popstate() {
      this.getListByPage();
    },
    logout: function logout() {
      var _this2 = this;
      this.$confirm('确定退出系统?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(function () {
        _this2.$store.dispatch('user/resetToken').then(function () {
          _this2.$message({
            type: 'success',
            message: '退出成功!'
          });
          // window.location = '/examinationSystem/login'
          _this2.$router.replace('/examinationSystem/login');
        });
      });
    },
    /*获取全部考试列表(无需登录)*/initData: function initData() {
      var that = this;
      var dataBody = {
        cid: (0, _auth.getAssocId)(),
        ctype: 4,
        page: this.page,
        size: this.size
      };
      (0, _examination.getAllExaminationList)(dataBody).then(function (response) {
        if (response.code == 200) {
          //数据获取成功
          that.total = response.data.total;
          response.data.content.forEach(function (item, i) {
            that.dataList.push(item);
          });
          that.handleData();
        } else {
          that.showMsg(2, response.message);
        }
      });
    },
    //处理加载数据
    handleData: function handleData() {
      // examSignUpOpen  是否开启报名 0开启（自定义表单报名） 1 未开启
      // examMethod 考试方式  0 定时定点  1 定时  2 自由
      // examCate  考试方式  0 不限 1 app考  2  pc考试
      this.dataList.map(function (item, i) {
        var currentTime = new Date().getTime(); // 当前时间
        var endTime = new Date(item.examEndTime).getTime(); // 当前时间
        // console.log(item.examLateMinute);
        if (currentTime > endTime) {
          item.statusText = '已结束';
          item.statusValue = 4;
          item.btnLeftText = '查看详情';
          item.btnLeftType = '查看详情';
          item.btnRightText = '';
          item.btnRightType = '';
          return item;
        }
        if (item.itemId) {
          // 有培训项目
          console.log('有培训项目');
          if (item.isPayItem == 2) {
            // 已购买培训项目
            console.log('已购买培训项目');
            if (item.isDb) {
              console.log('学时达标');
              if (item.examMethod == 2) {
                console.log('学完自由考');
                if (item.price > 0) {
                  console.log('需缴费');
                  item.statusText = '待缴费';
                  item.statusValue = 3;
                  item.btnLeftText = '查看详情';
                  item.btnLeftType = '查看详情';
                  item.btnRightText = '缴费';
                  item.btnRightType = '缴费';
                } else {
                  console.log('无需缴费');
                  if (item.userExamInfo) {
                    console.log('已开始考试');
                    if (item.userExamInfo && item.userExamInfo.examStatus == 1) {
                      console.log('正在考试');
                      //正在考试
                      item.statusText = '答题中';
                      item.statusValue = 4;
                      item.btnLeftText = '查看详情';
                      item.btnLeftType = '查看详情';
                      item.btnRightText = '继续考试';
                      item.btnRightType = '考试'; //报名 直接报名
                    } else if (item.userExamInfo && item.userExamInfo.examStatus == 2) {
                      console.log('正在判卷');
                      //判卷中
                      item.statusText = '判卷中';
                      item.statusValue = 4;
                      item.btnLeftText = '查看详情';
                      item.btnLeftType = '查看详情';
                      item.btnRightText = '查看结果';
                      item.btnRightType = '判卷中结果';
                    } else if (item.userExamInfo && item.userExamInfo.examStatus == 3) {
                      //已出结果
                      console.log('已出结果');
                      if (item.userExamInfo.isPass == 1) {
                        console.log('合格');
                        if (item.isTest == 0) {
                          console.log('开启模拟考试----可继续考试');
                          item.statusText = '合格';
                          item.statusValue = 3;
                          item.btnLeftText = '查看结果';
                          item.btnLeftType = '合格结果'; //查看试卷
                          item.btnRightText = '模拟考试';
                          item.btnRightType = '考试'; //开始考试
                        } else {
                          console.log('未开启模拟考试----合格');
                          //通过考试
                          item.statusText = '合格';
                          item.statusValue = 3;
                          item.btnLeftText = '查看详情';
                          item.btnLeftType = '查看详情';
                          item.btnRightText = '查看结果';
                          item.btnRightType = '合格结果'; ////////////////////////////////////////
                        }
                      } else if (item.userExamInfo.isPass == 2) {
                        console.log('不合格');
                        //未通过考试
                        item.statusText = '不合格';
                        item.statusValue = 4;
                        item.btnLeftText = '查看结果';
                        item.btnLeftType = '不合格结果'; //查看试卷
                        if (item.examNum === 1) {
                          console.log('允许单次考试');
                          item.btnRightText = '';
                        } else {
                          console.log('允许多次考试');
                          if (item.userExamInfoNum < item.examNum || item.examNum == 0) {
                            // 还能再次考试
                            console.log('还能再次考试');
                            item.btnRightText = '再次考试';
                            item.btnRightType = '考试'; //开始考试
                          } else {
                            console.log('不能再次考试');
                            item.btnRightText = '';
                          }
                        }
                      }
                    }
                  } else {
                    console.log('未开始考试');
                    item.statusText = '待考试';
                    item.statusValue = 2;
                    item.btnLeftText = '查看详情';
                    item.btnLeftType = '查看详情';
                    item.btnRightText = '开始考试';
                    item.btnRightType = '考试'; ///开始考试 直接跳转到考试界面
                  }
                }
              } else {
                console.log('定时定点或定时不定点');
                item.statusValue = 2;
                if (item.signUpInfo) {
                  console.log('已报名');
                  if (item.signUpInfo.status == 0) {
                    console.log('未报名');
                    item.statusText = '需报名';
                    item.statusValue = 2;
                    if (item.examSignUpOpen == 0) {
                      //0开启（自定义表单报名）
                      item.btnLeftText = '查看详情';
                      item.btnLeftType = '查看详情';
                      item.btnRightText = '报名';
                      item.btnRightType = '表单报名'; //报名 去自定义表单界面
                    } else if (item.examSignUpOpen == 1) {
                      //1 未开启自定义表单报名
                      item.btnLeftText = '查看详情';
                      item.btnLeftType = '查看详情';
                      item.btnRightText = '报名';
                      item.btnRightType = '直接报名'; //报名 直接报名
                    }
                  } else if (item.signUpInfo.status == 1) {
                    console.log('审核中');
                    //待审
                    item.statusText = '审核中';
                    item.statusValue = 2;
                    item.btnLeftText = '查看详情';
                    item.btnLeftType = '查看详情';
                    item.btnRightText = '';
                  } else if (item.signUpInfo.status == 2) {
                    console.log('审核通过');
                    //审核通过
                    if (item.price == '' || item.price == 0) {
                      console.log('无需缴费');
                      if (item.userExamInfo) {
                        console.log('已开始考试');
                        if (item.userExamInfo && item.userExamInfo.examStatus == 1) {
                          console.log('正在考试');
                          //正在考试
                          item.statusText = '答题中';
                          item.statusValue = 4;
                          item.btnLeftText = '查看详情';
                          item.btnLeftType = '查看详情';
                          item.btnRightText = '继续考试';
                          item.btnRightType = '考试'; //报名 直接报名
                        } else if (item.userExamInfo && item.userExamInfo.examStatus == 2) {
                          console.log('正在判卷');
                          //判卷中
                          item.statusText = '判卷中';
                          item.statusValue = 4;
                          item.btnLeftText = '查看详情';
                          item.btnLeftType = '查看详情';
                          item.btnRightText = '查看结果';
                          item.btnRightType = '判卷中结果';
                        } else if (item.userExamInfo && item.userExamInfo.examStatus == 3) {
                          //已出结果
                          console.log('已出结果');
                          if (item.userExamInfo.isPass == 1) {
                            console.log('合格');
                            if (item.isTest == 0) {
                              console.log('开启模拟考试----可继续考试');
                              item.statusText = '合格';
                              item.statusValue = 3;
                              item.btnLeftText = '查看结果';
                              item.btnLeftType = '合格结果'; //查看试卷
                              item.btnRightText = '模拟考试';
                              item.btnRightType = '考试'; //开始考试
                            } else {
                              console.log('未开启模拟考试----合格');
                              //通过考试
                              item.statusText = '合格';
                              item.statusValue = 3;
                              item.btnLeftText = '查看详情';
                              item.btnLeftType = '查看详情';
                              item.btnRightText = '查看结果';
                              item.btnRightType = '合格结果'; ////////////////////////////////////////
                            }
                          } else if (item.userExamInfo.isPass == 2) {
                            console.log('不合格');
                            //未通过考试
                            item.statusText = '不合格';
                            item.statusValue = 4;
                            item.btnLeftText = '查看结果';
                            item.btnLeftType = '不合格结果'; //查看试卷
                            if (item.examNum === 1) {
                              console.log('允许单次考试');
                              item.btnRightText = '';
                            } else {
                              console.log('允许多次考试');
                              if (item.userExamInfoNum < item.examNum || item.examNum == 0) {
                                // 还能再次考试
                                console.log('还能再次考试');
                                item.btnRightText = '再次考试';
                                item.btnRightType = '考试'; //开始考试
                              } else {
                                console.log('不能再次考试');
                                item.btnRightText = '';
                              }
                            }
                          }
                        }
                      } else {
                        console.log('未开始考试');
                        item.statusText = '待考试';
                        item.statusValue = 2;
                        item.btnLeftText = '查看详情';
                        item.btnLeftType = '查看详情';
                        item.btnRightText = '开始考试';
                        item.btnRightType = '考试'; ///开始考试 直接跳转到考试界面
                      }
                    } else {
                      console.log('需缴费');
                      if (item.signUpInfo.isPay == 2) {
                        console.log('已缴费');
                        if (item.userExamInfo != '') {
                          console.log('已开始考试');
                          if (item.userExamInfo && item.userExamInfo.examStatus == 1) {
                            console.log('正在考试');
                            //正在考试
                            item.statusText = '答题中';
                            item.statusValue = 4;
                            item.btnLeftText = '查看详情';
                            item.btnLeftType = '查看详情';
                            item.btnRightText = '继续考试';
                            item.btnRightType = '考试'; //报名 直接报名
                          } else if (item.userExamInfo && item.userExamInfo.examStatus == 2) {
                            console.log('正在判卷');
                            //判卷中
                            item.statusText = '判卷中';
                            item.statusValue = 4;
                            item.btnLeftText = '查看详情';
                            item.btnLeftType = '查看详情';
                            item.btnRightText = '查看结果';
                            item.btnRightType = '判卷中结果';
                          } else if (item.userExamInfo && item.userExamInfo.examStatus == 3) {
                            //已出结果
                            console.log('已出结果');
                            if (item.userExamInfo.isPass == 1) {
                              console.log('合格');
                              if (item.isTest == 0) {
                                console.log('开启模拟考试----可继续考试');
                                item.statusText = '合格';
                                item.statusValue = 3;
                                item.btnLeftText = '查看结果';
                                item.btnLeftType = '合格结果'; //查看试卷
                                item.btnRightText = '模拟考试';
                                item.btnRightType = '考试'; //开始考试
                              } else {
                                console.log('未开启模拟考试----合格');
                                //通过考试
                                item.statusText = '合格';
                                item.statusValue = 3;
                                item.btnLeftText = '查看详情';
                                item.btnLeftType = '查看详情';
                                item.btnRightText = '查看结果';
                                item.btnRightType = '合格结果'; ////////////////////////////////////////
                              }
                            } else if (item.userExamInfo.isPass == 2) {
                              console.log('不合格');
                              //未通过考试
                              item.statusText = '不合格';
                              item.statusValue = 4;
                              item.btnLeftText = '查看结果';
                              item.btnLeftType = '不合格结果'; //查看试卷
                              if (item.examNum === 1) {
                                console.log('允许单次考试');
                                item.btnRightText = '';
                              } else {
                                console.log('允许多次考试');
                                if (item.userExamInfoNum < item.examNum || item.examNum == 0) {
                                  // 还能再次考试
                                  console.log('还能再次考试');
                                  item.btnRightText = '再次考试';
                                  item.btnRightType = '考试'; //开始考试
                                } else {
                                  console.log('不能再次考试');
                                  item.btnRightText = '';
                                }
                              }
                            }
                          }
                        } else {
                          console.log('未开始考试');
                          item.statusText = '待考试';
                          item.statusValue = 2;
                          item.btnLeftText = '查看详情';
                          item.btnLeftType = '查看详情';
                          item.btnRightText = '开始考试';
                          item.btnRightType = '考试'; ///开始考试 直接跳转到考试界面
                        }
                      } else {
                        console.log('未缴费');
                        //未缴费
                        if (item.userExamInfo) {
                          console.log('已开始考试，无需缴费');
                          if (item.userExamInfo != '') {
                            console.log('已开始考试');
                            if (item.userExamInfo != '') {
                              console.log('已开始考试');
                              if (item.userExamInfo && item.userExamInfo.examStatus == 1) {
                                console.log('正在考试');
                                //正在考试
                                item.statusText = '答题中';
                                item.statusValue = 4;
                                item.btnLeftText = '查看详情';
                                item.btnLeftType = '查看详情';
                                item.btnRightText = '继续考试';
                                item.btnRightType = '考试'; //报名 直接报名
                              } else if (item.userExamInfo && item.userExamInfo.examStatus == 2) {
                                console.log('正在判卷');
                                //判卷中
                                item.statusText = '判卷中';
                                item.statusValue = 4;
                                item.btnLeftText = '查看详情';
                                item.btnLeftType = '查看详情';
                                item.btnRightText = '查看结果';
                                item.btnRightType = '判卷中结果';
                              } else if (item.userExamInfo && item.userExamInfo.examStatus == 3) {
                                //已出结果
                                console.log('已出结果');
                                if (item.userExamInfo.isPass == 1) {
                                  console.log('合格');
                                  if (item.isTest == 0) {
                                    console.log('开启模拟考试----可继续考试');
                                    item.statusText = '合格';
                                    item.statusValue = 3;
                                    item.btnLeftText = '查看结果';
                                    item.btnLeftType = '合格结果'; //查看试卷
                                    item.btnRightText = '模拟考试';
                                    item.btnRightType = '考试'; //开始考试
                                  } else {
                                    console.log('未开启模拟考试----合格');
                                    //通过考试
                                    item.statusText = '合格';
                                    item.statusValue = 3;
                                    item.btnLeftText = '查看详情';
                                    item.btnLeftType = '查看详情';
                                    item.btnRightText = '查看结果';
                                    item.btnRightType = '合格结果'; ////////////////////////////////////////
                                  }
                                } else if (item.userExamInfo.isPass == 2) {
                                  console.log('不合格');
                                  //未通过考试
                                  item.statusText = '不合格';
                                  item.statusValue = 4;
                                  item.btnLeftText = '查看结果';
                                  item.btnLeftType = '不合格结果'; //查看试卷
                                  if (item.examNum === 1) {
                                    console.log('允许单次考试');
                                    item.btnRightText = '';
                                  } else {
                                    console.log('允许多次考试');
                                    if (item.userExamInfoNum < item.examNum || item.examNum == 0) {
                                      // 还能再次考试
                                      console.log('还能再次考试');
                                      item.btnRightText = '再次考试';
                                      item.btnRightType = '考试'; //开始考试
                                    } else {
                                      console.log('不能再次考试');
                                      item.btnRightText = '';
                                    }
                                  }
                                }
                              }
                            } else {
                              console.log('未开始考试');
                              item.statusText = '待考试';
                              item.statusValue = 2;
                              item.btnLeftText = '查看详情';
                              item.btnLeftType = '查看详情';
                              item.btnRightText = '开始考试';
                              item.btnRightType = '考试'; ///开始考试 直接跳转到考试界面
                            }
                          } else {
                            console.log('未开始考试');
                            item.statusText = '待考试';
                            item.statusValue = 2;
                            item.btnLeftText = '查看详情';
                            item.btnLeftType = '查看详情';
                            item.btnRightText = '开始考试';
                            item.btnRightType = '考试'; ///开始考试 直接跳转到考试界面
                          }
                        } else {
                          console.log('没有考试信息，需付费');
                          item.statusText = '待付费';
                          item.statusValue = 3;
                          item.btnLeftText = '查看详情';
                          item.btnLeftType = '查看详情';
                          item.btnRightText = '缴费';
                          item.btnRightType = '缴费'; ///开始考试 直接跳转到考试界面
                        }
                      }
                    }
                  } else if (item.signUpInfo.status == 3) {
                    console.log('审核拒绝');
                    //拒绝
                    item.statusText = '已拒绝';
                    item.statusValue = 4;
                    item.btnLeftText = '查看原因';
                    item.btnLeftType = '查看原因';
                    item.btnRightText = '重新报名';
                    if (item.examSignUpOpen == 0) {
                      //0开启（自定义表单报名）
                      console.log('重新报名 自定义表单报名');
                      item.btnRightType = '表单报名'; //报名 去自定义表单界面
                    } else if (item.examSignUpOpen == 1) {
                      console.log('重新报名 直接报名');
                      //1 未开启自定义表单报名
                      item.btnRightType = '直接报名'; //报名 直接报名
                    }
                  }
                } else {
                  console.log('未报名');
                  item.statusText = '需报名';
                  item.statusValue = 2;
                  if (item.examSignUpOpen == 0) {
                    console.log('开启报名审核表单');
                    item.btnLeftText = '查看详情';
                    item.btnLeftType = '查看详情';
                    item.btnRightText = '报名';
                    item.btnRightType = '表单报名'; //报名 去自定义表单界面
                  } else {
                    console.log('未开启报名审核表单');
                    item.btnLeftText = '查看详情';
                    item.btnLeftType = '查看详情';
                    item.btnRightText = '报名';
                    item.btnRightType = '直接报名'; //报名 去自定义表单界面
                  }
                }
              }
            } else {
              console.log('学时未达标');
              item.statusText = '待学习';
              item.statusValue = 2;
              item.btnLeftText = '查看详情';
              item.btnLeftType = '查看详情';
              item.btnRightText = '继续学习';
              item.btnRightType = '继续学习';
            }
          } else {
            // 未购买培训项目
            console.log('未购买培训项目');
            item.statusText = '待报名';
            item.statusValue = 2;
            item.btnLeftText = '查看详情';
            item.btnLeftType = '查看详情';
            item.btnRightText = '报名培训项目';
            item.btnRightType = '报名培训项目';
          }
        } else {
          // 没有培训项目
          console.log('没有培训项目');
          if (item.signUpInfo) {
            console.log('已报名');
            if (item.signUpInfo.status == 0) {
              console.log('未报名');
              item.statusText = '需报名';
              item.statusValue = 2;
              if (item.examSignUpOpen == 0) {
                //0开启（自定义表单报名）
                item.btnLeftText = '查看详情';
                item.btnLeftType = '查看详情';
                item.btnRightText = '报名';
                item.btnRightType = '表单报名'; //报名 去自定义表单界面
              } else if (item.examSignUpOpen == 1) {
                //1 未开启自定义表单报名
                item.btnLeftText = '查看详情';
                item.btnLeftType = '查看详情';
                item.btnRightText = '报名';
                item.btnRightType = '直接报名'; //报名 直接报名
              }
            } else if (item.signUpInfo.status == 1) {
              console.log('审核中');
              //待审
              item.statusText = '审核中';
              item.statusValue = 2;
              item.btnLeftText = '查看详情';
              item.btnLeftType = '查看详情';
              item.btnRightText = '';
            } else if (item.signUpInfo.status == 2) {
              console.log('审核通过');
              //审核通过
              if (item.price == '' || item.price == 0) {
                console.log('无需缴费');
                //不需要付费
                // item.statusText = "待考试";
                // item.statusValue = 2;
                // item.btnLeftText = "查看详情";
                // item.btnLeftType = "查看详情";
                // item.btnRightText = "开始考试";
                // item.btnRightType = "考试"; ///开始考试 直接跳转到考试界面
                if (item.userExamInfo != null) {
                  console.log('已开始考试');
                  if (item.userExamInfo && item.userExamInfo.examStatus == 1) {
                    console.log('正在考试');
                    //正在考试
                    item.statusText = '答题中';
                    item.statusValue = 4;
                    item.btnLeftText = '查看详情';
                    item.btnLeftType = '查看详情';
                    item.btnRightText = '继续考试';
                    item.btnRightType = '考试'; //报名 直接报名
                  } else if (item.userExamInfo && item.userExamInfo.examStatus == 2) {
                    console.log('正在判卷');
                    //判卷中
                    item.statusText = '判卷中';
                    item.statusValue = 4;
                    item.btnLeftText = '查看详情';
                    item.btnLeftType = '查看详情';
                    item.btnRightText = '查看结果';
                    item.btnRightType = '判卷中结果';
                  } else if (item.userExamInfo && item.userExamInfo.examStatus == 3) {
                    //已出结果
                    console.log('已出结果');
                    if (item.userExamInfo.isPass == 1) {
                      console.log('合格');
                      if (item.isTest == 0) {
                        console.log('开启模拟考试----可继续考试');
                        item.statusText = '合格';
                        item.statusValue = 3;
                        item.btnLeftText = '查看结果';
                        item.btnLeftType = '合格结果'; //查看试卷
                        item.btnRightText = '模拟考试';
                        item.btnRightType = '考试'; //开始考试
                      } else {
                        console.log('未开启模拟考试----合格');
                        //通过考试
                        item.statusText = '合格';
                        item.statusValue = 3;
                        item.btnLeftText = '查看详情';
                        item.btnLeftType = '查看详情';
                        item.btnRightText = '查看结果';
                        item.btnRightType = '合格结果'; ////////////////////////////////////////
                      }
                    } else if (item.userExamInfo && item.userExamInfo.isPass == 2) {
                      console.log('不合格');
                      //未通过考试
                      item.statusText = '不合格';
                      item.statusValue = 4;
                      item.btnLeftText = '查看结果';
                      item.btnLeftType = '不合格结果'; //查看试卷
                      if (item.examNum === 1) {
                        console.log('允许单次考试');
                        item.btnRightText = '';
                      } else {
                        console.log('允许多次考试');
                        if (item.userExamInfoNum < item.examNum || item.examNum == 0) {
                          // 还能再次考试
                          console.log('还能再次考试');
                          item.btnRightText = '再次考试';
                          item.btnRightType = '考试'; //开始考试
                        } else {
                          console.log('不能再次考试');
                          item.btnRightText = '';
                        }
                      }
                    }
                  }
                } else {
                  console.log('未开始考试');
                  item.statusText = '待考试';
                  item.statusValue = 2;
                  item.btnLeftText = '查看详情';
                  item.btnLeftType = '查看详情';
                  item.btnRightText = '开始考试';
                  item.btnRightType = '考试'; ///开始考试 直接跳转到考试界面
                }
              } else {
                console.log('需缴费');
                if (item.signUpInfo.isPay == 2) {
                  console.log('已缴费');
                  //已缴费
                  // item.statusText = "待考试";
                  // item.statusValue = 2;
                  // item.btnLeftText = "查看详情";
                  // item.btnLeftType = "查看详情";
                  // item.btnRightText = "开始考试";
                  // item.btnRightType = "考试"; ///开始考试 直接跳转到考试界面
                  if (item.userExamInfo != '') {
                    console.log('已开始考试');
                    if (item.userExamInfo && item.userExamInfo.examStatus == 1) {
                      console.log('正在考试');
                      //正在考试
                      item.statusText = '答题中';
                      item.statusValue = 4;
                      item.btnLeftText = '查看详情';
                      item.btnLeftType = '查看详情';
                      item.btnRightText = '继续考试';
                      item.btnRightType = '考试'; //报名 直接报名
                    } else if (item.userExamInfo && item.userExamInfo.examStatus == 2) {
                      console.log('正在判卷');
                      //判卷中
                      item.statusText = '判卷中';
                      item.statusValue = 4;
                      item.btnLeftText = '查看详情';
                      item.btnLeftType = '查看详情';
                      item.btnRightText = '查看结果';
                      item.btnRightType = '判卷中结果';
                    } else if (item.userExamInfo && item.userExamInfo.examStatus == 3) {
                      //已出结果
                      console.log('已出结果');
                      if (item.userExamInfo.isPass == 1) {
                        console.log('合格');
                        if (item.isTest == 0) {
                          console.log('开启模拟考试----可继续考试');
                          item.statusText = '合格';
                          item.statusValue = 3;
                          item.btnLeftText = '查看结果';
                          item.btnLeftType = '合格结果'; //查看试卷
                          item.btnRightText = '模拟考试';
                          item.btnRightType = '考试'; //开始考试
                        } else {
                          console.log('未开启模拟考试----合格');
                          //通过考试
                          item.statusText = '合格';
                          item.statusValue = 3;
                          item.btnLeftText = '查看详情';
                          item.btnLeftType = '查看详情';
                          item.btnRightText = '查看结果';
                          item.btnRightType = '合格结果'; ////////////////////////////////////////
                        }
                      } else if (item.userExamInfo.isPass == 2) {
                        console.log('不合格');
                        //未通过考试
                        item.statusText = '不合格';
                        item.statusValue = 4;
                        item.btnLeftText = '查看结果';
                        item.btnLeftType = '不合格结果'; //查看试卷
                        if (item.examNum === 1) {
                          console.log('允许单次考试');
                          item.btnRightText = '';
                        } else {
                          console.log('允许多次考试');
                          if (item.userExamInfoNum < item.examNum || item.examNum == 0) {
                            // 还能再次考试
                            console.log('还能再次考试');
                            item.btnRightText = '再次考试';
                            item.btnRightType = '考试'; //开始考试
                          } else {
                            console.log('不能再次考试');
                            item.btnRightText = '';
                          }
                        }
                      }
                    }
                  } else {
                    console.log('未开始考试');
                    item.statusText = '待考试';
                    item.statusValue = 2;
                    item.btnLeftText = '查看详情';
                    item.btnLeftType = '查看详情';
                    item.btnRightText = '开始考试';
                    item.btnRightType = '考试'; ///开始考试 直接跳转到考试界面
                  }
                } else {
                  console.log('未缴费');
                  //未缴费
                  item.statusText = '待付费';
                  item.statusValue = 3;
                  item.btnLeftText = '查看详情';
                  item.btnLeftType = '查看详情';
                  item.btnRightText = '缴费';
                  item.btnRightType = '缴费'; ///开始考试 直接跳转到考试界面
                }
              }
            } else if (item.signUpInfo.status == 3) {
              console.log('审核拒绝');
              //拒绝
              item.statusText = '已拒绝';
              item.statusValue = 4;
              item.btnLeftText = '查看原因';
              item.btnLeftType = '查看原因';
              item.btnRightText = '重新报名';
              if (item.examSignUpOpen == 0) {
                //0开启（自定义表单报名）
                console.log('重新报名 自定义表单报名');
                item.btnRightType = '表单报名'; //报名 去自定义表单界面
              } else if (item.examSignUpOpen == 1) {
                console.log('重新报名 直接报名');
                //1 未开启自定义表单报名
                item.btnRightType = '直接报名'; //报名 直接报名
              }
            }
          } else {
            console.log('未报名');
            item.statusText = '需报名';
            item.statusValue = 2;
            if (item.examSignUpOpen == 0) {
              console.log('开启报名审核表单');
              item.btnLeftText = '查看详情';
              item.btnLeftType = '查看详情';
              item.btnRightText = '报名';
              item.btnRightType = '表单报名'; //报名 去自定义表单界面
            } else {
              console.log('未开启报名审核表单');
              item.btnLeftText = '查看详情';
              item.btnLeftType = '查看详情';
              item.btnRightText = '报名';
              item.btnRightType = '直接报名'; //报名 去自定义表单界面
            }
          }
        }
        return item;
      });
      this.loading = false;
    },
    //登录成功后需请求此接口  访问列表
    initDataForLogin: function initDataForLogin() {
      var _this3 = this;
      this.refreshListLoading = true;
      this.loading = true;
      var that = this;
      var dataBody = {
        cid: (0, _auth.getAssocId)(),
        ctype: 4,
        page: this.page,
        size: this.size,
        search: this.searchContent,
        examCate: '3'
      };
      (0, _examination.getSimulationExamList)(dataBody).then(function (response) {
        if (response.code == 200) {
          //数据获取成功
          that.total = response.data.total;
          that.dataList = response.data.list;
          //examSignUpOpen  是否开启报名 0开启（自定义表单报名） 1 未开启
          // examMethod 考试方式  0 定时定点  1 定时  2 自由
          // examCate  考试方式  0 不限 1 app考  2  pc考试
          that.handleData();
        } else {
          that.showMsg(2, response.message);
        }
      }).finally(function () {
        _this3.loading = false;
        _this3.refreshListLoading = false;
      });
    },
    //单条考试信息中 底部左边按钮
    itemBtnLeftClick: function itemBtnLeftClick(type, item) {
      var body;
      if (type == '查看详情') {
        body = {
          from: '详情',
          id: item.id
        };
      } else if (type == '合格结果') {
        body = {
          from: '合格结果',
          data: item
        };
      } else if (type == '查看原因') {
        body = {
          from: '查看原因',
          data: item
        };
      } else if (type == '不合格试卷') {
        body = {
          from: '不合格试卷',
          data: item
        };
      } else if (type == '不合格结果') {
        body = {
          from: '不合格结果',
          data: item
        };
      }
      this.OnNoticeData(body);
    },
    // 模拟考试
    moniClick: function moniClick(item) {
      var body = {
        from: '考试',
        data: item
      };
      this.OnNoticeData(body);
    },
    //单条考试信息中 底部右边按钮
    itemBtnRightClick: function itemBtnRightClick(type, item) {
      var _this4 = this;
      if ((0, _auth.getToken)()) {
        //首先判断登录没有
        var body;
        if (type == '表单报名') {
          body = {
            from: '表单报名',
            data: item
          };
        } else if (type == '直接报名') {
          this.$alert('确定报名考试？', '考试报名', {
            confirmButtonText: '确定',
            callback: function callback(action) {
              if (action == 'confirm') _this4.toSignUp(item.id);
            }
          });
        } else if (type == '考试') {
          body = {
            from: '考试',
            data: item
          };
        } else if (type == '判卷中结果') {
          body = {
            from: '判卷中结果',
            data: item
          };
        } else if (type == '查看证书') {
          body = {
            from: '查看证书',
            data: item
          };
        } else if (type == '缴费') {
          body = {
            from: '缴费',
            data: item
          };
        } else if (type == '报名培训项目') {
          body = {
            from: '报名培训项目',
            data: item
          };
        } else if (type == '继续学习') {
          body = {
            from: '继续学习',
            data: item
          };
        } else if (type == '查看证书') {
          body = {
            from: '查看证书',
            data: item
          };
        } else if (type == '合格结果') {
          body = {
            from: '合格结果',
            data: item
          };
        }
        this.OnNoticeData(body);
      } else {
        //没有登录   需要提示登录
        eventBus.$emit('zhucedengluss', true);
      }
    },
    //报名
    toSignUp: function toSignUp(id) {
      var _this5 = this;
      var that = this;
      var dataBody = {
        id: id,
        cate: 1
      };
      (0, _examination.signUp)(dataBody).then(function (response) {
        if (response.code == 200) {
          that.showMsg(1, '报名成功');
          that.dataList = [];
          that.initDataForLogin();
        } else {
          that.showMsg(2, response.message);
          _this5.planId = list.itemId;
          _this5.errorDialogVisible = true;
          _this5.tips = '您需要购买课程';
          _this5.tipsChild = '您报名的考试需先购买关联课程学习，完成学时达标进行报名考试';
          _this5.correctBtnText = '跳转课程';
          _this5.errorBtnText = '关闭';
        }
      });
    },
    //提示信息
    showMsg: function showMsg(type, msg) {
      if (type == 1) {
        this.$notify({
          title: '提示',
          message: msg,
          type: 'success'
        });
      } else {
        this.$notify({
          title: '提示',
          message: msg,
          type: 'error'
        });
      }
    },
    pagingSizeChange: function pagingSizeChange(val) {
      // this.$router.push('/allExamination/' + val)
      this.$router.push('/examinationSystem/examList/' + val);
      this.page = val;
      this.initDataForLogin();
    },
    //base64 转文件
    dataURLtoFile: function dataURLtoFile(base64Str, fileName) {
      var arr = base64Str.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        //base64解析出来的图片类型
        bstr = atob(arr[1]),
        //对base64串进行操作，去掉url头，并转换为byte atob为window内置方法
        len = bstr.length,
        ab = new ArrayBuffer(len),
        //将ASCII码小于0的转换为大于0
        u8arr = new Uint8Array(ab); //
      while (len--) {
        u8arr[len] = bstr.charCodeAt(len);
      }
      // 创建新的 File 对象实例[utf-8内容，文件名称或者路径，[可选参数，type：文件中的内容mime类型]]
      return new File([u8arr], fileName, {
        type: mime
      });
    },
    //上传图片
    uploadPic: function uploadPic(formFile) {
      var _this6 = this;
      this.aliUpload(formFile, 'trainExam').then(function (res) {
        if (res.res.status === 200) {
          _this6.comparisonBtn = false;
          _this6.comparisonPic("https://oss.hangxintong.cn/".concat(res.name));
        } else {
          _this6.showMsg(2, response.message);
        }
      });
    },
    comparisonPic: function comparisonPic(pic) {
      var that = this;
      (0, _examination.aiFaceMatch)({
        face: this.bottomPicture,
        face1: pic
      }).then(function (response) {
        that.dialogLoad = false;
        if (response.code == 200) {
          //数据获取成功
          if (response.flag) {
            if (parseInt(response.data) >= 70) {
              that.closeFaceVerificationDialog(2);
              that.imgSrc = '';
            } else {
              that.comparisonBtn = true;
              that.showMsg(2, '人脸比对失败，请重试');
            }
          } else {
            that.imgSrc = '';
            that.comparisonBtn = true;
            that.showMsg(2, response.message);
          }
        } else {
          that.imgSrc = '';
          that.comparisonBtn = true;
          that.showMsg(2, response.message);
        }
      }).catch(function (error) {
        that.dialogLoad = false;
        that.imgSrc = '';
        that.comparisonBtn = true;
        that.showMsg(2, error.message);
      });
    },
    // 弹框左边按钮点击事件
    closeCorrectDialog: function closeCorrectDialog() {
      if (this.correctBtnText == '跳转课程') {
        this.$router.push({
          path: '/teachinfo/' + this.planId
        });
      }
    },
    // 弹框右边按钮点击事件
    closeErrorDialog: function closeErrorDialog() {
      if (this.errorBtnText == '关闭') {
        this.errorDialogVisible = false;
      }
    },
    // 调用权限（打开摄像头功能）
    getCompetence: function getCompetence() {
      var _this = this;
      _this.thisCancas = document.getElementById('canvasCamera');
      _this.thisContext = this.thisCancas.getContext('2d');
      _this.thisVideo = document.getElementById('videoCamera');
      _this.thisVideo.style.display = 'block';
      // 获取媒体属性，旧版本浏览器可能不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
      // 使用getUserMedia，因为它会覆盖现有的属性。
      // 这里，如果缺少getUserMedia属性，就添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia;
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            //不存在则报错
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
      var constraints = {
        audio: false,
        video: {
          width: this.videoWidth,
          height: this.videoHeight,
          transform: 'scaleX(-1)'
        }
      };
      navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
        // 旧的浏览器可能没有srcObject
        if ('srcObject' in _this.thisVideo) {
          _this.thisVideo.srcObject = stream;
        } else {
          // 避免在新的浏览器中使用它，因为它正在被弃用。
          _this.thisVideo.src = window.URL.createObjectURL(stream);
        }
        _this.thisVideo.onloadedmetadata = function (e) {
          _this.thisVideo.play();
        };
      }).catch(function (err) {});
    },
    //人脸比对拍摄照片
    takePhoto: function takePhoto() {
      var _this = this;
      _this.dialogLoad = true;
      // canvas画图
      _this.thisContext.drawImage(_this.thisVideo, 0, 0, _this.videoWidth, _this.videoHeight);
      // 获取图片base64链接
      var image = this.thisCancas.toDataURL('image/png');
      _this.imgSrc = image; //赋值并预览图片
    },
    //关闭人脸核验弹窗  关闭摄像头 val 1 : 正常关闭  2 人脸验证成功之后关闭
    closeFaceVerificationDialog: function closeFaceVerificationDialog(val) {
      this.dialogLoad = false;
      this.dialogFaceVerification = false;
      this.imgSrc = '';
      this.comparisonBtn = true;
      this.thisVideo.srcObject.getTracks()[0].stop();
      if (val == 1) {
        //正常关闭
      } else if (val == 2) {
        //人脸验证成功之后关闭
        this.$router.push('/startExamination/' + this.id + ',1');
      }
    },
    //验证考试数据
    VerifyTestData: function VerifyTestData(list) {
      if (list.examMethod == 0) {
        //定时定点考
        if (list.examCate == 1) {
          //需要在app 考
          this.showMsg(2, '此考试不能在电脑端进行');
        } else {
          this.$router.push('/waitingForTheExam/' + list.id);
        }
      } else if (list.examMethod == 1) {
        //定时考
        if (list.examCate == 1) {
          //需要在app 考
          this.showMsg(2, '此考试不能在电脑端进行');
        } else {
          this.$router.push('/waitingForTheExam/' + list.id);
        }
      } else if (list.examMethod == 2) {
        //自由考
        if (list.isPayItem == 2) {
          //培训项目已付费
          this.id = list.id;
          this.AppExamination(list);
        } else {
          //培训项目未付费，需提示用户去购买培训项目
          this.planId = list.itemId;
          this.errorDialogVisible = true;
          this.tips = '您需要购买课程';
          this.tipsChild = '您报名的考试需先购买关联课程学习，完成学时达标进行报名考试';
          this.correctBtnText = '跳转课程';
          this.errorBtnText = '关闭';
        }
      }
    },
    //自由考  进入验证
    AppExamination: function AppExamination(list) {
      //是否开启考前人脸核验  0 是  1 否
      var that = this;
      if (list.examStartFaceOpen == 0) {
        //去人脸核验
        that.imgSrc = '';
        that.getBaseInfo();
      } else if (list.examStartFaceOpen == 1) {
        this.$router.push('/startExamination/' + list.id + ',1');
      }
    },
    //获取个人基本信息
    getBaseInfo: function getBaseInfo() {
      var that = this;
      (0, _examination.getUserInfo)({}).then(function (response) {
        if (response.code == 200) {
          //数据获取成功
          that.bottomPicture = response.data.user.bottomPicture; //底图
          that.dialogFaceVerification = true; //显示人脸核验弹窗

          //打开摄像头
          setTimeout(function () {
            that.getCompetence();
          }, 300);
        } else {
          that.showMsg(2, response.message);
        }
      });
    },
    OnNoticeData: function OnNoticeData(data) {
      var _this7 = this;
      var that = this;
      if (data.from == '详情') {
        this.id = data.id;
        this.$router.push('/examdetail/' + data.id);
      } else if (data.from == '考试') {
        this.VerifyTestData(data.data);
      } else if (data.from == '表单报名') {
        var formId = data.data.formId;
        if (formId) {
          this.$router.push('/dynamicForm/' + formId + ',' + data.data.id);
        } else {
          this.showMsg(2, '表单数据未关联');
        }
      } else if (data.from == '判卷中结果') {
        this.id = data.id;
        this.$router.push({
          path: '/JudgingPapers',
          query: {
            id: data.data.userExamInfo.utrId,
            type: 2
          }
        });
      } else if (data.from == '缴费') {
        (0, _order.buyExamPlanOrder)(data.data.id).then(function (res) {
          if (res.code === 200) {
            // this.$router.push('/payment/' + res.data.id)
            _this7.$router.push({
              path: '/ExaminationPayment',
              query: {
                id: res.data.id,
                page: 1
              }
            });
          }
        });
        // this.$router.push("/payment/" + data.data.id);
      } else if (data.from == '合格试卷') {
        this.$router.push('/showAnswers/' + data.id + ',考试结果');
      } else if (data.from == '报名培训项目') {
        this.$router.push('/teachinfo/' + data.data.itemId);
      } else if (data.from == '继续学习') {
        var urlData = {
          token: (0, _auth.getToken)(),
          to: 5,
          id: data.data.itemId
        };
        (0, _examinationSystem.findWebsiteInfoByXId)().then(function (res) {
          if (res.code === 200) {
            if (res.data.pcTrainWeburl) {
              var webUrl = res.data.pcTrainWeburl.split(',')[0];
              var urlStrData = btoa(JSON.stringify(urlData));
              window.open('https://' + webUrl + '/memberToTrain?data=' + urlStrData);
            }
          }
        });
        // window.open("/teachinfo/" + data.data.itemId);
      } else if (data.from == '查看原因') {
        this.errorDialogVisible = true;
        this.tips = '审核未通过';
        this.tipsChild = data.data.signUpInfo.remark;
        this.correctBtnText = '';
        this.errorBtnText = '关闭';
      } else if (data.from == '不合格试卷') {
        this.$router.push('/showAnswers/' + data.data.userExamInfo.utrId + ',考试结果');
      } else if (data.from == '合格结果') {
        this.id = data.id;
        // this.$router.push('/JudgingPapers/' + data.data.userExamInfo.utrId)
        this.$router.push({
          path: '/JudgingPapers',
          query: {
            id: data.data.userExamInfo.utrId,
            type: 2
          }
        });
      } else if (data.from == '不合格结果') {
        this.id = data.id;
        // this.$router.push('/JudgingPapers/' + data.data.userExamInfo.utrId)
        this.$router.push({
          path: '/JudgingPapers',
          query: {
            id: data.data.userExamInfo.utrId,
            type: 2
          }
        });
      }
    }
  }
};