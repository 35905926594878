var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "teachfour" }, [
    _c("div", { staticClass: "four-top" }, [
      _vm.list == null
        ? _c("p", { staticClass: "nonenav" }, [_vm._v(" 该项目无实操课程!! ")])
        : _vm._e(),
      _vm.list != null ? _c("h2", [_vm._v("实操说明")]) : _vm._e(),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.list) } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }