"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.function.name.js");
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  assocToken: function assocToken(state) {
    return state.assocInfo ? state.assocInfo.assocToken : '';
  },
  isRenzheng: function isRenzheng(state) {
    return state.user.isRenzheng;
  },
  assocName: function assocName(state) {
    return state.app.assocName;
  },
  assocBanner: function assocBanner(state) {
    return state.app.assocBanner;
  },
  assocFooter: function assocFooter(state) {
    return state.app.assocFooter;
  },
  assocLogo: function assocLogo(state) {
    return state.app.assocLogo;
  },
  assocId: function assocId(state) {
    return state.app.assocId;
  },
  assocTel: function assocTel(state) {
    return state.app.tel;
  },
  navList: function navList(state) {
    return state.app.navList;
  },
  code: function code(state) {
    return state.app.code;
  },
  host: function host(state) {
    return state.app.host;
  },
  identity: function identity(state) {
    return state.user.identity;
  },
  // 身份证号
  eemcUserInfo: function eemcUserInfo(state) {
    return state.user.eemcUserInfo;
  } // 能效中心登陆账号（后端接口使用eemc控制是否可以学习当前课程使用）
};
var _default = exports.default = getters;