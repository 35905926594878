var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.body",
          value: _vm.spinning,
          expression: "spinning",
          modifiers: { body: true },
        },
      ],
      staticClass: "container",
    },
    [
      _c("div", { staticClass: "searchBar" }, [
        _c(
          "div",
          { staticClass: "inputBox" },
          [
            _c("el-input", {
              attrs: { placeholder: "请输入搜索关键字", clearable: "" },
              model: {
                value: _vm.keyword,
                callback: function ($$v) {
                  _vm.keyword = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "keyword",
              },
            }),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.handleSearch } },
              [_vm._v("搜索")]
            ),
          ],
          1
        ),
      ]),
      _vm.newsData.length > 0
        ? _c(
            "div",
            { staticClass: "content" },
            [
              _vm._l(_vm.newsData, function (item, index) {
                return _c("div", { key: index, staticClass: "newsList" }, [
                  _c("div", {
                    staticClass: "title",
                    domProps: { innerHTML: _vm._s(item.title) },
                    on: {
                      click: function ($event) {
                        return _vm.goPage(item.id)
                      },
                    },
                  }),
                  _c("div", { staticClass: "tagTime" }, [
                    _c("div", { staticClass: "tag" }, [
                      _vm._v(_vm._s(item.tag)),
                    ]),
                    _c("span", { staticClass: "time" }, [
                      _vm._v("发布时间：" + _vm._s(item.publishTime)),
                    ]),
                  ]),
                ])
              }),
              _vm.newsData.length > 0
                ? _c(
                    "div",
                    { staticClass: "page" },
                    [
                      _c("el-pagination", {
                        staticClass: "page",
                        attrs: {
                          layout: "prev, pager, next",
                          total: _vm.total,
                          "page-size": 2,
                          background: "",
                        },
                        on: { "current-change": _vm.getNewsList },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          )
        : _c("div", [_vm._v("暂无数据")]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }