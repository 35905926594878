"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
var _examination = require("../../api/examination.js");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'pcpage',
  data: function data() {
    return {
      id: '',
      index: 0,
      loading: true,
      popularcoursestwe: '',
      assocId: (0, _auth.getAssocId)(),
      classified: '',
      type: 2,
      classIds: '',
      totalScore: '',
      examTime: '',
      cardsNo: '',
      userName: '',
      examPassScore: '',
      userExamTime: '',
      titlell: '',
      num: 0,
      chuanguolai: this.$route.query.neirong,
      nonediv: false,
      noneop: false,
      navgatorIndex: 0,
      listBoxState: true,
      show: true,
      kaoshi_wu: false,
      kaoshi_hege: null,
      showResult: false,
      //手动下发成绩
      kaoshi_buhege: false,
      examCertId: '',
      examId: '',
      certLogId: '',
      // 证书id
      isTextValue: null,
      // 是否模拟考
      // 考试类型
      examType: 0,
      userAgent: "",
      // 是否展示“查看试卷”按钮
      showTestBtn: false
    };
  },
  created: function created() {
    this.id = this.$route.query.id;
    this.getbaseinfo();
    this.userAgent = navigator.userAgent.toLowerCase();
    console.log("userAgent", this.userAgent);
  },
  mounted: function mounted() {},
  methods: {
    btnClick: function btnClick(val) {
      // 1 证书  2 试卷
      if (val == 1) {
        if (!this.certLogId) {
          this.$message.error('您还未收到证书，请耐心等待');
          return false;
        }
        this.$router.push("/personalCenter/certificate?certId=".concat(this.certLogId));
      } else if (val == 2) {
        if (this.isTextValue == 1) {
          // this.$router.push('/showAnswers/' + this.id + ',考试结果')
          this.$router.push('/showAnswers/' + this.id);
          this.$router.push({
            path: '/showAnswers',
            query: {
              id: this.id,
              type: this.$route.query.type,
              mockId: this.$route.query.mockId
            }
          });
        } else {
          // this.$router.push('/showAnswers/' + this.id + ',课程详情')
          this.$router.push({
            path: '/showAnswers',
            query: {
              id: this.id,
              type: this.$route.query.type,
              mockId: this.$route.query.mockId
            }
          });
        }
      }
    },
    //关闭
    closeThis: function closeThis() {
      console.log("userAgent", this.userAgent);
      if (this.userAgent.includes('electron')) {
        this.$router.push('/examinationSystem/examList/1');
        return;
      } else {
        // 要求无论正式考试还是模拟考试都回到列表页面
        this.$router.push('/allExamination/1');
        // 1 正式考试   2 模拟考试
        // if (this.$route.query.type == 1) {
        //   this.$router.push('/allExamination/1')
        // } else if (this.$route.query.type == 2) {
        //   this.$router.push(`/waitingForTheExam/${this.$route.query.mockId}`)
        // }
      }
    },
    getbaseinfo: function getbaseinfo() {
      // 1、调接口、获取个人考试结果信息、
      // 2.是否需判卷 0需要 状态为0的显示待判卷页面、状态为1显示分数合格页面、状态为2显示分数不合格页面、is_pass字段判断
      // 3.算考试用时时间、转换、
      var that = this;
      (0, _examination.examCardInfo)({
        recordId: this.id
      }).then(function (res) {
        that.loading = false;
        if (res.code == 200) {
          that.isTextValue = res.data.record.isTest;
          that.examId = res.data.planServiceOne.id;
          that.examCertId = res.data.planServiceOne.examCertId;
          that.examType = res.data.planServiceOne.examMethod;
          that.showTestBtn = res.data.planServiceOne.isTest;
          that.showResult = res.data.record.showResult;
          if (that.showResult === false) {
            //当showResult为false,即为手动下发成绩，直接走等待考试界面
            res.data.record.isPass = 0;
          }
          if (res.data.record.isPass == 0) {
            that.kaoshi_hege = 0;
            that.userName = !res.data.record.userName ? 0 : res.data.record.userName; //学员姓名
            that.examPassScore = res.data.planServiceOne.examPassScore || 0; //及格分数
            that.examTime = res.data.planServiceOne.examTime || 0; //考试总时长
            var r = res.data.record; //算学员考试用时
            var date1 = new Date(r.startTime);
            var date2 = new Date(r.endTime);
            var s1 = date1.getTime();
            var s2 = date2.getTime();
            var userExamTime = parseInt((s2 - s1) / 60000);
            console.log(userExamTime, 'userExamTime');
            that.userExamTime = userExamTime; //学员考试用时
          } else if (res.data.record.isPass == 1) {
            // 考试合格
            that.certLogId = res.data.record.certLogId;
            that.kaoshi_hege = 1;
            that.totalScore = res.data.record.totalScore || 0; //总分
            that.examTime = res.data.planServiceOne.examTime || 0; //考试总时长
            that.cardsNo = res.data.cardsNo || 0; //错题数
            var r = res.data.record; //算学员考试用时
            var _date = new Date(r.startTime);
            var _date2 = new Date(r.endTime);
            var _s = _date.getTime();
            var _s2 = _date2.getTime();
            var userExamTime = parseInt((_s2 - _s) / 60000);
            console.log(userExamTime);
            that.userExamTime = userExamTime; //学员考试用时
          } else {
            that.kaoshi_hege = 2;
            that.totalScore = res.data.record.totalScore || 0; //总分
            that.examTime = res.data.planServiceOne.examTime || 0; //考试总时长
            that.cardsNo = res.data.cardsNo || 0; //错题数
            var r = res.data.record; //算学员考试用时
            var _date3 = new Date(r.startTime);
            var _date4 = new Date(r.endTime);
            var _s3 = _date3.getTime();
            var _s4 = _date4.getTime();
            var userExamTime = parseInt((_s4 - _s3) / 60000);
            console.log(userExamTime);
            that.userExamTime = userExamTime; //学员考试用时
          }
        } else {}
      }).catch(function () {
        that.loading = false;
      });
    }
  }
};