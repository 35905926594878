"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GenerateQRcode = GenerateQRcode;
exports.announcement = announcement;
exports.classifiedquery = classifiedquery;
exports.detailsget = detailsget;
exports.followxiehui = followxiehui;
exports.getcaptchas = getcaptchas;
exports.hotCoures = hotCoures;
exports.inquiryannouncement = inquiryannouncement;
exports.kechengfapiao = kechengfapiao;
exports.newAllClasstifityList = newAllClasstifityList;
exports.newAllcourseList = newAllcourseList;
exports.userreg = userreg;
exports.wholehotCoures = wholehotCoures;
exports.zhaihuiuserreg = zhaihuiuserreg;
exports.zhaohuigetcaptchas = zhaohuigetcaptchas;
var _request = _interopRequireDefault(require("@/utils/request"));
// 首页公告
function inquiryannouncement(xid) {
  return (0, _request.default)({
    url: '/api/notice/getImportantNotice',
    method: 'get',
    params: {
      xid: xid
    }
  });
}

// 已学课程，我的发票
function kechengfapiao() {
  return (0, _request.default)({
    url: '/info/countNum',
    method: 'get'
  });
}

// 公告列表
function announcement(query) {
  return (0, _request.default)({
    url: '/api/notice/getNoticeList',
    method: 'get',
    params: query
  });
}

// 全部课程-查询分类
function classifiedquery(assocId, type) {
  return (0, _request.default)({
    url: '/train/class/querysimplelist/' + assocId,
    method: 'get',
    params: {
      assocId: assocId,
      type: type
    }
  });
}

// 公告详情
function detailsget(id) {
  return (0, _request.default)({
    url: '/api/notice/getInfo/' + id,
    method: 'get'
  });
}

// 首页热门课程列表
function hotCoures(size) {
  return (0, _request.default)({
    url: '/train/item/pcTrainItemList',
    method: 'get',
    params: {
      size: size
    }
  });
}

// 全部课程
function wholehotCoures(itemCateId, page, size, keyword) {
  return (0, _request.default)({
    url: '/train/item/pcTrainItemList',
    method: 'get',
    params: {
      itemCateId: itemCateId,
      page: page,
      size: size,
      keyword: keyword
    }
  });
}

// 全部课程
function newAllcourseList(params) {
  return (0, _request.default)({
    url: '/zyg/web/training/learner/item/list',
    method: 'get',
    params: params
  });
}
// 全部课程
function newAllClasstifityList(params) {
  return (0, _request.default)({
    url: '/zyg/web/training/learner/clazz',
    method: 'get',
    params: params
  });
}

// 生成二维码
function GenerateQRcode() {
  return (0, _request.default)({
    url: '/login/loginQRcode',
    method: 'post'
  });
}

// 首页注册验证码
function getcaptchas(phone, status) {
  return (0, _request.default)({
    url: '/Alisdk/SendSmsPcNew',
    method: 'post',
    params: {
      phone: phone,
      status: status
    }
  });
}

// 首页找回密码验证码
function zhaohuigetcaptchas(phone, status, forget) {
  return (0, _request.default)({
    url: '/Alisdk/SendSmsPcNew',
    method: 'post',
    params: {
      phone: phone,
      status: status,
      forget: forget
    }
  });
}

// 找回密码-确认修改密码
function zhaihuiuserreg(mobile, code, passwd, passwdr) {
  return (0, _request.default)({
    url: '/login/forgetPasswd',
    method: 'post',
    params: {
      mobile: mobile,
      code: code,
      passwd: passwd,
      passwdr: passwdr
    }
  });
}

// 首页注册
function userreg(username, mobile, code, passwd) {
  return (0, _request.default)({
    url: '/login/user/userReg',
    method: 'post',
    params: {
      username: username,
      mobile: mobile,
      code: code,
      passwd: passwd
    }
  });
}

// 关注协会
function followxiehui(cid, ctype) {
  return (0, _request.default)({
    url: 'Info/follow',
    method: 'post',
    params: {
      cid: cid,
      ctype: ctype
    }
  });
}