var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "app-container",
      staticStyle: {
        display: "flex",
        "flex-direction": "column",
        height: "100%",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            flex: "1",
            display: "flex",
            "flex-direction": "column",
            width: "100%",
            background: "#ffffff",
            height: "calc(100vh - 172px)",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "border-bottom": "0.01rem solid #e4e4e4",
                "font-weight": "bold",
                "font-size": "1.1rem",
                color: "#303030",
                height: "3rem",
                display: "flex",
                "align-items": "center",
                "padding-left": "1.6rem",
              },
            },
            [_vm._v(" 我学习的记录 ")]
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                padding: "0 6.1rem",
                height: "9.2rem",
                "align-items": "center",
                "justify-content": "space-around",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { color: "#666666", "font-size": "1.1rem" },
                    },
                    [_vm._v("今天学习")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "flex-end",
                        "margin-top": "1rem",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "2rem",
                            color: "#666666",
                          },
                        },
                        [_vm._v(_vm._s(_vm.todyHour))]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "0.8rem",
                            color: "#666666",
                          },
                        },
                        [_vm._v("学时")]
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", {
                staticStyle: {
                  background: "#f1f1f1",
                  height: "4.3rem",
                  width: "0.1rem",
                },
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { color: "#666666", "font-size": "1.1rem" },
                    },
                    [_vm._v("累积学习")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "flex-end",
                        "margin-top": "1rem",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "2rem",
                            color: "#666666",
                          },
                        },
                        [_vm._v(_vm._s(_vm.studyHour))]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "0.8rem",
                            color: "#666666",
                          },
                        },
                        [_vm._v("学时")]
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", {
                staticStyle: {
                  background: "#f1f1f1",
                  height: "4.3rem",
                  width: "0.1rem",
                },
              }),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { color: "#666666", "font-size": "1.1rem" },
                    },
                    [_vm._v("累计减免学时")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "flex-end",
                        "margin-top": "1rem",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "2rem",
                            color: "#666666",
                          },
                        },
                        [_vm._v(_vm._s(_vm.reduceHour))]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "0.8rem",
                            color: "#666666",
                          },
                        },
                        [_vm._v("学时")]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._m(0),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.trainListLoading,
                  expression: "trainListLoading",
                },
              ],
              staticClass: "trainList_div",
            },
            [
              _c(
                "div",
                { staticClass: "trainList" },
                _vm._l(_vm.itemUserDTO, function (item, i) {
                  return _c("div", { key: i, staticClass: "trainItem" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "57%",
                          display: "flex",
                          "align-items": "center",
                          "margin-left": "1.6rem",
                          cursor: "pointer",
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            ref: "count1",
                            refInFor: true,
                            attrs: { target: "_blank" },
                            on: {
                              click: function ($event) {
                                return _vm.winOpen1(item, i)
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: {
                                "min-width": "5.8rem",
                                "max-width": "5.8rem",
                                height: "3.4rem",
                              },
                              attrs: { src: item.trainItem.itemCover, alt: "" },
                            }),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              height: "3.4rem",
                              display: "flex",
                              "flex-direction": "column",
                              "justify-content": "space-between",
                              overflow: "hidden",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "lin-1",
                                staticStyle: {
                                  "font-size": "0.9rem",
                                  color: "#303030",
                                  "margin-left": "1.5rem",
                                  cursor: "pointer",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(item.trainItem.itemName) + " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "0.9rem",
                                  color: "#303030",
                                  "margin-left": "1.5rem",
                                  cursor: "pointer",
                                },
                              },
                              [_vm._v(" " + _vm._s(item.assocName) + " ")]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "column",
                          "align-items": "flex-start",
                          width: "30%",
                          "justify-content": "center",
                          color: "#ff4d3b",
                          "font-size": "0.9rem",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(item.userTotalHours) +
                              "学时(必修" +
                              _vm._s(item.requiredHours) +
                              " | 选修" +
                              _vm._s(item.searchHours) +
                              ") / " +
                              _vm._s(
                                item.totalClassHours.requirdTarget +
                                  item.totalClassHours.searchTarget
                              ) +
                              "学时"
                          ),
                        ]),
                        item.itemUserRecord && item.itemUserRecord.status === 3
                          ? _c(
                              "el-tag",
                              {
                                staticStyle: { "margin-top": "5px" },
                                attrs: { type: "success", size: "small" },
                              },
                              [_vm._v("已达标")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "20%",
                          display: "flex",
                          "justify-content": "center",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              padding: "0.5rem",
                              "padding-right": "10%",
                              "font-size": "0.9rem",
                              cursor: "pointer",
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                ref: "count4",
                                refInFor: true,
                                attrs: { href: "", target: "_blank" },
                                on: {
                                  click: function ($event) {
                                    return _vm.winOpen4(item, i)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    item.userTotalHours === 0
                                      ? "开始学习"
                                      : "继续学习"
                                  )
                                ),
                              ]
                            ),
                            item.itemUserRecord && item.itemUserRecord.certLogId
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#007bff",
                                      "margin-top": "10px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.$router.push(
                                          "/personalCenter/certificatery?trainId=" +
                                            item.itemConfig.itemId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("查看证书")]
                                )
                              : _vm._e(),
                            item.itemUserRecord &&
                            item.itemUserRecord.status !== 3 &&
                            ![255].includes(_vm.assocId)
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#007bff",
                                      "margin-top": "10px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.apply(item)
                                      },
                                    },
                                  },
                                  [_vm._v("申请学时减免")]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            display: "flex",
            "align-items": "center",
            "justify-content": "flex-end",
            background: "#ffffff",
            padding: "0 2rem",
            height: "4rem",
          },
        },
        [
          _c("el-pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.trainListLoading,
                expression: "!trainListLoading",
              },
            ],
            attrs: {
              background: "",
              layout: "prev, pager, next",
              "page-size": _vm.size,
              total: _vm.total,
            },
            on: { "current-change": _vm.handleCurrentChange },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
          staticClass: "popup-tip",
        },
        [_vm._v(" 学时减免申请已提成功请前往我的审核中查看申请进度 ")]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          background: "#f8f8f8",
          height: "4.2rem",
          display: "flex",
          "align-items": "center",
          "padding-top": "1.6rem",
          "font-size": "0.9rem",
          color: "#999999",
        },
      },
      [
        _c("div", { staticStyle: { width: "60%", "padding-left": "1.6rem" } }, [
          _vm._v("课程信息"),
        ]),
        _c(
          "div",
          {
            staticStyle: {
              width: "40%",
              display: "flex",
              "justify-content": "center",
            },
          },
          [_vm._v(" 已学学时(不包含学时减免) / 达标学时要求 ")]
        ),
        _c(
          "div",
          {
            staticStyle: {
              width: "30%",
              display: "flex",
              "justify-content": "center",
            },
          },
          [_vm._v(" 操作 ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }