var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "announcementbox" },
    [
      _c(
        "div",
        {
          staticClass:
            "titleBg d-flex align-items-center justify-content-between",
        },
        [
          _c("div", { staticClass: "titleTextSy" }, [_vm._v("等待考试")]),
          _c(
            "button",
            { staticClass: "border backSy", on: { click: _vm.backHtml } },
            [_vm._v("返回")]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass:
            "box w-100 flex-column h-100 d-flex align-items-center justify-content-center",
        },
        [
          _vm._m(0),
          _c("div", { staticClass: "d-flex align-items-center" }, [
            _c("div", { staticClass: "d-flex align-items-center boxSy" }, [
              _c("img", {
                attrs: { src: require("@/assets/img/icon_dx.png"), alt: "" },
              }),
              _c("div", { staticClass: "d2" }, [_vm._v(_vm._s(_vm.title))]),
            ]),
            _c("div", { staticClass: "d-flex align-items-center boxSy" }, [
              _c("img", {
                attrs: { src: require("@/assets/img/icon_dx.png"), alt: "" },
              }),
              _c("div", { staticClass: "d1" }, [_vm._v("时长：")]),
              _c("div", { staticClass: "d2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.examTime == 0 ? "不限时长" : _vm.examTime + "分钟"
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("div", { staticClass: "d-flex align-items-center boxSy" }, [
              _c("img", {
                attrs: { src: require("@/assets/img/icon_dx.png"), alt: "" },
              }),
              _c("div", { staticClass: "d1" }, [_vm._v("总分：")]),
              _c("div", { staticClass: "d2" }, [
                _vm._v(_vm._s(_vm.examScore) + "分"),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "divTips rounded-pill" }, [
            _vm._v(
              " " + _vm._s(!_vm.mockExamination ? _vm.typeText : "") + " "
            ),
          ]),
          _vm.isTestValue != null
            ? _c("div", { staticClass: "btn_div" }, [
                _c(
                  "button",
                  {
                    staticClass: "submitBtn",
                    class: !_vm.mockExamination ? "unSubmitBtn" : "submitBtn",
                    attrs: { disabled: !_vm.mockExamination },
                    on: {
                      click: function ($event) {
                        return _vm.submit(0)
                      },
                    },
                  },
                  [_vm._v(" 开始考试 ")]
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm.dialogFaceVerification
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "提示",
                top: "40vh",
                visible: _vm.dialogFaceVerification,
                close: "closeFaceVerificationDialog(1)",
                width: "40%",
                center: "",
                "close-on-click-modal": false,
                "close-on-press-escape": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFaceVerification = $event
                },
              },
            },
            [
              _c("el-alert", {
                attrs: {
                  title: "请调试好摄像设备，端正坐姿，正对摄像头开始核验",
                  type: "warning",
                  "show-icon": "",
                },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoad,
                      expression: "dialogLoad",
                    },
                  ],
                  staticClass: "d-flex w-100",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-column d-flex justify-content-center align-items-center",
                      staticStyle: { flex: "1" },
                    },
                    [
                      _c("div", { staticClass: "dialog_tips_blue" }, [
                        _vm._v("现场照片"),
                      ]),
                      _c("video", {
                        attrs: {
                          id: "videoCamera",
                          width: _vm.videoWidth,
                          height: _vm.videoHeight,
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-column align-items-center d-flex align-items-center",
                      staticStyle: { flex: "1" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex-column align-items-center d-flex align-items-center",
                          staticStyle: { flex: "1" },
                        },
                        [
                          _c("div", { staticClass: "dialog_tips_blue" }, [
                            _vm._v("留底照片"),
                          ]),
                          _c("img", {
                            staticStyle: { width: "12rem", height: "14rem" },
                            attrs: { src: _vm.bottomPicture, alt: "" },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _vm.comparisonBtn
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.takePhoto },
                        },
                        [_vm._v("比对")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("canvas", {
        staticStyle: { display: "none" },
        attrs: {
          id: "canvasCamera",
          width: _vm.videoWidth,
          height: _vm.videoHeight,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "imgParent position-relative" }, [
      _c("img", { attrs: { src: require("@/assets/img/0104.png"), alt: "" } }),
      _c("div", {
        staticClass: "position-absolute d-flex justify-content-center w-100",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }