var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading",
        },
      ],
      staticClass: "app-container",
      staticStyle: {
        display: "flex",
        "flex-direction": "column",
        "min-height": "686px",
      },
    },
    [
      _vm.showType == 2
        ? _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                "min-height": "686px",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { "font-size": "1.4rem", color: "#303030" },
                    },
                    [_vm._v("订单详情")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "100%",
                        display: "flex",
                        "align-items": "center",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$router.back()
                        },
                      },
                    },
                    [_vm._v(" 返回上一页 ")]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    flex: "1",
                    background: "#ffffff",
                    "margin-top": "1.5rem",
                    padding: "1.5rem",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "main-top" },
                    [
                      _c("div", { staticClass: "main-info" }, [
                        _vm._v(" 订单编号： "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.detailsContent.orderNum)),
                        ]),
                      ]),
                      _c("div", { staticClass: "main-info" }, [
                        _vm._v(" 订单状态： "),
                        _vm.detailsContent.status == 1
                          ? _c("span", [_vm._v("待付款")])
                          : _vm._e(),
                        _vm.detailsContent.status == 2
                          ? _c("span", [_vm._v("已付款")])
                          : _vm._e(),
                        _vm.detailsContent.status == 3
                          ? _c("span", [_vm._v("取消")])
                          : _vm._e(),
                        _vm.detailsContent.status == 4
                          ? _c("span", [_vm._v("支付失败")])
                          : _vm._e(),
                        _vm.detailsContent.status == 5
                          ? _c("span", [_vm._v("已退款")])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "main-info main-flex" }, [
                        _c("div", [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.detailsContent.goodsImg ||
                                "https://res.hangxintong.cn/pcCoursecover.png",
                            },
                          }),
                        ]),
                        _c("div", [
                          _vm._v(_vm._s(_vm.detailsContent.goodsTitle)),
                        ]),
                        _vm.detailsContent.freeBuy
                          ? _c("div", [_vm._v("免费")])
                          : _c("div", [
                              _vm._v(
                                "￥" + _vm._s(_vm.detailsContent.realTotalMoney)
                              ),
                            ]),
                      ]),
                      _c("div", { staticClass: "main-info" }, [
                        _vm._v(
                          "下单时间：" + _vm._s(_vm.detailsContent.payTime)
                        ),
                      ]),
                      _c("div", { staticClass: "main-info" }, [
                        _vm._v(" 支付类型： "),
                        _vm.detailsContent.payType == 0
                          ? _c("span", [_vm._v("个人订单")])
                          : _vm._e(),
                        _vm.detailsContent.payType == 1
                          ? _c("span", [_vm._v("企业订单")])
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "main-info" }, [
                        _vm._v(" 支付方式： "),
                        _vm.detailsContent.payWay == 1
                          ? _c("span", [_vm._v("支付宝")])
                          : _vm._e(),
                        _vm.detailsContent.payWay == 2
                          ? _c("span", [_vm._v("微信")])
                          : _vm._e(),
                        _vm.detailsContent.payWay == 3
                          ? _c("span", [_vm._v("银行卡")])
                          : _vm._e(),
                        _vm.detailsContent.payWay == 4
                          ? _c("span", [_vm._v("对公账户转账")])
                          : _vm._e(),
                        _vm.detailsContent.payWay == 5
                          ? _c("span", [_vm._v("线下付款")])
                          : _vm._e(),
                        _vm.detailsContent.payWay == 6
                          ? _c("span", [_vm._v("抵用券付款")])
                          : _vm._e(),
                      ]),
                      _vm.orderLog.id
                        ? [
                            _c("div", { staticClass: "main-info" }, [
                              _vm._v(
                                " 退款时间：" +
                                  _vm._s(
                                    _vm.detailsContent.updateTime ||
                                      _vm.orderLog.updateTime ||
                                      _vm.detailsContent.deleteTime
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "main-info" }, [
                              _vm._v(
                                " 退款方式：" +
                                  _vm._s(
                                    _vm.orderLog.refundWay == 0
                                      ? "原路退款"
                                      : _vm.orderLog.refundWay == 1
                                      ? "支付宝"
                                      : _vm.orderLog.refundWay == 2
                                      ? "线下退款"
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("div", { staticClass: "main-info" }, [
                              _vm._v(
                                "退款原因：" + _vm._s(_vm.orderLog.refundRemark)
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.showType == 3
        ? _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "column",
                height: "100%",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { "font-size": "1.4rem", color: "#303030" },
                    },
                    [_vm._v("申请发票")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "100%",
                        display: "flex",
                        "align-items": "center",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$router.back()
                        },
                      },
                    },
                    [_vm._v(" 返回上一页 ")]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    flex: "1",
                    background: "#ffffff",
                    "margin-top": "1.5rem",
                    padding: "1.5rem",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-tabs",
                        { staticClass: "table-box" },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "dataForm",
                              staticClass: "elFormList",
                              staticStyle: { width: "550px" },
                              attrs: {
                                rules: _vm.rules,
                                model: _vm.invoiceData,
                                "label-position": "right",
                                "label-width": "110px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "项目名称：" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#606266",
                                        "font-size": "inherit",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.detailsContent.goodsTitle
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "可开票金额：" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "font-size": "inherit",
                                      },
                                    },
                                    [
                                      _c("span", [_vm._v("¥")]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.detailsContent.realTotalMoney
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticClass: "select-box",
                                  attrs: {
                                    label: "发票类型：",
                                    prop: "invoType",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "selectDown",
                                      attrs: { placeholder: "请选择" },
                                      on: {
                                        change: function ($event) {
                                          return _vm.ChangeIptEctronFirst()
                                        },
                                      },
                                      model: {
                                        value: _vm.invoiceData.invoType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.invoiceData,
                                            "invoType",
                                            $$v
                                          )
                                        },
                                        expression: "invoiceData.invoType",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-option",
                                        {
                                          attrs: {
                                            value: "1",
                                            label: "增值税电子普通发票",
                                          },
                                        },
                                        [_vm._v(" 增值税电子普通发票 ")]
                                      ),
                                      _c(
                                        "el-option",
                                        {
                                          attrs: {
                                            value: "2",
                                            label: "增值税专用发票",
                                            disabled: true,
                                          },
                                        },
                                        [_vm._v(" 增值税专用发票 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.ShowelEctron,
                                      expression: "ShowelEctron",
                                    },
                                  ],
                                  attrs: {
                                    label: "发票抬头：",
                                    prop: "invoTitle",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "embranchment",
                                    attrs: {
                                      placeholder: "请输入",
                                      maxlength: "30",
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.invoiceData.invoTitle =
                                          _vm.removeAllSpaces(
                                            _vm.invoiceData.invoTitle
                                          )
                                      },
                                    },
                                    model: {
                                      value: _vm.invoiceData.invoTitle,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.invoiceData,
                                          "invoTitle",
                                          $$v
                                        )
                                      },
                                      expression: "invoiceData.invoTitle",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.ShowelEctron,
                                      expression: "ShowelEctron",
                                    },
                                  ],
                                  attrs: {
                                    label: "公司税号：",
                                    prop: "invoCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "embranchment",
                                    attrs: {
                                      placeholder: "请输入 ",
                                      maxlength: "20",
                                      onkeyup:
                                        "this.value=this.value.replace(/[^\\w]/g,'');",
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.invoiceData.invoCode =
                                          _vm.removeAllSpaces(
                                            _vm.invoiceData.invoCode
                                          )
                                      },
                                    },
                                    model: {
                                      value: _vm.invoiceData.invoCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.invoiceData,
                                          "invoCode",
                                          $$v
                                        )
                                      },
                                      expression: "invoiceData.invoCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.ShowelEctron,
                                      expression: "ShowelEctron",
                                    },
                                  ],
                                  attrs: {
                                    label: "公司电话：",
                                    prop: "invoTel",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "embranchment",
                                    attrs: {
                                      placeholder: "请输入",
                                      maxlength: "11",
                                      onkeyup:
                                        "this.value=this.value.replace(/[^0-9]/g,'')",
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.invoiceData.invoTel =
                                          _vm.removeAllSpaces(
                                            _vm.invoiceData.invoTel
                                          )
                                      },
                                    },
                                    model: {
                                      value: _vm.invoiceData.invoTel,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.invoiceData,
                                          "invoTel",
                                          $$v
                                        )
                                      },
                                      expression: "invoiceData.invoTel",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.ShowelEctron,
                                      expression: "ShowelEctron",
                                    },
                                  ],
                                  attrs: {
                                    label: "开户银行：",
                                    prop: "invoBank",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "embranchment",
                                    attrs: { placeholder: "请输入" },
                                    on: {
                                      input: function ($event) {
                                        _vm.invoiceData.invoBank =
                                          _vm.removeAllSpaces(
                                            _vm.invoiceData.invoBank
                                          )
                                      },
                                    },
                                    model: {
                                      value: _vm.invoiceData.invoBank,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.invoiceData,
                                          "invoBank",
                                          $$v
                                        )
                                      },
                                      expression: "invoiceData.invoBank",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.ShowelEctron,
                                      expression: "ShowelEctron",
                                    },
                                  ],
                                  attrs: {
                                    label: "银行账号:",
                                    prop: "invoBankCode",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "embranchment",
                                    attrs: {
                                      placeholder: "请输入",
                                      maxlength: "25",
                                      onkeyup:
                                        "this.value=this.value.replace(/[^\\w]/g,'');",
                                    },
                                    on: {
                                      input: function ($event) {
                                        _vm.invoiceData.invoBankCode =
                                          _vm.removeAllSpaces(
                                            _vm.invoiceData.invoBankCode
                                          )
                                      },
                                    },
                                    model: {
                                      value: _vm.invoiceData.invoBankCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.invoiceData,
                                          "invoBankCode",
                                          $$v
                                        )
                                      },
                                      expression: "invoiceData.invoBankCode",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.ShowelEctron,
                                      expression: "ShowelEctron",
                                    },
                                  ],
                                  attrs: {
                                    label: "公司地址:",
                                    prop: "address",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "embranchment",
                                    attrs: { placeholder: "请输入" },
                                    on: {
                                      input: function ($event) {
                                        _vm.invoiceData.address =
                                          _vm.removeAllSpaces(
                                            _vm.invoiceData.address
                                          )
                                      },
                                    },
                                    model: {
                                      value: _vm.invoiceData.address,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.invoiceData,
                                          "address",
                                          $$v
                                        )
                                      },
                                      expression: "invoiceData.address",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.ShowelEctron,
                                      expression: "ShowelEctron",
                                    },
                                  ],
                                  attrs: { label: "收票邮箱:", prop: "email" },
                                },
                                [
                                  _c("el-input", {
                                    staticClass: "embranchment",
                                    attrs: { placeholder: "请输入" },
                                    on: {
                                      input: function ($event) {
                                        _vm.invoiceData.email =
                                          _vm.removeAllSpaces(
                                            _vm.invoiceData.email
                                          )
                                      },
                                    },
                                    model: {
                                      value: _vm.invoiceData.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.invoiceData, "email", $$v)
                                      },
                                      expression: "invoiceData.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "备注:" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 3,
                                      placeholder: "请输入内容",
                                      maxlength: "50",
                                      "show-word-limit": "",
                                    },
                                    model: {
                                      value: _vm.invoiceData.remarkLog,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.invoiceData,
                                          "remarkLog",
                                          $$v
                                        )
                                      },
                                      expression: "invoiceData.remarkLog",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.ShowUse,
                                      expression: "ShowUse",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "联系人:",
                                        prop: "receiver",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "embranchment",
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value: _vm.invoiceData.receiver,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.invoiceData,
                                              "receiver",
                                              $$v
                                            )
                                          },
                                          expression: "invoiceData.receiver",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "联系电话:",
                                        prop: "receiver_tel",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "embranchment",
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value: _vm.invoiceData.receiver_tel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.invoiceData,
                                              "receiver_tel",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "invoiceData.receiver_tel",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "邮寄地址:",
                                        prop: "receiverAddress",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "embranchment",
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value:
                                            _vm.invoiceData.receiverAddress,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.invoiceData,
                                              "receiverAddress",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "invoiceData.receiverAddress",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "dialog-footer",
                          attrs: { slot: "footer" },
                          slot: "footer",
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.savehalde },
                            },
                            [_vm._v("保存提交")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }