var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-main1 container" }, [
    _c(
      "div",
      { staticClass: "main-center" },
      [
        _c(
          "el-menu",
          {
            staticClass: "el-menu-vertical-demo",
            attrs: { "default-active": _vm.oldName },
          },
          [
            _c(
              "el-menu-item",
              {
                attrs: { index: "1" },
                on: {
                  click: function ($event) {
                    return _vm.handleSelect(1)
                  },
                },
              },
              [
                _c("i", { staticClass: "el-icon-user" }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("个人信息"),
                ]),
              ]
            ),
            _c(
              "el-submenu",
              { attrs: { index: "3" } },
              [
                _c("template", { slot: "title" }, [
                  _c("i", { staticClass: "el-icon-location" }),
                  _c("span", [_vm._v("我的申请")]),
                ]),
                _c(
                  "el-menu-item-group",
                  { staticClass: "item-top" },
                  [
                    _c(
                      "el-menu-item",
                      {
                        attrs: { index: "3-1" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSelect(31)
                          },
                        },
                      },
                      [_vm._v("报名申请 ")]
                    ),
                    _c(
                      "el-menu-item",
                      {
                        attrs: { index: "3-2" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSelect(32)
                          },
                        },
                      },
                      [_vm._v("学时减免申请 ")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _vm.cId !== "255"
              ? _c(
                  "el-submenu",
                  { attrs: { index: "4" } },
                  [
                    _c("template", { slot: "title" }, [
                      _c("i", { staticClass: "el-icon-location" }),
                      _c("span", [_vm._v("我的订单")]),
                    ]),
                    _c(
                      "el-menu-item-group",
                      { staticClass: "item-top" },
                      [
                        _c(
                          "el-menu-item",
                          {
                            attrs: { index: "4-1" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSelect(3)
                              },
                            },
                          },
                          [_vm._v("待付款 ")]
                        ),
                        _c(
                          "el-menu-item",
                          {
                            attrs: { index: "4-2" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSelect(4)
                              },
                            },
                          },
                          [_vm._v("已付款 ")]
                        ),
                        _c(
                          "el-menu-item",
                          {
                            attrs: { index: "4-4" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSelect(12)
                              },
                            },
                          },
                          [_vm._v("退款 ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm.cId !== "255"
              ? _c(
                  "el-submenu",
                  { attrs: { index: "5" } },
                  [
                    _c("template", { slot: "title" }, [
                      _c("i", { staticClass: "el-icon-folder-checked" }),
                      _c("span", [_vm._v("我的发票")]),
                    ]),
                    _c(
                      "el-menu-item-group",
                      { staticClass: "item-top" },
                      [
                        _c(
                          "el-menu-item",
                          {
                            attrs: { index: "5-1" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSelect(9)
                              },
                            },
                          },
                          [_vm._v("未开票 ")]
                        ),
                        _c(
                          "el-menu-item",
                          {
                            attrs: { index: "5-2" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSelect(8)
                              },
                            },
                          },
                          [_vm._v("已开票 ")]
                        ),
                        _c(
                          "el-menu-item",
                          {
                            attrs: { index: "5-3" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSelect(13)
                              },
                            },
                          },
                          [_vm._v("已换开 ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "el-submenu",
              { attrs: { index: "6" } },
              [
                _c("template", { slot: "title" }, [
                  _c("i", { staticClass: "el-icon-menu" }),
                  _c("span", [_vm._v("我的课程")]),
                ]),
                _c(
                  "el-menu-item-group",
                  { staticClass: "item-top" },
                  [
                    _c(
                      "el-menu-item",
                      {
                        attrs: { index: "6-1" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSelect(5)
                          },
                        },
                      },
                      [_vm._v("学习进度 ")]
                    ),
                    _c(
                      "el-menu-item",
                      {
                        attrs: { index: "6-2" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSelect(6)
                          },
                        },
                      },
                      [_vm._v("我的收藏 ")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "el-menu-item",
              {
                attrs: { index: "7" },
                on: {
                  click: function ($event) {
                    return _vm.handleSelect(10)
                  },
                },
              },
              [
                _c("i", { staticClass: "el-icon-notebook-2" }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("我的证书"),
                ]),
              ]
            ),
            _c(
              "el-menu-item",
              {
                attrs: { index: "8" },
                on: {
                  click: function ($event) {
                    return _vm.handleSelect(34)
                  },
                },
              },
              [
                _c("i", { staticClass: "el-icon-postcard" }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("我的准考证"),
                ]),
              ]
            ),
            _c(
              "el-menu-item",
              {
                attrs: { index: "9" },
                on: {
                  click: function ($event) {
                    return _vm.handleSelect(35)
                  },
                },
              },
              [
                _c("i", { staticClass: "el-icon-bell" }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("我的通知"),
                ]),
              ]
            ),
            ["688"].includes(_vm.cId)
              ? _c(
                  "el-submenu",
                  { attrs: { index: "11" } },
                  [
                    _c("template", { slot: "title" }, [
                      _c("i", { staticClass: "el-icon-shopping-cart-2" }),
                      _c("span", [_vm._v("批量采购")]),
                    ]),
                    _c(
                      "el-menu-item-group",
                      { staticClass: "item-top" },
                      [
                        _c(
                          "el-menu-item",
                          {
                            attrs: { index: "11-1" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSelect("采购课程")
                              },
                            },
                          },
                          [_vm._v("课程列表 ")]
                        ),
                        _c(
                          "el-menu-item",
                          {
                            attrs: { index: "11-2" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSelect("我的采购单")
                              },
                            },
                          },
                          [_vm._v("我的采购单 ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _c("div"),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "content",
        staticStyle: {
          flex: "1",
          "margin-top": "15px",
          "margin-left": "16px",
          width: "1150px",
        },
      },
      [_c("router-view", { key: _vm.key })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }