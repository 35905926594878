var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "period" },
    [
      _c("h4", [_vm._v("学时减免申请")]),
      _c(
        "section",
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "header-cell-style": {
                  "text-align": "center",
                  borderColor: "#ccc",
                },
                "cell-style": { "text-align": "center", borderColor: "#ccc" },
                height: "600",
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "trainName", label: "项目名称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "价格", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.freeBuy
                          ? _c("span", [_vm._v("免费")])
                          : _c("span", [
                              _vm._v("¥" + _vm._s(scope.row.trainPrice)),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "address", width: "120", label: "申请减免学时" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(" " + _vm._s(scope.row.hours || "0") + " "),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "address", width: "120", label: "实际减免学时" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(scope.row.hoursActual || "0") + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "myAuditStatus",
                  width: "100",
                  label: "申请状态",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "creationTime",
                  width: "180",
                  label: "提交时间",
                },
              }),
              _c("el-table-column", {
                attrs: { width: "150", label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.see(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        scope.row.auditStatus === 2
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.seeReject(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看拒绝原因")]
                            )
                          : _vm._e(),
                        scope.row.auditStatus === 2
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { color: "red" },
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.apply(scope.row)
                                  },
                                },
                              },
                              [_vm._v("重新提交")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "footer",
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "page-size": _vm.size,
              layout: "prev, pager, next",
              total: _vm.totalPage,
            },
            on: { "current-change": _vm.currentChange },
          }),
        ],
        1
      ),
      _vm.isRefusePopup
        ? _c(
            "hxt-popup",
            {
              attrs: {
                icon: require("@/assets/img/assoc_dialog.png"),
                title: "拒绝原因",
                visible: _vm.isRefusePopup,
                size: "1",
                "show-close": true,
              },
              on: {
                close: function ($event) {
                  _vm.isRefusePopup = false
                },
              },
            },
            [
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _c(
                  "div",
                  { staticStyle: { padding: "20px" } },
                  [
                    _c("el-input", {
                      attrs: {
                        value: _vm.rejectReason,
                        type: "textarea",
                        autosize: { minRows: 9, maxRows: 9 },
                        readonly: true,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.isRefusePopup = false
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }