var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "registration-status" }, [
    _c("div", { staticClass: "status-card" }, [
      _c("img", {
        staticStyle: { width: "200px", "margin-bottom": "20px" },
        attrs: { src: require("@/assets/img/publicAddress.jpg"), alt: "" },
      }),
      _c("p", [
        _vm._v("请使用微信扫一扫查看进度；电话：" + _vm._s(_vm.assocTel)),
      ]),
      _c("h4", [_vm._v("您提交的报名验证正在审核中，请耐心等待")]),
      _c(
        "div",
        { staticClass: "btns" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.push("/")
                },
              },
            },
            [_vm._v("返回首页")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("查看我的申请")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }