var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "newsPageContainer" },
    [
      _c("NewsList", {
        attrs: {
          title: _vm.$route.query.title || "标题",
          list: _vm.list,
          isShowBtn: false,
        },
        on: { goDetail: _vm.goDetail },
      }),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }