"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  provide: function provide() {
    return {
      handleSelect: this.handleSelect
    };
  },
  name: 'PersonalcenterTab',
  data: function data() {
    return {
      activeIndex: '1',
      owuindex: this.$route.query.index,
      oldName: '1',
      item: [],
      jumpType: ''
    };
  },
  computed: {
    key: function key() {
      if (this.$route.path === '/personalCenter') {
        this.$router.push('/personalCenter/personalInfo');
        return '/personalCenter/personalInfo';
      } else {
        return this.$route.path;
      }
    },
    // 协会id
    cId: function cId() {
      return (0, _auth.getAssocId)();
    }
  },
  created: function created() {
    if (this.$route.path === '/personalCenter' || this.$route.path === '/personalCenter/personalInfo') {
      this.oldName = '1';
    } else if (this.$route.path === '/personalCenter/allOrder') {
      this.oldName = '4-3';
    } else if (this.$route.path === '/personalCenter/appliedOrder') {
      this.oldName = '4-1';
    } else if (this.$route.path === '/personalCenter/applyingOrder') {
      this.oldName = '4-2';
    } else if (this.$route.path === '/personalCenter/refundOrder') {
      this.oldName = '4-4';
    } else if (this.$route.path === '/personalCenter/personalStudy') {
      this.oldName = '6-1';
    } else if (this.$route.path === '/personalCenter/personalCollection') {
      this.oldName = '6-2';
    } else if (this.$route.path === '/personalCenter/certificate') {
      this.oldName = '7';
    } else if (this.$route.path === '/personalCenter/appliedInvoice') {
      this.oldName = '5-2';
    } else if (this.$route.path === '/personalCenter/applyingInvoice') {
      this.oldName = '5-1';
    } else if (this.$route.path === '/personalCenter/exchangeInvoice') {
      this.oldName = '5-3';
    } else if (this.$route.path === '/personalCenter/certificate') {
      this.oldName = '10';
    } else if (this.$route.path.indexOf('personalOrder') > -1) {
      this.oldName = this.$route.query.menuName;
    } else if (this.$route.path === '/personalCenter/registration') {
      this.oldName = '3-1';
    } else if (this.$route.path === '/personalCenter/period') {
      this.oldName = '3-2';
    } else if (this.$route.path === '/personalCenter/myRegistration/refuse') {
      this.oldName = '3-3';
    } else if (this.$route.path === '/personalCenter/MyNotice') {
      this.oldName = '9';
    } else if (this.$route.path === '/personalCenter/admissionTicket') {
      this.oldName = '8';
    } else if (this.$route.path === '/personalCenter/allexam') {
      this.oldName = '2-1';
    } else if (this.$route.path === '/personalCenter/myexam') {
      this.oldName = '2-2';
    }
    //  else if (this.$route.path === '/personalCenter/purchaseCourse') {
    //   this.oldName = '11-1'
    // } else if (this.$route.path === '/personalCenter/myPurchase') {
    //   this.oldName = '11-2'
    // }
  },
  methods: {
    handleSelect: function handleSelect(key, item, jumpType, menuName) {
      // this.activeIndex = key
      if (key == 1) {
        this.$router.push('/personalCenter/personalInfo');
      } else if (key == 2) {
        this.$router.push('/personalCenter/allOrder');
      } else if (key == 3) {
        this.$router.push('/personalCenter/appliedOrder');
      } else if (key == 4) {
        this.$router.push('/personalCenter/applyingOrder');
      } else if (key == 5) {
        this.$router.push('/personalCenter/personalStudy');
      } else if (key == 6) {
        this.$router.push('/personalCenter/personalCollection');
      } else if (key == 7) {
        this.$router.push('/personalCenter/aminat/personalCenter/exion');
      } else if (key == 8) {
        this.$router.push('/personalCenter/appliedInvoice');
      } else if (key == 9) {
        this.$router.push('/personalCenter/applyingInvoice');
      } else if (key == 10) {
        this.$router.push('/personalCenter/certificate');
      } else if (key == 11) {
        this.item = item;
        this.jumpType = jumpType;
        this.$router.push({
          path: '/personalCenter/personalOrder',
          query: {
            id: item.id,
            jumpType: jumpType,
            menuName: menuName
          }
        });
      } else if (key == 12) {
        this.$router.push('/personalCenter/refundOrder');
      } else if (key === 13) {
        this.$router.push('/personalCenter/exchangeInvoice');
      } else if (key == 31) {
        // this.$router.push('/personalCenter/myRegistration/underReview')
        this.$router.push('/personalCenter/registration');
      } else if (key == 32) {
        // this.$router.push('/personalCenter/myRegistration/passed')
        this.$router.push('/personalCenter/period');
      } else if (key == 33) {
        this.$router.push('/personalCenter/myRegistration/refuse');
      } else if (key == 34) {
        this.$router.push('/personalCenter/admissionTicket');
      } else if (key == 35) {
        this.$router.push('/personalCenter/MyNotice');
      } else if (key === '全部考试') {
        this.$router.push('/personalCenter/allexam');
      } else if (key === '我参与的') {
        this.$router.push('/personalCenter/myexam');
      } else if (key === '采购课程') {
        this.$router.push('/personalCenter/purchaseCourse');
      } else if (key === '我的采购单') {
        this.$router.push('/personalCenter/myPurchase');
      }
    }
  }
};