var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "examlistitem" },
    [
      _c("div", { staticClass: "left" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.itemcon.planName)),
        ]),
        _c("div", { staticClass: "desc-frist" }, [
          _c("span", [
            _vm._v(
              "考试时长：" +
                _vm._s(
                  _vm.itemcon.examTime == 0
                    ? "不限时长"
                    : _vm.itemcon.examTime + "分钟"
                )
            ),
          ]),
          _c("span", [
            _vm._v("题数：" + _vm._s(_vm.itemcon.examQuestionNum) + "题"),
          ]),
          _c("span", [_vm._v("总分：" + _vm._s(_vm.itemcon.examScore) + "分")]),
          _c("span", [
            _vm._v("合格：" + _vm._s(_vm.itemcon.examPassScore) + "分"),
          ]),
        ]),
        _c("div", { staticClass: "desc-second" }, [
          _c("span", { staticClass: "type" }, [
            _vm._v(_vm._s(_vm.examMethodcin)),
          ]),
          _vm.itemcon.examStartTime
            ? _c("span", [
                _vm._v(
                  "考试时间：" +
                    _vm._s(_vm.itemcon.examStartTime) +
                    " - " +
                    _vm._s(_vm.itemcon.examEndTime)
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _vm.handleData1.btnLeftText
            ? _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.itemBtnLeftClick(
                        _vm.handleData1.btnLeftType,
                        _vm.handleData1
                      )
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.handleData1.btnLeftText))]
              )
            : _vm._e(),
          _vm.handleData1.btnRightText
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.itemBtnRightClick(
                        _vm.handleData1.btnRightType,
                        _vm.handleData1
                      )
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.handleData1.btnRightText))]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticStyle: { "z-index": "999999" },
          attrs: {
            title: "提示",
            top: "20vh",
            visible: _vm.dialogFaceVerification,
            close: "closeFaceVerificationDialog(1)",
            width: "40%",
            center: "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFaceVerification = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoad,
                  expression: "dialogLoad",
                },
              ],
              staticClass: "d-flex w-100",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex-column d-flex justify-content-center align-items-center",
                  staticStyle: { flex: "1" },
                },
                [
                  _c("div", { staticClass: "dialog_tips_blue" }, [
                    _vm._v("现场照片"),
                  ]),
                  _c("video", {
                    attrs: {
                      id: "videoCamera",
                      width: _vm.videoWidth,
                      height: _vm.videoHeight,
                    },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "flex-column align-items-center d-flex align-items-center",
                  staticStyle: { flex: "1" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-column align-items-center d-flex align-items-center",
                      staticStyle: { flex: "1" },
                    },
                    [
                      _c("div", { staticClass: "dialog_tips_blue" }, [
                        _vm._v("留底照片"),
                      ]),
                      _c("img", {
                        staticStyle: { width: "7.57rem", height: "9.07rem" },
                        attrs: { src: _vm.bottomPicture, alt: "" },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex-column align-items-end d-flex align-items-center",
                      staticStyle: { flex: "1" },
                    },
                    [
                      _c("div", { staticClass: "dialog_tips_blue" }, [
                        _vm._v("比对照片"),
                      ]),
                      _c("img", {
                        staticStyle: { width: "7.57rem", height: "9.07rem" },
                        attrs: {
                          src: _vm.imgSrc ? _vm.imgSrc : _vm.verification,
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.comparisonBtn
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.takePhoto },
                    },
                    [_vm._v("比对")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c("canvas", {
        staticStyle: { display: "none" },
        attrs: {
          id: "canvasCamera",
          width: _vm.videoWidth,
          height: _vm.videoHeight,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            top: "20vh",
            visible: _vm.errorDialogVisible,
            "show-close": false,
            width: "25%",
            center: "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.errorDialogVisible = $event
            },
          },
        },
        [
          _vm.tips
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center",
                  staticStyle: { "font-size": "1.71rem", color: "#303030" },
                },
                [_vm._v(" " + _vm._s(_vm.tips) + " ")]
              )
            : _vm._e(),
          _vm.tipsChild
            ? _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "1rem",
                    color: "#333333",
                    "margin-top": "1.14rem",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.tipsChild) + " ")]
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.correctBtnText
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.closeCorrectDialog },
                    },
                    [_vm._v(_vm._s(_vm.correctBtnText))]
                  )
                : _vm._e(),
              _vm.errorBtnText
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.closeErrorDialog },
                    },
                    [_vm._v(_vm._s(_vm.errorBtnText))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }