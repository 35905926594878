var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.dialogStartExamination
        ? _c(
            "el-dialog",
            {
              staticClass: "showAnswers_dialog",
              attrs: {
                "close-on-press-escape": false,
                "close-on-click-modal": false,
                showCancelButton: false,
                visible: _vm.dialogStartExamination,
                fullscreen: true,
                "show-close": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogStartExamination = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "flex-column box",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-between titleBg",
                    },
                    [
                      _c("div", { staticClass: "d-flex align-items-center" }, [
                        _c("img", { attrs: { src: _vm.assocLogo, alt: "" } }),
                        _c("div", { staticClass: "titleText" }, [
                          _vm._v("考试系统"),
                        ]),
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "border backSy",
                          on: { click: _vm.backHtml },
                        },
                        [_vm._v("返回")]
                      ),
                    ]
                  ),
                  _c("div", { staticClass: "d-flex childBox" }, [
                    _c(
                      "div",
                      { staticClass: "problemBox d-flex flex-column" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "border-bottom title d-flex align-items-center",
                          },
                          [_vm._v(" 考试题目 ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "d-flex flex-column specificproblem",
                            staticStyle: { flex: "1" },
                          },
                          [
                            _vm.problemItem
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "border-bottom problemtitle",
                                    staticStyle: {
                                      "padding-bottom": "1.41rem",
                                    },
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "problemtitle",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.problemItem.tqContentOb.title
                                        ),
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "problemtitle" },
                                      [
                                        _vm._v(
                                          " (" +
                                            _vm._s(
                                              _vm.problemItem.tqType == 1
                                                ? "单选题"
                                                : _vm.problemItem.tqType == 2
                                                ? "多选题"
                                                : _vm.problemItem.tqType == 3
                                                ? "判断题"
                                                : _vm.problemItem.tqType == 4
                                                ? "填空题"
                                                : _vm.problemItem.tqType == 5
                                                ? "问答题"
                                                : "组合题"
                                            ) +
                                            "，" +
                                            _vm._s(_vm.problemItem.score) +
                                            "分) "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticStyle: { height: "calc(100vh - 294px)" },
                              },
                              [
                                _vm.problemItem.tqType == 1
                                  ? _c(
                                      "div",
                                      [
                                        _vm._l(
                                          _vm.problemItem.tqContentOb.options,
                                          function (item, index) {
                                            return _c("div", { key: index }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center choiceItem",
                                                  class:
                                                    item.sel == 1
                                                      ? "item-option-choice-sel"
                                                      : item.sel == 2
                                                      ? "item-option-choice-error"
                                                      : "item-option-choice",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src:
                                                        item.sel == 1
                                                          ? _vm.icon_dui
                                                          : item.sel == 2
                                                          ? _vm.icon_cuo
                                                          : _vm.icon_wxz,
                                                      alt: "",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "con" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.answerList[index]
                                                        ) + "、"
                                                      ),
                                                    ]
                                                  ),
                                                  _c("div", {
                                                    staticClass: "con",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.value
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ])
                                          }
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "answerTips d-flex align-items-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "d-flex su" },
                                              [
                                                _vm._v(" 正确答案： "),
                                                _vm._l(
                                                  _vm.problemItem.answerList,
                                                  function (chi, ins) {
                                                    return _c(
                                                      "div",
                                                      { key: ins },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.ABC[chi]
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex",
                                                class:
                                                  _vm.problemItem.flag == 1
                                                    ? "su"
                                                    : "er",
                                              },
                                              [
                                                _vm._v(" 考生答案： "),
                                                _vm._l(
                                                  _vm.problemItem.userAnswer,
                                                  function (chi, ins) {
                                                    return _c(
                                                      "div",
                                                      { key: ins },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.ABC[chi]
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm.problemItem.tqType == 2
                                  ? _c(
                                      "div",
                                      [
                                        _vm._l(
                                          _vm.problemItem.tqContentOb.options,
                                          function (item, index) {
                                            return _c("div", { key: index }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center choiceItem",
                                                  class:
                                                    item.sel == 1
                                                      ? "item-option-choice-sel"
                                                      : item.sel == 2
                                                      ? "item-option-choice-error"
                                                      : "item-option-choice",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src:
                                                        item.sel == 1
                                                          ? _vm.icon_dui
                                                          : item.sel == 2
                                                          ? _vm.icon_cuo
                                                          : _vm.icon_wxz,
                                                      alt: "",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "con" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.answerList[index]
                                                        ) + "、"
                                                      ),
                                                    ]
                                                  ),
                                                  _c("div", {
                                                    staticClass: "con",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.value
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ])
                                          }
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "answerTips d-flex align-items-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "d-flex su" },
                                              [
                                                _vm._v(" 正确答案： "),
                                                _vm._l(
                                                  _vm.problemItem.answerList,
                                                  function (chi, ins) {
                                                    return _c(
                                                      "div",
                                                      { key: ins },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.ABC[chi]
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex",
                                                class:
                                                  _vm.problemItem.flag == 1
                                                    ? "su"
                                                    : "er",
                                              },
                                              [
                                                _vm._v(" 考生答案： "),
                                                _vm._l(
                                                  _vm.problemItem.userAnswer,
                                                  function (chi, ins) {
                                                    return _c(
                                                      "div",
                                                      { key: ins },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.ABC[chi]
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm.problemItem.tqType == 3
                                  ? _c(
                                      "div",
                                      [
                                        _vm._l(
                                          _vm.problemItem.tqContentOb.options,
                                          function (item, index) {
                                            return _c("div", { key: index }, [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-items-center choiceItem",
                                                  class:
                                                    item.sel == 1
                                                      ? "item-option-choice-sel"
                                                      : item.sel == 2
                                                      ? "item-option-choice-error"
                                                      : "item-option-choice",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src:
                                                        item.sel == 1
                                                          ? _vm.icon_dui
                                                          : item.sel == 2
                                                          ? _vm.icon_cuo
                                                          : _vm.icon_wxz,
                                                      alt: "",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "con" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.answerList[index]
                                                        ) + "、"
                                                      ),
                                                    ]
                                                  ),
                                                  _c("div", {
                                                    staticClass: "con",
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        item.value
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ])
                                          }
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "answerTips d-flex align-items-center",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "d-flex su" },
                                              [
                                                _vm._v(" 正确答案： "),
                                                _vm._l(
                                                  _vm.problemItem.answerList,
                                                  function (chi, ins) {
                                                    return _c(
                                                      "div",
                                                      { key: ins },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.ABC[chi]
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "d-flex",
                                                class:
                                                  _vm.problemItem.flag == 1
                                                    ? "su"
                                                    : "er",
                                              },
                                              [
                                                _vm._v(" 考生答案： "),
                                                _vm._l(
                                                  _vm.problemItem.userAnswer,
                                                  function (chi, ins) {
                                                    return _c(
                                                      "div",
                                                      { key: ins },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.ABC[chi]
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        ),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm.problemItem.tqType == 4
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.problemItem.showAnswer,
                                        function (item, index) {
                                          return _c("div", { key: index }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "item-option-completion",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "divNum" },
                                                  [
                                                    _vm._v(
                                                      "填空" + _vm._s(index + 1)
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "divSuText",
                                                      },
                                                      [_vm._v("正确答案：")]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "divSu d-flex align-items-center",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.correct.replace(
                                                                /,/g,
                                                                "或"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex align-items-center answerParent",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        class:
                                                          item.correct ==
                                                          item.user
                                                            ? "divSuText"
                                                            : "divErText",
                                                      },
                                                      [_vm._v(" 考生答案： ")]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex align-items-center",
                                                        class:
                                                          item.correct ==
                                                          item.user
                                                            ? "divSu"
                                                            : "divEr",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.user) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _vm.problemItem.tqType == 5
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "item-option-completion",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "divSuText" },
                                            [_vm._v("正确答案：")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex textareaParent",
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "textareaSu w-100 rounded",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.problemItem
                                                      .answerList[0]
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              class:
                                                _vm.problemItem.flag == 1
                                                  ? "divSuText"
                                                  : "divErText",
                                            },
                                            [_vm._v(" 考生答案： ")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex textareaParent",
                                            },
                                            [
                                              _c("div", {
                                                staticClass: "w-100 rounded",
                                                class:
                                                  _vm.problemItem.flag == 1
                                                    ? "textareaSu"
                                                    : "textareaEr",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.problemItem.value
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.problemItem.tqType == 6
                                  ? _c(
                                      "div",
                                      _vm._l(
                                        _vm.problemItem.children,
                                        function (item, index) {
                                          return _c("div", { key: index }, [
                                            item.tqContentOb.title
                                              ? _c("div", {
                                                  staticClass:
                                                    "d-flex problemtitle-combination",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      item.tqContentOb.title
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            item.tqType == 1
                                              ? _c(
                                                  "div",
                                                  [
                                                    _vm._l(
                                                      item.tqContentOb.options,
                                                      function (
                                                        itemChildren,
                                                        childrenindex
                                                      ) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: childrenindex,
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-items-center choiceItem",
                                                                class:
                                                                  itemChildren.sel ==
                                                                  1
                                                                    ? "item-option-choice-sel"
                                                                    : itemChildren.sel ==
                                                                      2
                                                                    ? "item-option-choice-error"
                                                                    : "item-option-choice",
                                                              },
                                                              [
                                                                _c("img", {
                                                                  attrs: {
                                                                    src:
                                                                      itemChildren.sel ==
                                                                      1
                                                                        ? _vm.icon_dui
                                                                        : itemChildren.sel ==
                                                                          2
                                                                        ? _vm.icon_cuo
                                                                        : _vm.icon_wxz,
                                                                    alt: "",
                                                                  },
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "con",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .answerList[
                                                                            childrenindex
                                                                          ]
                                                                        ) +
                                                                        "、 "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c("div", {
                                                                  staticClass:
                                                                    "con",
                                                                  domProps: {
                                                                    innerHTML:
                                                                      _vm._s(
                                                                        itemChildren.value
                                                                      ),
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "answerTips d-flex align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex su",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " 正确答案： "
                                                            ),
                                                            _vm._l(
                                                              item.answerList,
                                                              function (
                                                                chi,
                                                                ins
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  { key: ins },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .ABC[
                                                                            chi
                                                                          ]
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex",
                                                            class:
                                                              item.flag == 1
                                                                ? "su"
                                                                : "er",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " 考生答案： "
                                                            ),
                                                            _vm._l(
                                                              item.userAnswer,
                                                              function (
                                                                chi,
                                                                ins
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  { key: ins },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .ABC[
                                                                            chi
                                                                          ]
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            item.tqType == 2
                                              ? _c(
                                                  "div",
                                                  [
                                                    _vm._l(
                                                      item.tqContentOb.options,
                                                      function (
                                                        itemChildren,
                                                        childrenindex
                                                      ) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: childrenindex,
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-items-center choiceItem",
                                                                class:
                                                                  itemChildren.sel ==
                                                                  1
                                                                    ? "item-option-choice-sel"
                                                                    : itemChildren.sel ==
                                                                      2
                                                                    ? "item-option-choice-error"
                                                                    : "item-option-choice",
                                                              },
                                                              [
                                                                _c("img", {
                                                                  attrs: {
                                                                    src:
                                                                      itemChildren.sel ==
                                                                      1
                                                                        ? _vm.icon_dui
                                                                        : itemChildren.sel ==
                                                                          2
                                                                        ? _vm.icon_cuo
                                                                        : _vm.icon_wxz,
                                                                    alt: "",
                                                                  },
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "con",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .answerList[
                                                                            childrenindex
                                                                          ]
                                                                        ) +
                                                                        "、 "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c("div", {
                                                                  staticClass:
                                                                    "con",
                                                                  domProps: {
                                                                    innerHTML:
                                                                      _vm._s(
                                                                        itemChildren.value
                                                                      ),
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "answerTips d-flex align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex su",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " 正确答案： "
                                                            ),
                                                            _vm._l(
                                                              item.answerList,
                                                              function (
                                                                chi,
                                                                ins
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  { key: ins },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .ABC[
                                                                            chi
                                                                          ]
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex",
                                                            class:
                                                              item.flag == 1
                                                                ? "su"
                                                                : "er",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " 考生答案： "
                                                            ),
                                                            _vm._l(
                                                              item.userAnswer,
                                                              function (
                                                                chi,
                                                                ins
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  { key: ins },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .ABC[
                                                                            chi
                                                                          ]
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            item.tqType == 3
                                              ? _c(
                                                  "div",
                                                  [
                                                    _vm._l(
                                                      item.tqContentOb.options,
                                                      function (
                                                        itemChildren,
                                                        childrenindex
                                                      ) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: childrenindex,
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-items-center choiceItem",
                                                                class:
                                                                  itemChildren.sel ==
                                                                  1
                                                                    ? "item-option-choice-sel"
                                                                    : itemChildren.sel ==
                                                                      2
                                                                    ? "item-option-choice-error"
                                                                    : "item-option-choice",
                                                              },
                                                              [
                                                                _c("img", {
                                                                  attrs: {
                                                                    src:
                                                                      itemChildren.sel ==
                                                                      1
                                                                        ? _vm.icon_dui
                                                                        : itemChildren.sel ==
                                                                          2
                                                                        ? _vm.icon_cuo
                                                                        : _vm.icon_wxz,
                                                                    alt: "",
                                                                  },
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "con",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .answerList[
                                                                            childrenindex
                                                                          ]
                                                                        ) +
                                                                        "、 "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c("div", {
                                                                  staticClass:
                                                                    "con",
                                                                  domProps: {
                                                                    innerHTML:
                                                                      _vm._s(
                                                                        itemChildren.value
                                                                      ),
                                                                  },
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "answerTips d-flex align-items-center",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex su",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " 正确答案： "
                                                            ),
                                                            _vm._l(
                                                              item.answerList,
                                                              function (
                                                                chi,
                                                                ins
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  { key: ins },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .ABC[
                                                                            chi
                                                                          ]
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex",
                                                            class:
                                                              item.flag == 1
                                                                ? "su"
                                                                : "er",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " 考生答案： "
                                                            ),
                                                            _vm._l(
                                                              item.userAnswer,
                                                              function (
                                                                chi,
                                                                ins
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  { key: ins },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .ABC[
                                                                            chi
                                                                          ]
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                            item.tqType == 4
                                              ? _c(
                                                  "div",
                                                  _vm._l(
                                                    item.showAnswer,
                                                    function (
                                                      itemChildren,
                                                      childrenindex
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        { key: childrenindex },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "item-option-completion",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "divNum",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "填空" +
                                                                      _vm._s(
                                                                        childrenindex +
                                                                          1
                                                                      )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-items-center",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "divSuText",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "正确答案："
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "divSu d-flex align-items-center",
                                                                    domProps: {
                                                                      innerHTML:
                                                                        _vm._s(
                                                                          itemChildren.correct.replace(
                                                                            /,/g,
                                                                            "或"
                                                                          )
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-items-center answerParent",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      class:
                                                                        itemChildren.correct ==
                                                                        itemChildren.user
                                                                          ? "divSuText"
                                                                          : "divErText",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " 考生答案： "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c("div", {
                                                                    staticClass:
                                                                      "d-flex align-items-center",
                                                                    class:
                                                                      itemChildren.correct ==
                                                                      itemChildren.user
                                                                        ? "divSu"
                                                                        : "divEr",
                                                                    domProps: {
                                                                      innerHTML:
                                                                        _vm._s(
                                                                          itemChildren.user
                                                                        ),
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            item.tqType == 5
                                              ? _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "item-option-completion",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "divSuText",
                                                        },
                                                        [_vm._v("正确答案：")]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex textareaParent",
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass:
                                                              "textareaSu w-100 rounded",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item
                                                                  .answerList[0]
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          class:
                                                            item.flag == 1
                                                              ? "divSuText"
                                                              : "divErText",
                                                        },
                                                        [_vm._v(" 考生答案： ")]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "d-flex textareaParent",
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass:
                                                              "w-100 rounded",
                                                            class:
                                                              item.flag == 1
                                                                ? "textareaSu"
                                                                : "textareaEr",
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.value
                                                              ),
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "bottom border-top d-flex align-items-center justify-content-end",
                          },
                          [
                            _c("div", { staticClass: "d-flex" }, [
                              _vm.problemItemIndex !== 0
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btnSYT",
                                      on: {
                                        click: function ($event) {
                                          return _vm.buttomBntClick(2)
                                        },
                                      },
                                    },
                                    [_vm._v(" 上一题 ")]
                                  )
                                : _vm._e(),
                              _vm.problemItemIndex !== this.allList.length - 1
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btnXYT",
                                      on: {
                                        click: function ($event) {
                                          return _vm.buttomBntClick(3)
                                        },
                                      },
                                    },
                                    [_vm._v(" 下一题 ")]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "cardBox d-flex flex-column" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "border-bottom title d-flex align-items-center justify-content-between",
                        },
                        [
                          _c("div", { staticClass: "t1" }, [_vm._v("答题卡")]),
                          _c(
                            "div",
                            {
                              staticClass: "d-flex align-items-center selType",
                            },
                            [
                              _c("div", {
                                staticClass: "rounded-circle img cardItem2",
                              }),
                              _c("div", [_vm._v("答对")]),
                              _c("div", {
                                staticClass: "rounded-circle img cardItem1",
                              }),
                              _c("div", [_vm._v("答错")]),
                              _c("div", {
                                staticClass: "rounded-circle img cardItem3",
                              }),
                              _c("div", [_vm._v("部分对")]),
                              _c("div", {
                                staticClass: "rounded-circle img cardItem5",
                              }),
                              _c("div", [_vm._v("当前")]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "cardBox-body",
                          staticStyle: { flex: "1" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "row row-cols-6" },
                            _vm._l(_vm.allList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass:
                                    "d-flex align-items-center justify-content-center",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-center cardItem",
                                      class:
                                        _vm.problemItemIndex === index
                                          ? "cardItem5"
                                          : item.flag == 0 || item.flag == 9
                                          ? "cardItem1"
                                          : item.flag == 1
                                          ? "cardItem2"
                                          : "cardItem3",
                                      on: {
                                        click: function ($event) {
                                          return _vm.cardItemClick(index)
                                        },
                                      },
                                    },
                                    [_c("div", [_vm._v(_vm._s(index + 1))])]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            top: "40vh",
            visible: _vm.errorDialogVisible,
            "show-close": false,
            width: "25%",
            center: "",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.errorDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-center justify-content-center" },
            [_vm._v(" " + _vm._s(_vm.tips) + " ")]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.errorBtnText
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.closeErrorDialog },
                    },
                    [_vm._v(_vm._s(_vm.errorBtnText))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }