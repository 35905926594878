"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.array.push.js");
var _train = require("@/api/train");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  inject: ['handleSelect'],
  data: function data() {
    return {
      showLoadingDiv: false,
      total: 100,
      list: null,
      listLoading: true,
      listQuery: {
        page: 1,
        size: 100,
        status: 1
      },
      yualmsihw: ''
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    xiazais: function xiazais() {
      var bdhtml = window.document.body.innerHTML;
      var jubuData;
      if (document.querySelectorAll('#pdfDom').length > 1) {
        document.querySelectorAll('#pdfDom').forEach(function (it) {
          jubuData += it.innerHTML;
        });
      } else {
        jubuData = document.querySelector('#pdfDom').innerHTML;
      }
      window.document.body.innerHTML = jubuData;
      window.document.body.style.padding = '20px';
      window.print();
      // 重新给页面内容赋值；
      // console.log(document.execCommand("print"))
      // if(console.log(document.execCommand("print"))){
      //   window.close()
      window.document.body.innerHTML = bdhtml;
      // }
      location.reload();
      // this.getPdf()
    },
    yulans: function yulans(item) {
      this.yualmsihw = JSON.parse(item);
      this.showLoadingDiv = true;
    },
    // 查看审核
    deletemappingfun: function deletemappingfun(id) {
      this.$router.push({
        path: '/fillOutTheForm/sno2ij928vn/',
        query: {
          id: id.trainId,
          type: 2
        }
      });
    },
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      (0, _train.getRegistrationListByStatus)(1).then(function (res) {
        if (res.code === 200) {
          _this.list = res.data.list;
          _this.total = res.data.total;
        }
      }).finally(function () {
        _this.listLoading = false;
      });
    }
  }
};