"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.aliUpload = aliUpload;
exports.aliUploadBase64 = aliUploadBase64;
exports.aliUploadFile = aliUploadFile;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.match.js");
require("core-js/modules/web.atob.js");
require("core-js/modules/web.dom-exception.constructor.js");
require("core-js/modules/web.dom-exception.stack.js");
require("core-js/modules/web.dom-exception.to-string-tag.js");
require("core-js/modules/es.array-buffer.slice.js");
require("core-js/modules/es.typed-array.uint8-array.js");
require("core-js/modules/es.typed-array.at.js");
require("core-js/modules/es.typed-array.copy-within.js");
require("core-js/modules/es.typed-array.every.js");
require("core-js/modules/es.typed-array.fill.js");
require("core-js/modules/es.typed-array.filter.js");
require("core-js/modules/es.typed-array.find.js");
require("core-js/modules/es.typed-array.find-index.js");
require("core-js/modules/es.typed-array.find-last.js");
require("core-js/modules/es.typed-array.find-last-index.js");
require("core-js/modules/es.typed-array.for-each.js");
require("core-js/modules/es.typed-array.includes.js");
require("core-js/modules/es.typed-array.index-of.js");
require("core-js/modules/es.typed-array.iterator.js");
require("core-js/modules/es.typed-array.join.js");
require("core-js/modules/es.typed-array.last-index-of.js");
require("core-js/modules/es.typed-array.map.js");
require("core-js/modules/es.typed-array.reduce.js");
require("core-js/modules/es.typed-array.reduce-right.js");
require("core-js/modules/es.typed-array.reverse.js");
require("core-js/modules/es.typed-array.set.js");
require("core-js/modules/es.typed-array.slice.js");
require("core-js/modules/es.typed-array.some.js");
require("core-js/modules/es.typed-array.sort.js");
require("core-js/modules/es.typed-array.subarray.js");
require("core-js/modules/es.typed-array.to-locale-string.js");
require("core-js/modules/es.typed-array.to-reversed.js");
require("core-js/modules/es.typed-array.to-sorted.js");
require("core-js/modules/es.typed-array.to-string.js");
require("core-js/modules/es.typed-array.with.js");
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
var OSS = require('ali-oss');
var path = require('path');
/**
 * 上传文件接口
 * @param {*} fileInfo 上传文件info
 * @param {*} eventName 上传类型
 * @returns 返回 Promise
 */
function aliUpload(fileInfo, eventName) {
  var _this = this;
  console.log(fileInfo);
  var client, fileName, fileloadingNum;
  // 获取client信息
  return (0, _request.default)({
    url: '/zyg/bigFileUpload',
    method: 'get'
  }).then(function (res) {
    if (res.code === 200) {
      // 设置文件名
      fileName = 'assoc' + (0, _auth.getAssocId)() + '/' + eventName + '/' + _this.parseTime(new Date(), '{y}{m}{d}') + '/' + new Date().getTime() + '/' + fileInfo.file.name;
      // 实例化OSS
      client = new OSS({
        accessKeyId: res.data.accessKeyId,
        accessKeySecret: res.data.accessKeySecret,
        stsToken: res.data.securityToken,
        region: res.data.region,
        bucket: res.data.policy
      });
      // 上传接口 返回promise
      if (fileInfo.file.size / 1024 / 1024 >= 1) {
        // 分片上传
        return client.multipartUpload(fileName, fileInfo.file, {
          progress: function progress(percentage) {
            fileloadingNum = Math.ceil(percentage * 100) + '%';
            console.log(fileloadingNum); // 上传文件进度
          },
          checkpoint: fileloadingNum,
          meta: {
            xid: 135,
            filename: encodeURI(fileInfo.file.name)
          },
          mime: 'text/plain'
        });
      } else {
        // 普通上传
        return client.put(fileName, fileInfo.file, {
          // progress: function (percentage) {
          //   fileloadingNum = Math.ceil(percentage * 100) + '%'
          //   console.log(fileloadingNum) // 上传文件进度
          // },
          // checkpoint: fileloadingNum,
          meta: {
            filename: encodeURI(fileInfo.file.name)
          },
          mime: 'text/plain'
        });
      }
    }
  }).catch(function (err) {
    console.log(err);
    _this.$message.error('附件上传失败，请稍后重试');
  });
}

/**
 * 上传文件接口
 * @param {*} base64Data base64格式文件
 * @param {*} eventName 上传类型
 * @returns 返回 Promise
 */
function aliUploadBase64(base64Data, eventName, isBlob) {
  var _this2 = this;
  var base64url;
  if (!isBlob) {
    base64url = base64ToBlob(base64Data);
  } else {
    base64url = base64Data;
  }
  var client, fileName, fileloadingNum;
  // 获取client信息
  return (0, _request.default)({
    url: '/zyg/bigFileUpload',
    method: 'get'
  }).then(function (res) {
    if (res.code === 200) {
      // 设置文件名
      fileName = 'assoc' + (0, _auth.getAssocId)() + '/' + eventName + '/' + _this2.parseTime(new Date(), '{y}{m}{d}') + '/' + new Date().getTime() + '.' + base64url.type.split('/')[1];
      console.log(fileName);
      // 实例化OSS
      client = new OSS({
        accessKeyId: res.data.accessKeyId,
        accessKeySecret: res.data.accessKeySecret,
        stsToken: res.data.securityToken,
        region: res.data.region,
        bucket: res.data.policy
      });
      return new Promise(function (resolve) {
        var reader = new FileReader();
        console.log(base64url);
        reader.readAsArrayBuffer(base64url);
        reader.onload = function (event) {
          var buffer = new OSS.Buffer(event.target.result);
          // 上传接口 返回promise
          client.multipartUpload(fileName, buffer, {
            progress: function progress(percentage) {
              fileloadingNum = Math.ceil(percentage * 100) + '%';
              console.log(fileloadingNum); // 上传文件进度
            },
            checkpoint: fileloadingNum,
            meta: {
              xid: 135,
              filename: encodeURI(path.basename(fileName))
            },
            mime: 'text/plain'
          }).then(function (res) {
            console.log(res);
            resolve(res);
          });
        };
      });
    }
  }).catch(function (err) {
    console.log(err);
    _this2.$message.error('附件上传失败，请稍后重试');
  });
}

// base64转blob
function base64ToBlob(base64Data) {
  var arr = base64Data.split(","),
    fileType = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    l = bstr.length,
    u8Arr = new Uint8Array(l);
  while (l--) {
    u8Arr[l] = bstr.charCodeAt(l);
  }
  return new Blob([u8Arr], {
    type: fileType
  });
}
function aliUploadFile(file, eventName) {
  var _this3 = this;
  var client, fileName, fileloadingNum;
  // 获取client信息
  return (0, _request.default)({
    url: '/zyg/bigFileUpload',
    method: 'get'
  }).then(function (res) {
    if (res.code === 200) {
      // 设置文件名
      fileName = 'assoc' + (0, _auth.getAssocId)() + '/' + eventName + '/' + _this3.parseTime(new Date(), '{y}{m}{d}') + '/' + new Date().getTime() + '/' + file.name;
      // 实例化OSS
      client = new OSS({
        accessKeyId: res.data.accessKeyId,
        accessKeySecret: res.data.accessKeySecret,
        stsToken: res.data.securityToken,
        region: res.data.region,
        bucket: res.data.policy
      });
      // 上传接口 返回promise
      return client.multipartUpload(fileName, file, {
        progress: function progress(percentage) {
          fileloadingNum = Math.ceil(percentage * 100) + '%';
          console.log(fileloadingNum); // 上传文件进度
        },
        checkpoint: fileloadingNum,
        meta: {
          xid: 135,
          filename: encodeURI(file.name)
        },
        mime: 'text/plain'
      });
    }
  }).catch(function (err) {
    console.log(err);
    _this3.$message.error('附件上传失败，请稍后重试');
  });
}