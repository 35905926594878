"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.find.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.string.starts-with.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Certificate',
  data: function data() {
    return {
      index: 0,
      popularcourses: [],
      popularcoursestwe: '',
      assocId: (0, _auth.getAssocId)(),
      classified: '',
      type: 2,
      certPhoto: '',
      certTitle: '',
      createTime: '',
      zhenglist: false,
      wuzheng: false,
      yulan: false,
      certId: '',
      certList: [],
      allCertList: [],
      //用于查询个人的所有整数
      currentPage1: '',
      page: 1,
      totalElements: 0,
      size: 8,
      centerDialogVisible: false,
      imgUrl: '',
      isshow: true,
      ctList: '',
      cartItem: null,
      certListLoading: false // 证书列表loading
    };
  },
  created: function created() {
    this.getbaseinfo();
    //修复携带证书id遍历列表，但列表只有8条，找不到证书的问题
    //若携带考试id，则来自考试查看证书，请求个人所有证书，便利查看
    if (this.$route.query.certId) {
      this.getAllCerttificateList();
    }
  },
  mounted: function mounted() {},
  methods: {
    toCertInfo: function toCertInfo() {
      var _this = this;
      this.cartItem = this.allCertList.find(function (item) {
        return item.id === _this.$route.query.certId;
      });
      console.log(this.cartItem, '根据id获得的证书项');
      if (this.cartItem) {
        this.isshow = false;
      } else {
        this.$message.error('未查询到该证书');
      }
    },
    btnback: function btnback() {
      this.$router.push("/personalCenter/certificate");
      this.isshow = true;
    },
    downloadImg: function downloadImg(url) {
      console.log(url);
      window.open(url);
    },
    dainjifun: function dainjifun(item) {
      this.cartItem = item;
      this.$router.push("/personalCenter/certificate?certId=".concat(item.id));
      // 预览方法、并带上id、
      this.isshow = false;
      // this.id = item.id;
      // this.getbasyulan();
      // this.$router.push({
      // path: '/certificatery',
      // query: {
      // id: item.id
      //   }
      // })
    },
    pagingSizeChange: function pagingSizeChange(val) {
      // 分页方法
      this.page = val;
      this.getbaseinfo(); // 调用接口的方法
    },
    handleSizeChange: function handleSizeChange(val) {
      console.log("\u6BCF\u9875 ".concat(val, " \u6761"));
    },
    handleCurrentChange: function handleCurrentChange(val) {
      console.log("\u5F53\u524D\u9875: ".concat(val));
    },
    getbaseinfo: function getbaseinfo() {
      var _this2 = this;
      this.certListLoading = true;
      var that = this;
      // 1、调证书列表接口、获取个人证书结果信息、
      // 2.有则循环展示、
      (0, _request.default)({
        url: '/zyg/web/cert/training/learner/myCertLists',
        method: 'get'
        // params: {
        //   page: that.page,
        //   size: that.size,
        // },
      }).then(function (res) {
        if (res.code === 200) {
          if (Array.isArray(res.data) && res.data.length > 0) {
            res.data.forEach(function (item) {
              if (item.imgPath.startsWith('https://res.hangxintong.cn')) {
                item.myImgPath = item.imgPath.replace('https://res.hangxintong.cn', 'https://oss.hangxintong.cn');
              } else if (item.imgPath.startsWith('http://res.dev.hangxintong.cn')) {
                item.myImgPath = item.imgPath.replace('http://res.dev.hangxintong.cn', 'https://oss.dev.hangxintong.cn');
              }
            });
          }
          _this2.certList = res.data || [];
          _this2.totalElements = res.data.totalElements;
        }
      }).finally(function () {
        _this2.certListLoading = false;
      });
    },
    //获取个人所有证书列表，当从考试来后，使用id遍历所有个人证书查内容
    getAllCerttificateList: function getAllCerttificateList() {
      var _this3 = this;
      (0, _request.default)({
        url: '/zyg/web/cert/training/learner/myCertLists',
        method: 'get'
        // params: {
        //   page: 1,
        //   size: 999,
        // },
      }).then(function (res) {
        if (res.code === 200) {
          if (Array.isArray(res.data) && res.data.length > 0) {
            res.data.forEach(function (item) {
              if (item.imgPath.startsWith('https://res.hangxintong.cn')) {
                item.myImgPath = item.imgPath.replace('https://res.hangxintong.cn', 'https://oss.hangxintong.cn');
              } else if (item.imgPath.startsWith('http://res.dev.hangxintong.cn')) {
                item.myImgPath = item.imgPath.replace('http://res.dev.hangxintong.cn', 'https://oss.dev.hangxintong.cn');
              }
            });
          }
          _this3.allCertList = res.data || [];
          _this3.toCertInfo();
        }
      }).finally(function () {
        _this3.certListLoading = false;
      });
    },
    getbasyulan: function getbasyulan() {
      var that = this;
      // 1、调预览证书接口、获取证书结果信息、
      // 2.展示
      (0, _request.default)({
        url: 'zyg/lmTrainExamCertCertificateLog/getOne',
        method: 'post',
        params: {
          id: this.id
        }
      }).then(function (res) {
        that.ctList = res.data;
      });
    },
    printpage: function printpage(obj) {
      // 打印方法、@click="printpage(i)"放到打印按钮DIV里、:id="'print'+i"放到需要打印的的DIV里
      var head_str = '<html><head><title></title></head><body>'; // 先生成头部
      var foot_str = '</body></html>'; // 生成尾部
      var older = document.body.innerHTML;
      var new_str = document.getElementById('print' + obj).innerHTML; // 获取指定打印区域
      console.log(obj);
      // eslint-disable-next-line no-unused-vars
      var old_str = document.body.innerHTML; // 获得原本页面的代码
      document.body.innerHTML = head_str + new_str + foot_str; // 构建新网页
      window.print(); // 打印刚才新建的网页
      document.body.innerHTML = older; // 将网页还原
      return false;
    }
  }
};