var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "teachtwo" },
    [
      _vm._l(_vm.list, function (item, index) {
        return _c(
          "el-collapse",
          {
            key: index,
            staticClass: "otitle",
            model: {
              value: _vm.activeNames,
              callback: function ($$v) {
                _vm.activeNames = $$v
              },
              expression: "activeNames",
            },
          },
          [
            _c(
              "el-collapse-item",
              { attrs: { title: item.courseName, name: "1" } },
              [
                _c("div"),
                _vm._l(item.chapters, function (items, index1) {
                  return _c(
                    "div",
                    { key: index1 },
                    [
                      _c(
                        "h2",
                        {
                          staticStyle: {
                            "font-size": "18px",
                            "margin-left": "18px",
                          },
                        },
                        [_vm._v(" " + _vm._s(items.chapterTitle) + " ")]
                      ),
                      _vm._l(items.section, function (item1, index2) {
                        return _c("div", { key: index2 }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "95%",
                                margin: "0 auto",
                                display: "flex",
                                "justify-content": "space-between",
                                "align-items": "center",
                                background: "rgba(244, 244, 244, 1)",
                                "margin-top": "20px",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.playVideo(item1, item)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "24px" } },
                                [
                                  _c("p", [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "8px" },
                                      },
                                      [
                                        _c("i", { staticClass: "iconfont" }, [
                                          _vm._v(""),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(
                                      " " + _vm._s(item1.sectionTitle) + " "
                                    ),
                                    _vm.order && item1.userStudyLog.status == 2
                                      ? _c("span", [
                                          _vm._v(
                                            "(" +
                                              _vm._s(item1.classHours) +
                                              "学时)"
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.order && item1.userStudyLog.status == 1
                                      ? _c("span", [
                                          _vm._v(
                                            "(" +
                                              _vm._s(item1.completeRatio) +
                                              ")"
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-right": "40px" } },
                                [
                                  _vm.order && item1.userStudyLog.status == 1
                                    ? _c(
                                        "p",
                                        { staticStyle: { color: "#f62727" } },
                                        [_vm._v(" 继续学习 ")]
                                      )
                                    : _vm._e(),
                                  _vm.order && item1.userStudyLog.status == 2
                                    ? _c(
                                        "p",
                                        { staticStyle: { color: "#279cf6" } },
                                        [_vm._v(" 已学完 ")]
                                      )
                                    : _vm._e(),
                                  item1.isTryLearn == 0 && _vm.order.status != 2
                                    ? _c(
                                        "p",
                                        { staticStyle: { color: "#f62727" } },
                                        [_vm._v(" 付费观看 ")]
                                      )
                                    : _vm._e(),
                                  item1.isTryLearn == 1 && _vm.order.status != 2
                                    ? _c(
                                        "p",
                                        { staticStyle: { color: "#279cf6" } },
                                        [_vm._v(" 免费播放 ")]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ])
                      }),
                    ],
                    2
                  )
                }),
              ],
              2
            ),
          ],
          1
        )
      }),
      _vm.dialogVideoVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "mChangeHeader",
              attrs: {
                visible: _vm.dialogVideoVisible,
                "append-to-body": "",
                "close-on-click-modal": false,
                title: "课件播放",
                width: "75rem",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVideoVisible = $event
                },
                close: _vm.closeVideoWin,
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    left: "20px",
                    top: "17px",
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "1.5rem", height: "1.5rem" },
                    attrs: {
                      src: require("@/assets/img/assoc_dialog.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c(
                "div",
                { staticStyle: { color: "white", "max-height": "36.25rem" } },
                [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c(
                      "div",
                      { staticStyle: { flex: "1", background: "#232323" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "mVidowWrap",
                            staticStyle: { height: "32.3rem" },
                          },
                          [
                            _vm.updateVideoWrap
                              ? _c("div", [
                                  _c("div", {
                                    ref: "prismPlayer",
                                    attrs: { id: "player-con" },
                                  }),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "flex-basis": "17.5rem",
                          padding: "2.12rem 1rem",
                          background: "#131313",
                          "max-height": "36.25rem",
                          overflow: "auto",
                        },
                        attrs: { id: "style-3" },
                      },
                      _vm._l(
                        _vm.coursePlayList.chapters,
                        function (itemC, indexC) {
                          return _c("div", { key: indexC }, [
                            _c(
                              "div",
                              { staticStyle: { "padding-bottom": "1rem" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.computedIndex(indexC + 1)) +
                                    _vm._s(itemC.chapterTitle) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticStyle: { "padding-bottom": "0.6rem" } },
                              _vm._l(itemC.section, function (ele, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass: "singleVideo",
                                    class: { playActive: ele.isPlay },
                                    staticStyle: { "margin-bottom": "0.6rem" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.mChangePlay(ele)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "iconfont",
                                        staticStyle: {
                                          "margin-right": "0.5rem",
                                          color: "#9cd4ff",
                                        },
                                      },
                                      [_vm._v("  ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mFlex",
                                        staticStyle: {
                                          color: "rgba(255, 255, 255, 0.6)",
                                          width: "87%",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "mEllipsis",
                                            staticStyle: {
                                              width: "60%",
                                              overflow: "hidden",
                                              "text-overflow": "ellipsis",
                                              "white-space": "nowrap",
                                            },
                                            attrs: { title: ele.sectionTitle },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(ele.sectionTitle) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                (ele.duration / 60).toFixed(1)
                                              ) +
                                              "分钟)"
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ])
                        }
                      ),
                      0
                    ),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "dialog_box",
          attrs: {
            title: "人脸比对窗口",
            visible: _vm.visible,
            showClose: false,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            width: "680px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
        },
        [
          _c(
            "p",
            {
              staticStyle: {
                "font-size": "1.05rem",
                color: "red",
                "line-height": "0.5rem",
              },
            },
            [
              _vm._v(
                " 请调整坐姿确保面部位于现场照片采集中，同时点击点击比对按钮 "
              ),
            ]
          ),
          _c("div", { staticClass: "box" }, [
            _c(
              "p",
              { staticStyle: { "text-align": "center", width: "500px" } },
              [_vm._v("现场照片")]
            ),
            _c("video", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.video_show,
                  expression: "video_show",
                },
              ],
              staticClass: "canvas",
              attrs: {
                id: "videoCamera",
                width: _vm.videoWidth,
                height: _vm.videoHeight,
                autoplay: "",
              },
            }),
            _c("canvas", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.can_show,
                  expression: "can_show",
                },
              ],
              staticClass: "canvas",
              attrs: {
                id: "canvasCamera",
                width: _vm.videoWidth,
                height: _vm.videoHeight,
              },
            }),
          ]),
          _c(
            "div",
            { staticClass: "box", staticStyle: { "padding-left": "30px" } },
            [
              _c("p", { staticStyle: { "text-align": "center" } }, [
                _vm._v("留底照片"),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "center",
                    border: "1px solid #ccc",
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "100px" },
                    attrs: { src: _vm.uinfo.bottomPicture, alt: "" },
                  }),
                ]
              ),
            ]
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { display: "block", margin: "0 auto" },
                  attrs: { icon: "el-icon-camera", type: "warning", plain: "" },
                  on: { click: _vm.drawImage },
                },
                [_vm._v("比对并继续学习")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }