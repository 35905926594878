"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.regexp.constructor.js");
require("core-js/modules/es.regexp.dot-all.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.sticky.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.string.trim.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.json.stringify.js");
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.array.concat.js");
var _examination = require("../../api/examination.js");
var _icon_wxz = _interopRequireDefault(require("@/assets/img/icon_wxz.png"));
var _icon_sel_xz = _interopRequireDefault(require("@/assets/img/icon_sel_xz.png"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formId: '',
      loading: true,
      showDialog: true,
      dialogVisible: false,
      formList: [],
      dialogImageUrl: '',
      icon_wxz: _icon_wxz.default,
      icon_bj_xz: _icon_sel_xz.default,
      imgList: [],
      imgParentId: '',
      examId: '',
      TipsDialogVisible: false,
      correctBtnText: '',
      errorBtnText: '',
      tips: '',
      faceImg: '',
      //头像
      username: '',
      //姓名
      mobile: '',
      //手机号
      businessId: '',
      arrInput: ['imgupload'],
      strInput: ['input', 'select', 'textarea', 'time', 'datePicker', 'personnel', 'member', 'radio', 'personnel', 'member', 'checkbox', 'member']
    };
  },
  methods: {
    //关闭弹窗
    closeDialog: function closeDialog() {
      window.history.back(-1);
    },
    closeCorrectDialog: function closeCorrectDialog() {},
    // 提交成功之后  返回前一页按钮
    closeErrorDialog: function closeErrorDialog() {
      this.closeDialog();
    },
    // 效验数组类型的表单
    checkArrTypeInput: function checkArrTypeInput(item) {
      // 效验是否必填
      if (!item.options.required) return;
      // 效验是否有值
      if (!Array.isArray(item.value) || item.value.length === 0) return "".concat(item.name, "\u4E3A\u5FC5\u586B\u9879");
      // 是否有正则效验
      if (!item.options.pattern) return;
      // 效验正则是否合法
      try {
        var reg = new RegExp(item.options.pattern);
        if (!reg.test(item.value)) return "\u8BF7\u586B\u5199\u7B26\u5408\u6B63\u5219\u6548\u9A8C\u89C4\u5219\u7684".concat(item.name);
      } catch (error) {
        return '不是合法的正则效验规则';
      }
      return;
    },
    // 效验字符串类型的表单
    checkStringTypeInput: function checkStringTypeInput(item) {
      // 效验是否必填
      if (!item.options.required) return;
      // 效验是否有值
      if (!item.value || item.value.trim() === '') return "".concat(item.name, "\u4E3A\u5FC5\u586B\u9879");
      // 是否有正则效验
      if (!item.options.pattern) return;
      // 效验正则是否合法
      try {
        var reg = new RegExp(item.options.pattern);
        if (!reg.test(item.value)) return "\u8BF7\u586B\u5199\u7B26\u5408\u6B63\u5219\u6548\u9A8C\u89C4\u5219\u7684".concat(item.name);
      } catch (error) {
        return '不是合法的正则效验规则';
      }
      return;
    },
    //生成表单数据，报名考试  报名之后  才可以提交自定义表单
    submit: function submit() {
      var that = this;
      var formList = this.formList,
        arrInput = this.arrInput,
        strInput = this.strInput;
      var list = [];
      for (var i = 0; i < formList.length; i++) {
        // 效验是否必填
        var currentItem = formList[i];
        // 效验数组类型的值
        if (arrInput.includes(currentItem.elementType)) {
          var message = this.checkArrTypeInput(currentItem);
          if (message) return this.$message.warning(message);
        }
        // 效验字符串类型的值
        if (strInput.includes(currentItem.elementType)) {
          var _message = this.checkStringTypeInput(currentItem);
          if (_message) return this.$message.warning(_message);
        }
        list.push({
          content: currentItem.value,
          elementId: currentItem.elementId,
          elementType: currentItem.type,
          formId: that.formId,
          xid: (0, _auth.getAssocId)()
        });
      }
      var dataBody = {
        id: this.examId,
        cate: 1
      };
      that.loading = true;
      (0, _examination.signUp)(dataBody).then(function (response) {
        if (response.code == 200) {
          //数据获取成功
          // console.log(JSON.stringify(response))
          // that.businessId = response.data.id
          // localStorage.setItem('businessId', response.data.id)
          var businessId = response.data.id;
          list.forEach(function (item) {
            item.businessId = businessId;
          });
          // list.map(function(item) {
          //   item.businessId = that.businessId
          //   return item
          // })
          that.submitData(list);
        } else {
          that.loading = false;
          that.showMsg(2, response.message);
        }
      }).catch(function () {
        that.loading = false;
      });

      // this.formList.map(function(item) {
      //   if (item.type == 'imgupload') {
      //     list.push({
      //         content: item.value,
      //         elementId: item.elementId,
      //         elementType: item.type,
      //         formId: that.formId,
      //         xid: getAssocId()
      //     })
      //   } else {
      //     list.push({
      //       content: item.value,
      //       elementId: item.elementId,
      //       elementType: item.type,
      //       formId: that.formId,
      //       xid: getAssocId()
      //     })
      //   }
      //   return item
      // })
      // console.log(list,'list');
      // return
      // if (that.businessId) {
      //   list.map(function(item) {
      //     item.businessId = that.businessId
      //     return item
      //   })

      //   // that.submitData(list)
      // } else {
      //   var dataBody = {
      //     id: this.examId,
      //     cate: 1
      //   }
      //   return
      //   //报名  生成businessId
      //   signUp(dataBody).then((response) => {
      //     if (response.code == 200) {
      //       //数据获取成功
      //       console.log(JSON.stringify(response))
      //       that.businessId = response.data.id
      //       localStorage.setItem('businessId', response.data.id)
      //       list.map(function(item) {
      //         item.businessId = that.businessId
      //         return item
      //       })
      //       that.submitData(list)
      //     } else {
      //       that.loading = false
      //       that.showMsg(2, response.message)
      //     }
      //   }).catch(() => {
      //     that.loading = false
      //   })
      // }
    },
    //提交表单数据
    submitData: function submitData(list) {
      var that = this;
      console.log(list);
      (0, _examination.insertForm)(list).then(function (response) {
        // localStorage.removeItem('businessId')
        if (response.code == 200) {
          //数据获取成功
          that.showDialog = false;
          that.errorDialogVisible = true;
          that.errorBtnText = '返回';
          that.tips = '报名成功，请在列表中关注报名审核状态';
          that.loading = false;
        } else {
          that.loading = false;
          that.showMsg(2, response.message);
        }
      }).catch(function () {
        that.loading = false;
      });
    },
    //获取个人基本信息
    getUserDetails: function getUserDetails() {
      var that = this;
      (0, _examination.getUserInfo)({}).then(function (response) {
        console.log(JSON.stringify(response));
        if (response.code == 200) {
          //数据获取成功
          that.faceImg = response.data.user.faceImg;
          that.username = response.data.user.username;
          that.mobile = response.data.user.mobile;
        } else {
          that.showMsg(2, response.message);
        }
      });
    },
    imgListChange: function imgListChange(id) {
      this.imgParentId = id;
    },
    //单选多选选择点击事件
    choiceClick: function choiceClick(id, value, from) {
      this.formList.map(function (item) {
        if (item.elementId == id) {
          if (from == 1) {
            //单选
            item.options.options.map(function (children) {
              children.sel = 0;
              return children;
            });
            item.options.options.map(function (children) {
              if (children.value == value) {
                children.sel = 1;
                item.value = children.value;
              }
              return children;
            });
          } else if (from == 2) {
            //多选

            item.options.options.map(function (children) {
              if (children.value == value) {
                if (children.sel == 1) {
                  children.sel = 0;
                } else {
                  children.sel = 1;
                }
              }
              return children;
            });
            var vals = [];
            item.options.options.map(function (children) {
              if (children.sel == 1) {
                vals.push(children.value);
              }
              return children;
            });
            item.value = vals.join(',');
          }
        }
        return item;
      });
    },
    handleRemove: function handleRemove(file, item) {
      item.value = item.value.filter(function (item) {
        return !item.includes(file.name);
      });
    },
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadSuccess: function uploadSuccess(file, item) {
      this.aliUpload(file, 'trainExam').then(function (res) {
        if (res.res.status === 200) {
          console.log(file, item, 'file,item');
          console.log(res, 'res');
          var attachUrl = "".concat(process.env.VUE_APP_UPLOAD_ALI_HOST, "/").concat(res.name);
          item.value.push(attachUrl);
        }
      });
    },
    //请求自定义表单数据
    initData: function initData() {
      var that = this;
      var dataBody = {
        formId: this.formId,
        xid: (0, _auth.getAssocId)()
      };
      (0, _examination.getFormInfo)(dataBody).then(function (response) {
        if (response.code == 200) {
          //数据获取成功
          var list = response.data.list;
          that.formList = list.map(function (item) {
            if (['radio', 'checkbox', 'member'].includes(item.type)) {
              item.options.options.map(function (children) {
                children.sel = 0;
                return children;
              });
            }
            item.value = '';
            if (item.type == 'textarea') {
              // 判断是否有默认值
              if (item.options.defaultValue) {
                item.value = item.options.defaultValue;
              }
            }
            if (item.type == 'imgupload') {
              item.value = [];
            }
            return item;
          });
          that.loading = false;
        } else {
          that.showMsg(2, response.message);
        }
      });
    },
    //提示信息
    showMsg: function showMsg(type, msg) {
      if (type == 1) {
        this.$notify({
          title: '提示',
          message: msg,
          type: 'success'
        });
      } else {
        this.$notify({
          title: '提示',
          message: msg,
          type: 'error'
        });
      }
    }
  },
  created: function created() {
    var data = this.$route.params.id;
    this.formId = data.split(',')[0];
    this.examId = data.split(',')[1];
    this.businessId = localStorage.getItem('businessId');
    this.initData();
    this.getUserDetails();
  },
  watch: {}
};