"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _personal = require("@/api/personal");
var _examLIst = _interopRequireDefault(require("./examlist/examLIst"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Allexam',
  components: {
    ExamList: _examLIst.default
  },
  data: function data() {
    return {
      listQuery: {
        cate: 1,
        page: 1,
        search: '',
        size: 5
      },
      // 全部考试列表
      contentcontent: [],
      total: 0
    };
  },
  created: function created() {
    this.getListall();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      (0, _personal.examList)(this.listQuery).then(function (response) {
        if (response.code == 200) {
          _this.contentcontent = response.data.content;
          _this.total = response.data.totalElements;
          // console.log(response);
        }
      });
    },
    changeSize: function changeSize(val) {
      this.$router.push({
        path: '/allexam/' + val
      });
      // this.listQuery.page = val;
      // this.getList();
    },
    getListall: function getListall() {
      var page = parseInt(this.$route.params.page);
      // console.log(typeof page)
      if (page) {
        this.listQuery.page = page;
        this.getList();
      } else {
        this.getList();
      }
    }
  }
};