var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "teach-header" },
    [
      _c(
        "div",
        { staticClass: "teach-top container", attrs: { data: _vm.list } },
        [
          _c("div", { staticClass: "oimg" }, [
            _c("p", [
              _c("img", { attrs: { src: _vm.list.itemCover, alt: "" } }),
            ]),
            _c(
              "div",
              {
                ref: "testRef",
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                  "margin-top": "14px",
                  position: "relative",
                },
              },
              [
                _c("div", {
                  staticClass: "opList",
                  domProps: { innerHTML: _vm._s(_vm.itemIntr1) },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isItemIntr1Show,
                        expression: "isItemIntr1Show",
                      },
                    ],
                    ref: "btnRef",
                    staticStyle: {
                      cursor: "pointer",
                      "margin-left": "10px",
                      color: "#999999",
                      position: "absolute",
                      bottom: "16px",
                      right: "0",
                      "font-size": "14px",
                      "margin-top": "-10px",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.infoClick()
                      },
                    },
                  },
                  [
                    _vm._v(" 查看全部 "),
                    _c("span", {
                      staticClass: "el-icon-arrow-right",
                      staticStyle: { "margin-top": "8px" },
                    }),
                  ]
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "otitle" }, [
            _c("div", { staticClass: "text-top" }, [
              _c("h4", [_vm._v(_vm._s(_vm.list.itemName))]),
            ]),
            _c("div", { staticClass: "text-rate" }, [
              _c(
                "p",
                [
                  _c("el-rate", {
                    staticClass: "tach-rate",
                    attrs: { value: 5, disabled: "", "text-color": "#ff9900" },
                  }),
                ],
                1
              ),
            ]),
            _c("div", [
              _vm.list.freeBuy
                ? _c(
                    "p",
                    { staticStyle: { color: "#ff3b3b", "font-size": "20px" } },
                    [_vm._v(" 免费 ")]
                  )
                : _c(
                    "p",
                    { staticStyle: { color: "#ff3b3b", "font-size": "20px" } },
                    [_vm._v(" ￥" + _vm._s(_vm.list.vipPrice) + " ")]
                  ),
            ]),
            _c(
              "div",
              { staticStyle: { "margin-top": "70px", display: "flex" } },
              [
                _vm.effective && _vm.sellOnLine
                  ? _c(
                      "el-button",
                      {
                        staticClass: "obtn",
                        attrs: { icon: "el-icon-plus", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.signUpClick()
                          },
                        },
                      },
                      [_vm._v(" 立即报名 ")]
                    )
                  : _vm._e(),
                !_vm.effective && _vm.sellOnLine
                  ? _c(
                      "el-button",
                      {
                        staticClass: "obtn",
                        attrs: { type: "primary", disabled: "" },
                      },
                      [_vm._v(" ！课程已到期 ")]
                    )
                  : _vm._e(),
                _vm.isCollect == false
                  ? _c(
                      "el-button",
                      {
                        staticClass: "obtn1",
                        attrs: { plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.shoucClick(_vm.list)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "15px", height: "14px" },
                          attrs: { src: require("../../assets/img/img2.png") },
                        }),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-left": "5px",
                              "font-size": "16px",
                            },
                          },
                          [_vm._v("收藏")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.isCollect == true
                  ? _c(
                      "el-button",
                      {
                        staticClass: "obtn1",
                        attrs: { plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.qxClick(_vm.list)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "15px", height: "14px" },
                          attrs: { src: require("../../assets/img/img1.png") },
                        }),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-left": "5px",
                              "font-size": "16px",
                            },
                          },
                          [_vm._v("已收藏")]
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "otitle1", staticStyle: { "margin-top": "18px" } },
            [
              _c(
                "el-tabs",
                {
                  staticClass: "obClass",
                  model: {
                    value: _vm.activeName1,
                    callback: function ($$v) {
                      _vm.activeName1 = $$v
                    },
                    expression: "activeName1",
                  },
                },
                [
                  _c("el-tab-pane", { attrs: { name: "first" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "tooltip",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                        attrs: { slot: "label" },
                        slot: "label",
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "12.5px", height: "20.5px" },
                          attrs: {
                            src: require("../../assets/img/4.png"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("用手机观看")]),
                      ]
                    ),
                    _vm.code !== ""
                      ? _c("div", { staticClass: "tooltiptext1" }, [
                          _c("h2", [_vm._v("扫一扫用手机观看")]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "108px",
                                height: "108px",
                                margin: "0 auto",
                              },
                            },
                            [
                              _c("img", {
                                staticStyle: { width: "100%", height: "100%" },
                                attrs: { src: _vm.code, alt: "" },
                              }),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticStyle: {
                                width: "100%",
                                "text-align": "center",
                                "font-size": "12px",
                                color: "#666666",
                              },
                            },
                            [_vm._v(" APP下载 ")]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("el-tab-pane", { attrs: { name: "second" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "tooltip1",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "margin-top": "1rem",
                        },
                        attrs: { slot: "label" },
                        slot: "label",
                      },
                      [
                        _c("img", {
                          staticStyle: { width: "20px", height: "20px" },
                          attrs: {
                            src: require("../../assets/img/2.png"),
                            alt: "",
                          },
                        }),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              margin: "0",
                              "margin-left": "0.2rem",
                            },
                          },
                          [_vm._v("分享")]
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "tooltiptext1" }, [
                      _c("h2", [_vm._v("扫一扫分享给好友")]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "108px",
                            height: "108px",
                            margin: "0 auto",
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: { src: _vm.oImg, alt: "" },
                          }),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            width: "100%",
                            "text-align": "center",
                            "font-size": "12px",
                            color: "#666666",
                          },
                        },
                        [_vm._v(" 分享给微信好友 ")]
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "teach-bottom container" }, [
        _c(
          "div",
          { staticClass: "bottom-left" },
          [
            _c(
              "el-tabs",
              {
                staticClass: "left-table",
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "课程目录", name: "first" } },
                  [
                    _vm.tokenList == undefined
                      ? _c("teachall", {
                          attrs: { trainIsSup: _vm.trainIsSup },
                        })
                      : _vm._e(),
                    _vm.tokenList
                      ? _c("teachall1", {
                          attrs: {
                            trainIsSup: _vm.trainIsSup,
                            "must-complete-data": _vm.mustCompleteData,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "用户评论", name: "second" } },
                  [
                    _vm.tokenList == undefined ? _c("teachfive") : _vm._e(),
                    _vm.tokenList != undefined ? _c("teachfive1") : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "bottom-right_div" }, [
          _c(
            "div",
            { staticClass: "bottom-right", attrs: { data: _vm.companyList } },
            [
              _c("div", { staticClass: "top-bottom" }, [
                _c("div", { staticClass: "top-img" }, [
                  _c("div", [
                    _c("img", {
                      staticStyle: { width: "100%", "margin-top": "8px" },
                      attrs: { src: this.$store.getters.assocLogo, alt: "" },
                    }),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        color: "rgba(80, 93, 104, 1)",
                        padding: "0 16px",
                      },
                    },
                    [
                      _c("p", [_vm._v(_vm._s(_vm.companyList.assocName))]),
                      _c("p", [_vm._v(_vm._s(_vm.companyList.webpinyin))]),
                    ]
                  ),
                ]),
              ]),
              _vm.contactNumberConfig.educationalServices_mobileConfig
                ? _c("div", { staticStyle: { "margin-top": "48px" } }, [
                    _vm._m(0),
                    _c("div", {
                      staticClass: "rich-text",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.contactNumberConfig
                            .educationalServices_mobileConfig
                        ),
                      },
                    }),
                  ])
                : _vm._e(),
              _vm.xid !== "255"
                ? _c("div", { staticStyle: { "margin-top": "48px" } }, [
                    _c("div", { staticClass: "right-top" }, [
                      _c("h2", [_vm._v("技术服务")]),
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/serviceQrcode.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticStyle: { "margin-left": "30px" } }, [
                        _vm._v("请使用微信扫一扫"),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.companyList.assocId == 162
                ? _c("div", { staticStyle: { "margin-top": "24px" } }, [
                    _vm._m(1),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "16px",
                          "margin-bottom": "16px",
                          "font-size": "12px",
                          color: "rgba(80, 93, 104, 1)",
                          "text-align": "justify",
                          "text-justify": "newspaper",
                          "word-break": "break-all",
                        },
                      },
                      [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary", underline: false },
                            on: { click: _vm.openLearning },
                          },
                          [_vm._v("http://39.104.57.86/userloginUI.action ")]
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm.trainIsSup == 1
            ? _c(
                "div",
                {
                  staticClass: "bottom-right",
                  staticStyle: { "margin-top": "1rem" },
                },
                [_vm._m(2)]
              )
            : _c(
                "div",
                {
                  staticClass: "bottom-right",
                  staticStyle: { "margin-top": "1rem" },
                },
                [_vm._m(3)]
              ),
        ]),
      ]),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "branch-box",
              attrs: {
                title: "项目介绍",
                visible: _vm.dialogFormVisible,
                width: "35%",
                "close-on-click-modal": false,
                "close-on-press-escape": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [_c("div", { domProps: { innerHTML: _vm._s(_vm.itemIntr) } })]
          )
        : _vm._e(),
      _vm.atteDialogVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "attestation",
              attrs: {
                visible: _vm.atteDialogVisible,
                width: "600px",
                height: "227px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.atteDialogVisible = $event
                },
              },
            },
            [
              _c("span", { staticClass: "att_span1" }, [
                _c("i", {
                  staticClass: "el-icon-warning",
                  staticStyle: { "font-size": "26px" },
                }),
                _vm._v(" 您的实名认证还未完善 "),
              ]),
              _c("span", { staticClass: "att_span2" }, [
                _vm._v("您需要完成实名认证后可以报名学习，请实名认证"),
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "att_btn1",
                      on: { click: _vm.toAttestation },
                    },
                    [_vm._v("去认证")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "att_btn2",
                      on: {
                        click: function ($event) {
                          _vm.atteDialogVisible = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.tuikDisable
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "购买须知",
                visible: _vm.tuikDisable,
                width: "33%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.tuikDisable = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { height: "400px", "overflow-y": "auto" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        height: "129px",
                        margin: "0 auto 10px",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: {
                          src: require("../../assets/img/73207.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        width: "100%",
                        margin: "0 auto",
                      },
                    },
                    [
                      _c("p", [
                        _vm._v(
                          "1、请您在购买时认真核实所购买课程是否是您选择的课程。"
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 2、交费成功后，详细的退款政策需咨询学校/协会，按照相关政策执行。若学校/协会同意且可在线发起退款，款项会原路退回到您的支付账户。 "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 3、PC端用户可在“登陆个人中心一我的订单一已付款”内找到相应的课程点击申请退款。 "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          " 4、移动端用户可在“登陆APP一我的一我的订单”内找到相应课程点击申请退款。 "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticStyle: { width: "100%", "text-align": "center" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "400px", "font-size": "14px" },
                      attrs: {
                        type: _vm.timeout == 0 ? "warning" : "info",
                        disabled: _vm.timeout != 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.peyMoneyClick()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.timeout == 0
                              ? "已阅读并同意协议"
                              : _vm.timeout + "秒已阅读并同意协议"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.jnow34vcp2yre
        ? _c(
            "el-dialog",
            {
              attrs: { visible: _vm.jnow34vcp2yre, width: "25%" },
              on: {
                "update:visible": function ($event) {
                  _vm.jnow34vcp2yre = $event
                },
              },
            },
            [
              _c("div", [
                _c("div", [
                  _c("p", { staticClass: "olpoe" }, [
                    _c("span", { staticClass: "el-icon-warning" }),
                    _vm._v("您购买的课程正在审核中 "),
                  ]),
                  _c("p", { staticClass: "wev0j2" }, [
                    _vm._v("可在我的订单待付款中查看审核状态或付款"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "onioin673sc" }, [
                _c("p"),
                _c(
                  "p",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            _vm.jnow34vcp2yre = false
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push({
                              path: "/personalCenter/registration",
                            })
                          },
                        },
                      },
                      [_vm._v("我的审核 ")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.showCart
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "批量采购",
                visible: _vm.showCart,
                width: "520px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showCart = $event
                },
                close: _vm.closeAddCart,
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c("span", { staticClass: "cart-title" }, [
                    _vm._v("购买数量："),
                  ]),
                  _c("el-input-number", {
                    staticClass: "goodsNum-input",
                    attrs: { "step-strictly": true, min: 1 },
                    model: {
                      value: _vm.goodsNum,
                      callback: function ($$v) {
                        _vm.goodsNum = $$v
                      },
                      expression: "goodsNum",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.addShoppingCart },
                    },
                    [_vm._v("加入采购单")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right-top" }, [
      _c("h2", [_vm._v("教务服务")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "right-top" }, [
      _c("h2", [_vm._v("公共课学习入口")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-top": "1rem" } }, [
      _c("div", { staticClass: "right-top" }, [_c("h2", [_vm._v("监学说明")])]),
      _c(
        "div",
        {
          staticStyle: {
            "margin-top": "24px",
            "font-size": "12px",
            color: "rgba(80, 93, 104, 1)",
          },
        },
        [
          _c("p", [
            _vm._v(
              " 1.该课程已开启监学模式，在进行学习前，需要进行人脸识别，确保学员本人进行学习。（人脸核验未通过，弹屏提示如果点击“继续学习”也将视为无效学时没有审验卡，由于以上原因造成的学时无法确认，自行承担相关责任 "
            ),
          ]),
          _c("p", [
            _vm._v(
              " 2.学习过程中不定时抓拍学员学习照片确保学员本人正常学习（如若人脸不识别不成功或抓拍时学员不在画面里。都视为无效学时没有审验卡，由于以上原因造成的学时无法确认，自行承担相关责任 "
            ),
          ]),
          _c("p", [
            _vm._v(
              " 3.学习过程中如发生快进，切换页面都视为无效课时没有审验卡，由于以上原因造成的学时无法确认，自行承担相关责任 "
            ),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-top": "1rem" } }, [
      _c("div", { staticClass: "right-top" }, [_c("h2", [_vm._v("课程说明")])]),
      _c(
        "div",
        {
          staticStyle: {
            "margin-top": "24px",
            "font-size": "12px",
            color: "rgba(80, 93, 104, 1)",
          },
        },
        [
          _c("p", [
            _vm._v(
              " 1.课程首次学习时请勿快进，否则会导致课时无效且学习课时无效没有审验卡，由于以上原因造成的学时无法确认，自行承担相关责任！ "
            ),
          ]),
          _c("p", [
            _vm._v(
              " 2.课程首次学习时请勿开启倍速学习，会导致课程学时无效没有审验卡，由于以上原因造成的学时无法确认，自行承担相关责任！ "
            ),
          ]),
          _c("p", [
            _vm._v(
              " 3.学习时不要切换到其他页面，会导致课程学时无效没有审验卡，由于以上原因造成的学时无法确认，自行承担相关责任！ "
            ),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }