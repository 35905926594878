"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.autoLogin = autoLogin;
var _request = _interopRequireDefault(require("@/utils/request"));
// 质量学员登录
function autoLogin(data) {
  return (0, _request.default)({
    url: '/zyglogin/autoLogin',
    method: 'post',
    data: data
  });
}