var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "announcementbox",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "titleBg d-flex align-items-center justify-content-between",
        },
        [
          _c("div", { staticClass: "titleTextSy" }, [_vm._v("考试详情")]),
          _c(
            "button",
            {
              staticClass: "border backSy",
              on: {
                click: function ($event) {
                  return _vm.backHtml()
                },
              },
            },
            [_vm._v("返回")]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "d-flex flex-column h-100 overflow-auto box",
          staticStyle: { background: "#ffffff", height: "100%" },
        },
        [
          _c(
            "div",
            {
              staticClass: "w-100 flex-column",
              staticStyle: { flex: "1", padding: "2.29rem" },
            },
            [
              _c(
                "div",
                { staticClass: "row", staticStyle: { margin: "0 0 0 -15px" } },
                [
                  _c("div", { staticClass: "col-3 item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "border rounded detalisItem d-flex flex-column align-items-center justify-content-center",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/icon_details_fs.png"),
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "t1" }, [_vm._v("试卷总分")]),
                        _c("div", { staticClass: "t2" }, [
                          _vm._v(_vm._s(_vm.data.examScore) + "分"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "col-3 item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "border rounded detalisItem d-flex flex-column align-items-center justify-content-center",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/icon_details_jg.png"),
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "t1" }, [_vm._v("及格分")]),
                        _c("div", { staticClass: "t2" }, [
                          _vm._v(_vm._s(_vm.data.examPassScore) + "分"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "col-3 item" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "border rounded detalisItem d-flex flex-column align-items-center justify-content-center",
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/img/icon_details_zt.png"),
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "t1" }, [_vm._v("总题数")]),
                        _c("div", { staticClass: "t2" }, [
                          _vm._v(_vm._s(_vm.data.examQuestionNum) + "题"),
                        ]),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "col-3 item",
                      staticStyle: { "padding-right": "0" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "border rounded detalisItem d-flex flex-column align-items-center justify-content-center",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/img/icon_details_sc.png"),
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "t1" }, [_vm._v("总时长")]),
                          _c("div", { staticClass: "t2" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.data.examTime == 0
                                    ? "不限时长"
                                    : _vm.data.examTime + "分钟"
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "border w-100",
                  staticStyle: { "margin-top": "2.29rem" },
                },
                [
                  _vm.data.examStartTime && _vm.data.examEndTime
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "border-bottom details_L d-flex align-items-center justify-content-between",
                        },
                        [
                          _c("div", { staticClass: "t1" }, [
                            _vm._v("考试时间："),
                          ]),
                          _c("div", { staticClass: "t2" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.data.examStartTime.substring(0, 16)
                                ) +
                                " 至 " +
                                _vm._s(_vm.data.examEndTime.substring(0, 16)) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.data.examAddress
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "border-bottom details_L d-flex align-items-center justify-content-between",
                        },
                        [
                          _c("div", { staticClass: "t1" }, [
                            _vm._v("考试地址："),
                          ]),
                          _c("div", { staticClass: "t2" }, [
                            _vm._v(_vm._s(_vm.data.examAddress)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.data.examContent
                    ? _c("div", { staticClass: "details_B html" }, [
                        _c(
                          "div",
                          {
                            staticClass: "t1 d-flex align-items-center",
                            staticStyle: { height: "4.57rem" },
                          },
                          [_vm._v(" 考试须知： ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "t2",
                            staticStyle: { "white-space": "pre-wrap" },
                          },
                          [
                            _c("div", {
                              staticClass: "htmlChildren alashan",
                              staticStyle: {
                                "word-break": "break-all",
                                width: "100%",
                              },
                              domProps: {
                                innerHTML: _vm._s(_vm.data.examContent),
                              },
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }