"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.map.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.json.stringify.js");
var _refundProgress = _interopRequireDefault(require("./refundProgress.vue"));
var _personal = require("@/api/personal");
var _request = _interopRequireDefault(require("@/utils/request.js"));
var _payservice = require("@/api/payservice");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  inject: ['handleSelect'],
  components: {
    refundProgress: _refundProgress.default
  },
  data: function data() {
    return {
      tkPayWay: null,
      // 退款item payWay
      showProgress: false,
      // 退款进度弹框
      refundProgressItem: null,
      // 退款进度弹框Item
      progressActive: 3,
      total: 100,
      list: null,
      listLoading: true,
      disabList: false,
      textarea: '',
      disablist1: false,
      oid: '',
      remark: '',
      listQuery: {
        // cid: getAssocId("mCid"),
        page: 1,
        size: 100,
        status: 2
      },
      refundFlag: 1,
      // 退款flag 1=》退款原因  2=》无法退款
      companyList: {},
      // 协会信息
      datamessage: '无法退款'
    };
  },
  created: function created() {
    this.getList();
    this.getWebconfiginfo();
  },
  methods: {
    getWebconfiginfo: function getWebconfiginfo() {
      var _this = this;
      (0, _request.default)({
        url: 'pc/webconfig/webconfiginfo',
        method: 'get',
        params: {
          xid: +this.xid
        }
      }).then(function (response) {
        _this.companyList = response.data;
      });
    },
    //获取订单信息
    getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      (0, _personal.orderList)(this.listQuery).then(function (response) {
        _this2.list = response.data.content.map(function (item) {
          var payDay = new Date(item.payTime).getTime();
          var countDown = new Date(item.payTime).getTime() + 604800000;
          var NowData = new Date().getTime();
          if (countDown <= NowData) {
            item.maynot = false;
          } else {
            item.maynot = true;
          }
          item.purchaseDays = parseInt((NowData - payDay) / 1000 / 60 / 60 / 24);
          return item;
        });
        _this2.total = response.data.totalElements;
        _this2.listLoading = false;
      });
    },
    details: function details(item, jumpType, flag) {
      if (flag === 8) {
        this.$router.push("/personalcenter/alreadyPay?id=".concat(item.id));
      } else {
        this.handleSelect(11, item, jumpType, '4-2');
      }
    },
    // 兑换码支付退款
    volumeClick: function volumeClick() {
      var _this3 = this;
      this.datamessage = '无法退款';
      (0, _payservice.couponRefund)(this.oid, this.textarea).then(function (res) {
        if (res.code == 200) {
          _this3.showProgress = false;
          _this3.disabList = false;
          _this3.getList();
          _this3.$message({
            message: '已提交申请，请耐心等待平台审核',
            type: 'success'
          });
        } else {
          if (res.message === '距离付款时间已超过0天，无法退款') {
            _this3.datamessage = '当前课程不支持退款';
          } else {
            _this3.datamessage = res.message;
          }
          _this3.refundFlag = 2;
        }
      });
    },
    qxClick: function qxClick(item) {
      var _this4 = this;
      this.tkPayWay = item.payWay;
      (0, _request.default)({
        url: '/isOrderFlag',
        method: 'get',
        params: {
          orderId: item.id
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this4.oid = response.data.id;
          _this4.textarea = '';
          _this4.refundFlag = 1;
          _this4.disabList = true;
        } else {
          _this4.$alert(response.message, '提示', {
            confirmButtonText: '确定',
            type: 'info',
            callback: function callback(action) {}
          });
        }
      });
    },
    reQxClick: function reQxClick() {
      this.qxClick(this.refundProgressItem);
    },
    infoClick: function infoClick(item) {
      this.$router.push({
        path: '/teachinfo/' + item.goodsId
      });
    },
    examClick: function examClick(item) {
      console.log(JSON.stringify(item.goodsId, null, 2));
      this.$router.push("/waitingForTheExam/".concat(item.goodsId));
    },
    // 退款进度
    refundProgress: function refundProgress(item) {
      this.refundProgressItem = item;
      console.log(this.refundProgressItem);
      this.showProgress = true;
    },
    closeDid: function closeDid() {
      this.getList();
      this.showProgress = false;
    },
    //银盛退款
    yinshengOrderRefundCreate: function yinshengOrderRefundCreate(data) {
      var _this5 = this;
      (0, _payservice.ysOrderRefund)(data).then(function (res) {
        if (res.code == 200) {
          _this5.showProgress = false;
          _this5.disabList = false;
          _this5.getList();
          _this5.$message({
            message: '申请退款成功',
            type: 'success'
          });
        } else {
          _this5.datamessage = res.message;
          _this5.refundFlag = 2;
        }
      });
    },
    tkMoneyClick: function tkMoneyClick() {
      var _this6 = this;
      if (!this.textarea) {
        this.$message.warning('请填写退款原因！');
        return false;
      }
      switch (this.tkPayWay) {
        case 6:
          this.volumeClick();
          break;
        case 12:
          this.yinshengOrderRefundCreate({
            id: this.oid,
            remark: this.textarea
          });
          break;
        default:
          (0, _request.default)({
            url: '/order/applyForRefund',
            method: 'post',
            params: {
              oid: this.oid,
              remark: this.textarea
            }
          }).then(function (response) {
            if (response.code == 200) {
              _this6.showProgress = false;
              _this6.disabList = false;
              _this6.getList();
              _this6.$message({
                message: '申请退款成功',
                type: 'success'
              });
            } else {
              _this6.datamessage = response.message;
              _this6.refundFlag = 2;
            }
          });
          break;
      }
    }
  }
};