"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.number.constructor.js");
var _table = require("@/api/table");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    datas: {
      type: Number
    }
  },
  data: function data() {
    return {
      total: 0,
      page: 1,
      size: 10,
      list: null
    };
  },
  created: function created() {
    this.fetchData();
  },
  methods: {
    fetchData: function fetchData() {
      var _this = this;
      this.listLoading = true;
      var dataBody = {
        page: this.page,
        size: this.size
      };
      (0, _table.getInvoiceRecords)(dataBody).then(function (response) {
        if (response.code == 200) {
          _this.list = response.data.info.content;
          _this.total = response.data.info.totalElements;
        } else {}
        _this.listLoading = false;
      });
    },
    details: function details(item) {
      eventBus.$emit('showDetails', item);
    },
    withdraw: function withdraw(item) {
      var _this2 = this;
      //取消申请
      this.$alert('<strong><div>' + '<div style="color: #303030;font-size: 0.9rem;margin-top:0.5rem;">是否取消申请？</div>' + ' </div></strong>', '取消申请', {
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        cancelButtonText: '取消',
        showConfirmButton: true,
        confirmButtonText: '确定'
      }).then(function () {
        var dataBody = {
          //取消收藏参数
          id: item.id
        };
        /*取消申请接口*/
        (0, _table.withdrawInvoice)(dataBody).then(function (response) {
          if (response.code == 200) {
            _this2.$message({
              type: 'success',
              message: '取消申请成功!'
            });
            /*取消成功之后  刷新数据*/
            _this2.page = 1;
            _this2.fetchData();
          } else {
            _this2.$message.error(response.message);
          }
        });
      }).catch(function () {});
    }
  }
};