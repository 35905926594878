"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.map.js");
var _examination = require("../../api/examination.js");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      page: 1,
      dataList: [],
      total: 0,
      size: 6,
      loading: true,
      hasToken: "",
      searchContent: ""
    };
  },
  methods: {
    //搜索
    seekTitle: function seekTitle() {
      this.loading = true;
      this.page = 1;
      this.dataList = [];
      this.initData();
    },
    //顶部tab 切换
    changeIndex: function changeIndex() {
      this.$router.push("/allExamination/1");
    },
    /*获取全部考试列表(无需登录)*/initData: function initData() {
      var that = this;
      // parseInt(getAssocId())
      var dataBody = {
        cid: (0, _auth.getAssocId)(),
        ctype: 4,
        page: this.page,
        size: this.size,
        search: this.searchContent
      };
      (0, _examination.getAllExaminationList)(dataBody).then(function (response) {
        if (response.code == 200) {
          //数据获取成功
          that.total = response.data.totalElements;
          response.data.content.forEach(function (item, i) {
            that.dataList.push(item);
          });
          that.handleData();
        } else {
          that.showMsg(2, response.message);
        }
      });
    },
    //处理加载数据
    handleData: function handleData() {
      //examSignUpOpen  是否开启报名 0开启（自定义表单报名） 1 未开启
      // examMethod 考试方式  0 定时定点  1 定时  2 自由
      // examCate  考试方式  0 不限 1 app考  2  pc考试
      this.dataList.map(function (item) {
        item.statusText = "已结束";
        item.statusValue = 4;
        item.btnLeftText = "查看详情";
        item.btnLeftType = "查看详情";
        if (item.examStartTime) {
          item.examStartTime = item.examStartTime.substring(0, 16);
        }
        return item;
      });
      this.loading = false;
    },
    //单条考试信息中 底部左边按钮
    itemBtnLeftClick: function itemBtnLeftClick(item) {
      this.$router.push("/examdetail/" + item.id);
    },
    //提示信息
    showMsg: function showMsg(type, msg) {
      if (type == 1) {
        this.$notify({
          title: "提示",
          message: msg,
          type: "success"
        });
      } else {
        this.$notify({
          title: "提示",
          message: msg,
          type: "error"
        });
      }
    },
    pagingSizeChange: function pagingSizeChange(val) {
      this.$router.push("/forMe/" + val);
    }
  },
  created: function created() {
    this.page = this.$route.params.page;
    this.hasToken = (0, _auth.getToken)();
    this.initData();
  },
  watch: {}
};