"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "cannotAccess",
  computed: {
    message: function message() {
      return "The webmaster said that you can not enter this page...";
    }
  },
  methods: {
    Refresh: function Refresh() {
      this.$router.push('/examinationSystem/login');
    }
  }
};