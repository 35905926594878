var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "l90m32c" }, [
    _c(
      "div",
      { staticClass: "top_title" },
      [
        _c("span", [_vm._v(_vm._s(_vm.g8gbiwebn.validateName || "--"))]),
        _c("el-button", { on: { click: _vm.goBack } }, [_vm._v("关闭")]),
      ],
      1
    ),
    _c("div", { staticClass: "btm_content" }, [
      _c("div", { staticClass: "weq908un2s" }, [
        _vm._m(0),
        _vm.$route.query.verifyResult == 1
          ? _c("p", { staticClass: "wsqcfec" }, [
              _vm._v("报名信息已提交，请前往“我的审核”完成付款"),
            ])
          : _c("p", { staticClass: "wsqcfec" }, [
              _vm._v("您提交的报名验证正在审核中，请耐心等待"),
            ]),
        _c("p", { staticClass: "pkpo" }, [
          _vm._v("电话：" + _vm._s(_vm.assocTel)),
        ]),
        _c(
          "div",
          { staticClass: "ompoqqw" },
          [
            _c(
              "el-button",
              {
                staticStyle: { width: "160px", height: "38px" },
                attrs: { size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({ path: "/dashboard" })
                  },
                },
              },
              [_vm._v("返回首页 ")]
            ),
            _c(
              "el-button",
              {
                staticStyle: { width: "160px", height: "38px" },
                attrs: { size: "mini", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.$router.push({
                      path: "/personalCenter/registration",
                    })
                  },
                },
              },
              [_vm._v("查看我的审核 ")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("img", {
        staticStyle: { width: "132px", height: "132px" },
        attrs: {
          src: require("../../../assets/img/erweimasimage.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }