"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CancelCollection = CancelCollection;
exports.DeleteMapping = DeleteMapping;
exports.LoginSubmit = LoginSubmit;
exports.getClassHours = getClassHours;
exports.getCollection = getCollection;
exports.getInvoiceMake = getInvoiceMake;
exports.getInvoiceNewRecords = getInvoiceNewRecords;
exports.getInvoiceRecords = getInvoiceRecords;
exports.getInvoiceRecordsDetails = getInvoiceRecordsDetails;
exports.getLearningRecord = getLearningRecord;
exports.getList = getList;
exports.getOrderInfo = getOrderInfo;
exports.getOrderLogInfo = getOrderLogInfo;
exports.invoiceLogGet = invoiceLogGet;
exports.orderGoodsInfo = orderGoodsInfo;
exports.orderinvoiceApply = orderinvoiceApply;
exports.withdrawInvoice = withdrawInvoice;
var _request = _interopRequireDefault(require("@/utils/request"));
function getList(params) {
  return (0, _request.default)({
    url: '/vue-admin-template/table/list',
    method: 'get',
    params: params
  });
}
/*获取个人收藏列表*/
function getCollection(params) {
  return (0, _request.default)({
    url: '/self/collection',
    method: 'post',
    params: params
  });
}
/*取消收藏*/
function CancelCollection(params) {
  return (0, _request.default)({
    url: '/award/delservercollect',
    method: 'post',
    params: params
  });
}
/*获取累计学时总累计学时*/
function getClassHours(params) {
  return (0, _request.default)({
    url: '/train/studyLog/studyHours',
    method: 'post',
    params: params
  });
}

/*获取学习记录*/
function getLearningRecord(params) {
  return (0, _request.default)({
    url: '/train/specialuser/appStudyLists',
    method: 'post',
    params: params
  });
}

/*获取申请发票记录/      旧的*/
function getInvoiceRecords(params) {
  return (0, _request.default)({
    url: '/invoice/applyLog',
    method: 'post',
    params: params
  });
}

/*获取申请发票记录/      新的 未开票里使用*/
function getInvoiceNewRecords(params) {
  return (0, _request.default)({
    url: '/order/invoiceList',
    method: 'get',
    params: params
  });
}

//开票信息详情
function invoiceLogGet(invoiceLogId) {
  return (0, _request.default)({
    url: '/invoice/manager/detail',
    method: 'get',
    params: {
      invoiceLogId: invoiceLogId
    }
  });
}
//申请发票
function orderinvoiceApply(data) {
  return (0, _request.default)({
    url: 'invoice/apply',
    method: 'post',
    data: data
  });
}
//申请发票成功
function getInvoiceMake(data) {
  return (0, _request.default)({
    url: '/invoice/manager/use/make',
    method: 'post',
    data: data
  });
}

/*申请发票记录详情*/
function getInvoiceRecordsDetails(params) {
  return (0, _request.default)({
    url: '/invoice/info',
    method: 'post',
    params: params
  });
}

/*取消申请发票*/
function withdrawInvoice(params) {
  return (0, _request.default)({
    url: 'invoice/cancle',
    method: 'post',
    params: params
  });
}

/*取消申请发票*/
function LoginSubmit(params) {
  return (0, _request.default)({
    url: 'login/login',
    method: 'post',
    params: params
  });
}

/*删除订单*/
function DeleteMapping(id) {
  return (0, _request.default)({
    url: 'order/delOrder',
    method: 'delete',
    params: {
      id: id
    }
  });
}
/*订单详情*/
function getOrderInfo(id) {
  return (0, _request.default)({
    url: '/order/info',
    method: 'post',
    params: {
      id: id
    }
  });
}

/*根据退款订单ID获取对于退款信息*/
function getOrderLogInfo(id) {
  return (0, _request.default)({
    url: 'zyg/train/order/getOrderLogInfo/' + id,
    method: 'get'
  });
}

/*获取订单对应的抵用券详情列表[Web]*/
function orderGoodsInfo(id) {
  return (0, _request.default)({
    url: "zyg/web/order/shoppingCartGoodsInfo/".concat(id),
    method: 'get'
  });
}