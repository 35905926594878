"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.number.constructor.js");
require("core-js/modules/es.array.push.js");
var _payservice = require("@/api/payservice");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    codeUrl: {
      type: String,
      required: true
    },
    payMoney: {
      type: Number,
      required: true
    },
    payType: {
      type: Number,
      required: true
    }
  },
  created: function created() {},
  methods: {
    payFinish: function payFinish() {
      var _this = this;
      (0, _payservice.getOrderInfo)(this.$route.params.id).then(function (res) {
        if (res.code === 200) {
          switch (res.data.status) {
            case 1:
              _this.$notify({
                title: "失败",
                message: "订单未支付，请扫码支付！",
                type: "error"
              });
              break;
            case 2:
              _this.$notify({
                title: "成功",
                message: "支付成功！",
                type: "success"
              });
              _this.$router.push("/teachinfo/".concat(res.data.goodsId));
              break;
            default:
              _this.$notify({
                title: "失败",
                message: "支付失败，请稍后重试",
                type: "error"
              });
              break;
          }
        } else {
          _this.$notify({
            title: "失败",
            message: "订单信息获取失败，请稍后重试",
            type: "error"
          });
        }
      });
    },
    hideDialog: function hideDialog() {
      this.$emit("payFinish");
    }
  }
};