"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkMonitorLog = checkMonitorLog;
var _request = _interopRequireDefault(require("@/utils/request"));
// 监学

/**
 * 获取监学信息
 * trainId		所处培训项目ID
 * sectionId	所处培训课程节ID	
 * @returns 
 */
function checkMonitorLog(trainId, sectionId) {
  return (0, _request.default)({
    url: "/zyg/web/training/learner/studyLog/monitorLog",
    method: "get",
    params: {
      trainId: trainId,
      sectionId: sectionId
    }
  });
}