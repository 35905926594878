"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAssocConfig = getAssocConfig;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 
 * @param {*} params {
            group: 'educationalServices[groupKey]',
            code: 'mobileConfig'[codeKey]}
 * @returns 
 */
function getAssocConfig(params) {
  return (0, _request.default)({
    method: "get",
    url: "/zyg/web/training/learner/assocConfig/getGroupDetails",
    params: params
  });
}