var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
    },
    [
      _c(
        "div",
        {
          staticClass: "announcementbox",
          staticStyle: { "min-height": "800px" },
        },
        [
          _c("p", [_vm._v("培训通知")]),
          _c(
            "div",
            [
              !Array.isArray(_vm.noticenotice) || _vm.noticenotice.length === 0
                ? _c("div", { staticClass: "zanwutz" }, [_vm._v("暂无通知")])
                : _vm._l(_vm.noticenotice, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "new",
                        on: {
                          click: function ($event) {
                            return _vm.details(item.id)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "title" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                        _c("div", { staticClass: "time" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.createTime &&
                                  item.createTime.substring(0, 10)
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    )
                  }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "block" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next",
                  total: _vm.total,
                },
                on: { "current-change": _vm.changePage },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }