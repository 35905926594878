var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "allcoursesbox position-relative",
    },
    [
      _c("img", {
        staticClass: "position-absolute img",
        attrs: { src: require("../../assets/img/0102.png"), alt: "" },
      }),
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center justify-content-center position-absolute w-100 h-100",
          staticStyle: { left: "0", top: "0" },
        },
        [
          _vm.kaoshi_hege == 0
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center flex-column",
                },
                [
                  _c("div", { staticClass: "flex-column d-flex itemC" }, [
                    _vm._m(0),
                    _c(
                      "div",
                      {
                        staticClass:
                          "border-bottom d-flex flex-column align-items-center justify-content-center",
                      },
                      [
                        _c("div", { staticClass: "dengdai" }, [
                          _vm._v(_vm._s(_vm.userName)),
                        ]),
                        _c("div", { staticClass: "jieguo" }, [
                          _vm._v("您的考卷已提交，请耐心等待判卷结果"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "boot d-flex",
                        staticStyle: { flex: "1" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center",
                            staticStyle: { flex: "1", height: "8.93rem" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "border-right w-100 flex-column d-flex align-items-center justify-content-center",
                              },
                              [
                                _c("div", { staticClass: "shichang" }, [
                                  _vm._v("考试总时长："),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "shijian_s d-flex align-items-end",
                                  },
                                  [
                                    _vm.examTime != 0
                                      ? _c(
                                          "div",
                                          { staticClass: "shijian_h" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.examTime) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("div", { staticClass: "shijian_k" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.examTime != 0
                                              ? "分钟"
                                              : "不限时长"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center",
                            staticStyle: { flex: "1", height: "8.93rem" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "border-right w-100 flex-column d-flex align-items-center justify-content-center",
                              },
                              [
                                _c("div", { staticClass: "shichang" }, [
                                  _vm._v("考试用时："),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "shijian_s d-flex align-items-end",
                                  },
                                  [
                                    _c("div", { staticClass: "shijian_h" }, [
                                      _vm._v(_vm._s(_vm.userExamTime)),
                                    ]),
                                    _c("div", { staticClass: "shijian_k" }, [
                                      _vm._v("分钟"),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center",
                            staticStyle: { flex: "1", height: "8.93rem" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 flex-column d-flex align-items-center justify-content-center",
                              },
                              [
                                _c("div", { staticClass: "shichang" }, [
                                  _vm._v("合格分数："),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "shijian_s d-flex align-items-end",
                                  },
                                  [
                                    _c("div", { staticClass: "shijian_h" }, [
                                      _vm._v(_vm._s(_vm.examPassScore)),
                                    ]),
                                    _c("div", { staticClass: "shijian_k" }, [
                                      _vm._v("分"),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "anniu d-flex align-items-center justify-content-center",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "deng_guanbi",
                          attrs: { round: "" },
                          on: { click: _vm.closeThis },
                        },
                        [_vm._v("关闭 ")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.kaoshi_hege == 1
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center flex-column",
                },
                [
                  _c("div", { staticClass: "flex-column d-flex itemC" }, [
                    _c("img", {
                      staticClass: "opsy_one",
                      attrs: {
                        src: require("../../assets/img/0101.png"),
                        alt: "",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "border-bottom d-flex flex-column align-items-center justify-content-center",
                      },
                      [
                        _c("div", { staticClass: "fenshu" }, [
                          _vm._v(_vm._s(_vm.totalScore) + "分"),
                        ]),
                        _c("div", { staticClass: "jieguo" }, [
                          _vm._v("本次考试分数（合格）"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "boot d-flex",
                        staticStyle: { flex: "1" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center",
                            staticStyle: { flex: "1", height: "8.93rem" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "border-right w-100 flex-column d-flex align-items-center justify-content-center",
                              },
                              [
                                _c("div", { staticClass: "shichang" }, [
                                  _vm._v("考试总时长："),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "shijian_s d-flex align-items-end",
                                  },
                                  [
                                    _vm.examTime != 0
                                      ? _c(
                                          "div",
                                          { staticClass: "shijian_h" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.examTime) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("div", { staticClass: "shijian_k" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.examTime != 0
                                              ? "分钟"
                                              : "不限时长"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center",
                            staticStyle: { flex: "1", height: "8.93rem" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "border-right w-100 flex-column d-flex align-items-center justify-content-center",
                              },
                              [
                                _c("div", { staticClass: "shichang" }, [
                                  _vm._v("考试用时："),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "shijian_s d-flex align-items-end",
                                  },
                                  [
                                    _c("div", { staticClass: "shijian_h" }, [
                                      _vm._v(_vm._s(_vm.userExamTime)),
                                    ]),
                                    _c("div", { staticClass: "shijian_k" }, [
                                      _vm._v("分钟"),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center",
                            staticStyle: { flex: "1", height: "8.93rem" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 flex-column d-flex align-items-center justify-content-center",
                              },
                              [
                                _c("div", { staticClass: "shichang" }, [
                                  _vm._v("考试错题："),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "shijian_s d-flex align-items-end",
                                  },
                                  [
                                    _c("div", { staticClass: "shijian_h" }, [
                                      _vm._v(_vm._s(_vm.cardsNo)),
                                    ]),
                                    _c("div", { staticClass: "shijian_k" }, [
                                      _vm._v("题"),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "anniu d-flex align-items-center justify-content-center",
                    },
                    [
                      _vm.examType === 2
                        ? _c(
                            "el-button",
                            {
                              staticClass: "zhengshu",
                              attrs: { round: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push(
                                    "/personalCenter/certificate"
                                  )
                                },
                              },
                            },
                            [_vm._v("查看我的证书")]
                          )
                        : _vm.examCertId
                        ? _c(
                            "el-button",
                            {
                              class: _vm.certLogId
                                ? "zhengshu"
                                : "zhengshuDisabled",
                              attrs: { round: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.btnClick(1)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.certLogId ? "查看证书" : "等待发证"
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.$route.query.type == 2 && _vm.showTestBtn !== 1
                        ? _c(
                            "el-button",
                            {
                              staticClass: "shijuan",
                              attrs: { round: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.btnClick(2)
                                },
                              },
                            },
                            [_vm._v(" 查看试卷 ")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          staticClass: "guanbi",
                          attrs: { round: "" },
                          on: { click: _vm.closeThis },
                        },
                        [_vm._v("关闭")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.kaoshi_hege == 2
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center flex-column",
                },
                [
                  _c("div", { staticClass: "itemC flex-column d-flex" }, [
                    _c("img", {
                      staticClass: "opsy_one",
                      attrs: {
                        src: require("../../assets/img/0103.png"),
                        alt: "",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass:
                          "border-bottom d-flex flex-column align-items-center justify-content-center",
                      },
                      [
                        _c("div", { staticClass: "buhege" }, [
                          _vm._v(_vm._s(_vm.totalScore) + "分"),
                        ]),
                        _c("div", { staticClass: "jieguo" }, [
                          _vm._v("本次考试分数（不合格）"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "boot d-flex",
                        staticStyle: { flex: "1" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center",
                            staticStyle: { flex: "1", height: "8.93rem" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "border-right w-100 flex-column d-flex align-items-center justify-content-center",
                              },
                              [
                                _c("div", { staticClass: "shichang" }, [
                                  _vm._v("考试总时长："),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "shijian_s d-flex align-items-end",
                                  },
                                  [
                                    _vm.examTime != 0
                                      ? _c(
                                          "div",
                                          { staticClass: "shijian_h" },
                                          [
                                            _vm._v(
                                              " " + _vm._s(_vm.examTime) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("div", { staticClass: "shijian_k" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.examTime != 0
                                              ? "分钟"
                                              : "不限时长"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center",
                            staticStyle: { flex: "1", height: "8.93rem" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "border-right w-100 flex-column d-flex align-items-center justify-content-center",
                              },
                              [
                                _c("div", { staticClass: "shichang" }, [
                                  _vm._v("考试用时："),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "shijian_s d-flex align-items-end",
                                  },
                                  [
                                    _c("div", { staticClass: "shijian_h" }, [
                                      _vm._v(_vm._s(_vm.userExamTime)),
                                    ]),
                                    _c("div", { staticClass: "shijian_k" }, [
                                      _vm._v("分钟"),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-center",
                            staticStyle: { flex: "1", height: "8.93rem" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-100 flex-column d-flex align-items-center justify-content-center",
                              },
                              [
                                _c("div", { staticClass: "shichang" }, [
                                  _vm._v("考试错题："),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "shijian_s d-flex align-items-end",
                                  },
                                  [
                                    _c("div", { staticClass: "shijian_h" }, [
                                      _vm._v(_vm._s(_vm.cardsNo)),
                                    ]),
                                    _c("div", { staticClass: "shijian_k" }, [
                                      _vm._v("题"),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "anniu d-flex align-items-center justify-content-center",
                    },
                    [
                      _vm.$route.query.type == 2 && _vm.showTestBtn !== 1
                        ? _c(
                            "el-button",
                            {
                              staticClass: "shijuan",
                              attrs: { round: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.btnClick(2)
                                },
                              },
                            },
                            [_vm._v("查看试卷 ")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          staticClass: "zaici_guanbi",
                          attrs: { round: "" },
                          on: { click: _vm.closeThis },
                        },
                        [_vm._v("关闭 ")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-flex align-items-center justify-content-center" },
      [
        _c("img", {
          staticStyle: {
            width: "18.57rem",
            height: "15.71rem",
            "margin-top": "0.93rem",
          },
          attrs: { src: require("../../assets/img/0104.png"), alt: "" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }