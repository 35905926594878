var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "teachtwo" },
    [
      _vm.list.length != 0
        ? _c(
            "div",
            { staticClass: "teachTwo" },
            [
              _c("el-alert", { attrs: { title: "", type: "error" } }, [
                _c("span", [
                  _vm._v(
                    "以下课程需完成" + _vm._s(_vm.listname) + "课时的学习"
                  ),
                ]),
                _vm.listNameRrainIsSup === 1
                  ? _c("span", [_vm._v("，并全程进行视频监学")])
                  : _vm._e(),
              ]),
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "el-collapse",
                  {
                    key: index,
                    staticClass: "otitle",
                    model: {
                      value: _vm.activeNames,
                      callback: function ($$v) {
                        _vm.activeNames = $$v
                      },
                      expression: "activeNames",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      { attrs: { title: item.courseName, name: index } },
                      [
                        _c("div"),
                        item.teachers && item.teachers.length > 0
                          ? _c(
                              "div",
                              { staticClass: "teacher-container" },
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("讲师："),
                                ]),
                                _vm._l(item.teachers, function (it, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "teacherCard" },
                                    [
                                      it.teacherIcon
                                        ? _c("img", {
                                            attrs: {
                                              src: it.teacherIcon,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            "popper-class": "tooltip-teacher",
                                            disabled:
                                              !it.teacherIntroduce ||
                                              it.teacherIntroduce.length == 0,
                                            effect: "dark",
                                            content: it.teacherIntroduce,
                                            placement: "bottom-start",
                                          },
                                        },
                                        [
                                          _c("span", { staticClass: "name" }, [
                                            _vm._v(_vm._s(it.teacherName)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._l(item.chapters, function (items, index1) {
                          return _c(
                            "div",
                            { key: index1 },
                            [
                              item.chapters.length > 1
                                ? _c("h2", {
                                    staticStyle: {
                                      "font-size": "18px",
                                      "margin-left": "18px",
                                    },
                                  })
                                : _vm._e(),
                              _vm._l(items.section, function (item1, index2) {
                                return _c("div", { key: index2 }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "95%",
                                        margin: "0 auto",
                                        display: "flex",
                                        "justify-content": "space-between",
                                        "align-items": "center",
                                        background: "rgba(244, 244, 244, 1)",
                                        "margin-top": "20px",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.playVideo(item1, item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-left": "24px",
                                          },
                                        },
                                        [
                                          _c("p", [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-right": "8px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "i",
                                                  { staticClass: "iconfont" },
                                                  [_vm._v("")]
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(item1.sectionTitle) +
                                                " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-right": "40px",
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-right": "24px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " 学时: " +
                                                  _vm._s(item1.classHours) +
                                                  "学时 "
                                              ),
                                            ]
                                          ),
                                          item1.isTryLearn == 0
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    color: "#f62727",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.signUpClick1()
                                                    },
                                                  },
                                                },
                                                [_vm._v(" 需付费 ")]
                                              )
                                            : _vm._e(),
                                          item1.isTryLearn == 1
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    color: "#279cf6",
                                                  },
                                                },
                                                [_vm._v(" 免费播放 ")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              }),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.list1.length != 0
        ? _c(
            "div",
            { staticClass: "teachTwo" },
            [
              _c(
                "el-alert",
                {
                  staticClass: "apst",
                  attrs: { title: "", type: "error", closable: false },
                },
                [
                  _c("span", [
                    _vm._v(
                      "以下课程需完成" + _vm._s(_vm.listname1) + "课时的学习"
                    ),
                  ]),
                  _vm.listnameRrainIsSup1 === 1
                    ? _c("span", [_vm._v("，并全程进行视频监学")])
                    : _vm._e(),
                ]
              ),
              _vm._l(_vm.list1, function (item, index) {
                return _c(
                  "el-collapse",
                  {
                    key: index,
                    staticClass: "otitle",
                    model: {
                      value: _vm.activeNames1,
                      callback: function ($$v) {
                        _vm.activeNames1 = $$v
                      },
                      expression: "activeNames1",
                    },
                  },
                  [
                    _c(
                      "el-collapse-item",
                      { attrs: { title: item.courseName, name: index } },
                      [
                        item.teachers && item.teachers.length > 0
                          ? _c(
                              "div",
                              { staticClass: "teacher-container" },
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v("讲师："),
                                ]),
                                _vm._l(item.teachers, function (it, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "teacherCard" },
                                    [
                                      it.teacherIcon
                                        ? _c("img", {
                                            attrs: {
                                              src: it.teacherIcon,
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            "popper-class": "tooltip-teacher",
                                            disabled:
                                              !it.teacherIntroduce ||
                                              it.teacherIntroduce.length == 0,
                                            effect: "dark",
                                            content: it.teacherIntroduce,
                                            placement: "bottom-start",
                                          },
                                        },
                                        [
                                          _c("span", { staticClass: "name" }, [
                                            _vm._v(_vm._s(it.teacherName)),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._l(item.chapters, function (items, index1) {
                          return _c(
                            "div",
                            { key: index1 },
                            [
                              item.chapters.length > 1
                                ? _c(
                                    "h2",
                                    {
                                      staticStyle: {
                                        "font-size": "18px",
                                        "margin-left": "18px",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(items.chapterTitle) + " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._l(items.section, function (item1, index2) {
                                return _c("div", { key: index2 }, [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "95%",
                                        margin: "0 auto",
                                        display: "flex",
                                        "justify-content": "space-between",
                                        "align-items": "center",
                                        background: "rgba(244, 244, 244, 1)",
                                        "margin-top": "20px",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.playVideo(item1, item)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-left": "24px",
                                          },
                                        },
                                        [
                                          _c("p", [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-right": "8px",
                                                },
                                              },
                                              [
                                                _c(
                                                  "i",
                                                  { staticClass: "iconfont" },
                                                  [_vm._v("")]
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              _vm._s(item1.sectionTitle) + " "
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-right": "40px",
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-right": "24px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " 学时: " +
                                                  _vm._s(item1.classHours) +
                                                  "学时 "
                                              ),
                                            ]
                                          ),
                                          item1.isTryLearn == 0
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    color: "#f62727",
                                                  },
                                                },
                                                [_vm._v(" 需付费 ")]
                                              )
                                            : _vm._e(),
                                          item1.isTryLearn == 1
                                            ? _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    color: "#279cf6",
                                                  },
                                                },
                                                [_vm._v(" 免费播放 ")]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              }),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.oprtContent.length != 0 || _vm.listText != ""
        ? _c(
            "div",
            { staticClass: "teachTwo" },
            [
              _c(
                "el-alert",
                {
                  staticClass: "apst",
                  attrs: { title: "", type: "warning", closable: false },
                },
                [
                  _c("span", [
                    _vm._v(
                      "以下课程需完成" + _vm._s(_vm.listname2) + "课时的学习"
                    ),
                  ]),
                ]
              ),
              _vm.listText != ""
                ? _c(
                    "div",
                    {
                      staticClass: "four-top",
                      staticStyle: { "min-height": "80px" },
                    },
                    [
                      _c("h2", [_vm._v("实操说明")]),
                      _c("div", {
                        attrs: { id: "contBox" },
                        domProps: { innerHTML: _vm._s(_vm.listText) },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm.oprtContent.length != 0
                ? _c("div", { staticClass: "four-top" }, [
                    _c("h2", [_vm._v("实操内容")]),
                    _c("div", { staticClass: "htoul" }, [
                      _c(
                        "ul",
                        _vm._l(_vm.oprtContent, function (item, index) {
                          return _c("li", { key: index }, [
                            _c("div", { staticClass: "liDiv" }, [
                              _c("p", [
                                _vm._v(
                                  "第" + _vm._s(item.handClassHour) + "课时"
                                ),
                              ]),
                              item.id == "" && item.status == ""
                                ? _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        color: "#0060ef",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.photoClick(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" 上传 ")]
                                  )
                                : _vm._e(),
                              item.id != "" && item.status == 1
                                ? _c(
                                    "p",
                                    {
                                      staticStyle: {
                                        color: "#edac4a",
                                        cursor: "pointer",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.bcClick(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" 已保存 ")]
                                  )
                                : _vm._e(),
                              item.id != "" && item.status == 2
                                ? _c(
                                    "p",
                                    { staticStyle: { color: "#303030" } },
                                    [_vm._v(" 已提交 ")]
                                  )
                                : _vm._e(),
                            ]),
                            item.operationImg != ""
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "flex-wrap": "wrap",
                                    },
                                  },
                                  _vm._l(
                                    item.operationImg.split(","),
                                    function (item1, index1) {
                                      return _c(
                                        "div",
                                        {
                                          key: index1,
                                          staticStyle: {
                                            "margin-bottom": "5px",
                                            display: "flex",
                                            "margin-top": "10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "100px",
                                                height: "160px",
                                                "margin-left": "5px",
                                              },
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "100%",
                                                  height: "100%",
                                                },
                                                attrs: { src: item1, alt: "" },
                                              }),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            item.operationImg == ""
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "14px",
                                      color: "#787878",
                                      "margin-top": "16px",
                                    },
                                  },
                                  [_vm._v(" 照片:0张 ")]
                                )
                              : _vm._e(),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.dialogVideoVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "mChangeHeader",
              attrs: {
                visible: _vm.dialogVideoVisible,
                "append-to-body": "",
                "close-on-click-modal": false,
                title: "课件播放",
                width: "75rem",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVideoVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    left: "20px",
                    top: "17px",
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "1.5rem", height: "1.5rem" },
                    attrs: {
                      src: require("@/assets/img/assoc_dialog.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c(
                "div",
                { staticStyle: { color: "white", "max-height": "36.25rem" } },
                [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c(
                      "div",
                      { staticStyle: { flex: "1", background: "#232323" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "mVidowWrap",
                            staticStyle: { height: "32.3rem" },
                          },
                          [
                            _vm.updateVideoWrap
                              ? _c("div", [
                                  _c("div", {
                                    ref: "prismPlayer",
                                    attrs: { id: "player-con" },
                                  }),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "flex-basis": "17.5rem",
                          padding: "2.12rem 1rem",
                          background: "#131313",
                          "max-height": "36.25rem",
                          overflow: "auto",
                        },
                        attrs: { id: "style-3" },
                      },
                      _vm._l(
                        _vm.coursePlayList.chapters,
                        function (itemC, indexC) {
                          return _c("div", { key: indexC }, [
                            _c(
                              "div",
                              { staticStyle: { "padding-bottom": "1rem" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.computedIndex(indexC + 1)) +
                                    _vm._s(itemC.chapterTitle) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticStyle: { "padding-bottom": "0.6rem" } },
                              _vm._l(itemC.section, function (ele, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass: "singleVideo",
                                    class: { playActive: ele.isPlay },
                                    staticStyle: { "margin-bottom": "0.6rem" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.mChangePlay(ele)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "iconfont",
                                        staticStyle: {
                                          "margin-right": "0.5rem",
                                          color: "#9cd4ff",
                                        },
                                      },
                                      [_vm._v("  ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mFlex",
                                        staticStyle: {
                                          color: "rgba(255, 255, 255, 0.6)",
                                          width: "87%",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "mEllipsis",
                                            staticStyle: {
                                              width: "60%",
                                              overflow: "hidden",
                                              "text-overflow": "ellipsis",
                                              "white-space": "nowrap",
                                            },
                                            attrs: { title: ele.sectionTitle },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(ele.sectionTitle) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c("div", [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                (ele.duration / 60).toFixed(1)
                                              ) +
                                              "分钟)"
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ])
                        }
                      ),
                      0
                    ),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "branch-box",
              attrs: {
                title: _vm.textMap[_vm.dialogStatus],
                visible: _vm.dialogFormVisible,
                "close-on-click-modal": false,
                width: "40%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    top: "20px",
                    left: "26px",
                  },
                },
                [
                  _c("img", {
                    staticStyle: { width: "25px", height: "25px" },
                    attrs: {
                      src: require("../../assets/img/assoc_dialog.png"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c(
                "el-tabs",
                { staticClass: "table-box" },
                [
                  _c(
                    "el-form",
                    {
                      staticStyle: { margin: "0 30px" },
                      attrs: {
                        model: _vm.temp,
                        "label-position": "left",
                        "label-width": "140px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "实操描述：" } },
                        [
                          _c("el-input", {
                            staticClass: "textClassHt",
                            staticStyle: { height: "160px" },
                            attrs: {
                              type: "textarea",
                              rows: 2,
                              placeholder: "请输入内容",
                            },
                            model: {
                              value: _vm.temp.textarea,
                              callback: function ($$v) {
                                _vm.$set(_vm.temp, "textarea", $$v)
                              },
                              expression: "temp.textarea",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "span",
                            { attrs: { slot: "label" }, slot: "label" },
                            [
                              _vm._v(
                                "最多上传" +
                                  _vm._s(_vm.handUploadEnd) +
                                  "张图片"
                              ),
                            ]
                          ),
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                action: "",
                                "list-type": "picture-card",
                                "on-remove": _vm.handleRemove,
                                "http-request": _vm.handSuccess,
                                "on-preview": _vm.handlePictureCardPreview,
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "el-icon-plus",
                                attrs: { slot: "default" },
                                slot: "default",
                              }),
                            ]
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: { visible: _vm.dialogVisible },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.dialogVisible = $event
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  width: "100%",
                                  src: _vm.dialogImageUrl,
                                  alt: "",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.updateDatalist } }, [
                    _vm._v(" 存草稿"),
                  ]),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogStatus === "create"
                            ? _vm.createData()
                            : _vm.updateData()
                        },
                      },
                    },
                    [_vm._v("提交 ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }