var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("el-input", {
        staticStyle: { "margin-bottom": "30px" },
        attrs: { placeholder: "Filter keyword" },
        model: {
          value: _vm.filterText,
          callback: function ($$v) {
            _vm.filterText = $$v
          },
          expression: "filterText",
        },
      }),
      _c("el-tree", {
        ref: "tree2",
        staticClass: "filter-tree",
        attrs: {
          data: _vm.data2,
          props: _vm.defaultProps,
          "filter-node-method": _vm.filterNode,
          "default-expand-all": "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }