var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "theme_second_footer_container" }, [
    _c("div", { staticClass: "inner" }, [
      _c("div", { staticClass: "infoBar" }, [
        _c("div", { staticClass: "infoBox" }, [
          _vm._m(0),
          _c("div", {
            staticClass: "boxItem",
            domProps: { innerHTML: _vm._s(_vm.$store.getters.assocFooter) },
          }),
          _vm._m(1),
        ]),
        _vm._m(2),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "boxItem" }, [
      _c("span", [_vm._v("地址：")]),
      _c("span", [_vm._v("赤峰市松山区五金机电城中信大厦C座3楼 ")]),
      _c("span", [_vm._v("|")]),
      _c("span", [_vm._v("联系电话：")]),
      _c("span", [_vm._v("0476-8403311")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "boxItem imgs" }, [
      _c("img", {
        attrs: {
          src: "http://147.nuxt.hangxintong.com/%E7%BB%84%2076252.png",
          alt: "",
        },
      }),
      _c("img", {
        attrs: {
          src: "http://147.nuxt.hangxintong.com/%E7%BB%84%2076248.png",
          alt: "",
        },
      }),
      _c("img", {
        attrs: {
          src: "http://147.nuxt.hangxintong.com/%E7%BB%84%2076249.png",
          alt: "",
        },
      }),
      _c("img", {
        attrs: {
          src: "http://147.nuxt.hangxintong.com/%E7%BB%84%2076251.png",
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "qrcode" }, [
      _c("img", {
        attrs: {
          src: "https://res.hangxintong.cn/assoc147/web/qrcode.png",
          alt: "",
        },
      }),
      _c("div", { staticClass: "text" }, [_vm._v("微信二维码")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }