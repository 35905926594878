"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Index',
  props: {
    title: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: ''
    },
    showClose: {
      type: Boolean,
      default: true
    },
    visible: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      top: '15vh',
      width: '',
      height: '',
      titles: '',
      showCloses: null,
      icons: '',
      fulls: false
    };
  },
  created: function created() {
    console.log(this.$slots.footer);
    console.log(this.title, this.size, this.showClose, this.visible, this.icon);
    this.titles = this.title || '行信通';
    this.PopopSizes = this.size || '1';
    this.showCloses = this.showClose || true;
    this.icons = this.icon || '';
    switch (this.PopopSizes) {
      case '1':
      case 1:
        this.fulls = false;
        this.width = 520 + 'px';
        this.height = 320 + 'px';
        break;
      case '2':
      case 2:
        this.fulls = false;
        // this.height = 600 + 'px'
        this.width = 800 + 'px';
        this.height = 'calc(70vh - 102.5px)';
        break;
      case '3':
      case 3:
        this.fulls = true;
        this.width = 100 + '%';
        this.height = this.$slots.footer ? 'calc(100vh - 102.5px)' : 'calc(100vh - 52px)';
        break;
      case '4':
      case 4:
        this.top = '50px';
        this.fulls = false;
        this.width = '1360px';
        this.height = this.$slots.footer ? 'calc(100vh - 147px)' : 'calc(100vh - 101px)';
        break;
      default:
        this.fulls = false;
        this.width = 520 + 'px';
        this.height = 320 + 'px';
    }
    // if (this.PopopSizes) {
    //   if (this.PopopSizes == 1) {
    //     this.fulls = false;
    //     this.width = 520 + "px";
    //     this.height = 320 + "px";
    //   }
    //   if (this.PopopSizes == 2) {
    //     this.fulls = false;
    //     // this.height = 600 + 'px'
    //     this.width = 800 + "px";
    //     this.height = "calc(70vh - 102.5px)";
    //   }
    //   if (this.PopopSizes == 3) {
    //     this.fulls = true;
    //     this.width = 100 + "%";
    //     this.height = this.$slots.footer
    //       ? "calc(100vh - 102.5px)"
    //       : "calc(100vh - 52px)";
    //   }
    //   if (this.PopopSizes == 4) {
    //     this.top = "50px";
    //     this.fulls = false;
    //     this.width = "1360px";
    //     this.height = this.$slots.footer
    //       ? "calc(100vh - 147px)"
    //       : "calc(100vh - 97px)";
    //   }
    //   console.log(this.PopopSizes, 111111111);
    // } else {
    //   this.fulls = false;
    //   this.width = 520 + "px";
    //   this.height = 320 + "px";
    // }
  },
  methods: {
    close: function close() {
      this.$emit('close', false);
    }
  }
};