"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.string.repeat.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.string.trim.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.array.push.js");
var _objectSpread2 = _interopRequireDefault(require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _defineProperty2 = _interopRequireDefault(require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _personal = require("@/api/personal");
var _user = require("@/api/user");
var _formValidator = require("@/utils/form-validator");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'AccountSecurity',
  data: function data() {
    return {
      bindCompanyName: '',
      // 所属企业input绑定值
      showEditCompanyInput: false,
      // 显示隐藏所属企业input
      personaData: {},
      newMobile: null,
      passwd: '',
      rpasswd: '',
      listNumber: 0,
      listNumber1: 0,
      listNumber2: 0,
      passwordInfo: '******',
      dialogPhonevisible: false,
      dialogEmailvisible: false,
      dialogPasswordSeptDisible: false,
      dialogbindEmailvisible: false,
      listLoading: false,
      show1: true,
      show3: true,
      show2: true,
      isbind: '绑定',
      isReadOnly: false,
      // 判断邮箱账号输入框是否可写
      isReadOnlyMobile: false,
      // 判断手机号输入框是否可写
      count1: '',
      // 修改手机号倒计时
      count2: '',
      // 修改密码倒计时
      count3: '',
      // 修改邮箱倒计时
      timer1: null,
      // 修改手机号计时器id
      timer2: null,
      // 修改密码计时器id
      timer3: null,
      // 修改邮箱计时器id
      mobileCode: '',
      // 手机号验证码
      loginPassword: '',
      // 更换手机号输入登录密码
      emailCode: '',
      // 邮箱验证码
      passwdCode: '',
      // 更换密码验证码
      formData: '',
      // 更换手机号对象
      //  mobile:'',
      formDataMIX: '',
      // 修改密码对象
      isChrome: true,
      formTwoData: '',
      formDataThree: {},
      inputs: '',
      isShow1: true,
      isShow2: false,
      status: '',
      mobile: '',
      dialogStatus: '',
      dialogPasswoedisible: false,
      disabled: false,
      emailInfo: '',
      mobileInfo: '',
      textMap: {
        create: '新绑定手机号',
        update: '更换手机号'
      },
      textMapTwo: {
        create1: '新绑定新邮箱',
        update1: '更换邮箱'
      },
      rules: (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
        userName: [{
          required: true,
          message: '请输入用户名称',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        card: [{
          required: true,
          message: '请输入证件号',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }, {
          pattern: _formValidator.VAILDATOR_COMMON.PHONE_REGEXP,
          message: _formValidator.VAILDATOR_COMMON.PHONE_TIP,
          trigger: 'change'
        }],
        email: [{
          required: true,
          message: '请输入常用邮箱',
          trigger: 'blur'
        }
        //   {
        //     pattern: VAILDATOR_COMMON.EMAIL_REGEXP,
        //     message: VAILDATOR_COMMON.EMAIL_TIP,
        //     trigger: "change",
        //   },
        ]
      }, "email", [{
        required: true,
        message: '请输入常用邮箱',
        trigger: 'blur'
      }, {
        type: 'email',
        message: '请输入正确的邮箱地址',
        trigger: ['blur', 'change']
      }]), "address", [{
        required: true,
        message: '请输入常用邮箱',
        trigger: 'blur'
      }]), "passwd", [{
        required: true,
        message: '请输入密码',
        trigger: 'blur'
      }]), "rpasswd", [{
        required: true,
        message: '请确认输入密码',
        trigger: 'blur'
      }])
    };
  },
  computed: {
    mobileInfoCopy: function mobileInfoCopy() {
      return this.mobileInfo.replace(this.mobileInfo.substr(3, 4), '*'.repeat(5));
    }
  },
  created: function created() {
    //  this.listNumber = (this.listNumber2 + this.listNumber1 + 10 )
    this.getList();
  },
  mounted: function mounted() {
    var nav = window.navigator.userAgent;
    if (nav.includes('chrome') > -1) {
      this.isChrome = true;
    } else {
      this.isChrome = false;
    }
  },
  methods: {
    // 绑定所属企业
    bindCompany: function bindCompany() {
      var _this = this;
      // if (this.bindCompanyName.trim() == "") {
      //   this.$message.warning("请输入企业名称");
      //   return false;
      // }
      var DATA = (0, _objectSpread2.default)({}, this.personaData);
      DATA.companyNames = this.bindCompanyName.trim();
      (0, _personal.savepresonalInfo)(DATA).then(function (res) {
        if (res.code === 200) {
          _this.getList();
          _this.$message({
            title: '成功',
            message: DATA.companyNames == '' ? '已取消绑定' : '绑定成功',
            type: 'success',
            duration: 2000
          });
          _this.showEditCompanyInput = false;
        } else {
          _this.$message.error(res.message);
        }
      });
    },
    // 显示所属企业Input
    showCompanyInputFun: function showCompanyInputFun() {
      this.bindCompanyName = this.personaData.companyNames;
      this.showEditCompanyInput = true;
    },
    // 获取个人信息
    getList: function getList() {
      var _this2 = this;
      (0, _personal.getPersonalData)().then(function (response) {
        if (response.code == 200) {
          _this2.formData = (0, _objectSpread2.default)({}, response.data.userInfo);
          _this2.formDataMIX = (0, _objectSpread2.default)({}, response.data.userInfo);
          _this2.mobileInfo = response.data.userInfo.mobile;
          _this2.formTwoData = (0, _objectSpread2.default)({}, response.data.userInfo);
          _this2.emailInfo = response.data.userInfo.email;
          _this2.personaData = (0, _objectSpread2.default)({}, response.data.userInfo);
          if (_this2.mobileInfo) {
            _this2.listNumber = 70;
          }
          if (_this2.emailInfo) {
            _this2.listNumber = 70 + 20;
          }
          if (_this2.passwordInfo) {
            _this2.listNumber = 70 + 20 + 10;
          }
        }
      });
    },
    // 获取验证码
    getverification: function getverification() {
      var _this3 = this;
      if (this.mobileInfo == '') {
        return false;
      }
      var codeMobile = null;
      var forget = null;
      if (this.isShow1 === true) {
        codeMobile = this.formData.mobile;
        forget = '';
      } else if (this.isShow2 === true) {
        codeMobile = this.newMobile;
        forget = 0;
      }
      (0, _personal.sendPhone)(codeMobile, 5, forget).then(function (response) {
        if (response.code == 200) {
          var TIME_COUNT = 60;
          if (!_this3.timer1) {
            _this3.count1 = TIME_COUNT;
            _this3.show1 = false;
            _this3.timer1 = setInterval(function () {
              if (_this3.count1 > 0 && _this3.count1 <= TIME_COUNT) {
                _this3.count1--;
              } else {
                _this3.show1 = true;
                clearInterval(_this3.timer1);
                _this3.timer1 = null;
              }
            }, 1000);
          }
          _this3.dialogPhonevisible = true;
          _this3.$message({
            title: '成功',
            message: '手机验证码发送成功',
            type: 'success',
            duration: 2000
          });
          setTimeout(function () {
            _this3.listLoading = false;
          }, 1.5 * 1000);
        } else {
          _this3.$message.error({
            message: response.message,
            duration: 2000
          });
        }
      });
    },
    getverificationOne: function getverificationOne() {
      var _this4 = this;
      // 更换step2 获取验证码
      // this.show = false
      // this.listLoading = true;
      if (this.formDataMIX.mobile == '') {
        return false;
      }
      (0, _personal.sendPhone)(this.formDataMIX.mobile, 3).then(function (response) {
        if (response.code == 200) {
          var TIME_COUNT = 60;
          if (!_this4.timer2) {
            _this4.count2 = TIME_COUNT;
            _this4.show2 = false;
            _this4.timer2 = setInterval(function () {
              if (_this4.count2 > 0 && _this4.count2 <= TIME_COUNT) {
                _this4.count2--;
              } else {
                _this4.show2 = true;
                clearInterval(_this4.timer2);
                _this4.timer2 = null;
              }
            }, 1000);
          }
          _this4.dialogPasswoedisible = true;
          _this4.$message({
            title: '成功',
            message: '手机验证码发送成功',
            type: 'success',
            duration: 2000
          });
          setTimeout(function () {
            _this4.listLoading = false;
          }, 1.5 * 1000);
        } else {
          _this4.$message.error({
            message: response.message,
            duration: 2000
          });
        }
      });
    },
    // 获取邮箱验证码
    getEmail: function getEmail() {
      var _this5 = this;
      if (this.formTwoData.email == '') {
        return false;
      }
      (0, _personal.sendEmail)(this.formTwoData.email).then(function (response) {
        if (response.code == 200) {
          var TIME_COUNT = 60;
          if (!_this5.timer3) {
            _this5.count3 = TIME_COUNT;
            _this5.show3 = false;
            _this5.timer3 = setInterval(function () {
              if (_this5.count3 > 0 && _this5.count3 <= TIME_COUNT) {
                _this5.count3--;
              } else {
                _this5.show3 = true;
                clearInterval(_this5.timer3);
                _this5.timer3 = null;
              }
            }, 1000);
          }
          _this5.dialogEmailvisible = true;
          _this5.$message({
            title: '成功',
            message: '邮箱验证码发送成功',
            type: 'success',
            duration: 5000
          });
          setTimeout(function () {
            _this5.listLoading = false;
          }, 1.5 * 1000);
        } else {
          _this5.$message.error({
            message: response.message,
            duration: 2000
          });
        }
      });
    },
    // 更换手机号
    handlePhone: function handlePhone() {
      // 更换step1 显示更换手机窗口
      this.count1 = '';
      this.show1 = true;
      this.isShow1 = true;
      this.isShow2 = false;
      this.timer1 = null;
      this.mobileCode = '';
      this.loginPassword = '';
      this.dialogPhonevisible = true;
      this.dialogStatus = 'update';
      this.disabled = true;
      this.isReadOnlyMobile = true;
      this.formData.mobile = this.mobileInfo;
    },
    // 更换邮箱
    handleEmail: function handleEmail() {
      this.count3 = '';
      this.show3 = true;
      this.timer3 = null;
      this.emailCode = '';
      if (this.isbind == '绑定') {
        this.isShow1 = false;
        this.isShow2 = true;
        this.dialogStatus = 'create1';
        this.formTwoData.email = '';
      } else if (this.isbind == '更换') {
        this.isShow1 = true;
        this.isShow2 = false;
        this.dialogStatus = 'update1';
        this.isReadOnly = true;
        this.formTwoData.email = this.emailInfo;
      }
      this.dialogEmailvisible = true;
      //   //this.dialogStatus = "c1";
    },
    // 修改密码弹框
    handlePassword: function handlePassword() {
      this.count2 = '';
      this.show2 = true;
      this.timer2 = null;
      this.formData.mobile = this.mobileInfo;
      this.passwdCode = '';
      this.dialogPasswoedisible = true;
      this.formTwoData.email = this.emailInfo;
    },
    // 点击下一步后更换密码
    confirmSept: function confirmSept() {
      var _this6 = this;
      this.passwd = '';
      this.rpasswd = '';
      // 判断验证码是否为空
      if (!this.passwdCode || this.passwdCode.trim().length < 6) return this.$message.warning('请输入6位验证码');
      (0, _personal.checkPhoneCode)(this.formDataMIX.mobile, this.passwdCode).then(function (response) {
        if (response.code == 200) {
          _this6.dialogPasswordSeptDisible = true;
          _this6.dialogPasswoedisible = false;
          _this6.passwd = '';
        } else {
          _this6.$message.error({
            message: response.message,
            duration: 2000
          });
        }
      });
    },
    // 修改密码保存接口
    handlePasswordSave: function handlePasswordSave() {
      var _this7 = this;
      this.$refs['formDataThree'].validate(function (valid) {
        if (valid) {
          (0, _personal.changePasswordSave)({
            mobile: _this7.formDataMIX.mobile,
            passwd: _this7.passwd,
            rpasswd: _this7.rpasswd,
            code: _this7.passwdCode
          }).then(function (res) {
            if (res.code === 200) {
              _this7.getList();
              _this7.$message({
                title: '成功',
                message: '修改密码成功',
                type: 'success',
                duration: 2000
              });
              _this7.dialogPasswordSeptDisible = false;
            } else {
              _this7.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 更换step3 成功后提交数据
    createData: function createData() {
      var _this8 = this;
      if (!this.newMobile) {
        this.$message.error({
          message: '请输入新手机号',
          duration: 2000
        });
        return false;
      } else {
        var str = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
        if (!str.test(this.newMobile)) {
          this.$message.error({
            message: '请输入正确的手机号',
            duration: 2000
          });
          return false;
        }
      }
      if (!this.mobileCode) {
        this.$message.error({
          message: '请输入短信验证码',
          duration: 2000
        });
        return false;
      } else {
        if (this.mobileCode.length < 6) {
          this.$message.error({
            message: '短信验证码长度至少为6位',
            duration: 2000
          });
          return false;
        }
      }
      (0, _personal.changePhoneSave)({
        mobile: this.newMobile,
        code: this.mobileCode
      }).then(function (response) {
        if (response.code == 200) {
          _this8.isShow1 = true;
          _this8.isShow2 = false;
          _this8.dialogPhonevisible = false;
          _this8.count1 = '';
          _this8.mobileInfo = _this8.newMobile;
          _this8.$alert('手机号更换成功，请使用新手机号重新登陆！', '提示', {
            confirmButtonText: '确定',
            showClose: false,
            callback: function callback(action) {
              _this8.$store.dispatch('user/resetToken');
              setTimeout(function () {
                window.location = '/';
              }, 500);
              _this8.$router.push({
                path: '/dashboard'
              });
            }
          });
        } else {
          _this8.$message.error({
            message: response.message,
            duration: 2000
          });
        }
      });
    },
    // 保存新邮箱地址 新建绑定邮箱
    createEmailData: function createEmailData() {
      var _this9 = this;
      this.$refs['dataFormOne'].validate(function (valid) {
        if (valid) {
          _this9.dialogEmailvisible = false;
          (0, _personal.changeEmailSave)(_this9.formTwoData.email, _this9.emailCode).then(function (response) {
            if (response.code == 200) {
              _this9.dialogEmailvisible = false;
              _this9.emailInfo = _this9.formTwoData.email;
              _this9.isbind = '更换';
              _this9.isReadOnly = true;
              _this9.count3 = '';
              _this9.show3 = true;
              _this9.$message({
                title: '成功',
                message: '绑定新邮箱成功',
                type: 'success',
                duration: 2000
              });
            } else {
              _this9.$message.error({
                message: response.message,
                duration: 2000
              });
            }
          });
        } else {
          return false;
        }
      });
      // this.getAssocById();
    },
    // 更换手机号保存接口
    updateData: function updateData() {
      var _this10 = this;
      if (!this.loginPassword) {
        this.$message.error({
          message: '请输入登陆密码',
          duration: 2000
        });
        return false;
      }
      (0, _user.login)({
        mobile: this.formData.mobile,
        passwd: this.loginPassword
      }).then(function (res) {
        if (res.code == 200) {
          _this10.dialogStatus = 'create';
          _this10.isShow1 = false;
          _this10.isShow2 = true;
          _this10.disabled = false;
          _this10.loginPassword = '';
          _this10.mobileCode = '';
          _this10.count1 = '';
          _this10.show1 = true;
        } else {
          _this10.$message.error({
            message: res.message,
            duration: 2000
          });
        }
      });
      // checkPhoneCode(this.formData.mobile, this.mobileCode).then((response) => {
      //   if (response.code == 200) {
      //     this.dialogStatus = "create";
      //     this.isShow1 = false;
      //     this.isShow2 = true;
      //     this.disabled = false;
      //     this.loginPassword = "";
      //     this.mobileCode = "";
      //     this.count1 = "";
      //     this.show1 = true;
      //   } else {
      //     this.$message.error({
      //       message: response.message,
      //       duration: 2000,
      //     });
      //   }
      // });
    },
    // 更新邮箱
    updateEmailData: function updateEmailData() {
      var _this11 = this;
      (0, _personal.changeEmailSave)(this.formTwoData.email, this.emailCode).then(function (response) {
        if (response.code == 200) {
          _this11.formTwoData.email = '';
          _this11.dialogEmailvisible = true;
          _this11.isShow1 = false;
          _this11.isShow2 = true;
          _this11.dialogStatus = 'create1';
          _this11.isReadOnly = false;
          _this11.show3 = true;
          _this11.timer3 = null;
          _this11.count3 = '';
          _this11.emailCode = '';
        } else {
          _this11.$message.error({
            message: response.message,
            duration: 2000
          });
        }
      });
    },
    bindEmail: function bindEmail() {
      this.dialogbindEmailvisible = true;
    },
    handleFocus: function handleFocus() {
      this.$refs.type = 'password';
    },
    handleBlur: function handleBlur() {
      this.$refs.type = 'text';
    }
  }
};