"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _updateInvoice = require("@/api/updateInvoice");
var _validate = require("./validate");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// const validateEmail = (rule, value, callback) => {
//   const reg =
//     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//   const flag = reg.test(value)
//   flag ? callback() : callback(new Error('请输入正确的邮箱'))
// }
// const validateNumber = (rule, value, callback) => {
//   const reg = /^\d+$/
//   const flag = reg.test(value)
//   flag ? callback() : callback(new Error('请输入正确的银行账号'))
// }
// const validateTaxID = (rule, value, callback) => {
//   // 企业税号
//   // /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$
//   const TaxID = /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/
//   const cardId = /^\d{18}$|\d{17}[Xx]$/
//   const flag = [TaxID, cardId].some((item) => {
//     // console.log("every",item.test(value))
//     return item.test(value)
//   })
//   // console.log(flag)
//   flag ? callback() : callback(new Error('请输入公司税号或身份证号'))
// }
var _default2 = exports.default = {
  name: 'ExchangeForm',
  components: {},
  props: {
    // 当前点击的记录信息
    currentContent: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      invoiceType: '增值税电子普通发票',
      disabled: false,
      //控制按钮
      baseQuery: {
        sourceId: this.currentContent.recordId,
        scene: '1',
        //场景[1-培训 2-考试 3-会议活动 4-会费缴纳]
        orderId: this.currentContent.orderId,
        // 关联订单ID
        orderDate: this.currentContent.orderDate,
        // 关联订单下单时间[yyyy-MM-dd HH:mm:ss]
        applicationType: '',
        // 主体类型  发票申请主体类型[1-企业 2-个人]
        goodsName: this.currentContent.goodsName,
        // 开票商品名称
        goodsPrice: this.currentContent.goodsPrice // 商品单价(元)
      },
      ruleForm: {
        // type: '电子普票', // 发票类型
        applicationName: '',
        // 发票抬头
        applicationTaxNum: '',
        // 税号
        applicationPhone: '',
        // 公司电话
        applicationAddress: '',
        // 公司地址
        applicationBank: '',
        // 开户行名称
        applicationBankAccount: '',
        // 开户行账号
        applicationEmail: '',
        // 邮箱
        reApplyRemarks: '',
        // 换开理由
        invoicingRemarks: '' //开票备注
      },
      rules: {
        applicationName: [{
          required: true,
          message: '请输入发票抬头',
          trigger: 'blur'
        }, {
          min: 1,
          max: 255,
          message: '长度在 1 到 255个字',
          trigger: 'blur'
        }],
        applicationTaxNum: [{
          required: true,
          message: '请输入公司税号',
          trigger: 'blur'
        },
        // { min: 1, max: 64, message: '长度在 1 到 64个字', trigger: 'blur' },
        {
          validator: _validate.validateTaxID,
          trigger: 'blur'
        }],
        applicationEmail: [{
          required: true,
          message: '请输入收票邮箱',
          trigger: 'blur'
        }, {
          min: 1,
          max: 64,
          message: '长度在 1 到 64个字',
          trigger: 'blur'
        }, {
          validator: _validate.validateEmail,
          trigger: 'blur'
        }],
        applicationPhone: [{
          min: 1,
          max: 16,
          message: '长度在 1 到 16个字',
          trigger: 'blur'
        }, {
          validator: (0, _validate.validateNumber)('请输入正确的电话号'),
          trigger: 'blur'
        }],
        applicationAddress: [{
          min: 1,
          max: 200,
          message: '长度在 1 到 200个字',
          trigger: 'blur'
        }],
        applicationBank: [{
          min: 1,
          max: 255,
          message: '长度在 1 到 255个字',
          trigger: 'blur'
        }],
        applicationBankAccount: [{
          min: 1,
          max: 32,
          message: '长度在 1 到 32个字',
          trigger: 'blur'
        }, {
          validator: (0, _validate.validateNumber)('请输入正确的银行账号'),
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    console.log('基本信息', this.baseQuery);
  },
  mounted: function mounted() {},
  methods: {
    back: function back() {
      var flag = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.$emit('closeDrawer', flag);
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;
      var _this = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this2.disabled = true;
          // console.log("form数据",this.ruleForm)
          var data = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, _this2.baseQuery), _this2.ruleForm);
          // console.log('提交表单数据', data)
          (0, _updateInvoice.submitInvoiceChange)(data).then(function (res) {
            console.log('提交成功', res);
            _this.resetForm('ruleForm');
            if (res.code === 200) {
              _this2.setMessage(200);
            } else {
              _this2.setMessage(500, res.message);
            }
          }).catch(function (err) {
            _this.resetForm('ruleForm');
            _this2.setMessage(500);
          });
        } else {
          return false;
        }
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    setMessage: function setMessage(code, message) {
      this.disabled = false;
      if (code == 200) {
        this.$message({
          message: '操作成功',
          type: 'success'
        });
        this.back(true);
      } else {
        console.log('失败');
        // this.$message.error('申请换开发票失败')
        this.$message.error(message);
        this.back(false);
      }
    }
  }
};