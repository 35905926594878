"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      navList: [{
        id: 1,
        title: '协会概况',
        children: [{
          id: 101,
          title: '协会简介'
        }, {
          id: 102,
          title: '协会章程'
        }, {
          id: 103,
          title: '协会荣誉'
        }, {
          id: 104,
          title: '协会领导'
        }, {
          id: 105,
          title: '组织机构'
        }, {
          id: 106,
          title: '部门职能'
        }, {
          id: 107,
          title: '会员风采'
        }]
      }, {
        id: 2,
        title: '协会工作',
        children: [{
          id: 201,
          title: '行业调研'
        }, {
          id: 202,
          title: '评优评奖'
        }, {
          id: 203,
          title: '行业培训'
        }, {
          id: 204,
          title: '法律服务'
        }, {
          id: 205,
          title: '信息化服务'
        }, {
          id: 206,
          title: '行业标准'
        }, {
          id: 207,
          title: '服务中心'
        }]
      }, {
        id: 3,
        title: '新闻公告',
        children: [{
          id: 301,
          title: '协会动态'
        }, {
          id: 302,
          title: '文件公告'
        }, {
          id: 303,
          title: '政策法规'
        }, {
          id: 304,
          title: '行业动态'
        }, {
          id: 305,
          title: '电子会刊'
        }]
      }, {
        id: 4,
        title: '联系我们'
      }]
    };
  },
  components: {},
  computed: {},
  methods: {}
};