"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: ["refundProgressItem"],
  data: function data() {
    return {
      progressActive: 3
    };
  },
  mounted: function mounted() {},
  methods: {
    lookReason: function lookReason() {},
    closeDialog: function closeDialog() {
      this.$emit("closeDid", false);
    },
    withdraw: function withdraw() {
      var _this = this;
      (0, _request.default)({
        url: "/delForRefund",
        method: "delete",
        params: {
          oid: this.refundProgressItem.id
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this.$message({
            message: "已取消申请",
            type: "success"
          });
          _this.$emit("closeDid", false);
        } else {
          _this.$message.error(response.message);
        }
      });
    },
    qxClick: function qxClick() {
      this.$emit("reQxClick", true);
    },
    refundProgress: function refundProgress() {}
  }
};