var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "paymentbox", attrs: { data: _vm.orderInfo } },
    [
      _vm._m(0),
      _c("div", { staticClass: "commoditybox" }, [
        _c("p", { staticClass: "commodity" }, [_vm._v("商品介绍")]),
        _c("div", { staticClass: "commodityone" }, [
          _c("div", [
            _c("p", [
              _vm._v(" 商品名称："),
              _c("span", [_vm._v(_vm._s(_vm.orderInfo.goodsTitle))]),
            ]),
            _c("p", [
              _vm._v(" 商品编号："),
              _c("span", [_vm._v(_vm._s(_vm.orderInfo.goodsId))]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "amountofmoneybox" }, [
        _c("div", { staticClass: "amountofmoney" }, [
          _c("p", [_vm._v("支付金额")]),
          _c("p", { staticStyle: { "margin-left": "1.5rem" } }, [
            _vm._v("￥" + _vm._s(_vm.orderInfo.realTotalMoney)),
            _c("span", [_vm._v("/元")]),
          ]),
          _c("p", { staticStyle: { "margin-left": "1.5rem" } }, [
            _vm._v(" 订单编号："),
            _c("span", [_vm._v(_vm._s(_vm.orderInfo.orderNum))]),
          ]),
          _c("p", { staticStyle: { "margin-left": "1.5rem" } }, [
            _vm._v(" 购买时间："),
            _c("span", [_vm._v(_vm._s(_vm.orderInfo.createTime))]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "paymentmethod" }, [
        _c("p", { staticClass: "paymentmethodone" }, [_vm._v("支付方式")]),
        _c(
          "div",
          { staticClass: "btnList", staticStyle: { "margin-left": "1.5rem" } },
          _vm._l(_vm.btmList, function (item) {
            return _c(
              "div",
              {
                key: item.type,
                ref: "payMethod",
                refInFor: true,
                staticClass: "btnItem",
                style: {
                  border:
                    _vm.payClickType === item.type ? "1px solid #0060ef" : "",
                },
                on: {
                  click: function ($event) {
                    return _vm.btnItemClick(item.type)
                  },
                },
              },
              [
                _c("div", [
                  _c("i", { staticClass: "iconfont", class: item.icon }),
                  _c("span", [_vm._v(_vm._s(item.value))]),
                ]),
                _c("i", {
                  staticClass: "iconfont icondui",
                  style: {
                    color:
                      _vm.payClickType === item.type ? "#0060ef" : "#E4E4E4",
                  },
                }),
              ]
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "payClickDiv" }, [
        _c("div"),
        _c(
          "div",
          [
            _c("el-button", { on: { click: _vm.payClickNow } }, [
              _vm._v(" " + _vm._s("立即付款") + " "),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.checkErr,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.checkErr = $event
            },
          },
        },
        [
          _c("div", { staticClass: "checkErr" }, [
            _c("span", { staticClass: "checkErrTitle" }, [
              _c("i", { staticClass: "el-icon-error" }),
              _vm._v(_vm._s(_vm.messageData.title)),
            ]),
            _c("span", { staticClass: "checkErrMessage" }, [
              _vm._v(_vm._s(_vm.messageData.message)),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.checkErr = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.showPayDiv
        ? _c("pay-code", {
            attrs: {
              "pay-type": _vm.payType,
              "pay-money": _vm.orderInfo.realTotalMoney,
              codeUrl: _vm.payCode,
            },
            on: { payFinish: _vm.payFinish },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "paymentone" }, [
      _c("div", [_c("p", [_vm._v("确认订单信息")])]),
      _c("div"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }