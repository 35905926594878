var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "app-main-box" }, [
        _c(
          "div",
          {
            staticStyle: {
              "border-bottom": "0.1rem solid #e4e4e4",
              "font-weight": "bold",
              "font-size": "1.1rem",
              color: "#303030",
              height: "3rem",
              display: "flex",
              "align-items": "center",
              "padding-left": "1.6rem",
            },
          },
          [_vm._v(" 个人信息 ")]
        ),
        _c(
          "div",
          { staticClass: "main-top" },
          [
            _c(
              "div",
              { staticStyle: { color: "#303030", "font-size": "1rem" } },
              [_vm._v("信息完善度")]
            ),
            _c("el-progress", {
              staticStyle: { "margin-top": "0.5rem" },
              attrs: { percentage: _vm.listfen },
            }),
            _vm.listfen != 100
              ? _c("p", { staticStyle: { "margin-top": "0.5rem" } }, [
                  _vm._v(" 建议完善信息，以便更好的推荐课程 "),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticStyle: { background: "#f5f7f9", height: "1.1rem" } }),
        _c(
          "div",
          {
            staticClass: "main-content",
            staticStyle: { "min-height": "500px" },
          },
          [
            _c(
              "div",
              { staticStyle: { display: "flex", "align-items": "center" } },
              [
                _c("div", { staticClass: "uploud-header" }, [
                  _c("img", {
                    staticClass: "img-header",
                    staticStyle: { "object-fit": "cover" },
                    attrs: { src: _vm.personaldata.faceImg },
                  }),
                ]),
                _c(
                  "el-upload",
                  {
                    ref: "mupload",
                    attrs: {
                      action: "",
                      "http-request": _vm.handleUploadSuccess,
                      "show-file-list": false,
                      "before-upload": _vm.handImg,
                    },
                  },
                  [_c("span", { staticClass: "text-gh" }, [_vm._v("更换头像")])]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "main-con-div",
                staticStyle: { "margin-top": "1rem" },
              },
              [
                _c("div", { staticClass: "main-con-info" }, [
                  _vm._v(" 实名认证： "),
                  _vm.personaldata.identityStatus == 1
                    ? _c("div", [
                        _c("span", [_vm._v("未认证")]),
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleAttestation("开始认证")
                              },
                            },
                          },
                          [_vm._v("开始认证")]
                        ),
                      ])
                    : _vm._e(),
                  [3, 4].includes(_vm.personaldata.identityStatus)
                    ? _c("div", [
                        _c("span", [_vm._v("认证失败")]),
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleAttestation("开始认证")
                              },
                            },
                          },
                          [_vm._v("重新认证")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.personaldata.identityStatus == 2
                    ? _c("div", [
                        _vm._m(0),
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleAttestation("修改留底照片")
                              },
                            },
                          },
                          [_vm._v(" 修改留底照片 ")]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]
            ),
            _c("div", { staticClass: "main-con-div" }, [
              _c("div", { staticClass: "main-con-info" }, [
                _vm._v(" 完善信息： "),
                _vm.personalInfo.status !== 1
                  ? _c("div", [
                      _c("span", [_vm._v("未完善")]),
                      _c("span", { on: { click: _vm.handleInfo } }, [
                        _vm._v("去完善"),
                      ]),
                    ])
                  : _vm._e(),
                _vm.personalInfo.status == 1
                  ? _c("div", [
                      _c("span", [
                        _vm._v(" 已完善 "),
                        _c("b", [
                          _vm._v("(" + _vm._s(_vm.personalInfo.fen) + ")"),
                        ]),
                      ]),
                      _c("span", { on: { click: _vm.handleUpdataInfo } }, [
                        _vm._v("修改"),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("accountSecurity", {
              attrs: { "persona-data": _vm.personaldata },
            }),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading2,
              expression: "loading2",
            },
          ],
          attrs: {
            title: "实名认证",
            visible: _vm.dialogvisible,
            "close-on-click-modal": false,
            width: _vm.dialogChangeWidth,
            "close-on-press-escape": false,
            "element-loading-text": _vm.loadingTitle,
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(0, 0, 0, 0.8)",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogvisible = $event
            },
          },
        },
        [
          _vm.personaldata.identityStatus == 2
            ? _c(
                "div",
                { staticStyle: { "border-top": "0.1rem solid #e4e4e4" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        height: "3rem",
                        "margin-top": "1rem",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "1",
                            "text-align": "right",
                            "font-size": "1rem",
                            color: "#303030",
                          },
                        },
                        [_vm._v(" 姓名： ")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-left": "1rem",
                            "font-size": "0.8rem",
                            color: "#999999",
                            flex: "4",
                            height: "100%",
                            "border-bottom": "1px solid #bbbbbb",
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.realname) + " ")]
                      ),
                      _c("div", { staticStyle: { flex: "1" } }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        height: "3rem",
                        "margin-top": "1rem",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            flex: "1",
                            "text-align": "right",
                            "font-size": "1rem",
                            color: "#303030",
                          },
                        },
                        [_vm._v(" 证件号码： ")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-left": "1rem",
                            "font-size": "0.8rem",
                            color: "#999999",
                            flex: "4",
                            height: "100%",
                            "border-bottom": "1px solid #bbbbbb",
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.identity) + " ")]
                      ),
                      _c("div", { staticStyle: { flex: "1" } }),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm.showlist
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "70%",
                    height: "40px",
                    margin: "0 auto",
                    border: "1px solid red",
                    "line-height": "40px",
                    "text-align": "center",
                    color: "red",
                  },
                },
                [
                  _c("h3", [
                    _c("i", {
                      staticClass: "el-icon-warning",
                      staticStyle: { color: "red", "margin-right": "15px" },
                    }),
                    _vm._v("图片没上传 "),
                  ]),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "uploud-div", staticStyle: { display: "flex" } },
            [
              _c(
                "div",
                { staticClass: "uploudone", staticStyle: { width: "60%" } },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticStyle: { "font-size": "24px", color: "#303030" },
                      },
                      [_vm._v("未进行实名认证")]
                    ),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "14px",
                          color: "#333333",
                          "margin-top": "15px",
                        },
                      },
                      [
                        _vm._v(
                          " 您需要进行实名认证后才可以在线学习培训课程，考试、证书的颁发等功能。 "
                        ),
                      ]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "uploudtwe",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "margin-top": "18px",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "uploud-div-img",
                          staticStyle: { "text-align": "center" },
                          on: {
                            click: function ($event) {
                              return _vm.getClickType(1)
                            },
                          },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                action: "",
                                "on-progress": _vm.handleUploadCardSuccessZHENG,
                                headers: _vm.mToken,
                                "show-file-list": false,
                                "list-type": "picture",
                                "before-upload": _vm.handImg,
                                "auto-upload": false,
                                "on-change": _vm.changeUpload,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "min-width": "15rem",
                                    "max-width": "15rem",
                                    height: "9.3rem",
                                  },
                                },
                                [
                                  _vm.defaultImg1
                                    ? _c("img", {
                                        staticStyle: { "object-fit": "cover" },
                                        attrs: { src: _vm.defaultImg1 },
                                      })
                                    : _vm._e(),
                                  !_vm.defaultImg1
                                    ? _c("img", {
                                        attrs: {
                                          src: require("../../../assets/img/cardimg1.png"),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c("span", [_vm._v("点击图片上传身份证正面")]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "uploud-div-img",
                          staticStyle: { "text-align": "center" },
                          on: {
                            click: function ($event) {
                              return _vm.getClickType(2)
                            },
                          },
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                action: "",
                                "on-progress": _vm.handleUploadCardSuccessFAN,
                                "show-file-list": false,
                                "list-type": "picture",
                                "before-upload": _vm.handImg,
                                "auto-upload": false,
                                "on-change": _vm.changeUpload,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "min-width": "15rem",
                                    "max-width": "15rem",
                                    height: "9.3rem",
                                  },
                                },
                                [
                                  _vm.defaultImg2
                                    ? _c("img", {
                                        staticStyle: { "object-fit": "cover" },
                                        attrs: { src: _vm.defaultImg2 },
                                      })
                                    : _vm._e(),
                                  !_vm.defaultImg2
                                    ? _c("img", {
                                        attrs: {
                                          src: require("../../../assets/img/cardimg2.png"),
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                          _c("span", [_vm._v("点击图片上传身份证反面")]),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "uploudone",
                  staticStyle: { "margin-left": "35px", width: "40%" },
                },
                [
                  _c("div", { staticStyle: { "margin-left": "25px" } }, [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "14px",
                          color: "#0060ef",
                          "margin-top": "10px",
                        },
                      },
                      [_vm._v(" 上传标准： ")]
                    ),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "14px",
                          color: "#333333",
                          "margin-top": "15px",
                        },
                      },
                      [_vm._v(" ① 四角完整 ② 亮度均匀 ③ 照片清晰 ")]
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "uploudtwe twe",
                      staticStyle: { width: "90%", margin: "10px auto" },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: { src: require("../../../assets/img/zqfs.png") },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                "justify-content": "flex-end",
                "padding-right": "3.6rem",
                display: "flex",
                "align-items": "center",
              },
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogvisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.confirmUpload()
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.personaldata.identityStatus == 2 ? "修改提交" : "提交"
                    ) + " "
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            { staticClass: "table-box" },
            [
              _c(
                "el-form",
                {
                  ref: "dataForm",
                  staticClass: "elFormList",
                  attrs: {
                    rules: _vm.rules,
                    model: _vm.personaldata,
                    "label-position": "right",
                    "label-width": "110px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "1.5rem" },
                      attrs: { label: "学历:", prop: "xueli" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "embranchment el-form-item__content22",
                        staticStyle: { width: "30.5%" },
                        attrs: { placeholder: "填写" },
                        model: {
                          value: _vm.personaldata.xueli,
                          callback: function ($$v) {
                            _vm.$set(_vm.personaldata, "xueli", $$v)
                          },
                          expression: "personaldata.xueli",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-bottom": "1.5rem" },
                      attrs: { label: "专业职称:", prop: "professional" },
                    },
                    [
                      _c("el-input", {
                        staticClass: "embranchment el-form-item__content22",
                        staticStyle: { width: "30.5%" },
                        attrs: { placeholder: "填写" },
                        model: {
                          value: _vm.personaldata.professional,
                          callback: function ($$v) {
                            _vm.$set(_vm.personaldata, "professional", $$v)
                          },
                          expression: "personaldata.professional",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "职业地址:", prop: "sheng" } },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            "margin-top": "-8px",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass:
                                "filter-item el-form-item__content22",
                              staticStyle: { "margin-right": "1rem" },
                              attrs: { placeholder: "请选择省" },
                              on: {
                                change: function ($event) {
                                  return _vm.mchangeAddress($event, 1)
                                },
                              },
                              model: {
                                value: _vm.personaldata.sheng,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personaldata, "sheng", $$v)
                                },
                                expression: "personaldata.sheng",
                              },
                            },
                            _vm._l(_vm.sheng, function (item) {
                              return _c("el-option", {
                                key: item.areaId,
                                staticStyle: { "padding-left": "20px" },
                                attrs: {
                                  label: item.areaName,
                                  value: item.areaId,
                                },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-select",
                            {
                              staticClass:
                                "filter-item el-form-item__content22",
                              staticStyle: { "margin-right": "1rem" },
                              attrs: { placeholder: "请选择市" },
                              on: {
                                change: function ($event) {
                                  return _vm.mchangeAddress($event, 2)
                                },
                              },
                              model: {
                                value: _vm.personaldata.shi,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personaldata, "shi", $$v)
                                },
                                expression: "personaldata.shi",
                              },
                            },
                            _vm._l(_vm.shi, function (item) {
                              return _c("el-option", {
                                key: item.areaId,
                                staticStyle: { "padding-left": "20px" },
                                attrs: {
                                  label: item.areaName,
                                  value: item.areaId,
                                },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "margin-right": "1rem" },
                              attrs: { placeholder: "请选择区" },
                              model: {
                                value: _vm.personaldata.qu,
                                callback: function ($$v) {
                                  _vm.$set(_vm.personaldata, "qu", $$v)
                                },
                                expression: "personaldata.qu",
                              },
                            },
                            _vm._l(_vm.qu, function (item) {
                              return _c("el-option", {
                                key: item.areaId,
                                staticClass: "el-form-item__content22",
                                staticStyle: { "padding-left": "20px" },
                                attrs: {
                                  label: item.areaName,
                                  value: item.areaId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus == "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("确 定 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading2,
              expression: "loading2",
            },
          ],
          attrs: {
            title: _vm.QrCodeTitle,
            visible: _vm.isQrCode,
            width: "30%",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "element-loading-text": "人脸核验中...",
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(0, 0, 0, 0.8)",
          },
          on: {
            "update:visible": function ($event) {
              _vm.isQrCode = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "min-height": "300px",
                display: "flex",
                "align-items": "center",
                "flex-direction": "column",
              },
            },
            [
              _c("p", [_vm._v("请使用手机微信扫一扫")]),
              _c("img", { attrs: { src: _vm.h5QrCodeSrc, alt: "" } }),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading2,
              expression: "loading2",
            },
          ],
          attrs: {
            title: _vm.Verification == 1 ? "人脸核验" : "",
            visible: _vm.dialogVerificationVisible,
            width: _vm.dialogChangeWidth,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "element-loading-text": "人脸核验中...",
            "element-loading-spinner": "el-icon-loading",
            "element-loading-background": "rgba(0, 0, 0, 0.8)",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVerificationVisible = $event
            },
          },
        },
        [
          _vm.Verification == 1
            ? _c("div", [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "0.8rem",
                      color: "#333333",
                      padding: "11px 20px",
                    },
                  },
                  [
                    _vm._v(
                      " 您需要调取摄像头来完成人脸核验，核验完成后可进行监学的人脸识别功能。 "
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      padding: "0.2rem 2rem 0rem 4.2rem",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          border: "0.2rem dashed #e4e4e4",
                          "border-radius": "15px",
                          width: "26.6rem",
                          height: "19.2rem",
                          display: "flex",
                          "flex-direction": "column",
                          "justify-content": "center",
                          "align-items": "center",
                          cursor: "pointer",
                        },
                        on: { click: _vm.takePhoto },
                      },
                      [
                        _vm.verificationImg
                          ? _c("img", {
                              staticStyle: {
                                "object-fit": "cover",
                                width: "100%",
                                height: "100%",
                              },
                              attrs: { src: _vm.verificationImg },
                            })
                          : _vm._e(),
                        !_vm.verificationImg
                          ? _c("img", {
                              staticStyle: {
                                width: "5.4rem",
                                height: "5.4rem",
                              },
                              attrs: {
                                src: require("../../../assets/img/icon_take_photo.png"),
                              },
                            })
                          : _vm._e(),
                        !_vm.verificationImg
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#303030",
                                  "font-size": "1.3rem",
                                },
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.takePhoto },
                                  },
                                  [_vm._v("开始拍照")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      { staticStyle: { display: "flex", height: "9.2rem" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              border: "0.1rem solid #e4e4e4",
                              width: "6.6rem",
                              height: "9.2rem",
                              display: "flex",
                              "align-items": "center",
                              "flex-direction": "column",
                              "justify-content": "center",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "5.9rem", height: "7rem" },
                              attrs: {
                                src: require("../../../assets/img/icon_verification_examples.png"),
                                alt: "",
                              },
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#303030",
                                  "font-size": "0.7rem",
                                  "margin-top": "0.3rem",
                                },
                              },
                              [_vm._v(" 核验示例 ")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "1rem",
                              "max-width": "12.2rem",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#333333",
                                  "font-size": "0.8rem",
                                },
                              },
                              [_vm._v("核验要求：")]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "line-height": "1.5rem",
                                  color: "#999999",
                                  "font-size": "0.8rem",
                                  "margin-top": "0.7rem",
                                },
                              },
                              [
                                _vm._v(
                                  " ①电脑需调取摄像头，本机无摄像头需外接摄像头来完成核验 "
                                ),
                                _c("br"),
                                _vm._v("②核验时需脸部正对摄像头来完 成核验 "),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.Verification == 2
            ? _c("div", { staticStyle: { padding: "0 2.4rem" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("img", {
                      staticStyle: { width: "1.8rem", height: "1.8rem" },
                      attrs: {
                        src: require("../../../assets/img/icon_warning.png"),
                        alt: "",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "#303030",
                          "font-size": "1.5rem",
                          "margin-left": "0.6rem",
                        },
                      },
                      [_vm._v(" 无摄像头 ")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "0.9rem",
                      color: "#333333",
                      "margin-top": "1rem",
                    },
                  },
                  [
                    _vm._v(
                      " 检测您电脑没有安装摄像头，无法进行核验，请外接摄像头进行核验 "
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "margin-top": "2rem" } },
                  [
                    _c("img", {
                      staticStyle: {
                        width: "7.2rem",
                        height: "7.2rem",
                        "object-fit": "cover",
                      },
                      attrs: { src: _vm.erweimaimg, alt: "" },
                    }),
                    _c("div", { staticStyle: { "margin-left": "2.7rem" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "1.1rem",
                            color: "#333333",
                            "font-weight": "bold",
                          },
                        },
                        [_vm._v(" 您也可以下载APP进行实名认证 ")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            color: "#333333",
                            "font-size": "0.9rem",
                            "margin-top": "1.3rem",
                          },
                        },
                        [_vm._v(" 扫描左侧二维码在手机端实名认证 ")]
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.Verification == 3
            ? _c("div", { staticStyle: { padding: "0 2.4rem" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("img", {
                      staticStyle: { width: "2rem", height: "2rem" },
                      attrs: {
                        src: require("../../../assets/img/icon_type_false.png"),
                        alt: "",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-weight": "500",
                          color: "#303030",
                          "font-size": "1.5rem",
                          "margin-left": "0.5rem",
                        },
                      },
                      [_vm._v(" 核验失败、请重新核验 ")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "0.9rem",
                      color: "#333333",
                      "margin-top": "1rem",
                    },
                  },
                  [
                    _vm._v(
                      " 您核验的结果失败，请摆正坐姿，正脸对准摄像头，重新核验 "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.Verification == 4
            ? _c("div", { staticStyle: { padding: "0 2.4rem" } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c("img", {
                      staticStyle: { width: "2rem", height: "2rem" },
                      attrs: {
                        src: require("../../../assets/img/icon_type_true.png"),
                        alt: "",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-weight": "500",
                          color: "#303030",
                          "font-size": "1.5rem",
                          "margin-left": "0.5rem",
                        },
                      },
                      [_vm._v(" 您的实名认证已成功 ")]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "0.9rem",
                      color: "#333333",
                      "margin-top": "1rem",
                    },
                  },
                  [_vm._v(" 恭喜您实名认证已成功，您现在可以进行课程学习啦。 ")]
                ),
              ])
            : _vm._e(),
          _vm.Verification != 4
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "margin-bottom": "-8px",
                      },
                    },
                    [
                      _c("div"),
                      _vm.Verification == 2 || _vm.Verification == 3
                        ? _c("div", [
                            _c("p", { staticClass: "opsl" }, [
                              _vm._v(
                                "如您已接入外接摄像头，可点击重新核验人脸"
                              ),
                            ]),
                            _c("p", { staticClass: "rev-triangle" }),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm.disabledList == false
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "popover",
                              rawName: "v-popover:popover1",
                              arg: "popover1",
                            },
                          ],
                          staticClass: "opslone",
                          attrs: {
                            type:
                              _vm.Verification == 1
                                ? "primary"
                                : _vm.Verification == 2
                                ? "warning"
                                : _vm.Verification == 3
                                ? "danger"
                                : "primary",
                          },
                          on: { click: _vm.startVerification },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.Verification == 1
                                  ? "开始核验"
                                  : _vm.Verification == 2
                                  ? "再次核验"
                                  : _vm.Verification == 3
                                  ? "再次核验"
                                  : "完成认证"
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.disabledList == true
                    ? _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "popover",
                              rawName: "v-popover:popover1",
                              arg: "popover1",
                            },
                          ],
                          attrs: {
                            type: "info",
                            plain: "",
                            disabled: _vm.disabledList,
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.Verification == 1
                                  ? "开始核验"
                                  : _vm.Verification == 2
                                  ? "再次核验"
                                  : _vm.Verification == 3
                                  ? "再次核验"
                                  : "完成认证"
                              ) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.Verification == 1 || _vm.Verification == 2
                    ? _c(
                        "el-button",
                        {
                          staticClass: "opslone",
                          on: {
                            click: function ($event) {
                              _vm.dialogVerificationVisible = false
                            },
                          },
                        },
                        [_vm._v("取消 ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.Verification == 4
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _vm.val.index == 1
                    ? _c("el-button", { on: { click: _vm.histotLick } }, [
                        _vm._v("返回课程学习 "),
                      ])
                    : _vm._e(),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogVerificationVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.showTakePhoto,
            width: "550px",
            height: _vm.videoHeight,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showTakePhoto = $event
            },
            close: _vm.closeVideoWin,
          },
        },
        [
          _c("div", {
            staticClass: "faceBGI",
            style: {
              width: _vm.videoWidth + "px",
              height: _vm.videoHeight + "px",
            },
          }),
          _c("video", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.video_show,
                expression: "video_show",
              },
            ],
            staticClass: "canvas",
            attrs: {
              id: "videoCamera",
              width: _vm.videoWidth,
              height: _vm.videoHeight,
              autoplay: "",
            },
          }),
          _c("canvas", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.can_show,
                expression: "can_show",
              },
            ],
            staticClass: "canvas",
            attrs: {
              id: "canvasCamera",
              width: _vm.videoWidth,
              height: _vm.videoHeight,
            },
          }),
          _c(
            "div",
            {
              staticStyle: {
                "justify-content": "flex-end",
                display: "flex",
                "align-items": "center",
                "margin-top": "2rem",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "success" },
                  on: { click: _vm.takePhotoToPicture },
                },
                [_vm._v(_vm._s(_vm.can_show ? "重新拍照" : "拍照") + " ")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.quxiaoclick()
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitImg } },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.newShowList
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.newShowList,
                "close-on-click-modal": false,
                width: "35%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.newShowList = $event
                },
              },
            },
            [
              _c("div", { staticStyle: { width: "90%", margin: "0 auto" } }, [
                _c("div", [
                  _c("i", {
                    staticClass: "el-icon-error",
                    staticStyle: {
                      "margin-right": "12px",
                      "font-size": "24px",
                      color: "red",
                    },
                  }),
                  _c("span", { staticStyle: { "font-size": "24px" } }, [
                    _vm._v("您的身份信息认证失败"),
                  ]),
                ]),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "margin-top": "16px",
                      "font-size": "14px",
                      "margin-left": "40px",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.messages) + " ")]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.newClick()
                        },
                      },
                    },
                    [_vm._v("重新认证")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("cropPicture", {
        attrs: { img: _vm.cropPictureImg, "fixed-number": [85.6, 54] },
        on: { returnUrl: _vm.returnUrl },
        model: {
          value: _vm.showCropPicture,
          callback: function ($$v) {
            _vm.showCropPicture = $$v
          },
          expression: "showCropPicture",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", {
        staticStyle: { "vertical-align": "middle", "margin-right": "5px" },
        attrs: { src: require("../../../assets/img/success.png") },
      }),
      _vm._v(" 已认证 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }