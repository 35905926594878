var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "boss",
      staticStyle: { border: "1px solid #ccc" },
    },
    [
      _vm._m(0),
      _c("div", { staticClass: "listones" }, [
        _c("div", { staticClass: "shuming" }, [
          _c("p", [_vm._v(_vm._s(_vm.ctList.certName))]),
          _c("p", [
            _vm._v(
              "发证时间：" +
                _vm._s(
                  _vm.ctList.issueTime && _vm.ctList.issueTime.split(" ")[0]
                )
            ),
          ]),
        ]),
        _c("div", { staticClass: "imgshu" }, [
          _c("img", {
            staticClass: "tupian",
            attrs: { src: _vm.ctList.certImgUrl, alt: "" },
          }),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "diannao" },
        [
          _c(
            "el-row",
            [
              _c("el-button", [
                _c(
                  "a",
                  {
                    staticClass: "aa",
                    attrs: {
                      href: _vm.ctList.certImgUrl,
                      target: "_blank",
                      download: _vm.ctList.certImgUrl,
                    },
                  },
                  [_c("div", [_vm._v("保存到电脑")])]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "certificate-header" }, [
      _c("span", { staticClass: "kaozheng" }, [_vm._v("我获得的证书")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }