var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-container",
      staticStyle: {
        display: "flex",
        "flex-direction": "column",
        height: "100%",
        "min-height": "754px",
      },
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            flex: "1",
            display: "flex",
            "flex-direction": "column",
            "min-height": "500px",
            background: "#fff",
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "border-bottom": "0.01rem solid #E4E4E4",
                "font-weight": "bold",
                "font-size": "1.1rem",
                color: "#303030",
                height: "3rem",
                display: "flex",
                "align-items": "center",
                "padding-left": "1.6rem",
              },
            },
            [_vm._v(" 我收藏的课程 ")]
          ),
          _vm._m(0),
          _c(
            "div",
            {
              staticStyle: {
                background: "#FFFFFF",
                width: "100%",
                border: "0.01rem solid rgba(235,235,235,1)",
                flex: "1",
              },
            },
            _vm._l(_vm.list, function (item, i) {
              return _c(
                "div",
                {
                  key: i,
                  staticStyle: {
                    height: "7.6rem",
                    display: "flex",
                    "align-items": "center",
                    padding: "1.5rem 0 1.5rem 0",
                    width: "100%",
                    "border-bottom": "0.1rem solid rgba(235,235,235,1)",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "57%",
                        display: "flex",
                        "align-items": "center",
                        "margin-left": "1.6rem",
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          "min-width": "5.8rem",
                          "max-width": "5.8rem",
                          height: "3.4rem",
                        },
                        attrs: { src: item.itemCover, alt: "" },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "lin-1",
                          staticStyle: {
                            "font-size": "0.9rem",
                            color: "#303030",
                            "margin-left": "1.5rem",
                          },
                        },
                        [_vm._v(" " + _vm._s(item.itemName) + " ")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "30%",
                        "padding-left": "7%",
                        display: "flex",
                        "justify-content": "center",
                        color: "#FF4D3B",
                        "font-size": "0.9rem",
                      },
                    },
                    [_vm._v(" " + _vm._s(item.price) + "元 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "22%",
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "0.5rem",
                            "font-size": "0.9rem",
                            cursor: "pointer",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.ImmediatePayment(item)
                            },
                          },
                        },
                        [_vm._v(" 查看详情 ")]
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "0.5rem",
                            "font-size": "0.9rem",
                            cursor: "pointer",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.CancelCollection(item)
                            },
                          },
                        },
                        [_vm._v(" 取消收藏 ")]
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            display: "flex",
            "align-items": "center",
            "justify-content": "flex-end",
            background: "#ffffff",
          },
        },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "prev, pager, next",
              "page-size": _vm.size,
              total: _vm.total,
            },
            on: { "current-change": _vm.handleCurrentChange },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          height: "2.6rem",
          display: "flex",
          "align-items": "center",
          "margin-top": "1.6rem",
          "font-size": "0.9rem",
          color: "#999999",
        },
      },
      [
        _c("div", { staticStyle: { width: "60%", "padding-left": "1.6rem" } }, [
          _vm._v(" 课程信息 "),
        ]),
        _c(
          "div",
          {
            staticStyle: {
              width: "20%",
              display: "flex",
              "justify-content": "center",
            },
          },
          [_vm._v(" 金额 ")]
        ),
        _c(
          "div",
          {
            staticStyle: {
              width: "20%",
              display: "flex",
              "justify-content": "center",
            },
          },
          [_vm._v(" 操作 ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }