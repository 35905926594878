var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-main1 container" }, [
    _c(
      "div",
      { staticClass: "main-center" },
      [
        _c(
          "el-menu",
          {
            staticClass: "el-menu-vertical-demo",
            attrs: { "default-active": _vm.oldName },
          },
          [
            _c(
              "el-menu-item",
              {
                attrs: { index: "1" },
                on: {
                  click: function ($event) {
                    return _vm.handleSelect(1)
                  },
                },
              },
              [
                _c("i", { staticClass: "el-icon-user" }),
                _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                  _vm._v("个人信息"),
                ]),
              ]
            ),
            _c(
              "el-submenu",
              { attrs: { index: "3" } },
              [
                _c("template", { slot: "title" }, [
                  _c("i", { staticClass: "el-icon-tickets" }),
                  _c("span", [_vm._v("我的考试")]),
                ]),
                _c(
                  "el-menu-item-group",
                  { staticClass: "item-top" },
                  [
                    _c(
                      "el-menu-item",
                      {
                        attrs: { index: "3-1" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSelect(12)
                          },
                        },
                      },
                      [_vm._v("全部考试")]
                    ),
                    _c(
                      "el-menu-item",
                      {
                        attrs: { index: "3-2" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSelect(13)
                          },
                        },
                      },
                      [_vm._v("我参与的")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "el-submenu",
              { attrs: { index: "4" } },
              [
                _c("template", { slot: "title" }, [
                  _c("i", { staticClass: "el-icon-location" }),
                  _c("span", [_vm._v("我的订单")]),
                ]),
                _c(
                  "el-menu-item-group",
                  { staticClass: "item-top" },
                  [
                    _c(
                      "el-menu-item",
                      {
                        attrs: { index: "4-1" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSelect(3)
                          },
                        },
                      },
                      [_vm._v("待付款")]
                    ),
                    _c(
                      "el-menu-item",
                      {
                        attrs: { index: "4-2" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSelect(4)
                          },
                        },
                      },
                      [_vm._v("已付款")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "el-submenu",
              { attrs: { index: "5" } },
              [
                _c("template", { slot: "title" }, [
                  _c("i", { staticClass: "el-icon-folder-checked" }),
                  _c("span", [_vm._v("我的发票 ")]),
                ]),
                _c(
                  "el-menu-item-group",
                  { staticClass: "item-top" },
                  [
                    _c(
                      "el-menu-item",
                      {
                        attrs: { index: "5-1" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSelect(9)
                          },
                        },
                      },
                      [_vm._v("未开票")]
                    ),
                    _c(
                      "el-menu-item",
                      {
                        attrs: { index: "5-2" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSelect(8)
                          },
                        },
                      },
                      [_vm._v("已开票")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _c(
              "el-submenu",
              { attrs: { index: "6" } },
              [
                _c("template", { slot: "title" }, [
                  _c("i", { staticClass: "el-icon-menu" }),
                  _c("span", [_vm._v("我的课程")]),
                ]),
                _c(
                  "el-menu-item-group",
                  { staticClass: "item-top" },
                  [
                    _c(
                      "el-menu-item",
                      {
                        attrs: { index: "6-1" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSelect(5)
                          },
                        },
                      },
                      [_vm._v("学习进度 ")]
                    ),
                    _c(
                      "el-menu-item",
                      {
                        attrs: { index: "6-2" },
                        on: {
                          click: function ($event) {
                            return _vm.handleSelect(6)
                          },
                        },
                      },
                      [_vm._v("我的收藏 ")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
            _c("el-menu-item", { attrs: { index: "7 " } }, [
              _c("i", { staticClass: "el-icon-notebook-2" }),
              _c(
                "span",
                {
                  attrs: { slot: "title" },
                  on: {
                    click: function ($event) {
                      return _vm.handleSelect(10)
                    },
                  },
                  slot: "title",
                },
                [_vm._v("证书档案")]
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "content",
        staticStyle: { flex: "1", "margin-top": "15px", "margin-left": "16px" },
      },
      [
        _c(_vm.currentRole, {
          tag: "component",
          attrs: { "detail-data": _vm.item, "jump-type": _vm.jumpType },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }