"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
var _auth = require("@/utils/auth");
var _NoticeDetails = _interopRequireDefault(require("@/views/personalCenter/NoticeDetails.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'index',
  components: {
    NoticeDetails: _NoticeDetails.default
  },
  data: function data() {
    return {
      querylist: {
        page: 1,
        size: 9999,
        status: '',
        rightLoading: false
      },
      content: [],
      ids: '',
      wer43wewe: false
    };
  },
  created: function created() {
    this.webStation();
  },
  methods: {
    coles: function coles(data) {
      this.wer43wewe = data;
      this.webStation();
    },
    xiangqinags: function xiangqinags(item) {
      this.ids = item.id;
      this.wer43wewe = true;
    },
    webStation: function webStation() {
      var _this = this;
      (0, _request.default)({
        url: 'zyg/message/webStation/list',
        method: 'get',
        params: this.querylist,
        headers: {
          'xId': (0, _auth.getAssocId)()
        }
      }).then(function (res) {
        if (res.code == 200) {
          _this.content = res.data.content;
        } else {
          _this.content = [];
          _this.$message.error(res.message);
        }
      });
    }
  }
};