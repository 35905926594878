"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'NewsTitle',
  data: function data() {
    return {};
  },
  props: {
    title: {
      type: String,
      default: '标题'
    },
    isShowBtn: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  computed: {},
  methods: {
    handleMore: function handleMore() {
      this.$emit('more');
    }
  }
};