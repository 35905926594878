"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.examTitleMapping = void 0;
var examTitleMapping = exports.examTitleMapping = {
  251: "模拟考试系统",
  default: "考试系统",
  210: "模拟考试系统"
};