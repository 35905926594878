"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.string.repeat.js");
require("core-js/modules/es.string.trim.js");
var _mStore = _interopRequireDefault(require("@/store/modules/mStore.js"));
var _request = _interopRequireDefault(require("@/utils/request.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      // value: null
      mUrl: _mStore.default.mUrl,
      pllist: "",
      oid: "",
      pllistNum: "",
      order: "",
      list: {
        value1: null,
        content: ""
      },
      isComment: ""
    };
  },
  created: function created() {
    this.oid = this.$route.params && this.$route.params.id;
    this.plList();
    this.plnumlist();
    this.lisPlu();
  },
  methods: {
    plList: function plList() {
      var _this = this;
      (0, _request.default)({
        url: "/Info/countComment",
        method: "get",
        params: {
          infoId: this.oid,
          type: 5
        }
      }).then(function (response) {
        var data = response.data;
        _this.pllist = data;
      });
    },
    plnumlist: function plnumlist() {
      var _this2 = this;
      (0, _request.default)({
        url: "/Info/pagecomment",
        method: "post",
        params: {
          infoId: this.oid,
          page: 1,
          type: 5,
          size: 100
        }
      }).then(function (response) {
        var data = response.data;
        data.content.forEach(function (element) {
          if (element.score == "0") {
            element.score = 5;
          } else if (element.score == "1") {
            element.score = 1;
          } else if (element.score == "2") {
            element.score = 2;
          } else if (element.score == "3") {
            element.score = 3;
          } else if (element.score == "4") {
            element.score = 4;
          } else if (element.score == "5") {
            element.score = 5;
          }
        });
        _this2.pllistNum = data.content;
        _this2.pllistNum.forEach(function (item) {
          var reg = /^0?1[3|4|5|6|7|8][0-9]\d{8}$/;
          if (reg.test(item.name)) {
            item.name = item.name.replace(item.name.substr(3, 4), "*".repeat(5));
          }
        });
      });
    },
    lisPlu: function lisPlu() {
      var _this3 = this;
      (0, _request.default)({
        url: "/train/config/" + this.oid,
        method: "get"
      }).then(function (response) {
        //  isCollect

        if (response.code == 200) {
          _this3.order = response.data.order;
          _this3.isComment = response.data.isComment;
        }
      });
    },
    // 限制评论内容长度为5-200字符
    checkCommentLength: function checkCommentLength(val) {
      if (!val || val.trim().length < 5 || val.trim().length > 200) {
        this.$message.warning('请输入5-200字符内容');
        return;
      }
      return true;
    },
    discussClick: function discussClick() {
      var _this4 = this;
      var list = this.list;
      if (!this.checkCommentLength(list.content)) return;
      (0, _request.default)({
        url: "/Info/coursecommentadd",
        method: "post",
        data: {
          content: list.content,
          id: this.oid,
          type: 5,
          sourceType: 2,
          score: list.value1
        }
      }).then(function (response) {
        if (response.code == 200) {
          _this4.plnumlist();
          _this4.plList();
          _this4.lisPlu();
          _this4.$message({
            title: "成功",
            message: "评论成功!",
            type: "success",
            duration: 2000
          });
        }
      });
    }
  }
};