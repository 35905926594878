var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fillOutTheForm_content" }, [
    _c(
      "div",
      { staticClass: "top_title" },
      [
        _c("span", [_vm._v(_vm._s(_vm.g8gbiwebn.validateName || "--"))]),
        _c("el-button", { on: { click: _vm.goBack } }, [_vm._v("关闭")]),
      ],
      1
    ),
    _c("div", { staticClass: "btm_content" }, [_c("router-view")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }