var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login_content" }, [
    _c(
      "div",
      { staticClass: "login_div" },
      [
        _vm._m(0),
        _c("el-divider", { attrs: { direction: "vertical" } }),
        _c("div", { staticClass: "login_div_right" }, [
          _c("div", { staticClass: "topTitle" }, [_vm._v("登录")]),
          _c("div", { staticClass: "topBorder" }),
          _c("div", { staticClass: "id_div" }, [
            _c("div", { staticClass: "id_div_title" }, [_vm._v("身份证号")]),
            _c(
              "div",
              { staticClass: "id_div_input" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入您的身份证号" },
                  on: {
                    input: function ($event) {
                      _vm.identity = _vm.replaceChinaChar(_vm.identity)
                    },
                  },
                  model: {
                    value: _vm.identity,
                    callback: function ($$v) {
                      _vm.identity = $$v
                    },
                    expression: "identity",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "id_div_button" },
              [
                _c("el-button", { on: { click: _vm.loginClick } }, [
                  _vm._v("登录"),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "login_div_left" }, [
      _c("div", { staticClass: "topTitle" }, [_vm._v("考试须知")]),
      _c("div", { staticClass: "topBorder" }),
      _c("div", { staticClass: "test_instructions" }, [
        _c("span", [_vm._v("1.遵守考场纪律，服从监考人员指挥。")]),
        _c("span", [_vm._v(" 2.进入考场、手机关机。禁止抽烟，禁止吃零食。")]),
        _c("span", [_vm._v("3.未经工作人员允许，考试禁止随出入考试场。")]),
        _c("span", [_vm._v("4.考试场内禁止大声喧哗，禁止随意走动。")]),
        _c("span", [_vm._v("5.考试中认真答题，不准交头接耳。 ")]),
        _c("span", [_vm._v("6.考试中不准冒名顶替，不准弄虚作假。")]),
        _c("span", [_vm._v("7.注意考场卫生，禁止随地吐痰，禁止乱扔纸 ")]),
        _c("span", [_vm._v("8.爱护公物及考试设备。")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }