"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _allExam = _interopRequireDefault(require("./allExam"));
var _myExam = _interopRequireDefault(require("./myExam"));
var _personalInfo = _interopRequireDefault(require("../../personalcenter/personalInfo.vue"));
var _personalOrder = _interopRequireDefault(require("../../personalcenter/personalOrder.vue"));
var _personalInvoice = _interopRequireDefault(require("../../personalcenter/personalInvoice.vue"));
var _personalStudy = _interopRequireDefault(require("../../personalcenter/personalStudy.vue"));
var _certificate = _interopRequireDefault(require("../../personalcenter/certificate.vue"));
var _personalCollection = _interopRequireDefault(require("../../personalcenter/personalCollection.vue"));
var _examination = _interopRequireDefault(require("../../personalcenter/examination.vue"));
var _appliedOrder = _interopRequireDefault(require("../../personalcenter/order/appliedOrder.vue"));
var _applyingOrder = _interopRequireDefault(require("../../personalcenter/order/applyingOrder.vue"));
var _appliedInvoice = _interopRequireDefault(require("../../personalcenter/invoice/appliedInvoice.vue"));
var _applyingInvoice = _interopRequireDefault(require("../../personalcenter/invoice/applyingInvoice.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  provide: function provide() {
    return {
      handleSelect: this.handleSelect
    };
  },
  name: 'Myexampage',
  components: {
    personalInfo: _personalInfo.default,
    personalOrder: _personalOrder.default,
    personalInvoice: _personalInvoice.default,
    personalStudy: _personalStudy.default,
    personalCollection: _personalCollection.default,
    examination: _examination.default,
    appliedOrder: _appliedOrder.default,
    applyingOrder: _applyingOrder.default,
    appliedInvoice: _appliedInvoice.default,
    applyingInvoice: _applyingInvoice.default,
    certificate: _certificate.default,
    allExam: _allExam.default,
    myExam: _myExam.default
  },
  data: function data() {
    return {
      activeIndex: '1',
      currentRole: _personalInfo.default,
      owuindex: this.$route.query.index,
      oldName: '3-2',
      item: [],
      jumpType: ''
    };
  },
  mounted: function mounted() {
    if (this.owuindex === 7) {
      this.currentRole = _applyingInvoice.default;
      this.oldName = '5-1';
    }
    if (this.owuindex === 5) {
      this.currentRole = _personalStudy.default;
      this.oldName = '6-1';
    }
    if (this.owuindex === 4) {
      this.currentRole = _examination.default;
      this.oldName = '3';
    }
    if (this.$route.params.whichone) {
      this.activeIndex = this.$route.params.whichone;
      this.handleSelect(this.activeIndex);
    }
  },
  created: function created() {
    this.currentRole = _myExam.default;
  },
  methods: {
    handleSelect: function handleSelect(key, item, jumpType) {
      this.activeIndex = key;
      // if(key == this.owuindex){
      //   this.currentRole = personalInfo;
      // }

      if (key === 1) {
        this.currentRole = _personalInfo.default;
      } else if (key === 3) {
        this.currentRole = _appliedOrder.default;
      } else if (key === 4) {
        this.currentRole = _applyingOrder.default;
      } else if (key === 5) {
        this.currentRole = _personalStudy.default;
      } else if (key === 6) {
        this.currentRole = _personalCollection.default;
      } else if (key === 7) {
        this.currentRole = _examination.default;
      } else if (key === 8) {
        this.currentRole = _appliedInvoice.default;
      } else if (key === 9) {
        this.currentRole = _applyingInvoice.default;
      } else if (key === 10) {
        // this.currentRole = certificate
        this.$router.push('/certificatecopy');
      } else if (key === 11) {
        this.item = item;
        this.jumpType = jumpType;
        this.currentRole = _personalOrder.default;
      } else if (key === 12) {
        this.currentRole = _allExam.default;
        this.$router.push('/allexam');
      } else if (key === 13) {
        this.currentRole = _myExam.default;
        this.$router.push('/myexam');
      }
      if (key !== 13 && key !== 12) {
        console.log(key);
        this.$router.push({
          name: 'personalcenterTab',
          params: {
            routekey: key
          }
        });
      }
    }
  }
};