var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "apply-period",
    },
    [
      _c("h5", [_vm._v("学时减免")]),
      _c("section", [
        _c("div", { staticClass: "sction-item" }, [
          _c(
            "div",
            {
              staticClass: "label required",
              staticStyle: { "align-items": "center" },
            },
            [_vm._v(" 减免学时数： ")]
          ),
          _c(
            "div",
            [
              _c("el-input-number", {
                attrs: { size: "medium", min: 1, max: parseInt(_vm.maxHours) },
                model: {
                  value: _vm.classHours,
                  callback: function ($$v) {
                    _vm.classHours = $$v
                  },
                  expression: "classHours",
                },
              }),
              _c("span", { staticStyle: { "margin-left": "5px" } }, [
                _vm._v("学时"),
              ]),
              _c("span", [
                _vm._v("（最多可申请：" + _vm._s(_vm.maxHours) + "学时）"),
              ]),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "sction-item" }, [
          _c("div", { staticClass: "label" }, [_vm._v("申请原因：")]),
          _c(
            "div",
            { staticStyle: { width: "50vw" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 6, maxRows: 6 },
                  placeholder: "请输入内容",
                  "show-word-limit": "",
                  maxlength: 200,
                  resize: "none",
                },
                model: {
                  value: _vm.reason,
                  callback: function ($$v) {
                    _vm.reason = $$v
                  },
                  expression: "reason",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "sction-item" }, [
          _c("div", { staticClass: "label" }, [
            _vm._v("其他附件(最多上传3个)："),
          ]),
          _c(
            "div",
            { staticClass: "attach-box" },
            [
              _c(
                "div",
                { staticClass: "ul" },
                _vm._l(_vm.attachList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "li",
                      on: {
                        click: function ($event) {
                          return _vm.delAttachItem(index)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "left" }, [
                        _c("i", {
                          staticClass: "el-icon-tickets",
                          staticStyle: {
                            "font-size": "18px",
                            "margin-right": "5px",
                          },
                        }),
                        _c("span", { staticClass: "file-name" }, [
                          _vm._v(_vm._s(item.title)),
                        ]),
                        _c("span", { staticClass: "ml30" }, [
                          _vm._v("附件类型：" + _vm._s(item.fileSuffix)),
                        ]),
                        _c("span", { staticClass: "ml30" }, [
                          _vm._v("文件大小：" + _vm._s(item.size) + "MB"),
                        ]),
                      ]),
                      _c("i", {
                        staticClass: "el-icon-delete",
                        staticStyle: { "font-size": "18px", cursor: "pointer" },
                      }),
                    ]
                  )
                }),
                0
              ),
              !_vm.allowAttach
                ? _c(
                    "el-upload",
                    {
                      ref: "upload",
                      attrs: {
                        action: "",
                        "on-change": _vm.onChange,
                        "auto-upload": false,
                        multiple: false,
                        "show-file-list": false,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            icon: "el-icon-circle-plus",
                          },
                        },
                        [_vm._v("添加附件")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "footer",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submitHandler } },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }