"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.array.push.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {},
  components: {},
  data: function data() {
    return {
      identity: ''
    };
  },
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    // document.title = ''
  },
  methods: {
    replaceChinaChar: function replaceChinaChar(val) {
      return val.replace(/[\u4e00-\u9fa5]/g, '').replace(/\s*/g, '');
    },
    loginClick: function loginClick() {
      if (!this.identity) {
        this.$message.warning('请输入身份证号！');
        return false;
      } else {
        var str = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (!str.test(this.identity)) {
          this.$message.warning('证件号码格式不正确，请输入正确的证件号码');
          return false;
        }
      }
      this.loginSubmitData();
    },
    loginSubmitData: function loginSubmitData() {
      var _this = this;
      this.$store.dispatch('user/loginByIdentity', this.identity).then(function (response) {
        if (response.code == 200) {
          _this.$store.dispatch('user/getInfo').then(function (res) {
            _this.$router.push('/examinationSystem/examList/1');
          });
        } else {
          _this.$alert(response.message, '提示', {
            confirmButtonText: '确定',
            callback: function callback(action) {}
          });
        }
      });
    }
  }
};