"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.number.constructor.js");
var _updateInvoice = require("@/api/updateInvoice");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  name: 'InvoiceDetails',
  components: {},
  props: {
    currentContent: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    statusMapCode: {
      type: [Number, String],
      default: 1 // 1 已开票使用 statusMap 2 已换开使用 statusMapForChange
    }
  },
  data: function data() {
    return {};
  },
  created: function created() {
    // console.log('详情对象222', this.currentContent)
  },
  mounted: function mounted() {},
  methods: {
    back: function back() {
      this.$emit('back');
    },
    formatStatus: function formatStatus(index) {
      // 默认statusMapCode为1，1用statusMap，2用forChange
      var map = this.statusMapCode === 1 ? _updateInvoice.statusMap : _updateInvoice.statusMapForChange;
      return map[index] || '--';
    }
  }
};