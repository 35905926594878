var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.visible
        ? _c(
            "el-dialog",
            {
              staticClass: "mChangeHeader",
              attrs: {
                visible: _vm.visible,
                "append-to-body": true,
                "close-on-click-modal": false,
                width: _vm.width,
                "show-close": _vm.showCloses,
                "close-on-press-escape": false,
                "before-close": _vm.close,
                fullscreen: _vm.fulls,
                top: _vm.top,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visible = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "odiv",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("div", { staticClass: "odevtwe" }, [
                    _vm.icon
                      ? _c("img", {
                          staticClass: "oimg",
                          attrs: { src: _vm.icon },
                        })
                      : _vm._e(),
                    _c("span", { staticClass: "ospan" }, [
                      _vm._v(_vm._s(_vm.title)),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "content", style: { height: _vm.height } },
                [_vm._t("content")],
                2
              ),
              _vm.$slots.footer
                ? _c("div", { staticClass: "footer" }, [_vm._t("footer")], 2)
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }