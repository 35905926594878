"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.contactNumber = contactNumber;
exports.getClassCategoryList = getClassCategoryList;
exports.getClassList = getClassList;
exports.getRecentTraining = getRecentTraining;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取分类列表
function getClassCategoryList() {
  return (0, _request.default)({
    method: "get",
    url: "/zyg/web/training/learner/clazz",
    params: {
      type: 2 // 课程
    }
  });
}
function getClassList(_ref) {
  var _ref$page = _ref.page,
    page = _ref$page === void 0 ? 1 : _ref$page,
    _ref$size = _ref.size,
    size = _ref$size === void 0 ? 100 : _ref$size,
    classId = _ref.classId;
  return (0, _request.default)({
    method: "get",
    url: "/zyg/web/training/learner/item/list",
    params: {
      page: page,
      size: size,
      classId: classId
    }
  });
}

// 课程详情页联系电话
function contactNumber() {
  return (0, _request.default)({
    method: "get",
    url: "/zyg/web/training/learner/assocConfig/getGroupDetails",
    params: {
      group: 'educationalServices',
      code: 'mobileConfig'
    }
  });
}

/**
 * 
 *  @returns 返回学员最近学习的3门课程
 */
function getRecentTraining() {
  return (0, _request.default)({
    method: "get",
    url: "/zyg/web/training/learner/item/recentTraining",
    params: {
      fetchNum: 4
    }
  });
}