"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _assoc = require("@/api/assoc");
var _auth = require("@/utils/auth");
var _user = require("@/api/user");
var _request = _interopRequireDefault(require("@/utils/request"));
var state = {
  sidebar: {
    opened: _jsCookie.default.get('sidebarStatus') ? !!+_jsCookie.default.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  // 控制登录弹框显示与隐藏
  isShowNavBarLoginBox: false,
  userState: {
    login: true
  },
  assocInfo: {
    assocToken: ''
  },
  assocName: '',
  assocBanner: '',
  assocFooter: '',
  assocLogo: '',
  assocId: '',
  navList: {},
  code: '',
  tel: "" // 电话
};
var mutations = {
  // 修改navBar登录弹框显示与隐藏
  changeIsShowNavBarLoginBox: function changeIsShowNavBarLoginBox(state, val) {
    state.isShowNavBarLoginBox = val;
  },
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      _jsCookie.default.set('sidebarStatus', 1);
    } else {
      _jsCookie.default.set('sidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    _jsCookie.default.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.assocInfo.assocToken = token;
  },
  SET_ASSOCNAME: function SET_ASSOCNAME(state, assocName) {
    state.assocName = assocName;
  },
  SET_BANNER: function SET_BANNER(state, assocBanner) {
    state.assocBanner = assocBanner;
  },
  SET_FOOTER: function SET_FOOTER(state, assocFooter) {
    state.assocFooter = assocFooter;
  },
  SET_LOGO: function SET_LOGO(state, assocLogo) {
    state.assocLogo = assocLogo;
  },
  SET_ID: function SET_ID(state, assocId) {
    state.assocId = assocId;
  },
  SET_LIST: function SET_LIST(state, navList1) {
    state.navList = navList1;
  },
  SET_CODE: function SET_CODE(state, code) {
    state.code = code;
  },
  SET_TEL: function SET_TEL(state, tel) {
    state.tel = tel;
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var withoutAnimation = _ref3.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref4, device) {
    var commit = _ref4.commit;
    commit('TOGGLE_DEVICE', device);
  },
  domain: function domain(_ref5, device) {
    var commit = _ref5.commit;
    return new Promise(function (resolve, reject) {
      (0, _assoc.getAssocToken)().then(function (response) {
        var data = response.data;
        commit('SET_TOKEN', data.assocToken);
        (0, _auth.setAssocToken)(data.assocToken);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 获取协会信息(banner、footer、assocToken、assocTName、id)
  getAssocInfo: function getAssocInfo(_ref6) {
    var commit = _ref6.commit,
      state = _ref6.state;
    if (state.assocName) return Promise.resolve();
    return new Promise(function (resolve, reject) {
      (0, _user.getAssocInfo)().then(function (response) {
        var data = response.data;
        if (!data) {
          return reject('Verification failed, please Login again.');
        }
        (0, _auth.setAssocId)(data.xid);
        (0, _request.default)({
          url: '/appQrcodeNew?xid=' + (0, _auth.getAssocId)(),
          method: 'get'
        }).then(function (response) {
          if (response.code === 200) {
            commit('SET_CODE', response.data);
          }
        });
        commit('SET_ASSOCNAME', data.assocName);
        commit('SET_BANNER', data.banner);
        commit('SET_FOOTER', data.footer);
        commit('SET_LOGO', data.assocLogo);
        commit('SET_ID', data.xid);
        commit("SET_TEL", data.mobile);
        (0, _user.peixuncaidan22)(state.assocId, 4).then(function (res) {
          console.log(res, 'res');
          commit('SET_LIST', res.data);
        });
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};