var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "daboes" }, [
      _c("div", [
        _c("div", { staticClass: "tabile" }, [
          _vm._v(" " + _vm._s(_vm.dna98anomn.title || "--") + " "),
        ]),
        _c("div", { staticClass: "texbos" }, [
          _vm._v(" " + _vm._s(_vm.dna98anomn.text || "--") + " "),
        ]),
        _c("div", { staticClass: "jikans" }, [
          _vm._v(" 通知时间：" + _vm._s(_vm.dna98anomn.createTime) + " "),
        ]),
      ]),
    ]),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "align-items": "center",
          "justify-content": "space-between",
        },
      },
      [
        _c("p"),
        _c(
          "p",
          { staticStyle: { "margin-right": "12px" } },
          [
            _c(
              "el-button",
              {
                attrs: { plain: "", size: "mini" },
                on: { click: _vm.guanbis },
              },
              [_vm._v("关闭")]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }