var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-wrapper", class: _vm.classObj }, [
    _vm.device === "mobile" && _vm.sidebar.opened
      ? _c("div", {
          staticClass: "drawer-bg",
          on: { click: _vm.handleClickOutside },
        })
      : _vm._e(),
    _c(
      "div",
      { staticClass: "main-container" },
      [
        _c(
          "div",
          { class: { "fixed-header": _vm.fixedHeader } },
          [_c("navbar")],
          1
        ),
        _c("app-main"),
        _c("div", [_vm.isShow ? _c("Footbar") : _vm._e()], 1),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }