"use strict";

var _interopRequireDefault = require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.beforeUploadLimit = beforeUploadLimit;
exports.formatData = formatData;
exports.formatMoney = formatMoney;
exports.formatPhone = formatPhone;
exports.getNextDate = getNextDate;
exports.keepChinaChar = keepChinaChar;
exports.keepNumber = keepNumber;
exports.keepPlaces = keepPlaces;
exports.openNotify = openNotify;
exports.removeAllSpaces = removeAllSpaces;
exports.replaceChinaChar = replaceChinaChar;
var _toConsumableArray2 = _interopRequireDefault(require("E:/\u963F\u5C71\u7684\u9879\u76EE/pc\u57F9\u8BAD\uFF0819090\uFF09/hangxintong-pctrain/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
require("core-js/modules/es.number.to-fixed.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.test.js");
require("core-js/modules/es.string.replace.js");
require("core-js/modules/es.string.repeat.js");
require("core-js/modules/es.array.slice.js");
require("core-js/modules/es.function.name.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.join.js");
require("core-js/modules/es.array.map.js");
/* 保留小数方法
 * @小李
 * 参数1：num=>要转换的值，可以是字符串类型的数字
 * 参数2：length=>要保留的小数位数
 */
function keepPlaces(num, length) {
  if (num === undefined) {
    return '0.00';
  }
  if (typeof num !== 'number') {
    num = parseFloat(num);
  }
  return parseFloat(num.toFixed(length));
}

/* 图片加载失败替换为默认图片
 * @小李
 * 参数1：event=>要替换图片的DOM元素
 * 参数2：type=>转换的类型 1=>头像  2=>课程图片
 */
// export function replaceDefaultPicture(event, type) {
//   const userIconDefault = require('@/assets/img/tup.png')
//   const trainPicDefault = require('@/assets/img/jinsalogoas.png')
//   const formerrimg = require('@/assets/img/file.png')
//   const img = event.srcElement
//   if (type === 1) {
//     img.src = userIconDefault
//   } else if (type === 2) {
//     img.src = trainPicDefault
//   } else if (type === 3) {
//     img.src = formerrimg
//   }
//   img.onerror = null // 防止闪图
// }

/*
 * 时间戳格式化函数
 * @小李
 * 参数1：shijianchuo=>要格式化的时间戳
 */
function add0(m) {
  return m < 10 ? '0' + m : m;
}
function formatData(shijianchuo) {
  // shijianchuo是整数，否则要parseInt转换
  var time = new Date(shijianchuo);
  var y = time.getFullYear();
  var m = time.getMonth() + 1;
  var d = time.getDate();
  var h = time.getHours();
  var mm = time.getMinutes();
  var s = time.getSeconds();
  return y + '-' + add0(m) + '-' + add0(d);
  // return y + '-' + add0(m) + '-' + add0(d) + ' ' + add0(h) + ':' + add0(mm) + ':' + add0(s);
}

/* 手机号隐藏中间4位方法
 * @小李
 * 参数1：phone=>要替换的手机号
 */
function formatPhone(phone) {
  if (phone) {
    var str = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;
    if (!str.test(phone)) {
      console.log('formatPhone接收的手机号异常');
      return false;
    }
    return phone.replace(phone.substr(3, 5), '*'.repeat(5));
  } else {
    return false;
  }
}

/* 打开notify提示框
 * @小李
 * 参数1：type=>提示类型1:success 2:error  3:warning   4:info
 * 参数1：message=>提示文本
 */
function openNotify(type, message, title) {
  return this.$notify({
    title: title || (type === 1 ? '成功' : type === 2 ? '失败' : type === 3 ? '警告' : '提示'),
    message: message,
    type: type === 1 ? 'success' : type === 2 ? 'error' : type === 3 ? 'warning' : 'info'
  });
}

/* 获取日期前一天，后一天
 * @小李
 * 参数1：date 代表指定的日期，格式：2018-09-27
 * 参数2：day 传-1表始前一天，传1表始后一天
 */
function getNextDate(date, day) {
  var dd = new Date(date);
  dd.setDate(dd.getDate() + day);
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1;
  var d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate();
  return y + '-' + m + '-' + d;
}
/**
 * 金钱格式化
 * @小李
 * 参数：str 要格式化的金钱
 */
function formatMoney(number, places, symbol, thousand, decimal) {
  number = number || 0;
  places = !isNaN(places = Math.abs(places)) ? places : 2;
  symbol = symbol !== undefined ? symbol : '';
  thousand = thousand || ',';
  decimal = decimal || '.';
  var negative = number < 0 ? '-' : '';
  var i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + '';
  var j = (j = i.length) > 3 ? j % 3 : 0;
  return symbol + negative + (j ? i.substr(0, j) + thousand : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : '');
}
/**
 * 文件上传大小及类型限制
 * @param {*} file 上传的文件
 * @param {*} size 上传大小限制（MB）
 * @param {*} type 上传类型限制（img、word等）
 * @returns 返回Boolean
 */
var path = require('path');
function beforeUploadLimit(file) {
  var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var size = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 10;
  var fileType = path.extname(file.name);
  var fileTypeList = [];
  var typeFlag;
  if (type === '') {
    typeFlag = true;
  } else {
    type.split(',').forEach(function (item) {
      if (item.toLowerCase() == 'excel') {
        fileTypeList = [].concat((0, _toConsumableArray2.default)(fileTypeList), ['.xlsx', '.xls']);
      }
      if (item.toLowerCase() == 'word') {
        fileTypeList = [].concat((0, _toConsumableArray2.default)(fileTypeList), ['.doc', '.docx']);
      }
      if (item.toLowerCase() == 'png') {
        fileTypeList = [].concat((0, _toConsumableArray2.default)(fileTypeList), ['.png']);
      }
      if (item.toLowerCase() == 'jpg') {
        fileTypeList = [].concat((0, _toConsumableArray2.default)(fileTypeList), ['.jpg']);
      }
      if (item.toLowerCase() == 'pdf') {
        fileTypeList = [].concat((0, _toConsumableArray2.default)(fileTypeList), ['.pdf']);
      }
    });
    typeFlag = fileTypeList.indexOf(fileType) !== -1;
  }
  var isLt2M = file.size / 1024 / 1024 < size;
  if (!typeFlag) {
    this.openNotify(3, "\u53EA\u80FD\u4E0A\u4F20".concat(type.split(',').map(function (item) {
      item.toLowerCase().replace(item[0], item[0].toUpperCase());
      return item;
    }).join('/'), "\u6587\u4EF6!"));
    return false;
  }
  if (!isLt2M) {
    this.openNotify(3, "\u9644\u4EF6\u5927\u5C0F\u4E0D\u80FD\u8D85\u8FC7".concat(size, "MB!"));
    return false;
  }
  return typeFlag && isLt2M;
}

/* 去除字符串中文字符并去除所有空格
 * @小李
 * 参数1：val=>传入字符串
 */
function replaceChinaChar(val) {
  return val.replace(/[\u4e00-\u9fa5]/g, '').replace(/\s*/g, '');
}

/* 仅保留中文字符并去除所有空格
 * @小李
 * 参数1：val=>传入字符串
 */
function keepChinaChar(val) {
  return val.replace(/[^\u4E00-\u9FA5]/g, '').replace(/\s*/g, '');
}

/* 仅保留数字并去除所有空格
 * @小李
 * 参数1：val=>传入字符串
 */
function keepNumber(val) {
  return val.replace(/[^\d]/g, '');
}

/* 字符串去除所有空格
 * @小李
 * 参数1：val=>传入字符串
 */
function removeAllSpaces(val) {
  return val.replace(/\s*/g, '');
}