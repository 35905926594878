var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wapper",
    },
    [
      _c("div", { staticClass: "top" }, [
        _c("div", [_vm._v("订单详情")]),
        _c(
          "div",
          {
            staticClass: "right",
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v("返回订单列表")]
        ),
      ]),
      _c(
        "div",
        { staticClass: "info" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "cell-style": { "text-align": "center" },
                "header-cell-style": {
                  "text-align": "center",
                  background: "#eef1f6",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "orderNum", label: "订单编号", width: "180" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "realTotalMoney",
                  label: "总金额",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.realTotalMoney) + "元"),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "address", label: "状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.payType == 0
                          ? _c("span", [_vm._v("个人订单")])
                          : _vm._e(),
                        scope.row.payType == 1
                          ? _c("span", [_vm._v("企业订单")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "下单时间" },
              }),
              _c("el-table-column", {
                attrs: { prop: "address", label: "支付类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status == 1
                          ? _c("span", [_vm._v("待付款")])
                          : _vm._e(),
                        scope.row.status == 2
                          ? _c("span", [_vm._v("已付款")])
                          : _vm._e(),
                        scope.row.status == 3
                          ? _c("span", [_vm._v("取消")])
                          : _vm._e(),
                        scope.row.status == 4
                          ? _c("span", [_vm._v("支付失败")])
                          : _vm._e(),
                        scope.row.status == 5
                          ? _c("span", [_vm._v("已退款")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "address", label: "支付方式" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.payWay == 1
                          ? _c("span", [_vm._v("支付宝")])
                          : _vm._e(),
                        scope.row.payWay == 2
                          ? _c("span", [_vm._v("微信")])
                          : _vm._e(),
                        scope.row.payWay == 3
                          ? _c("span", [_vm._v("银行卡")])
                          : _vm._e(),
                        scope.row.payWay == 4
                          ? _c("span", [_vm._v("对公账户转账")])
                          : _vm._e(),
                        scope.row.payWay == 5
                          ? _c("span", [_vm._v("线下付款")])
                          : _vm._e(),
                        scope.row.payWay == 6
                          ? _c("span", [_vm._v("抵用券付款")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.down },
            },
            [_vm._v("批量下载")]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", "text-align": "center" },
              attrs: {
                data: _vm.couponData,
                border: "",
                "cell-style": { "text-align": "center" },
                "header-cell-style": {
                  "text-align": "center",
                  background: "#eef1f6",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "goodsContent", label: "兑换码", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { prop: "goodsPrice", label: "面额", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(scope.row.goodsPrice) + "元"),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "address", label: "使用状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.goodsStatus == 0
                          ? _c("span", [_vm._v("未使用")])
                          : _vm._e(),
                        scope.row.goodsStatus == 1
                          ? _c("span", [_vm._v("已使用")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "trainRecordInfo.userName", label: "使用人" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.trainRecordInfo
                          ? _c("span", [
                              _vm._v(
                                _vm._s(scope.row.trainRecordInfo.userName)
                              ),
                            ])
                          : _c("span", [_vm._v(" -- ")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "trainRecordInfo.trainName", label: "课程名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.trainRecordInfo
                          ? _c("span", [
                              _vm._v(
                                _vm._s(scope.row.trainRecordInfo.trainName)
                              ),
                            ])
                          : _c("span", [_vm._v(" -- ")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "address", label: "课时进度" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.trainRecordInfo
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.trainRecordInfo.trainStudyData
                                      .classHours
                                  ) +
                                  "/ " +
                                  _vm._s(
                                    scope.row.trainRecordInfo.trainStudyData
                                      .requiredTarget
                                  ) +
                                  "课时 "
                              ),
                            ])
                          : _c("span", [_vm._v(" -- ")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "address", label: "查看证书" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.trainRecordInfo &&
                        scope.row.trainRecordInfo.certLogId
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.getCertInfo(
                                      scope.row.trainRecordInfo.certLogId
                                    )
                                  },
                                },
                              },
                              [_vm._v("查看证书")]
                            )
                          : _c("span", [_vm._v(" -- ")]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }