var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "detailContainer" }, [
    _c("div", { staticClass: "tips" }, [
      _vm._v("发票问题请联系:" + _vm._s(_vm.$store.getters.assocName)),
    ]),
    _c("div", { staticClass: "titleBox" }, [
      _c("span", [_vm._v("发票详情")]),
      _c("span", { staticClass: "back", on: { click: _vm.back } }, [
        _vm._v("返回上一页"),
      ]),
    ]),
    _c("div", { staticClass: "infos" }, [
      _c("div", { staticClass: "topBox" }, [
        _c("div", { staticClass: "leftTopBox" }, [
          _c("div", { staticClass: "infoItem" }, [
            _c("span", [_vm._v(" 项目名称： ")]),
            _c("span", { staticClass: "infoDark" }, [
              _vm._v(" " + _vm._s(_vm.currentContent.orderName) + " "),
            ]),
          ]),
          _c("div", { staticClass: "infoItem" }, [
            _c("span", [_vm._v(" 订单编号： ")]),
            _c("span", { staticClass: "infoDark" }, [
              _vm._v(_vm._s(_vm.currentContent.orderId)),
            ]),
          ]),
          _c("div", { staticClass: "infoItem" }, [
            _c("span", [_vm._v(" 订单金额： ")]),
            _c("span", { staticClass: "price" }, [
              _vm._v("¥" + _vm._s(_vm.currentContent.goodsPrice)),
            ]),
          ]),
          _c("div", { staticClass: "infoItem" }, [
            _c("span", [_vm._v(" 订单状态： ")]),
            _c("span", { staticClass: "infoDark" }, [
              _vm._v(_vm._s(_vm.formatStatus(_vm.currentContent.state))),
            ]),
          ]),
          _c("div", { staticClass: "infoItem" }, [
            _c("span", [_vm._v(" 下单时间： ")]),
            _c("span", { staticClass: "infoDark" }, [
              _vm._v(_vm._s(_vm.currentContent.orderDate)),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "bottomBox" }, [
        _c("div", { staticClass: "infoItem" }, [
          _c("span", [_vm._v(" 发票抬头： ")]),
          _c("span", { staticClass: "infoDark" }, [
            _vm._v(" " + _vm._s(_vm.currentContent.applicationName) + " "),
          ]),
        ]),
        _vm._m(0),
        _c("div", { staticClass: "infoItem" }, [
          _c("span", [_vm._v(" 收票邮箱： ")]),
          _c("span", { staticClass: "infoDark" }, [
            _vm._v(" " + _vm._s(_vm.currentContent.applicationEmail) + " "),
          ]),
        ]),
        _vm.statusMapCode === 2
          ? _c("div", { staticClass: "infoItem" }, [
              _c("span", [_vm._v(" 换开原因： ")]),
              _c("span", { staticClass: "infoDark" }, [
                _vm._v(" " + _vm._s(_vm.currentContent.reApplyRemarks) + " "),
              ]),
            ])
          : _vm._e(),
        _vm.currentContent.auditingResult === 0
          ? _c("div", { staticClass: "infoItem" }, [
              _c("span", [_vm._v(" 拒绝原因： ")]),
              _c("span", { staticClass: "infoDark" }, [
                _vm._v(" " + _vm._s(_vm.currentContent.auditComments) + " "),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "infoItem" }, [
      _c("span", [_vm._v(" 发票类型： ")]),
      _c("span", { staticClass: "infoDark" }, [_vm._v(" 增值税电子普通发票 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }