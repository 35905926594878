var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "exchangeForm" }, [
    _c("div", { staticClass: "titleBox" }, [
      _vm._m(0),
      _c("div", [
        _c("span", { staticClass: "back", on: { click: _vm.back } }, [
          _vm._v("返回上一页"),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "contentBox" }, [
      _c("div", { staticClass: "info" }, [
        _c("div", { staticClass: "infoItem" }, [
          _c("span", [_vm._v(" 项目名称： ")]),
          _c("span", { staticClass: "infoDark" }, [
            _vm._v(" " + _vm._s(_vm.currentContent.goodsTitle) + " "),
          ]),
        ]),
        _c("div", { staticClass: "infoItem" }, [
          _c("span", [_vm._v(" 可开票金额： ")]),
          _c("span", { staticClass: "infoDark price" }, [
            _vm._v(" ¥" + _vm._s(_vm.currentContent.realTotalMoney) + " "),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "formBox" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "发票类型", prop: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择发票类型" },
                      model: {
                        value: _vm.invoiceType,
                        callback: function ($$v) {
                          _vm.invoiceType = $$v
                        },
                        expression: "invoiceType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "增值税电子普通发票", value: "" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发票抬头", prop: "applicationName" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 255 },
                    model: {
                      value: _vm.ruleForm.applicationName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "applicationName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.applicationName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公司税号", prop: "applicationTaxNum" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 20 },
                    model: {
                      value: _vm.ruleForm.applicationTaxNum,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "applicationTaxNum",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.applicationTaxNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收票邮箱", prop: "applicationEmail" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 64 },
                    model: {
                      value: _vm.ruleForm.applicationEmail,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "applicationEmail",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.applicationEmail",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "公司电话", prop: "applicationPhone" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: 16 },
                    model: {
                      value: _vm.ruleForm.applicationPhone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "applicationPhone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.applicationPhone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    maxlength: 255,
                    label: "开户银行",
                    prop: "applicationBank",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.applicationBank,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "applicationBank",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.applicationBank",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    maxlength: 32,
                    label: "银行账号",
                    prop: "applicationBankAccount",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.applicationBankAccount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "applicationBankAccount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.applicationBankAccount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    maxlength: 200,
                    label: "公司地址",
                    prop: "applicationAddress",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.applicationAddress,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "applicationAddress",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.applicationAddress",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开票备注", prop: "invoicingRemarks" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      "show-word-limit": "",
                      rows: 4,
                      maxlength: 50,
                    },
                    model: {
                      value: _vm.ruleForm.invoicingRemarks,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "invoicingRemarks",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.invoicingRemarks",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { disabled: _vm.disabled, type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("提交申请")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "titleText" }, [_vm._v("开票申请")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }